<template lang="html">
   <div class="blacklists">
      <ModalForm :showModal="showModal" :action="Blacklist.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Blacklist.wizard.pages" 
               :action="Blacklist.wizard.action" 
               :buttonText="Blacklist.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createBlacklist()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Blacklist
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Blacklist.table.table_fields"
         :icon="Blacklist.table.icon"
         :title="Blacklist.table.title"
         :subtitle="Blacklist.table.subtitle"
         :tableData="blacklists" 
         :searchFields="Blacklist.table.searchFields"
         :rowData="Blacklist.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.Blacklist = Models.Blacklist

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.Blacklist.wizard.action){
               await this.saveBlacklist(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            Blacklist: {},
            blacklists: [],
            showModal: false
         }
      },
      methods: {
         fetchblacklists(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "blacklist",
                     action: "list_blacklist",
                     data: {},
                     meta_data: {}, 
                  });
                  console.log("blacklist", res);
                  this.blacklists = res.data.result.blacklists.map(item => {
                      item.client_id = item.client_det[0].names || item.client_id
                      return item
                  })
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },

        fetchClients() {
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        action: "list",
                        resource: "client",
                        data: {},
                        meta_data: {},
                    });

                    let pages = this.Blacklist.wizard.pages
                    pages[0].forms[0].options = utils.appendOptions(
                        res.data.result.client_list,
                        "client_id",
                        "names"
                    )
                
                    resolve(true)
                } catch (err) {
                    reject(err)
                }
            });
			
		},
        
         createBlacklist() {
            this.showModal = true
         },
         saveBlacklist(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "blacklist",
                     action: "create_blacklist",
                     data: {
                        blacklist_details : data.blacklist_details, 
                        blacklist_remarks : data.blacklist_remarks, 
                        client_id : data.client_id,
                    },
                     meta_data: {},
                  });

                  await this.fetchblacklists()
                  notification.notify('success', 'Blacklist Added', `The Blacklist ${data.blacklist_remarks} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.Blacklist.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.Blacklist.wizard.action})
               }
            })
         }
      },
      async mounted(){
         await this.fetchblacklists()
         await this.fetchClients()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .blacklists{
      
   }
</style>