<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Opening Template</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add Account Opening Template</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="code">Template Code</label>
      <input type="text" class="form-control" id="code" placeholder="Code" v-model="actOpnTemplate.template_code">
    </div>
    <div class="form-group col-md-6">
      <label for="name">Template Name</label>
      <input type="text" class="form-control" id="name" v-model="actOpnTemplate.template_name" placeholder="Name">
    </div>
  </div>
    <div class="form-row">
        <div class="form-group col-md-6">
        <label for="description">Description</label>
        <input type="text" class="form-control" id="description" v-model="actOpnTemplate.template_description" placeholder="Description">
        </div>
            <div class="form-group col-md-6">
            <label for="name">Account Prefix</label>
            <input type="text" class="form-control" id="name" v-model="actOpnTemplate.prefix" placeholder="Name">
            </div>
    </div>  
      <div class="form-row">
        <div class="form-group col-md-6">
            <label >Select Sub General Ledger</label>
            <select class="custom-select" v-model="subgl_header_system_ref">
            <option v-for="gl in subgls" v-bind:value="gl.sub_gl_header_system_ref">
                {{ gl.sub_gl_header_name }}
            </option>
            </select>
            </div>

            <div class="form-group col-md-6">
                    <label for="description">Select Entity Type</label>
            <select class="custom-select" v-model="entityType">
            <option v-for="en in entities" v-bind:value="en.code">
                {{ en.name }}
            </option>
            </select>
            </div>
          </div>    
        <div class="form-group">
            <label for="description">Select Currency</label>
            <select class="custom-select" v-model="currency_system_ref">
            <option v-for="curr in currencies" v-bind:value="curr.currency_system_ref">
                {{ curr.name }}
            </option>
            </select>
        </div>
         <div class="form-group">
            <label for="description">Select Account Limit</label>
            <select class="custom-select" v-model="account_limit_system_ref">
            <option v-for="lim in Accountlimits" v-bind:value="lim.account_limit_system_ref">
                {{ lim.name }}
            </option>
            </select>
       </div>

        <div class="form-group">
            <label for="description">Select Account Statement</label>
            <select class="custom-select" v-model="statement_system_ref">
            <option v-for="actsmt in statements" v-bind:value="actsmt.account_statement_system_ref">
                {{ actsmt.name }}
            </option>
            </select>
       </div>

        <div class="form-group">
            <label for="description">Select Account Status</label>
            <select class="custom-select" v-model="account_status_system_ref">
            <option v-for="actst in Acountstatus" v-bind:value="actst.account_status_system_ref">
                {{ actst.account_status_name }}
            </option>
            </select>
       </div>


        <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveActOpnTmpl()" class="btn btn-primary">Save Account Opening Template</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             actOpnTemplate:{
                template_code: "",
                template_name:"",
                template_description:"",
                prefix:""
             },
             currency_system_ref:"",
             currencies:[],
             account_limit_system_ref:"",
             Accountlimits:[],
             account_status_system_ref:"",
             Acountstatus:[],
             subgl_header_system_ref:'',
             subgls:[],
             statement_system_ref:'',
             statements:[],
             entityType:'',
             entities:[
                 {
                     "name":"Underwriter",
                     "code":"UNDERWRITER"
                 },
                {
                     "name":"Client",
                     "code":"CLIENT"
                 },
                {
                     "name":"Bank",
                     "code":"BANK"
                 },
                {
                     "name":"Intermediary",
                     "code":"INTERMEDIARY"
                 },
                {
                     "name":"ServiceProvider",
                     "code":"SERVICEPROVIDER"
                 },
                      {
                     "name":"System",
                     "code":"SYSTEM"
                 },
                      {
                     "name":"School",
                     "code":"SCHOOL"
                 }
             ]

         }

      },
    methods: {
       async saveActOpnTmpl () {
          try {
					let res = await req.callServerSecure({
						resource: "entity_account_template",
						action: "add",
						data: {
							template_code: this.actOpnTemplate.template_code,
							template_name:this.actOpnTemplate.template_name,
							template_description:this.actOpnTemplate.template_description,
							sub_gl_header_system_ref: this.subgl_header_system_ref,
							account_limit_system_ref: this.account_limit_system_ref,
							account_status_system_ref: this.account_status_system_ref,
							account_statement_system_ref: this.statement_system_ref,
							currency_system_ref: this.currency_system_ref,
							entity_type: this.entityType,
							account_generation:{
                                    prefix:this.actOpnTemplate.prefix
							}
						},
						meta_data: {}
					});

               notification.notify('success', 'Account Opening Template Added', `The account template ${this.actOpnTemplate.template_name} was successfully added`)
                this.$router.push({ name: 'accounting.actopntemplate.list' })
  
          }
          catch (err) {
              console.log(err)
                  notification.notify('error', err)
          }
        },
        fetchCurrencies(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "currency",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.currencies = res.data.result.currencies
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
        fetchAccountLimits(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_limit",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.Accountlimits = res.data.result.account_limits
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
        fetchAccountStatus(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_status",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.Acountstatus = res.data.result.account_statuses
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
   fetchSubGl () {
        return new Promise(async (resolve,reject) => {
         try {
         let resp = await req.callServerSecure({
            resource: "sub_gl",
            action: "list",
            data: {},
            meta_data: {},
          });

                  this.subgls=resp.data.result.sub_gl_headers
                  resolve()
         }
         catch (err) {
         console.log(err)
         resolve()
         }
        })
     },
      fetchStatements(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_statement",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.statements = res.data.result.account_statements
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
    }, 
    async mounted(){
        await this.fetchCurrencies();
        await this.fetchAccountLimits();
        await this.fetchAccountStatus();
        await this.fetchSubGl();
        await this.fetchStatements()
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>