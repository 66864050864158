<template lang="html">
    <div class="page-profile">
        	<div class="app-loader" v-show="showLoader">
				<div class="center-text">
					<div class="df-logo pulsate-css">
						<div class="f-l">Nexus</div>
						<div class="f-l tx-primary smaller pl-2">Admin</div>
					</div>
				</div>
			</div>

        <CustomNav />

        	<div class="content content-fixed full-content">
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <!-- <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <Breadcrumb />
                        <h4 class="mg-b-0 tx-spacing--1">Welcome to Dashboard</h4>
                    </div>
                    <div class="d-none d-md-block">
                        <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail" class="wd-10 mg-r-5"></i> Email</button>
                        <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer" class="wd-10 mg-r-5"></i> Print</button>
                        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file" class="wd-10 mg-r-5"></i> Generate Report</button>
                    </div>
                </div> -->

                <transition>
                    <router-view/>
                </transition>
            </div>
        	</div>

        <CustomFooter />
    </div>
</template>

<script>
export default {
  beforeCreate() {
    if ($session.get("token") === null) {
      this.$router.push({ name: "LogIn" });
      notification.notify(
        "warning",
        "User Not Logged In",
        "Log In to continue"
      );
    }
  },
  created() {
    Event.$on("token:not-found", (isToThrow) => {
      if (isToThrow) {
        this.$router.push({ name: "LogIn" });
        notification.notify(
          "warning",
          "User Not Logged In",
          "Log In to continue"
        );
      }
    });

    Event.$on("loader:show", (status) => {
      this.showLoader = status;
      // feather.replace();
    });
  },
  data() {
    return {
      showLoader: true,
    };
  },
};
</script>

<style lang="less">
.layout {
}
</style>