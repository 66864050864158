

<script >
import { ComponentA } from '@frontend/components';
export default {
     data (){
        return {
          clients: [],
          underwriters: [],
          serviceProviders: [],
          users: []
        }
     },
	 components: {
       ComponentA
     },
	 methods: {
        fetchClients() {
			return new Promise(async (resolve,reject) => {
				let res = await get_req.fetchClients()
				this.clients = res.client_list
				
				resolve(true)
			})
		},
        fetchUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "user",
            action: "all_users",
            data: {},
            meta_data: {},
          });
          console.log(res);
          this.users = res.data.result.users.map((item) => {
            item.image = domain + item.profile_photo_path;
            return item;
          });
          resolve(true);
        } catch (err) {
          notification.notify(
            "error",
            "Fetching Insurance Service",
            `Fetching Insurance Service`
          );
          reject(err);
        }
      });
    },
	 },
	async mounted() {
        await this.fetchClients()
        await this.fetchUsers()
		await this.$nextTick()
	
		Event.$emit('loader:show', false)
	}
};
</script>

<template lang="html">
    <div class="dashboard">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
				
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Welcome to Admin Dashboard</h4>
					  <h6 class="mg-b-0 tx-spacing--1"> Hi</h6>
				<ComponentA msg="Welcome to the new Dashboard"/>     
            </div>

         

            </div>

            <div class="row row-xs">
            <div class="col-12">
                <div class="row justify-content-md-center">
                    <div class="col-12 col-lg-4">
                        <div class="card card-body">
                            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Number of Clients</h6>
                            <div class="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ clients.length }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div class="card card-body">
                            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Number of Service Providers</h6>
                            <div class="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ serviceProviders.length }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div class="card card-body">
                            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Number of Underwriters
                                </h6>
                            <div class="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ underwriters.length }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-xl-12 mg-t-10">
                <div class="card mg-b-10">
                    <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                        <div>
                            <h6 class="mg-b-5">Users List</h6>
                        </div>
                        <div class="d-flex mg-t-20 mg-sm-t-0">
                        </div>
                    </div><!-- card-header -->
                    <!-- card-body -->
                    <div class="table-responsive ">
                        <table class="table table-dashboard table-hover mg-b-0 m-8">
                            <thead>
                                <tr>
                                    <th class="text-left">No</th>
                                    <th class="text-left">Username</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Entity</th>
                                    <th class="text-left">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr v-for="(user, index) in users">
                                    <td class="tx-medium text-left tx-semibold">{{ index + 1  }}</td>
                                    <td class="tx-medium text-left tx-semibold">{{ user.username  }}</td>
                                    <td class="text-left tx-semibold tx-small">{{ user.registration_status }}</td>
                                    <td class="tx-small text-left tx-semibold">{{ user.entity_id }}</td>
                                    <td class="tx-small tx-semibold">{{ user.email }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div><!-- table-responsive -->
                </div><!-- card -->

            </div>          
       </div>
    </div>
</template>

<style lang="less">
.dashboard {
	#flotChart {
		width: 100%;
		// height: 380px;
	}
	#flotChart2 {
		width: 100%;
		// height: 225px;
    }
    .tx-big{
        font-size: 32px;
        font-weight: 600;
    }
    .tx-center{
        text-align: center;
    }
}
</style>