<template>
  <div class="add-Holiday">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Holiday</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new holiday</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="Holiday.wizard.pages"
          :action="Holiday.wizard.action"
          :buttonText="Holiday.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.Holiday = Models.Holiday;

    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Holiday.wizard.action) {
     
       await this.saveHoliday(payload.formData);
      }
    });
  },
  data() {
    return {
      Holiday: {},
    };
  },
  methods: {
    fetchHolidays() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            "resource":"holiday",
	"action":"list",
    "data" : {
      
    },
            meta_data: {},
          });

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    saveHoliday(data) {
      return new Promise(async (resolve, reject) => {
    
        const {name, type, description,event_occurence,day, month, year}= data
        try {
          let res = await req.callServerSecure({
       "resource":"holiday",
	"action":"create",
    "data" : {
        "holiday_name":name,
        "holiday_description":description,
        "holiday_type":"normal",
        "holiday_occurence":event_occurence,
        "holiday_date":{
            "day":day,
            "month":month,
            "year":year
        }
    },
            meta_data: {},
          });

          await this.fetchHolidays();
          notification.notify(
            "success",
            "Holiday Added",
            `The Holiday ${data.name} was successfully added`
          );
              this.$router.push({
        name: "Configs-Calendar-Holidays",
      
      });
         
       
            resolve(true)
        } catch (err) {
          reject(err)
    
        }
      });
    },
  },
  async mounted() {

     Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>