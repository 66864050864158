
<template lang="html">
    <div class="contents">

              <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Upload Crsp Model</li>
                    </ol>
                </nav>    
            </div>  
        </div>
    

  
                 <div class="contents">
                       
           <div class="row">
              <div class="col-sm-8 offset-sm-2">
                  <fieldset class="form-fieldset">
                  <legend>Crsp Model Upload Details</legend>
                     <div class="form-row">
                           <div class="form-group col-md-7">
                              <div class="custom-file">
                                 <input type="file" class="custom-file-input" id="fileSelector" name="files[]" @change="onFileSelected" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                                 <label class="custom-file-label" for="customFile">Upload File</label>
                              </div>
                                <figure class="pos-relative col-md-4" v-if="showImage" >
                                <p>{{form.placeholder}}</p>
                
                           </figure>
                            </div>

                         

                          </div>    
                        
                   
                     </fieldset>  
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                 <button type="button" class="btn btn-primary" @click="saveUpload()">Save Upload</button>
                              </div>
                            
                           </div>     
                </div> 
            </div> 
                     <CustomTable :tableFields="CrspModel.table.table_fields" 
                        :tableData="models" 
                        :title="CrspModel.table.title" 
                        :subtitle="CrspModel.table.subtitle" 
                        :searchFields="CrspModel.table.searchFields"
                        :rowData="CrspModel.table.rowData">
                  
                     </CustomTable>
		
      </div>
     </div>
</template>
<script>
export default {
   async created(){
         this.CrspModel = Models.crsp_model_upload_model;

   },
   data() {
		 return {
          file:'',
          countries:[],
          selectedcountry:'',
          showImage:false,
           form: {
                placeholder: '',
                model: '',
                url:''
            },
            models:[]
       }
   },

   methods: {
       async saveUpload () {
          try {
          /* let valii= await this.$validator.validate();
                     if(!valii) {
                       return reject(" Validation Error ");
                     }
                     */
                     Event.$emit('loader:show', true)
                    let respData= await this.uploadDoc();
                    console.log(" respData" ,respData);
                    //this.banks =respData.data.result.data
                    Event.$emit('loader:show', false)
                     notification.notify("success","Upload",`Upload  success`);
          }
          catch(err) {
              Event.$emit('loader:show', false)
             console.log(" upload error " ,err)
           notification.notify("error","Bank",` Upload Errored , Kindly retry Later`);
          
          }
            
      },
      uploadDoc () {
               return new Promise(async (resolve,reject) => {
                  try {
                        var formDataToUpload = new FormData();
                        formDataToUpload.append("file", this.file);   
                        formDataToUpload.append("file_type", "crs_model_upload");                                    
                        let respData=await req.callServerMultipart('/api/upload/v1/admin/crsp/model',formDataToUpload);
                        return resolve(respData)
                        }
                        catch (err) {
                            return reject(err)
                        }
            })
      },
      clearClass () {

      },
      onFileSelected(event){
              Array.from(event.target.files).forEach(file => {
                this.file=file
                 this.form.url=URL.createObjectURL(file);
                  this.form.placeholder = file.name
                  this.showImage=true;  
              });
      },
   fetchCountries() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "country",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.countries=res.data.result.countries;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
   fetchUploadedModels() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "uploads",
            action: "list-crsp-model",
            data: {},
            meta_data: {},
          });


          this.models=res.data.result;


          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
   },
    async mounted(){
    //   await this.fetchCountries();
       await this.fetchUploadedModels();
		Event.$emit('loader:show', false)
	}
    };
</script>

<style lang="less">
.uploadBankview {
}
</style>