module.exports = {
    name: 'Chargable Services',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_chargable_service',
        pages: [
            {
                title: 'Chargable Service',
                subtitle: 'Configure new Chargable Services',
                forms: [
                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'service_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Code', model: 'service_code',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    

                    {
                        type: 'select', form_type: 'text', label: 'Transaction Type', model: 'trans_type',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6', options: [
                            { id: 'topup', val: 'topup', label: 'Topup' },
                            { id: 'purchase', val: 'purchase', label: 'Purchase' },
                            { id: 'withdrawal', val: 'withdrawal', label: 'Withdrawal' },
                            { id: 'commission', val: 'commission', label: 'Commission' },
                            { id: 'invoice', val: 'invoice', label: 'Invoice' },
                            { id: 'settlement', val: 'settlement', label: 'Settlement' },
                        ]
                    },

                    {
                        type: 'select', form_type: 'text', label: 'Transaction Subtype', model: 'trans_subtype',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6', options: [
                            { id: 'card_topup', val: 'card_topup', label: 'card topup' },
                            { id: 'mobile_money_topup', val: 'mobile_money_topup', label: 'mobile money topup' },
                            { id: 'policy_purchase', val: 'policy_purchase', label: 'policy purchase' },
                            { id: 'intermediary_commission', val: 'intermediary_commission', label: 'Intermediary Commission' },
                            { id: 'sales_lead_commission', val: 'sales_lead_commission', label: 'sales lead commission' },
                            { id: 'service_provider_invoice', val: 'service_provider_invoice', label: 'Service Provider Invoice' },
                            { id: 'wallet_settlement', val: 'wallet_settlement', label: 'Wallet Settlement' },
                        ]
                    },

                  
                    {
                        type: 'select', label: 'Select Fee', model: 'system_fee_ref', id: "system_fee_ref",
                        validation: 'required|min:1|max:50', class: 'col-12'
                    },


                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'service_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ],
            }
        ]
    },
    table: {
        title: 'Chargable Services',
        subtitle: 'A list of chargable services',
        table_fields: [
            { name: 'Code', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'Module', align: 'l' },
            { name: 'Transaction Type', align: 'l' },
            { name: 'Trans Subtype', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["service_code", "service_name", "service_code"],
        rowData: [
            { type: 'text', field: 'service_code', class: 'left bolder' },
            { type: 'text', field: 'service_name', class: 'left bolder' },
            { type: 'text', field: 'service_description', class: 'left bolder' },
            { type: 'text', field: 'service_module', class: 'left bolder' },
            { type: 'text', field: 'tran_type', class: 'left bolder' },
            { type: 'text', field: 'tran_sub_type', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'Configure', event: 'chargableservice:configure', icon: 'ico-reply', params: [
                            { key: 'service', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },


    tableConfigured: {
        title: 'Configured Services',
        subtitle: 'A list of configured services',
        table_fields: [
            { name: 'Code', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'Module', align: 'l' },
            { name: 'Service Fee', align: 'l' },
            { name: 'Service Status', align: 'l' },
        ],
        searchFields: ["service_code", "service_name", "service_code"],
        rowData: [
            { type: 'text', field: 'service_code', class: 'left bolder' },
            { type: 'text', field: 'service_name', class: 'left bolder' },
            { type: 'text', field: 'service_description', class: 'left bolder' },
            { type: 'text', field: 'service_module', class: 'left bolder' },
            { type: 'text', field: 'fee', class: 'left bolder' },
            { type: 'text', field: 'service_status', class: 'left bolder' },
         
        ]
    },
    
}