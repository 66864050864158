<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="ClientType.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="ClientType.wizard.pages" 
                  :action="ClientType.wizard.action" 
                  :buttonText="ClientType.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addClientType()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Client Type
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="ClientType.table.table_fields" 
            :tableData="clientConfigs" 
            :title="ClientType.table.title" 
            :subtitle="ClientType.table.subtitle" 
            :searchFields="ClientType.table.searchFields"
            :rowData="ClientType.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.ClientType = Models.ClientType;
		let _this = this;

		this.clientConfigs = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.ClientType.wizard.action) {
				await this.addClientType(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("client:view", payload => {
			this.$router.push({
				name: "Configs-Insurance-ViewClientType",
				params: { id: payload.client_type.type_code }
			});
		});

		Event.$on("client:edit", payload => {
			console.log(" client type Edit ...",payload)
			this.$router.push({
				name: "Configs-edit-client-type",
				params: { id: payload.client_type.type_code }
			});
		});
	},
	data() {
		return {
			ClientType: {},
			clientConfigs: [],
			formData: {},
			showModal: false
		};
	},
	methods: {

        addClientType () {
          		this.$router.push({
				name: "Configs-add-client-type",
				params: {}
			});
        },
      fetchClientTypes () {
         return new Promise(async (resolve, reject) => {
				try {
					
					let res = await req.callServerSecure({
						resource: "client_type",
						action: "list",
						data: {},
						meta_data: {}
					});
               this.clientConfigs = res.data.result.client_types
					resolve(true);
				} catch (err) {
					notification.notify("error","Fetching Client type",`Error Fetching client type`);
					reject(err);
				}
			});
      },
		openAddClientTypeModal() {
			this.showModal = true;
		},
	
	},
	async mounted() {
      await this.fetchClientTypes()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>