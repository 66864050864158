module.exports = {
  name: 'Cover Area',
  gen_template: `
     {
        "image": "{{image.avatar}}",
        "name": "{{lorem.word}}",
        "type": "{{lorem.word}}",
        "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
        "status": "active"
     }
  `,
  table: {
    title: 'Cover Areas',
    subtitle: 'Cover Areas List',
    table_fields: [
      { name: 'Ref', align: 'c' },
      { name: 'Name', align: 'c' },
      { name: 'Description', align: 'r' },
      { name: 'Type', align: 'r' },      
      { name: 'Actions', align: 'c' },
    ],
    searchFields: ['cover_area_name', 'cover_area_description'],
    rowData: [
      { type: 'text', field: 'cover_area_system_ref', class: 'center' },
      { type: 'text', field: 'cover_area_name', class: 'center' },
      { type: 'text', field: 'cover_area_description', class: 'center' },
      { type: 'text', field: 'area_type', class: 'center' },      
      {
        type: 'action',
        field: 'name',
        class: 'center',
        icon: '',
        actions: [
          // {type: 'title', title: 'Actions'},

          {
            type: 'menu',
            title: 'View',
            event: 'cover-area:view',
            icon: 'ico-reply',
            params: [{ key: 'cover_area', field: '*' }],
          },
        ],
      },
    ],
  },
  wizard: {
    actionBtn: 'Save',
    action: 'add_cover_area',
    pages: [
      {
        title: 'Territory',
        subtitle: 'Add new territory',
        forms: [
          {
            type: 'radio',
            form_type: 'radio',
            label: 'Type',
            model: 'area_type',
            options: [
              {
                id: 'region',
                val: 'region',
                label: 'Region',
              },
              {
                id: 'country',
                val: 'country',
                label: 'Country',
              },
            ],
            class: 'col-12 col-md-12',
          },
          {
            type: 'text',
            form_type: 'text',
            label: 'Name',
            model: 'cover_area_name',
            validation: 'required|min:2|max:150',
            class: 'col-12 col-md-12',
          },
          {
            type: 'text',
            form_type: 'text',
            label: 'Description',
            model: 'cover_area_description',
            validation: 'required|min:2|max:150',
            class: 'col-12 col-md-12',
          },
          {
            type: 'location',
            form_type: 'text',
            label: 'Physical Location',
            model: 'coordinates',
            validation: 'required|min:2|max:200',
            class: 'col-12 col-md-12',
          },
          {
            type: 'text',
            form_type: 'text',
            label: 'Cover Radius in Metres',
            model: 'cover_radius',
            validation: 'required|min:1|max:500',
            class: 'col-12 col-md-12',
          },
        ],
      },
    ],
  },
};
