<template lang="html">
   <div class="orange">
      <div class="contents">
         <CustomTable :tableFields="UnderwritterProducts.table.table_fields" 
            :tableData="products" 
            :title="UnderwritterProducts.table.title" 
            :subtitle="UnderwritterProducts.table.subtitle" 
            :searchFields="UnderwritterProducts.table.searchFields"
            :rowData="UnderwritterProducts.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
   async created(){
      this.UnderwritterProducts = Models.UnderwritterProducts
      let _this = this
      
      Event.$on('modal:close',async (payload) => {
         console.log('Closing modal')
         this.showModal = false
      })

      Event.$on('product:view',(payload) => {
         console.log('Payload',payload)
         this.$router.push({name: 'Configs-UnderwritterProductss-ViewUnderwritterProducts',params: {id: payload.id}})
      })
   },
   data () {
      return {
         UnderwritterProducts: {},
         products: [],
         formData: {},
         showModal: false
      }
   },
   methods: {
      fetchProducts () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "product",
						action: "list",
						data: {
                     underwriter_id: this.$route.params.id
                  },
						meta_data: {}
					});

               this.products = res.data.result.products
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
   },
   async mounted(){
      await this.fetchProducts()
   }
}
</script>

<style lang="less">
   .orange{
      
   }
</style>