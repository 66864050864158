<template lang="html">
   <div class="channels">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createChannel()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Channel
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Channel.table.table_fields"
         :icon="Channel.table.icon"
         :title="Channel.table.title"
         :subtitle="Channel.table.subtitle"
         :tableData="channels" 
         :searchFields="Channel.table.searchFields"
         :rowData="Channel.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Channel = Models.Channel;

    Event.$on("channel:view", async (payload) => {
      this.$router.push({
        name: "Configs-Transactions-ViewChannel",
        params: { id: payload.account.channel_code },
      });
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Channel: {},
      channels: [],
      showModal: false,
    };
  },
  methods: {
    fetchData() {
      return new Promise(async (resolve, reject) => {
        try {
          let pages = this.Channel.wizard.pages;
          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[8].options = utils.appendOptions(
            res.data.result.accounts,
            "account_number",
            "account_name"
          );
          // res = await req.callServerSecure({
          //   resource: "currency",
          //   action: "list",
          //   data: {},
          //   meta_data: {},
          // });
          // pages[0].forms[5].options = utils.appendOptions(
          //   res.data.result.currencies,
          //   "currency_code",
          //   "name"
          // );

          this.Channel.wizard.pages = pages;
          resolve(true);
        } catch (err) {
          console.log(err);
        }
      });
    },
    fetchChannels() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_channel",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.channels = res.data.result.transaction_channels;
          console.log(this.channels);
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createChannel() {
      this.$router.push({
        name: "Configs-Transactions-AddChannel",
        params: {},
      });
    },
  },
  async mounted() {
    await this.fetchData();
    await this.fetchChannels();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.channels {
}
</style>