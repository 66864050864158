
<template lang="html">
   <div class="addClass">
              
          <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Class</li>
                    </ol>
                </nav>    
            </div>  
        </div>
    

             <div class="row">
               <div class="col-sm-8 offset-sm-2">

                    <div class="form-row">
                      <div class="form-group col-md-5">
                    <nav class="nav nav-icon-only">
                     <div class="dropdown">
                       <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Icon</button>
                    
                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                            <img :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail"  :alt="icon.originalname" v-for="(icon, index) in icons" @click="iconSelected(icon.file_path)">
                         </div>
                            </div>  
                       </nav> 
                     </div>
                        <div class="form-group col-md-5">
                         <img  v-bind:src="Class.icon" class="img wd-60 ht-60 rounded-circle" alt="">
                          </div>
                   </div>
                   
            
                   <div class="form-row">
                     <div class="form-group col-md-3">
                    <label>Select Insurance Category</label> 
                        <select class="custom-select"  id="insurancecategory" name="insurancecategory" v-model="Class.class_code" v-validate.continues="'required'">
                             <option disabled value="">Please select one</option>
                                <option value="general" >General Insurance</option>
                                <option value="life">Life Insurance</option>
                        </select>
                      <div v-if="errors.has('insurancecategory')" class="text-danger">{{ errors.first('insurancecategory') }}</div>
                    </div>

                      <div class="form-group col-md-3">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Class code" name="classCode" id="classCode" v-model="Class.code"   v-validate="'required'">
                       <div v-if="errors.has('classCode')" class="text-danger">{{ errors.first('classCode') }}</div>
                     </div>
                    <div class="form-group col-md-3">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Ira code" name="iraCode" id="iraCode" v-model="Class.ira_code"   v-validate="'required'">
                       <div v-if="errors.has('iraCode')" class="text-danger">{{ errors.first('iraCode') }}</div>
                     </div>
                      <div class="form-group col-md-3">
                       <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Class name" name="clientTypename" id="className" v-model="Class.name"   v-validate="'required'">
                       <div v-if="errors.has('className')" class="text-danger">{{ errors.first('className') }}</div>
                     </div>
                   </div>


                    <div class="form-row">
                      <div class="form-group col-md-12">
                       <label>Description</label> 
                      <textarea class="form-control" rows="2" placeholder="Class Description " name="classdesc" id="classdesc" v-model="Class.description"   v-validate="'required'"></textarea>
                       <div v-if="errors.has('classdesc')" class="text-danger">{{ errors.first('classdesc') }}</div>
                     </div>
                   </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <button type="button" class="btn btn-primary" @click="saveClass()">Save</button>
                     </div>
                      <div class="form-group col-md-6">
                          <button type="button" class="btn btn-warning" @click="clearClass()">Clear</button>
                     </div>
                   </div>


               </div>  
            </div>

 </div>
</template>

<script>
export default {
  async created() {},
  data() {
    return {
      icons: [],
      imageSelected: false,
      Class: {
        class_code: "",
        code: "",
        ira_code: "",
        name: "",
        description: "",
        icon: "",
      },
    };
  },
  methods: {
    getImg(path) {
      return req.getParsedUrl(path);
    },
    iconSelected(icon) {
      this.Class.icon = req.getParsedUrl(icon);
    },
    selectIcon() {
      alert(" selecting");
    },
    async saveClass() {
      try {
        let valii = await this.$validator.validate();
        if (!valii) {
          return reject(" Validation Error ");
        }
        let res = await req.callServerSecure({
          resource: "insurance_sub_class",
          action: "add",
          data: {
            class_code: this.Class.class_code,
            code: this.Class.code,
            ira_code: this.Class.ira_code,
            name: this.Class.name,
            description: this.Class.description,
            icon: this.Class.icon,
          },
        });
        notification.notify(
          "success",
          "Insurance Class",
          `Client Type ${this.Class.name} successfully added`
        );
        this.$router.push({
          name: "Configs-Insurance-InsuranceSubClass",
          params: {},
        });
      } catch (err) {
        console.log(err);
        Event.$emit("form:action-error", "An Error Has Occured");
      }
    },
    clearClass() {
      this.Class = {};
    },
    getIcons() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "icons",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.icons = res.data.result;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.getIcons();
    Event.$emit("loader:show", false);
  },
};
</script>
<style lang="less">
.addClass {
}
</style>