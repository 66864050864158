
<template lang="html">
   <div class="addClass">
              
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Notifications-Config'}">
                                Notifications
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Create Notification Event</li>
                    </ol>
                </nav>    
            </div>  
        </div>
    

    <div class="row">
        <div class="col-md-12">

                    
                  <!-- Select Input form -->
            <div class="form-row">

                <div class="form-group col-4">
                    <label for="module">Select Module</label>
                    <select class="custom-select" 
                        v-model="formData.module"
                        id="module"
                        @change="fetchModuleServices($event)"
                        name="module">
                            <option v-for="(option, index) in module_options" :key="index + 'mo'"  :value="option.module_ref">{{option.module_name}}</option>
                    </select>
                    <div v-if="errors.has('module')" class="text-danger">
                           {{ errors.first('module') }}
                    </div>
                </div>

                 <div class="form-group col-4">
                    
                    <div v-if="module_selected && formData.module">
                        <label for="module">Select Service</label>
                        <select class="custom-select" 
                            v-model="formData.service"
                            id="service"
                            @change="fetchServiceEvents($event)"
                            name="service">
                                <option disabled value="">Select service</option>
                                <option v-for="option in service_options" :value="option.name">{{option.name}}</option>
                        </select>
                        <div v-if="errors.has('service')" class="text-danger">
                            {{ errors.first('service') }}
                        </div>
                    </div>
                </div>

                 <div class="form-group col-4">
                    <div v-if="service_selected && formData.service">
                        <label for="module">Select Service Event</label>
                        <select class="custom-select" 
                            v-model="formData.service_event"
                            id="service_event"
                            name="service_event">
                                <option v-for="option in event_options" :value="option.event_name">{{option.event_description}}</option>
                        </select>
                        <div v-if="errors.has('service_event')" class="text-danger">
                            {{ errors.first('service_event') }}
                        </div>
                    </div>
                </div>

                <div  class="form-group col-12">
                    <label>Description</label> 
                       <textarea type="text" class="form-control" placeholder="Description" name="description" id="classCode" v-model="formData.description"   v-validate="'required'" rows="4"></textarea>
                       <div v-if="errors.has('description')" class="text-danger">{{ errors.first('description') }}</div>
                </div>

            </div>

            <div class="form-group col-md-6 my-2">
                <button type="button" class="btn btn-primary" @click="saveNotificationConfig()">Save</button>
            </div>
           


        </div>  


        
    </div>


    

 </div>
</template>

<script>
export default {
  async created() {},
  data() {
    return {
      country_image: "",
      file: "",
      formData: {},
      module_options: [],
      service_options: [],
      event_options: [],
      module_selected: false,
      service_selected: false,
      showImage: false,

      
    };
  },

  async mounted() {
    await this.fetchSystemModules();
    Event.$emit("loader:show", false);
  },
  methods: {
    // openFileUploader(form) {
    //   Event.$emit("file:upload", { form });
    // },
    async saveNotificationConfig() {
      try {
    
        let res = await req.callServerSecure({
          resource: "notification_event",
          action: "create",
          data: {
            event_name: this.formData.service_event,
            event_description: this.formData.description,
            service_name: this.formData.service,
            module: this.formData.module,
          },
        });
        notification.notify(
          "success",
          "Notification event",
          `Notification event ${this.formData.module} successfully added`
        );
        this.$router.push({
          name: "Notifications-Config",
          params: {},
        });
      } catch (err) {
        console.log(err);
        Event.$emit("form:action-error", "An Error Has Occured");
      }
    },

    async fetchSystemModules() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "module",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.module_options = res.data.result;

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },

    fetchModuleServices($event) {
      console.log(this.formData);
      req
        .callServerSecure({
          resource: "module",
          action: "services",
          data: {
            module_name: $event.target.value,
          },
          meta_data: {},
        })
        .then((res) => {
          console.log("module services", res.data.result.services);
          this.service_options = res.data.result.services;
          this.module_selected = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async fetchServiceEvents($event) {
      return new Promise(async (resolve, reject) => {
        this.event_options = [];
        try {
          let res = await req.callServerSecure({
            resource: "module",
            action: "service_events",
            data: {
              service_name: $event.target.value,
            },
            meta_data: {},
          });
          console.log("event_options", res.data.result);
          this.event_options = res.data.result.events;
          this.service_selected = true;

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
</script>
<style lang="less">
.addClass {
}
</style>