 
<template lang="html">
   <div class="editSubClientType">
              
           <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Client Sub Type</li>
                    </ol>
                </nav>    
            </div>  
        </div>


             <div class="row">
               <div class="col-sm-8 offset-sm-2">

                  <div class="form-row">
                      <div class="form-group col-md-5">
                    <nav class="nav nav-icon-only">
                     <div class="dropdown">
                       <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Icon</button>
              
                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                            <img :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail"  :alt="icon.originalname" v-for="(icon, index) in icons" @click="iconSelected(icon.file_path)">
                         </div>
                            </div>  
                       </nav> 
                     </div>
                        <div class="form-group col-md-5">
                         <img  v-bind:src="clientSubtype.subtype_icon" class="img wd-60 ht-60 rounded-circle" alt="">
                          </div>
                   </div>


                     <div class="form-row">
                      <div class="form-group col-md-6">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Client Sub type code" name="clientTypeCode" id="clientTypeCode" v-model="clientSubtype.subtype_code"  disabled >
                     </div>
                      <div class="form-group col-md-6">
                       <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Client Subtype name" name="clientTypename" id="clientTypename" v-model="clientSubtype.subtype_name"  >
                     </div>
                   </div>



                    <div class="form-row">
                      <div class="form-group col-md-12">
                       <label>Description</label> 
                      <textarea class="form-control" rows="2" placeholder="Client Sub Type Description " name="clientTypedesc" id="clientTypedesc" v-model="clientSubtype.subtype_description"   ></textarea>
                     </div>
                   </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <button type="button" class="btn btn-primary" @click="updateClientSubType()">Update</button>
                     </div>
                      <div class="form-group col-md-6">
                          <button type="button" class="btn btn-warning" @click="clearClientSubType()">Clear</button>
                     </div>
                   </div>


                   
               </div>
            </div>  
    
 
 </div>
</template>

<script>
export default {
	async created() {
    },
    data() {
		return {
            icons:[],
            imageSelected: false,
            clientSubtype:{}
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        iconSelected (icon) {
        this.clientSubtype.subtype_icon=req.getParsedUrl(icon)
        },
        selectIcon () {
        },
        async updateClientSubType() {
             try {

                    let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "edit",
						data: {
                            subtype_code:  this.clientSubtype.subtype_code,
                            update :{
                                    subtype_name:this.clientSubtype.subtype_name,
                                    subtype_description:this.clientSubtype.subtype_description,
                                    subtype_icon:this.clientSubtype.subtype_icon
                                }
                        }
               });
		       	notification.notify("success","Client Sub Type Updated",`Client Type ${this.clientSubtype.type_name} successfully added`);
                    this.$router.push({
                        name: "Configs-Insurance-ClientSubType",
                        params: {}
                    });
             }
             catch (err) {
                	console.log(err);
					notification.notify("error","Client Subtype Error ",err);
             }
           },
     fetchClientSubType() {
		    return new Promise(async (resolve,reject) => {
                try {
                let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "get",
						data: {
                            subtype_code:  this.$route.params.id,
                        }
               });
              this.clientSubtype=res.data.result.client_subtype_details
                     resolve()
                }
                catch (err) {
              console.log(err)
                     reject(err)
                }
            })
         }
        ,
        clearClientSubType () {
          this.clientSubtype={}
        },
         getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  this.icons = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
    }
    ,
    async mounted() {
        await this.getIcons()
        await this.fetchClientSubType()
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.editSubClientType {
}
</style>