module.exports = {
  name: "account_statement",
  gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "account_statement_config",
    pages: [
      {
        title: "Account Statement Config",
        subtitle: "Configure new account statement",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-6",
          },

          {
            type: "check",
            class: "col-12",
            model: "delivery_channels",
            multiple: true,
            options: [
              { val: "in-app", label: "In-App", id: "In-App" },
              { val: "email", label: "Email", id: "email" },
              { val: "sms", label: "SMS", id: "sms" },
            ],
            validation: "required",
            label: "Delivery Channels",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "frequency",
            options: [
              { val: "DAILY", label: "Daily" },
              { val: "WEEKLY", label: "Weekly" },
              { val: "MONTHLY", label: "Monthly" },
            ],
            validation: "required",
            label: "Frequency",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "type",
            options: [
              { val: "FULL_STATEMENT", label: "Full Statement" },
              { val: "MINI_STATEMENT", label: "Mini Statement" },
            ],
            validation: "required",
            label: "Statement Type",
          },
          {
            type: "text",
            form_type: "text",
            label: "Charges",
            model: "charges",
            validation: "required|min:1|max:9|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Number of Transactions",
            model: "no_of_transactions",
            validation: "required|min:1|max:9|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Day of Week",
            model: "day_of_week",
            validation: "required|min:1|max:1|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Day of Month",
            model: "day_of_month",
            validation: "required|min:1|max:2|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "Account Statement List",
    subtitle: "A list of configured account statements",
    table_fields: [
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "Frequency", align: "l" },
      { name: "Delivery Channel", align: "l" },
      { name: "Charges", align: "c" },
      { name: "Statement Type", align: "l" },
      { name: "Tran No.", align: "c" },
      { name: "actions", align: "c" },
    ],
    searchFields: [
      "code",
      "name",
      "frequency",
      "charge_amount",
      "statement_type",
    ],
    rowData: [
      { type: "text", field: "code", class: "left bolder small" },
      { type: "text", field: "name", class: "left bolder" },
      { type: "text", field: "frequency", class: "left bolder small" },
      { type: "text", field: "delivery_channel", class: "left bolder small" },
      { type: "text", field: "charge_amount", class: "center bolder small" },
      { type: "text", field: "statement_type", class: "left bolder small" },
      {
        type: "text",
        field: "num_of_transaction",
        class: "center bolder small",
      },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Edit",
            event: "client:edit",
            icon: "ico-right",
            params: [{ key: "client", field: "*" }],
          },
          {
            type: "menu",
            title: "View",
            event: "client:view",
            icon: "ico-reply",
            params: [
              { key: "email", field: "email" },
              { key: "name", field: "name" },
            ],
          },
          // {type: 'divider'},
          {
            type: "menu",
            title: "Delete",
            event: "client:delete",
            icon: "ico-mail-a",
            params: [{ key: "email", field: "email" }],
          },
        ],
      },
    ],
  },
};
