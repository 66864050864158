<template lang="html">
    <div class="UserGroups">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddUserGroup()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add User Group
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="UserGroup.table.table_fields" 
              :tableData="userGroups" 
              :title="UserGroup.table.title" 
              :subtitle="UserGroup.table.subtitle" 
              :searchFields="UserGroup.table.searchFields"
              :rowData="UserGroup.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.UserGroup = Models.UserGroup;

    Event.$on("form:submit", async (payload) => {      
      this.addUserGroup(payload.formData);
    });

    Event.$on("user-group:view", async (payload) => {            
      this.$router.push({
        name: "view_user_group",
        params: {id: payload['user-group'].group_id},
      });
    });

    Event.$on("UserGroup:approve", async (payload) => {
      console.log(payload);
    });

    Event.$on("UserGroup:edit", async (payload) => {
      console.log(payload);
    });
  },
  data() {
    return {
      UserGroup: {},
      userGroups: [],
    };
  },
  methods: {
    openAddUserGroup() {
      this.$router.push({
        name: "add_user_group",
        params: {},
      });
    },
    async listUserGroups() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "user_group",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.userGroups = res.data.result.user_groups;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    addUserGroup(data) {        
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "user_group",
            action: "create",
            data: {
              group_code: data.code,
              group_name: data.name,
              description: data.description,                                          
            },
            meta_data: {},
          });

          await this.listUserGroups();          

          notification.notify(
            "success",
            "User Group Entry Added",
            `The user group ${data.name} was successfully added`
          );  
          
          this.$router.push({ name: "list_user_groups", params: {}});
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.UserGroup.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.listUserGroups();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
