module.exports = {
  name: "gl",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "add_tran_channel",
    pages: [
      {
        title: "Transaction Channel",
        subtitle: "Configure new transaction channel",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-6",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12 col-md-6",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Category",
            model: "category",
            options: [
              { val: "MOBILE_MONEY", label: "Mobile Money" },
              { val: "CARD", label: "Card" },
              { val: "PAYPAL", label: "Paypal" },
              { val: "BANK", label: "Bank" },
            ],
            validation: "required",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Third Party Provider",
            model: "third_party",
            options: [
              { val: "MPESA_CHECKOUT", label: "Mpesa Checkout" },
              { val: "IVY_PAY", label: "Ivy Pay" },
              { val: "STRIPE", label: "Stripe" },
              { val: "AIRTEL_MONEY", label: "Airtel Money" },
            ],
            validation: "required",
          },
          {
            type: "text",
            form_type: "number",
            label: "Per Transaction Limit",
            model: "per_transaction_limit",
            validation: "required|min:1|max:10|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "check",
            class: "col-12 col-md-6",
            label: "Currency",
            model: "currency",
            options: [
              { id: "KES", label: "KES", val: "KES" },
              { id: "USD", label: "USD", val: "USD" },
            ],
          },
          {
            type: "radio",
            class: "col-12 col-md-6",
            label: "Refund Allowed",
            model: "refund_allowed",
            options: [
              { id: "Yes", val: "Yes", label: "Yes" },
              { id: "No", val: "No", label: "No" },
            ],
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Account",
            model: "account",
            options: [],
            // validation: "required",
          },
          {
            type: "keyValue",
            form_type: "text",
            label: "Other Details",
            model: "other_details",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "Channels",
    subtitle: "A list of transaction channels",
    table_fields: [
      { name: "Category", align: "l" },
      { name: "Name", align: "l" },
      { name: "Code", align: "l" },
      { name: "Third Party", align: "c" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    searchFields: [
      "channel_code",
      "channel_name",
      "type",
      "thirdparty_provider",
    ],
    rowData: [
      { type: "text", field: "type", class: "left bolder small" },
      { type: "text", field: "channel_name", class: "left bolder small" },
      { type: "text", field: "channel_code", class: "left bolder small" },
      {
        type: "text",
        field: "thirdparty_provider",
        class: "left bolder small",
      },
      { type: "text", field: "status", class: "left bolder small" },
      {
        type: "action",
        field: "account_number",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "channel:view",
            icon: "ico-reply",
            params: [{ key: "account", field: "*" }],
          },
        ],
      },
    ],
  },
};
