<template lang="html">
    <div class="Users">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddUser()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add User
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="User.table.table_fields" 
              :tableData="users" 
              :title="User.table.title" 
              :subtitle="User.table.subtitle" 
              :searchFields="User.table.searchFields"
              :rowData="User.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.User = Models.User;

    Event.$on("form:submit", async (payload) => {
      console.log(payload);
      this.addUser(payload.formData);
    });

    Event.$on("user:view", async (payload) => {
      this.$router.push({
        name: "view_user",
        params: {id: payload.Users.user_id},
      });
    });

    Event.$on("user:approve", async (payload) => {
      console.log(payload);
    });

    Event.$on("user:edit", async (payload) => {
      console.log(payload);
    });

    Event.$on("user:activate", async (payload) => {
      console.log("Payload: ", payload);
    });

    Event.$on("user:deactivate", async (payload) => {
      console.log("Payload: ", payload);
    });

    Event.$on("user:resetPassword", async (payload) => {
      console.log("Payload: ", payload);
    });

    Event.$on("user:audit", async (payload) => {
      console.log("Payload: ", payload);
    });

    Event.$on("user:loginHistory", async (payload) => {
      console.log("Payload: ", payload);
    });
  },
  data() {
    return {
      User: {},
      users: [],
      roles: []
    };
  },
  methods: {
    openAddUser() {
      this.$router.push({
        name: "add_users",
        params: {},
      });
    },
    listUsers() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "user",
            action: "list",
            data: {},
            meta_data: {},
          });

          console.log(res.data.result)
          this.users = res.data.result.users;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    fetchData() {
      return new Promise(async (resolve, reject) => {
        try {
          let pages = this.User.wizard.pages;

          let res = await req.callServerSecure({
            resource: "role",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[5].options = utils.appendOptions(
            res.data.result.roles,
            "role_code",
            "name"
          );
          
          res = await req.callServerSecure({
            resource: "department",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[6].options = utils.appendOptions(
            res.data.result.departments,
            "department_name",
            "department_name"
          );

           res = await req.callServerSecure({
            resource: "security_context",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[7].options = utils.appendOptions(
            res.data.result.security_contexts,
            "security_context_code",
            "name"
          );
          
          this.User.wizard.pages = pages;
          resolve(true);
        } catch (err) {
          console.log(err);
        }
      });
    },
    addUser(data) {        
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "user",
            action: "create",
            data: {
              employee_ref: data.ref,
              names: data.name,
              department: data.department,              
              contact: data.contact,              
              role_code: data.role,              
              email: data.email,              
              username: data.username,              
              security_context_code: data.security_context, 
              password: data.password,
             confirm_password: data.confirm_password                          
            },
            meta_data: {},
          });

          await this.listUsers();          

          notification.notify(
            "success",
            "User Entry Added",
            `The user ${data.name} was successfully added`
          );  
          
          this.$router.push({ name: "list_users", params: {}});
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.User.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {    
    await this.listUsers();
    //await this.fetchData();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
