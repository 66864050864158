module.exports = {
    name: "Postal Codes",
    table: {
        title: "Postal Codes",
        subtitle: "Postal Codes List",
        table_fields: [
            { name: "code", align: "r" },
            { name: "Name", align: "c" },
        ],
        searchFields: ["name"],
        rowData: [
            { type: "text", field: "postal_code", class: "small center" },
            { type: "text", field: "postal_name", class: "small center" },

        ],
    }
};