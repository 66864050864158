<template>
  <div class="orange" v-if="tnc_details">
    <TnCEditModal
      title="Edit Terms and Conditions"
      :showTnCEditModal="showTnCEditModal"
      :tnc_details="tnc_details"
      :name="tnc_details.Tnc_name"
    />
    <TnCDeleteModal
      title="Are you ABSOLUTELY sure?"
      :showTnCDeleteModal="showTnCDeleteModal"
      :name="tnc_details.Tnc_name"
    />
    <h4 class="mg-b-5">
      TnC <span class="text-primary">{{ tnc_details.Tnc_name }}</span>
    </h4>
    <hr />
    <div class="row mg-md-t-30">
      <div class="col-md-5 offset-md-1 mg-b-20">
        <div class="font-weight-bold">
          Reference:
          <span class="font-weight-normal">
            {{ tnc_details.Tnc_entity_internal_ref }}
          </span>
        </div>
        <div class="font-weight-bold">
          Name:
          <span class="font-weight-normal"> {{ tnc_details.Tnc_name }}</span>
        </div>
        <div class="font-weight-bold">
          Version: <span class="font-weight-normal"> 3 </span>
        </div>
        <div class="font-weight-bold">
          Created Date:
          <span class="font-weight-normal">
            {{ tnc_details.created_date }}
          </span>
        </div>
      </div>
      <div class="col-md-6 mg-b-20">
        <div class="font-weight-bold">
          Category:
          <span class="font-weight-normal"
            >{{ tnc_details.Tnc_category }}
          </span>
        </div>
        <div class="font-weight-bold">
          Description:
          <span class="font-weight-normal">
            {{ tnc_details.Tnc_description }}
          </span>
        </div>
        <div class="font-weight-bold">
          Type:
          <span class="font-weight-normal"> {{ tnc_details.Tnc_type }} </span>
        </div>
        <div class="font-weight-bold">
          Status:
          <span class="font-weight-normal"> {{ tnc_details.status }} </span>
        </div>
      </div>
      <div class="col-md-10">
        <div
          class="card doc"
          v-if="tnc_details.Tnc_type.toLowerCase() === 'text'"
        >
          <div class="card-body">
            <h6>Terms and Conditions</h6>
            <div v-html="tnc_details.Tnc_text" />
          </div>
        </div>
        <div v-if="tnc_details.Tnc_type.toLowerCase() === 'upload'">
          <label
            class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15"
            >Uploads</label
          >
          <div class="row row-xs">
            <div class="col-md-3">
              <a
                :href="getImg(tnc_details.Tnc_upload_path)"
                target="_blank"
                class="link-02"
              >
                <div class="card card-file">
                  <div
                    class="card-file-thumb tx-primary centered"
                    v-if="
                      fileExtension(
                        tnc_details.Tnc_upload_path
                      ).toLowerCase() === 'doc' ||
                        fileExtension(
                          tnc_details.Tnc_upload_path
                        ).toLowerCase() === 'docx'
                    "
                  >
                    <i class="far fa-file-word file"></i>
                  </div>
                  <div
                    class="card-file-thumb tx-danger centered"
                    v-if="
                      fileExtension(
                        tnc_details.Tnc_upload_path
                      ).toLowerCase() === 'pdf'
                    "
                  >
                    <i class="far fa-file-pdf file"></i>
                  </div>
                  <div
                    class="card-file-thumb tx-indigo centered"
                    v-if="
                      fileExtension(
                        tnc_details.Tnc_upload_path
                      ).toLowerCase() === 'jpg' ||
                        fileExtension(
                          tnc_details.Tnc_upload_path
                        ).toLowerCase() === 'jpeg' ||
                        fileExtension(
                          tnc_details.Tnc_upload_path
                        ).toLowerCase() === 'png'
                    "
                  >
                    <i class="far fa-file-image file"></i>
                  </div>
                  <div class="card-body">
                    <h6>
                      Terms and Conditions
                    </h6>
                  </div>
                </div>
              </a>
            </div>

            <!-- col -->
          </div>
          <!-- row -->
        </div>
      </div>
      <div class="col-md-2 d-md-flex justify-content-start  flex-column">
        <button
          class="btn-sm pd-x-15 mg-b-15 btn-primary btn-uppercase"
          @click="showTnCEditModal = true"
        >
          Edit
        </button>
        <button class="btn-sm pd-x-15 mg-b-15 btn-white btn-uppercase">
          Verify
        </button>
        <button class="btn-sm pd-x-15 mg-b-15 btn-white btn-uppercase">
          Audit Trail
        </button>
        <button
          class="btn-sm pd-x-15 mg-b-15 btn-white btn-uppercase"
          @click="showTnCDeleteModal = true"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    Event.$on("tncEditModal:close", async (payload) => {
      console.log("Closing modal");
      this.showTnCEditModal = false;
    });

    Event.$on("tncEditModal:submit", async (payload) => {
      console.log("THE PAYLOAD of edit", payload);
      await this.editTnCDetails(payload.tnc);
      this.showTnCEditModal = false;
    });

    Event.$on("tncDeleteModal:submit", async () => {
      await this.deleteTnC();
      this.showTnCDeleteModal = false;
    });
  },
  data() {
    return {
      tnc_details: null,
      showTnCEditModal: false,
      showTnCDeleteModal: false,
    };
  },
  methods: {
    fileExtension(file_url) {
      let extension = file_url.split(".").pop();
      return extension;
    },
    getImg(path) {
      return req.getParsedUrl(`/${path}`);
    },
    fetchTnCDetails() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_Tnc",
            action: "get",
            data: {
              system_tnc_ref: this.$route.params.id,
            },
          });
          console.log("RES DETAILS", res);
          this.tnc_details = res.data.result.Tnc;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    editTnCDetails(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_Tnc",
            action: "edit",
            data: {
              system_tnc_ref: this.tnc_details.system_tnc_ref,
              data: {
                Tnc_name: data.name,
                Tnc_text: data.content,
                Tnc_description: data.description,
                Tnc_type: data.type,
                Tnc_upload_path: ".../../path",
              },
            },
          });
          console.log("RES DETAILS", res);
          notification.notify(
            "success",
            "Edit Success",
            `${data.name} was edited successully`
          );
          this.showTnCEditModal = false;
          this.fetchTnCDetails();

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    deleteTnC() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_Tnc",
            action: "delete",
            data: {
              system_tnc_ref: this.tnc_details.system_tnc_ref,
            },
          });
          this.showTnCDeleteModal = false;
          notification.notify(
            "success",
            "Delete Success",
            `${this.tnc_details.Tnc_name} was deleted successully`
          );

          resolve(true);
          // this.$router.push({ name: "TnC" });
        } catch (err) {
          reject(err);
        }
      });
    },
  },

  async mounted() {
    await this.fetchTnCDetails();

    Event.$emit("loader:show", false);
  },
};
</script>

<style scoped>
.doc {
  height: 400px;
  background: #f5f6fa;
}
.centered {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.file {
  font-size: 4rem;
}
</style>
