// Entities
let Insurance = require("./entities/insurance.model");
let Wallet = require("./entities/wallet.model");
let UnderwritterProducts = require("./entities/products.model");
let UnderwritterDirectors = require("./entities/directors.model");
let UnderwritterSuperUsers = require("./entities/super-user.model");
let UnderwritterGroup = require("./entities/underwritter-group.model");
let Bank = require("./entities/bank.model");
let intermediary = require("./entities/intermediary.model");
let Blacklist = require("./entities/blacklist.model");
let PurchaseException = require("./entities/purchase-exception.model");
let School = require("./entities/school.model");

// Insurance Configs
let Class = require("./configs/insurance/class.model");
let ClientKYC = require("./configs/insurance/client-kyc.model");
let ClientSubtype = require("./configs/insurance/client-subtype.model");
let ClientType = require("./configs/insurance/client-type.model");
let ServiceCategories = require("./configs/insurance/servicecategory.model");
let InsuranceServices = require("./configs/insurance/service.modal");
let Assessment = require("./configs/insurance/assessment.model");
let CoverType = require("./configs/insurance/cover-types.model");
let KycField = require("./configs/insurance/kyc-fields.model");
let SubClass = require("./configs/insurance/sub-class.model");
let ProductRequirement = require("./configs/insurance/product-requirements.model");
let ProductCharge = require("./configs/insurance/product-charge.model");
let CoverArea = require("./configs/insurance/cover-area.model");

// Tran Configs
let Limit = require("./configs/transaction/limit.model");
let Charge = require("./configs/transaction/charges.model");
let Topup = require("./configs/transaction/topup.model");
let Channel = require("./configs/transaction/channel.model");
let PurchasePolicy = require("./configs/transaction/purchase-policy.model");

// Account Configs
let Accounts = require("./configs/accounting/account.model");
let AccountOpening = require("./configs/accounting/account-opening.model");
let SystemAccounts = require("./configs/accounting/system-account.model");
let COA = require("./configs/accounting/coa.model");
let Currency = require("./configs/accounting/currency.model");
let AccountEntity = require("./configs/accounting/entity.model");
let GL = require("./configs/accounting/gl.model");
let SubLedgers = require("./configs/accounting/subledgers.model");
let AccountLimit = require("./configs/accounting/limit.model");
let Statement = require("./configs/accounting/statement.model");
let Status = require("./configs/accounting/status.model");
let Transactions = require("./configs/accounting/transactions.model");
let formField = require("./configs/system/formField.model");
let EditFormField = require("./configs/system/edit-formField.model");
let uploadField = require("./configs/system/upload.model");
let viewupload = require("./configs/system/view-upload.model");
let reccomendation = require("./configs/system/reccomendation.model");
let claimtypes = require("./configs/insurance/claim-types.model");
let ClaimCategories = require("./configs/insurance/claim-categories.model");
let TaxCategory = require("./configs/accounting/tax-categories.model");
let Tax = require("./configs/accounting/tax.model");
// Account Configs
let NotificationQueues = require("./reports/notification-queue.model");
let PaymentQueues = require("./reports/payment-queue.model");
let Withdrawal = require("./configs/accounting/withdrawal.model");
let Settlement = require("./configs/accounting/settlement.model");
let Fees = require("./configs/accounting/fees.model");

// Calendar Configs
let Holiday = require("./configs/calendar/holiday.model");

// entities
let BenefitCategory = require("./entities/benefit-category.model");

// nexus
let TnC = require("./nexus/legal/tnc.model");

let ChargableService = require("./nexus/chargableservice.model");
let NotificationsConfig = require("./notifications/notificationsconfig.model");
let NotificationQueue = require("./notifications/notificationsqueue.model");

// urmg

let Department = require("./configs/usermanagement/department.model");
let User = require("./configs/usermanagement/users.model");
let ChatUser = require("./configs/usermanagement/chat-user.model");
let UserGroup = require("./configs/usermanagement/user-group.model");
let SecurityContext = require("./configs/usermanagement/security-context.model");
let Role = require("./configs/usermanagement/role.model");
let Resource = require("./configs/usermanagement/resources.model");
let Country = require("./configs/usermanagement/country.model");
let UploadedBanks = require("./configs/system/bank-upload.model");
let crs_make_upload_model = require("./configs/system/crs_make_upload_model");
let crsp_model_upload_model = require("./configs/system/crsp_model_upload_model");
let postal_codes_upload_model = require("./configs/system/postal_codes_upload_model");
let sic_industry_upload_model = require("./configs/system/sic-industry-upload.model");
let sic_job_upload_model = require("./configs/system/sic-job-upload.model");
let underwriterupload = require("./configs/system/underwriter-upload.model");

module.exports = {
  underwriterupload,
  sic_industry_upload_model,
  sic_job_upload_model,
  ProductCharge,
  NotificationQueues,
  PaymentQueues,
  ProductRequirement,
  AccountOpening,
  Transactions,
  Bank,
  Class,
  ClientKYC,
  Assessment,
  ClientSubtype,
  ClientType,
  CoverType,
  KycField,
  SubClass,
  SystemAccounts,
  COA,
  Currency,
  AccountEntity,
  GL,
  AccountLimit,
  Statement,
  Status,
  Topup,
  PurchasePolicy,
  Charge,
  Limit,
  Channel,
  Accounts,
  Insurance,
  Wallet,
  UnderwritterProducts,
  PurchaseException,
  School,
  UnderwritterDirectors,
  UnderwritterGroup,
  UnderwritterSuperUsers,
  formField,
  uploadField,
  reccomendation,
  intermediary,
  Blacklist,
  claimtypes,
  ClaimCategories,
  TaxCategory,
  BenefitCategory,
  Tax,
  Fees,
  viewupload,
  ServiceCategories,
  InsuranceServices,
  Holiday,
  Department,
  User,
  ChatUser,
  UserGroup,
  SecurityContext,
  Role,
  Resource,
  Country,
  EditFormField,
  UploadedBanks,
  crs_make_upload_model,
  crsp_model_upload_model,
  postal_codes_upload_model,
  CoverArea,
  Withdrawal,
  Settlement,
  ChargableService,
  TnC,
  NotificationsConfig,
  NotificationQueue,
  SubLedgers,
};
