<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Currency</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add Currency</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="code">Code</label>
      <input type="text" class="form-control" id="code" placeholder="Code" v-model="currency.currency_code">
    </div>
    <div class="form-group col-md-4">
      <label for="iscocode">Iso Code</label>
      <input type="text" class="form-control" id="iscocode" v-model="currency.currency_iso_code" placeholder="Iso Code">
    </div>
    <div class="form-group col-md-4">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" v-model="currency.name" placeholder="Name">
    </div>
  </div>
   <div class="form-row">
    <div class="form-group col-md-6">
       <label for="description">Description</label>
       <input type="text" class="form-control" id="description" v-model="currency.description" placeholder="Description">
     </div>
    <div class="form-group col-md-3">
      <label for="symbol">Symbol</label>
      <input type="text" class="form-control" id="symbol" v-model="currency.symbol" placeholder="Symbol">
    </div>
    <div class="form-group col-md-3">
      <label for="number">Number</label>
      <input type="text" class="form-control" id="number" v-model="currency.number" placeholder="Number">
    </div>
   </div>  
  


        <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveCurrency()" class="btn btn-primary">Add Accounting Currency</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             currency:{
                        currency_code: '',
                        currency_iso_code: '',
                        name: '',
                        description: '',
                        symbol: '',
                        number: '',
             }
                

         }

      },
    methods: {
       async saveCurrency () {
          try {
                  let res = await req.callServerSecure({
                     resource: "currency",
                     action: "add",
                     data: this.currency,
                     meta_data: {},
                  });
                notification.notify('success', 'Currency Added', `The gl ${this.currency.name} was successfully added`)
                this.$router.push({ name: 'accounting.currency.ListCurrency' })
  
          }
          catch (err) {
              console.log(err)
                  notification.notify('error', err)
          }
        }
    }, 
    async mounted(){
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>