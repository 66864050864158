module.exports = {
   name: 'Insurance Class',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'add_insurace_class',
      pages: [
         {
            title: 'Insurance Class',
            subtitle: 'Configure new insurance class',
            forms: [
               {
                  type: 'image', class: 'col-12 col-md-6', model: 'icon',
                  imageFormat: 'png', validation: 'required', label: 'Icon'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'Category',
                  options: [
                     { val: 'general', label: 'General' },
                     { val: 'life', label: 'Life' },
                  ], validation: 'required', label: 'Type'
               },
               {
                  type: 'text', form_type: 'text', label: 'Name', model: 'name',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Code', model: 'code',
                  validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'IRA Code', model: 'ira_code',
                  validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'textarea', form_type: 'text', label: 'Description', model: 'description',
                  validation: 'required|min:10|max:500', class: 'col-12'
               },
            ],
         }
      ]
   },
   table: {
      title: 'Insurance Class',
      subtitle: 'A list of configured insurance classes',
      table_fields: [
         // { name: 'Code', align: 'l' },
         { name: 'IRA Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["code", "name", "min_account_bal", "max_account_bal"],
      rowData: [
         // { type: 'text', field: 'code', class: 'left bolder small' },
         { type: 'text', field: 'ira_code', class: 'left bolder small' },
         { type: 'text', field: 'name', class: 'left bolder' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'email', field: 'email' },
                     { key: 'name', field: 'name' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}