module.exports = {
    name: 'Benefit Category',
    gen_template: `
       {
          "coa": "ASSET",
          "Icon": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_accounting_benefit_category',
        pages: [
            {
                title: 'Benefit Category',
                subtitle: 'Configure new Benefit category',
                forms: [
                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'benefit_category_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'select', class: 'col-12 col-md-6', label: "Image", model: 'benefit_category_image_path',
                        validation: 'required', options: []
                    },
                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'benefit_category_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ],
            },

        ]
    },
    updateWizard: {
        actionBtn: 'Update',
        action: 'update_benefit_category',
        pages: [
            {
                title: 'Benefit Category',
                subtitle: 'Update Benefit Category',
                forms: [
                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'benefit_category_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ]
            }
        ]
    },

    verifyWizard: {
        actionBtn: 'Verify',
        action: 'verify_benefit_category',
        pages: [
            {
                title: 'Benefit Category',
                subtitle: 'Verify Benefit Category',
                forms: [
                    {
                        type: 'textarea', form_type: 'text', label: 'Remarks', model: 'remarks',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ]
            }
        ]
    },
    table: {
        title: 'Benefit Category',
        subtitle: 'A list of configured benefit categories',
        table_fields: [
            { name: 'Icon', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["benefit_category_description", "benefit_category_name"],
        rowData: [
            { type: 'image', field: 'benefit_category_image_path', class: 'left bolder small' },
            { type: 'text', field: 'benefit_category_name', class: 'left bolder' },
            { type: 'text', field: 'benefit_category_description', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    // {
                    //     type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                    //     { key: 'client', field: '*' }
                    //     ]
                    // },
                    
                    {
                        type: 'menu', title: 'View', event: 'benefitCategory:view', icon: 'ico-reply', params: [
                            { key: 'BenefitCategory', field: '*' },
                        ]
                    },
                    // {
                    //     type: 'menu', title: 'Verify', event: 'benefitCategory:verify', icon: 'ico-thumbs-up', params: [
                    //         { key: 'BenefitCategory', field: '*' },
                    //     ]
                    // },
                    // {
                    //     type: 'menu', title: 'Delete', event: 'benefitCategory:delete', icon: 'ico-thumbs-up', params: [
                    //         { key: 'BenefitCategory', field: '*' },
                    //     ]
                    // },

                ]
            },
        ]
    },
}