module.exports = {
  name: "limit",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "add_limit",
    pages: [
      {
        title: "Transaction Limit",
        subtitle: "Configure new transaction limit",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12",
          },
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Daily Limit",
            model: "daily",
            validation: "required|min:1|max:8|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Weekly Limit",
            model: "weekly",
            validation: "required|min:1|max:8|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Monthly Limit",
            model: "monthly",
            validation: "required|min:1|max:8|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "Limits",
    subtitle: "A list of transction limits",
    table_fields: [
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "Daily", align: "l" },
      { name: "Weekly", align: "l" },
      { name: "Monthly", align: "l" },
      { name: "Actions", align: "c" },
    ],
    searchFields: [
      "code",
      "name",
      "daily_limit",
      "weekly_limit",
      "monthly_limit",
    ],
    rowData: [
      { type: "text", field: "code", class: "left bolder small" },
      { type: "text", field: "name", class: "left bolder" },
      { type: "text", field: "daily_limit", class: "left bolder small" },
      { type: "text", field: "weekly_limit", class: "left bolder small" },
      { type: "text", field: "monthly_limit", class: "left bolder small" },
      {
        type: "action",
        field: "account_number",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Edit",
            event: "client:edit",
            icon: "ico-right",
            params: [{ key: "account", field: "*" }],
          },
          {
            type: "menu",
            title: "View",
            event: "client:view",
            icon: "ico-reply",
            params: [{ key: "account", field: "*" }],
          },
          // {type: 'divider'},
          {
            type: "menu",
            title: "Delete",
            event: "client:delete",
            icon: "ico-mail-a",
            params: [{ key: "account", field: "*" }],
          },
        ],
      },
    ],
  },
};
