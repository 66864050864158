<template lang="html">
   <div class="chargableservices">
      <ModalForm :showModal="showModal" :action="ChargableService.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="ChargableService.wizard.pages" 
               :action="ChargableService.wizard.action" 
               :buttonText="ChargableService.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
         </div>
      </div>

      <CustomTable :tableFields="ChargableService.tableConfigured.table_fields"
         :icon="ChargableService.tableConfigured.icon"
         :title="'Configured Service Charges'"
         :subtitle="'A list of configured service charges'"
         :tableData="chargableservices" 
         :searchFields="ChargableService.tableConfigured.searchFields"
         :rowData="ChargableService.tableConfigured.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.ChargableService = Models.ChargableService;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.ChargableService.wizard.action) {
        await this.saveChargableService(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      ChargableService: {},
      chargableservices: [],
      showModal: false,
    };
  },
  methods: {
    async fetchchargableservices() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "service_charges",
            action: "list_configured_services",
            data: {},
            meta_data: {},
          });
          console.log(res.data.result.configured_services );
          this.chargableservices = res.data.result.configured_services;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createChargableService() {
      this.showModal = true;
    },
    saveChargableService(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource:"service_charges",
            action:"configure",
            data : {
               service_code : data.service_code,
               system_fee_ref : data.system_fee_ref,
               service_name: data.service_name,
               service_module: data.service_module,
               trans_type: data.trans_type,
               trans_subtype: data.trans_subtype,
               service_description: data.service_description,
            },
            meta_data: {},
          });

          await this.fetchchargableservices();
          notification.notify(
            "success",
            "ChargableService Added",
            `The ChargableService ${data.name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.ChargableService.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.ChargableService.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.fetchchargableservices();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
</style>