<template>
  <div
    class="modal fade"
    id="verifyModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Verify {{ field_code }}
          </h5>
          <button
            @click="close()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="remarks" class="col-form-label">Remarks:</label>
              <textarea
                class="form-control"
                id="remarks"
                placeholder="Remarks..."
                rows="5"
                v-model="remarks"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            @click="close()"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            @click="submit()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save
            <span class="lds-ring" v-show="loading"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.formField = Models.formField;
  },
  props: {
    showVerifyModal: {
      default: false,
    },
  },
  data() {
    return {
      remarks: '',
    };
  },
  watch: {
    showVerifyModal: function (to, from) {
      let action = 'hide';

      if (to) {
        action = 'show';
      }
      $('#verifyModal').modal(action);
    },
  },
  methods: {
    async submit() {
      Event.$emit('verifyModal:submit', { remarks: this.remarks });
      this.close();
    },
    close() {
      this.remarks = '';
      Event.$emit('verifyModal:close', {});
    },
  },
};
</script>

<style></style>
