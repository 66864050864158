<template lang="html">
  <div class="subclass">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              View Class
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <div class="card mg-b-20 mg-lg-b-25">
          <div
            class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between"
          >
            <h6 class="tx-uppercase tx-semibold mg-b-0">
              Subclass Details for : {{ subclass.name }}
            </h6>
            <nav class="nav nav-icon-only">
              <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
            </nav>
          </div>
          <!-- card-header -->
          <div class="card-body pd-20 pd-lg-25">
            <div class="media align-items-center mg-b-20">
              <div
                class="wd-80 ht-80 bg-ui-04 basic_details_avatar d-flex align-items-center justify-content-center"
              >
                <div class="avatar avatar-online">
                  <img
                    v-bind:src="subclass.icon"
                    class="rounded-circle"
                    alt=""
                  />
                </div>
              </div>
              <div class="media-body pd-l-15">
                <h6 class="mg-b-3">Class Code : {{ subclass.class_code }}</h6>
                <h6 class="mg-b-3">Code :{{ subclass.code }}</h6>
                <span class="d-block tx-13 tx-color-03"
                  >Description : {{ subclass.description }}</span
                >
                <span class="d-block tx-13 tx-color-03"
                  >Status : {{ subclass.status }}</span
                >
                <span class="d-block tx-13 tx-color-03"
                  >Supported Currencies : {{ subclass.currency }}</span
                >
                <span class="d-block tx-13 tx-color-03"
                  >Published Status : {{ subclass.publish }}</span
                >
              </div>
              <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start"
                >Created Date : {{ subclass.created_date }}</span
              >
            </div>
            <!-- media -->
          </div>
          <div
            class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20"
          >
            <nav class="nav nav-with-icon tx-13">
              <button
                class="btn btn-sm pd-x-15 btn-white btn-uppercase"
                @click="editClass()"
              >
                <i data-feather="edit"></i> Edit
              </button>
              <button
                class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-20"
                @click="selectAction(subclass.system_sub_class_ref)"
              >
                <i data-feather="edit"></i>
                {{ subclass.publish ? 'Unpublish' : 'Publish' }}
              </button>
              <div
                class="spinner-border mg-l-10"
                role="status"
                v-if="updating"
              ></div>
            </nav>
          </div>
          <!-- card-footer -->
        </div>
        <!-- card -->
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <button
          type="button"
          class="btn btn-outline-dark pull-right"
          @click="attachGeneralRequirement()"
        >
          Attach General Requirement
        </button>
        <h4 id="section1" class="mg-b-10">General Requirements</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Code</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sublassGeneralRequirement">
              <th scope="row">{{ index }}</th>
              <td>{{ item.field_code }}</td>
              <td>{{ item.field_name }}</td>
              <td>{{ item.field_description }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="removeGeneralRequirement(item)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <button
          type="button"
          class="btn btn-outline-dark pull-right"
          @click="attachProductRequirement()"
        >
          Attach Product Requirement
        </button>
        <h4 id="section1" class="mg-b-10">Product Requirements</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Code</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sublassProductRequirement">
              <th scope="row">{{ index }}</th>
              <td>{{ item.field_code }}</td>
              <td>{{ item.field_name }}</td>
              <td>{{ item.field_description }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="removeProductRequirement(item)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <button
          type="button"
          class="btn btn-outline-dark pull-right"
          @click="attachCovertype()"
        >
          Attach Covertypes
        </button>
        <h4 id="section1" class="mg-b-10">Subclass Covertypes</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Id</th>
              <th scope="col">Code</th>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sublassCovertypes">
              <th scope="row">{{ index }}</th>
              <td>{{ item.sub_class_id }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="removeCovertype(item)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <button
          type="button"
          class="btn btn-outline-dark pull-right"
          @click="attachClaimTypes()"
        >
          Attach Claim Types
        </button>
        <h4 id="section1" class="mg-b-10">Subclass Claim Types</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Code</th>
              <th scope="col">Name</th>
              <th scope="col">Descriptio</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sublassClaimTypes">
              <th scope="row">{{ index }}</th>
              <td>{{ item.claim_type_code }}</td>
              <td>{{ item.claim_type_name }}</td>
              <td>{{ item.claim_type_description }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="removeClaimTypes(item)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ModalForm :showModal="showModal">
      <template slot="form" v-if="showgeneralreqirementModal">
        <div class="custom-control custom-checkbox form-group">
          <li v-for="field in formFields">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="field.field_code"
              :value="field.field_code"
              v-model="selectedGeneralRequirements"
            />
            <label
              :for="field.field_code"
              class="custom-control-label label-checkbox"
              >{{ field.field_name }}</label
            >
          </li>
        </div>
        <div class="d-none d-md-block">
          <button
            class="btn btn-sm pd-x-15 btn-white btn-uppercase"
            @click="SaveGeneralRequirement()"
          >
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Save General
            Requirement(s)
          </button>
        </div>
      </template>

      <template slot="form" v-if="showproductreqirementModal">
        <div class="custom-control custom-checkbox form-group">
          <li v-for="field in formFields">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="field.field_code"
              :value="field.field_code"
              v-model="selectedProductRequirement"
            />
            <label
              :for="field.field_code"
              class="custom-control-label label-checkbox"
              >{{ field.field_name }}</label
            >
          </li>
        </div>
        <div class="d-none d-md-block">
          <button
            class="btn btn-sm pd-x-15 btn-white btn-uppercase"
            @click="SaveProductrequirement()"
          >
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Save Product
            Requirement(s)
          </button>
        </div>
      </template>

      <template slot="form" v-if="showcovertypeModal">
        <div class="custom-control custom-checkbox form-group">
          <li v-for="ctype in covertypes">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="ctype.code"
              :value="ctype.code"
              v-model="selectedCovertypes"
            />
            <label
              :for="ctype.code"
              class="custom-control-label label-checkbox"
              >{{ ctype.name }}</label
            >
          </li>
        </div>
        <div class="d-none d-md-block">
          <button
            class="btn btn-sm pd-x-15 btn-white btn-uppercase"
            @click="SaveCovertype()"
          >
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Save Covertype (s)
          </button>
        </div>
      </template>

      <template slot="form" v-if="showclaimtypeModal">
        <div class="custom-control custom-checkbox form-group">
          <li v-for="claimType in claimTypes">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="claimType.claim_type_code"
              :value="claimType.claim_type_code"
              v-model="selectedClaimTypes"
            />
            <label
              :for="claimType.claim_type_code"
              class="custom-control-label label-checkbox"
              >{{ claimType.claim_type_name }}</label
            >
          </li>
        </div>
        <div class="d-none d-md-block">
          <button
            class="btn btn-sm pd-x-15 btn-white btn-uppercase"
            @click="SaveClaimtype()"
          >
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Save Claim Type(s)
          </button>
        </div>
      </template>
    </ModalForm>
  </div>
</template>
<script>
export default {
  async created() {
    Event.$on('modal:close', async (payload) => {
      this.showModal = false;
    });
  },
  data() {
    return {
      subclass: {},
      showModal: false,
      showgeneralreqirementModal: false,
      showproductreqirementModal: false,
      showcovertypeModal: false,
      showclaimtypeModal: false,
      sublassGeneralRequirement: [],
      sublassProductRequirement: [],
      sublassCovertypes: [],
      sublassClaimTypes: [],

      selectedGeneralRequirements: [],
      selectedProductRequirement: [],
      selectedCovertypes: [],
      selectedClaimTypes: [],

      covertypes: [],
      claimTypes: [],

      formFields: [],
      updating: false,
    };
  },
  methods: {
    editClass() {
      this.$router.push({
        name: 'Configs-edit-subclass',
        params: { id: this.$route.params.id },
      });
    },
    selectAction(subclass_sys_ref) {
      if (this.subclass.publish === true) {
        this.unpublishClass(subclass_sys_ref);
      } else {
        this.publishClass(subclass_sys_ref);
      }
    },

    getImg(path) {
      return req.getParsedUrl(path);
    },
    async publishClass(subclass_sys_ref) {
      try {
        this.updating = true;
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'publish',
          data: {
            system_sub_class_ref: subclass_sys_ref,
          },
          meta_data: {},
        });

        this.fetchSubclass();

        this.updating = false;

        notification.notify(
          'success',
          'Insurance Class Published',
          `Insurance Class ${this.subclass.name} successfully published`
        );
      } catch (err) {
        notification.notify('error', 'Insurance Class Publish Error ', err);
      }
    },
    async unpublishClass(subclass_sys_ref) {
      try {
        this.updating = true;
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'unpublish',
          data: {
            system_sub_class_ref: subclass_sys_ref,
          },
          meta_data: {},
        });

        this.fetchSubclass();

        this.updating = false;

        notification.notify(
          'success',
          'Insurance Class Unpublished',
          `Insurance Class ${this.subclass.name} successfully unpublished`
        );
      } catch (err) {
        notification.notify('error', 'Insurance Class Unpublish Error ', err);
      }
    },
    async fetchSubclass() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'insurance_sub_class',
            action: 'summary',
            data: {
              code: this.$route.params.id,
            },
          });

          console.log(res.data.result.subclass_summary);

          this.subclass = res.data.result.subclass_summary;
          this.sublassCovertypes = res.data.result.subclass_summary.covertypes;
          this.sublassGeneralRequirement =
            res.data.result.subclass_summary.general_requirement;
          this.sublassProductRequirement =
            res.data.result.subclass_summary.product_requirement;
          this.sublassClaimTypes = res.data.result.subclass_summary.claim_type;

          resolve();
        } catch (err) {
          notification.notify(
            'error',
            'Error Fetching Subclass Details  ',
            `Kindly Retry Later`
          );
          reject(err);
        }
      });
    },
    async removeGeneralRequirement(GM) {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'remove_general_requirement',
          data: {
            code: this.$route.params.id,
            field_code: GM.field_code,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        notification.notify(
          'success',
          'General Requirement Removed Successfully ',
          `General Requirement Removed Successfully `
        );
      } catch (err) {
        notification.notify(
          'error',
          'Error Removing a General Requirement   ',
          err
        );
      }
    },
    async removeCovertype(ctype) {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'remove_covertypes',
          data: {
            code: this.$route.params.id,
            covertype_code: ctype.code,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        notification.notify(
          'success',
          'Covertype Removed Successfully ',
          `Covertype Successfully Attached `
        );
      } catch (err) {
        notification.notify('error', 'Error Removing a GCovertype   ', err);
      }
    },
    async removeProductRequirement(GM) {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'remove_product_requirements',
          data: {
            code: this.$route.params.id,
            field_code: GM.field_code,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        notification.notify(
          'success',
          'General Requirement Removed Successfully ',
          `General Requirement Successfully Attached `
        );
      } catch (err) {
        notification.notify(
          'error',
          'Error Removing a General Requirement   ',
          err
        );
      }
    },

    async removeClaimTypes(clt) {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'remove_claim_type',
          data: {
            code: this.$route.params.id,
            claim_type_code: clt.claim_type_code,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        notification.notify(
          'success',
          'Claim Type Removed Successfully ',
          `Claim Type Successfully Attached `
        );
      } catch (err) {
        notification.notify('error', 'Error Removing a Claim Type    ', err);
      }
    },

    fetchFormFields() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'form_field',
            action: 'list',
            data: {},
            meta_data: {},
          });

          this.formFields = res.data.result.form_fields;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchCoverTypes() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'covertype',
            action: 'list',
            data: {},
            meta_data: {},
          });

          this.covertypes = res.data.result.covertypes;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchClaimTypes() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'claim_type',
            action: 'list',
            data: {},
            meta_data: {},
          });

          this.claimTypes = res.data.result.claim_types;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    attachGeneralRequirement() {
      this.showgeneralreqirementModal = true;
      this.showproductreqirementModal = false;
      this.showcovertypeModal = false;
      this.showclaimtypeModal = false;
      this.showModal = true;
    },

    attachProductRequirement() {
      this.showgeneralreqirementModal = false;
      this.showproductreqirementModal = true;
      this.showcovertypeModal = false;
      this.showclaimtypeModal = false;
      this.showModal = true;
    },
    attachCovertype() {
      this.showgeneralreqirementModal = false;
      this.showproductreqirementModal = false;
      this.showcovertypeModal = true;
      this.showclaimtypeModal = false;
      this.showModal = true;
    },
    attachClaimTypes() {
      this.showgeneralreqirementModal = false;
      this.showproductreqirementModal = false;
      this.showcovertypeModal = false;
      this.showclaimtypeModal = true;
      this.showModal = true;
    },

    async SaveClaimtype() {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'update_claim_type',
          data: {
            code: this.$route.params.id,
            claim_types: this.selectedClaimTypes,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        this.showgeneralreqirementModal = false;
        this.showproductreqirementModal = false;
        this.showcovertypeModal = false;
        this.showclaimtypeModal = false;
        this.showModal = false;
        notification.notify(
          'success',
          'Claim Attached ',
          `The Claim(s) Successfully Attached `
        );
      } catch (err) {
        console.log(err);
        notification.notify('error', 'Error Saving Claim details   ', err);
      }
    },
    async SaveCovertype() {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'update_covertype',
          data: {
            code: this.$route.params.id,
            covertypes: this.selectedCovertypes,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        this.showgeneralreqirementModal = false;
        this.showproductreqirementModal = false;
        this.showcovertypeModal = false;
        this.showclaimtypeModal = false;
        this.showModal = false;
        notification.notify(
          'success',
          'Covertype  Attached ',
          `The Covertypes  Successfully Attached `
        );
      } catch (err) {
        console.log(err);
        notification.notify(
          'error',
          'Error Saving Product Requirement details  ',
          err
        );
      }
    },
    async SaveProductrequirement() {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'update_product_requirement',
          data: {
            code: this.$route.params.id,
            field_codes: this.selectedProductRequirement,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        this.showgeneralreqirementModal = false;
        this.showproductreqirementModal = false;
        this.showcovertypeModal = false;
        this.showclaimtypeModal = false;
        this.showModal = false;
        notification.notify(
          'success',
          'Product Requirement Attached ',
          `The Product Requirements(s) Successfully Attached `
        );
      } catch (err) {
        console.log(err);
        notification.notify(
          'error',
          'Error Saving Product Requirement details  ',
          err
        );
      }
    },
    async SaveGeneralRequirement() {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'update_general_requirement',
          data: {
            code: this.$route.params.id,
            field_codes: this.selectedGeneralRequirements,
          },
          meta_data: {},
        });

        await this.fetchSubclass();
        this.showgeneralreqirementModal = false;
        this.showproductreqirementModal = false;
        this.showcovertypeModal = false;
        this.showclaimtypeModal = false;
        this.showModal = false;
        notification.notify(
          'success',
          'General Requirement Attached ',
          `The General Requirements(s) Successfully Attached `
        );
      } catch (err) {
        console.log(err);
        notification.notify('error', 'Error Saving Claim details   ', err);
      }
    },
  },
  async mounted() {
    await this.fetchSubclass();
    await this.fetchFormFields();
    await this.fetchCoverTypes();
    await this.fetchClaimTypes();
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.subclass {
}
</style>
