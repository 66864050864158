module.exports = [
   {
      name: "Afghanistan",
      alpha2Code: "AF",
      alpha3Code: "AFG",
      timezones: ["UTC+04:30"],
      flag: "https://restcountries.eu/data/afg.svg",
      callingCodes: ["93"],
      numericCode: "004",
      subregion: "Southern Asia"
   },
   {
      name: "Åland Islands",
      alpha2Code: "AX",
      alpha3Code: "ALA",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/ala.svg",
      callingCodes: ["358"],
      numericCode: "248",
      subregion: "Northern Europe"
   },
   {
      name: "Albania",
      alpha2Code: "AL",
      alpha3Code: "ALB",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/alb.svg",
      callingCodes: ["355"],
      numericCode: "008",
      subregion: "Southern Europe"
   },
   {
      name: "Algeria",
      alpha2Code: "DZ",
      alpha3Code: "DZA",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/dza.svg",
      callingCodes: ["213"],
      numericCode: "012",
      subregion: "Northern Africa"
   },
   {
      name: "American Samoa",
      alpha2Code: "AS",
      alpha3Code: "ASM",
      timezones: ["UTC-11:00"],
      flag: "https://restcountries.eu/data/asm.svg",
      callingCodes: ["1684"],
      numericCode: "016",
      subregion: "Polynesia"
   },
   {
      name: "Andorra",
      alpha2Code: "AD",
      alpha3Code: "AND",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/and.svg",
      callingCodes: ["376"],
      numericCode: "020",
      subregion: "Southern Europe"
   },
   {
      name: "Angola",
      alpha2Code: "AO",
      alpha3Code: "AGO",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/ago.svg",
      callingCodes: ["244"],
      numericCode: "024",
      subregion: "Middle Africa"
   },
   {
      name: "Anguilla",
      alpha2Code: "AI",
      alpha3Code: "AIA",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/aia.svg",
      callingCodes: ["1264"],
      numericCode: "660",
      subregion: "Caribbean"
   },
   {
      name: "Antarctica",
      alpha2Code: "AQ",
      alpha3Code: "ATA",
      timezones: [
         "UTC-03:00",
         "UTC+03:00",
         "UTC+05:00",
         "UTC+06:00",
         "UTC+07:00",
         "UTC+08:00",
         "UTC+10:00",
         "UTC+12:00"
      ],
      flag: "https://restcountries.eu/data/ata.svg",
      callingCodes: ["672"],
      numericCode: "010",
      subregion: ""
   },
   {
      name: "Antigua and Barbuda",
      alpha2Code: "AG",
      alpha3Code: "ATG",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/atg.svg",
      callingCodes: ["1268"],
      numericCode: "028",
      subregion: "Caribbean"
   },
   {
      name: "Argentina",
      alpha2Code: "AR",
      alpha3Code: "ARG",
      timezones: ["UTC-03:00"],
      flag: "https://restcountries.eu/data/arg.svg",
      callingCodes: ["54"],
      numericCode: "032",
      subregion: "South America"
   },
   {
      name: "Armenia",
      alpha2Code: "AM",
      alpha3Code: "ARM",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/arm.svg",
      callingCodes: ["374"],
      numericCode: "051",
      subregion: "Western Asia"
   },
   {
      name: "Aruba",
      alpha2Code: "AW",
      alpha3Code: "ABW",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/abw.svg",
      callingCodes: ["297"],
      numericCode: "533",
      subregion: "Caribbean"
   },
   {
      name: "Australia",
      alpha2Code: "AU",
      alpha3Code: "AUS",
      timezones: [
         "UTC+05:00",
         "UTC+06:30",
         "UTC+07:00",
         "UTC+08:00",
         "UTC+09:30",
         "UTC+10:00",
         "UTC+10:30",
         "UTC+11:30"
      ],
      flag: "https://restcountries.eu/data/aus.svg",
      callingCodes: ["61"],
      numericCode: "036",
      subregion: "Australia and New Zealand"
   },
   {
      name: "Austria",
      alpha2Code: "AT",
      alpha3Code: "AUT",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/aut.svg",
      callingCodes: ["43"],
      numericCode: "040",
      subregion: "Western Europe"
   },
   {
      name: "Azerbaijan",
      alpha2Code: "AZ",
      alpha3Code: "AZE",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/aze.svg",
      callingCodes: ["994"],
      numericCode: "031",
      subregion: "Western Asia"
   },
   {
      name: "Bahamas",
      alpha2Code: "BS",
      alpha3Code: "BHS",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/bhs.svg",
      callingCodes: ["1242"],
      numericCode: "044",
      subregion: "Caribbean"
   },
   {
      name: "Bahrain",
      alpha2Code: "BH",
      alpha3Code: "BHR",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/bhr.svg",
      callingCodes: ["973"],
      numericCode: "048",
      subregion: "Western Asia"
   },
   {
      name: "Bangladesh",
      alpha2Code: "BD",
      alpha3Code: "BGD",
      timezones: ["UTC+06:00"],
      flag: "https://restcountries.eu/data/bgd.svg",
      callingCodes: ["880"],
      numericCode: "050",
      subregion: "Southern Asia"
   },
   {
      name: "Barbados",
      alpha2Code: "BB",
      alpha3Code: "BRB",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/brb.svg",
      callingCodes: ["1246"],
      numericCode: "052",
      subregion: "Caribbean"
   },
   {
      name: "Belarus",
      alpha2Code: "BY",
      alpha3Code: "BLR",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/blr.svg",
      callingCodes: ["375"],
      numericCode: "112",
      subregion: "Eastern Europe"
   },
   {
      name: "Belgium",
      alpha2Code: "BE",
      alpha3Code: "BEL",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/bel.svg",
      callingCodes: ["32"],
      numericCode: "056",
      subregion: "Western Europe"
   },
   {
      name: "Belize",
      alpha2Code: "BZ",
      alpha3Code: "BLZ",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/blz.svg",
      callingCodes: ["501"],
      numericCode: "084",
      subregion: "Central America"
   },
   {
      name: "Benin",
      alpha2Code: "BJ",
      alpha3Code: "BEN",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/ben.svg",
      callingCodes: ["229"],
      numericCode: "204",
      subregion: "Western Africa"
   },
   {
      name: "Bermuda",
      alpha2Code: "BM",
      alpha3Code: "BMU",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/bmu.svg",
      callingCodes: ["1441"],
      numericCode: "060",
      subregion: "Northern America"
   },
   {
      name: "Bhutan",
      alpha2Code: "BT",
      alpha3Code: "BTN",
      timezones: ["UTC+06:00"],
      flag: "https://restcountries.eu/data/btn.svg",
      callingCodes: ["975"],
      numericCode: "064",
      subregion: "Southern Asia"
   },
   {
      name: "Bolivia (Plurinational State of)",
      alpha2Code: "BO",
      alpha3Code: "BOL",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/bol.svg",
      callingCodes: ["591"],
      numericCode: "068",
      subregion: "South America"
   },
   {
      name: "Bonaire, Sint Eustatius and Saba",
      alpha2Code: "BQ",
      alpha3Code: "BES",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/bes.svg",
      callingCodes: ["5997"],
      numericCode: "535",
      subregion: "Caribbean"
   },
   {
      name: "Bosnia and Herzegovina",
      alpha2Code: "BA",
      alpha3Code: "BIH",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/bih.svg",
      callingCodes: ["387"],
      numericCode: "070",
      subregion: "Southern Europe"
   },
   {
      name: "Botswana",
      alpha2Code: "BW",
      alpha3Code: "BWA",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/bwa.svg",
      callingCodes: ["267"],
      numericCode: "072",
      subregion: "Southern Africa"
   },
   {
      name: "Bouvet Island",
      alpha2Code: "BV",
      alpha3Code: "BVT",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/bvt.svg",
      callingCodes: [""],
      numericCode: "074",
      subregion: ""
   },
   {
      name: "Brazil",
      alpha2Code: "BR",
      alpha3Code: "BRA",
      timezones: ["UTC-05:00", "UTC-04:00", "UTC-03:00", "UTC-02:00"],
      flag: "https://restcountries.eu/data/bra.svg",
      callingCodes: ["55"],
      numericCode: "076",
      subregion: "South America"
   },
   {
      name: "British Indian Ocean Territory",
      alpha2Code: "IO",
      alpha3Code: "IOT",
      timezones: ["UTC+06:00"],
      flag: "https://restcountries.eu/data/iot.svg",
      callingCodes: ["246"],
      numericCode: "086",
      subregion: "Eastern Africa"
   },
   {
      name: "United States Minor Outlying Islands",
      alpha2Code: "UM",
      alpha3Code: "UMI",
      timezones: ["UTC-11:00", "UTC-10:00", "UTC+12:00"],
      flag: "https://restcountries.eu/data/umi.svg",
      callingCodes: [""],
      numericCode: "581",
      subregion: "Northern America"
   },
   {
      name: "Virgin Islands (British)",
      alpha2Code: "VG",
      alpha3Code: "VGB",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/vgb.svg",
      callingCodes: ["1284"],
      numericCode: "092",
      subregion: "Caribbean"
   },
   {
      name: "Virgin Islands (U.S.)",
      alpha2Code: "VI",
      alpha3Code: "VIR",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/vir.svg",
      callingCodes: ["1 340"],
      numericCode: "850",
      subregion: "Caribbean"
   },
   {
      name: "Brunei Darussalam",
      alpha2Code: "BN",
      alpha3Code: "BRN",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/brn.svg",
      callingCodes: ["673"],
      numericCode: "096",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Bulgaria",
      alpha2Code: "BG",
      alpha3Code: "BGR",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/bgr.svg",
      callingCodes: ["359"],
      numericCode: "100",
      subregion: "Eastern Europe"
   },
   {
      name: "Burkina Faso",
      alpha2Code: "BF",
      alpha3Code: "BFA",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/bfa.svg",
      callingCodes: ["226"],
      numericCode: "854",
      subregion: "Western Africa"
   },
   {
      name: "Burundi",
      alpha2Code: "BI",
      alpha3Code: "BDI",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/bdi.svg",
      callingCodes: ["257"],
      numericCode: "108",
      subregion: "Eastern Africa"
   },
   {
      name: "Cambodia",
      alpha2Code: "KH",
      alpha3Code: "KHM",
      timezones: ["UTC+07:00"],
      flag: "https://restcountries.eu/data/khm.svg",
      callingCodes: ["855"],
      numericCode: "116",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Cameroon",
      alpha2Code: "CM",
      alpha3Code: "CMR",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/cmr.svg",
      callingCodes: ["237"],
      numericCode: "120",
      subregion: "Middle Africa"
   },
   {
      name: "Canada",
      alpha2Code: "CA",
      alpha3Code: "CAN",
      timezones: ["UTC-08:00", "UTC-07:00", "UTC-06:00", "UTC-05:00", "UTC-04:00", "UTC-03:30"],
      flag: "https://restcountries.eu/data/can.svg",
      callingCodes: ["1"],
      numericCode: "124",
      subregion: "Northern America"
   },
   {
      name: "Cabo Verde",
      alpha2Code: "CV",
      alpha3Code: "CPV",
      timezones: ["UTC-01:00"],
      flag: "https://restcountries.eu/data/cpv.svg",
      callingCodes: ["238"],
      numericCode: "132",
      subregion: "Western Africa"
   },
   {
      name: "Cayman Islands",
      alpha2Code: "KY",
      alpha3Code: "CYM",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/cym.svg",
      callingCodes: ["1345"],
      numericCode: "136",
      subregion: "Caribbean"
   },
   {
      name: "Central African Republic",
      alpha2Code: "CF",
      alpha3Code: "CAF",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/caf.svg",
      callingCodes: ["236"],
      numericCode: "140",
      subregion: "Middle Africa"
   },
   {
      name: "Chad",
      alpha2Code: "TD",
      alpha3Code: "TCD",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/tcd.svg",
      callingCodes: ["235"],
      numericCode: "148",
      subregion: "Middle Africa"
   },
   {
      name: "Chile",
      alpha2Code: "CL",
      alpha3Code: "CHL",
      timezones: ["UTC-06:00", "UTC-04:00"],
      flag: "https://restcountries.eu/data/chl.svg",
      callingCodes: ["56"],
      numericCode: "152",
      subregion: "South America"
   },
   {
      name: "China",
      alpha2Code: "CN",
      alpha3Code: "CHN",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/chn.svg",
      callingCodes: ["86"],
      numericCode: "156",
      subregion: "Eastern Asia"
   },
   {
      name: "Christmas Island",
      alpha2Code: "CX",
      alpha3Code: "CXR",
      timezones: ["UTC+07:00"],
      flag: "https://restcountries.eu/data/cxr.svg",
      callingCodes: ["61"],
      numericCode: "162",
      subregion: "Australia and New Zealand"
   },
   {
      name: "Cocos (Keeling) Islands",
      alpha2Code: "CC",
      alpha3Code: "CCK",
      timezones: ["UTC+06:30"],
      flag: "https://restcountries.eu/data/cck.svg",
      callingCodes: ["61"],
      numericCode: "166",
      subregion: "Australia and New Zealand"
   },
   {
      name: "Colombia",
      alpha2Code: "CO",
      alpha3Code: "COL",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/col.svg",
      callingCodes: ["57"],
      numericCode: "170",
      subregion: "South America"
   },
   {
      name: "Comoros",
      alpha2Code: "KM",
      alpha3Code: "COM",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/com.svg",
      callingCodes: ["269"],
      numericCode: "174",
      subregion: "Eastern Africa"
   },
   {
      name: "Congo",
      alpha2Code: "CG",
      alpha3Code: "COG",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/cog.svg",
      callingCodes: ["242"],
      numericCode: "178",
      subregion: "Middle Africa"
   },
   {
      name: "Congo (Democratic Republic of the)",
      alpha2Code: "CD",
      alpha3Code: "COD",
      timezones: ["UTC+01:00", "UTC+02:00"],
      flag: "https://restcountries.eu/data/cod.svg",
      callingCodes: ["243"],
      numericCode: "180",
      subregion: "Middle Africa"
   },
   {
      name: "Cook Islands",
      alpha2Code: "CK",
      alpha3Code: "COK",
      timezones: ["UTC-10:00"],
      flag: "https://restcountries.eu/data/cok.svg",
      callingCodes: ["682"],
      numericCode: "184",
      subregion: "Polynesia"
   },
   {
      name: "Costa Rica",
      alpha2Code: "CR",
      alpha3Code: "CRI",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/cri.svg",
      callingCodes: ["506"],
      numericCode: "188",
      subregion: "Central America"
   },
   {
      name: "Croatia",
      alpha2Code: "HR",
      alpha3Code: "HRV",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/hrv.svg",
      callingCodes: ["385"],
      numericCode: "191",
      subregion: "Southern Europe"
   },
   {
      name: "Cuba",
      alpha2Code: "CU",
      alpha3Code: "CUB",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/cub.svg",
      callingCodes: ["53"],
      numericCode: "192",
      subregion: "Caribbean"
   },
   {
      name: "Curaçao",
      alpha2Code: "CW",
      alpha3Code: "CUW",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/cuw.svg",
      callingCodes: ["599"],
      numericCode: "531",
      subregion: "Caribbean"
   },
   {
      name: "Cyprus",
      alpha2Code: "CY",
      alpha3Code: "CYP",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/cyp.svg",
      callingCodes: ["357"],
      numericCode: "196",
      subregion: "Southern Europe"
   },
   {
      name: "Czech Republic",
      alpha2Code: "CZ",
      alpha3Code: "CZE",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/cze.svg",
      callingCodes: ["420"],
      numericCode: "203",
      subregion: "Eastern Europe"
   },
   {
      name: "Denmark",
      alpha2Code: "DK",
      alpha3Code: "DNK",
      timezones: ["UTC-04:00", "UTC-03:00", "UTC-01:00", "UTC", "UTC+01:00"],
      flag: "https://restcountries.eu/data/dnk.svg",
      callingCodes: ["45"],
      numericCode: "208",
      subregion: "Northern Europe"
   },
   {
      name: "Djibouti",
      alpha2Code: "DJ",
      alpha3Code: "DJI",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/dji.svg",
      callingCodes: ["253"],
      numericCode: "262",
      subregion: "Eastern Africa"
   },
   {
      name: "Dominica",
      alpha2Code: "DM",
      alpha3Code: "DMA",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/dma.svg",
      callingCodes: ["1767"],
      numericCode: "212",
      subregion: "Caribbean"
   },
   {
      name: "Dominican Republic",
      alpha2Code: "DO",
      alpha3Code: "DOM",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/dom.svg",
      callingCodes: ["1809", "1829", "1849"],
      numericCode: "214",
      subregion: "Caribbean"
   },
   {
      name: "Ecuador",
      alpha2Code: "EC",
      alpha3Code: "ECU",
      timezones: ["UTC-06:00", "UTC-05:00"],
      flag: "https://restcountries.eu/data/ecu.svg",
      callingCodes: ["593"],
      numericCode: "218",
      subregion: "South America"
   },
   {
      name: "Egypt",
      alpha2Code: "EG",
      alpha3Code: "EGY",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/egy.svg",
      callingCodes: ["20"],
      numericCode: "818",
      subregion: "Northern Africa"
   },
   {
      name: "El Salvador",
      alpha2Code: "SV",
      alpha3Code: "SLV",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/slv.svg",
      callingCodes: ["503"],
      numericCode: "222",
      subregion: "Central America"
   },
   {
      name: "Equatorial Guinea",
      alpha2Code: "GQ",
      alpha3Code: "GNQ",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/gnq.svg",
      callingCodes: ["240"],
      numericCode: "226",
      subregion: "Middle Africa"
   },
   {
      name: "Eritrea",
      alpha2Code: "ER",
      alpha3Code: "ERI",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/eri.svg",
      callingCodes: ["291"],
      numericCode: "232",
      subregion: "Eastern Africa"
   },
   {
      name: "Estonia",
      alpha2Code: "EE",
      alpha3Code: "EST",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/est.svg",
      callingCodes: ["372"],
      numericCode: "233",
      subregion: "Northern Europe"
   },
   {
      name: "Ethiopia",
      alpha2Code: "ET",
      alpha3Code: "ETH",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/eth.svg",
      callingCodes: ["251"],
      numericCode: "231",
      subregion: "Eastern Africa"
   },
   {
      name: "Falkland Islands (Malvinas)",
      alpha2Code: "FK",
      alpha3Code: "FLK",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/flk.svg",
      callingCodes: ["500"],
      numericCode: "238",
      subregion: "South America"
   },
   {
      name: "Faroe Islands",
      alpha2Code: "FO",
      alpha3Code: "FRO",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/fro.svg",
      callingCodes: ["298"],
      numericCode: "234",
      subregion: "Northern Europe"
   },
   {
      name: "Fiji",
      alpha2Code: "FJ",
      alpha3Code: "FJI",
      timezones: ["UTC+12:00"],
      flag: "https://restcountries.eu/data/fji.svg",
      callingCodes: ["679"],
      numericCode: "242",
      subregion: "Melanesia"
   },
   {
      name: "Finland",
      alpha2Code: "FI",
      alpha3Code: "FIN",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/fin.svg",
      callingCodes: ["358"],
      numericCode: "246",
      subregion: "Northern Europe"
   },
   {
      name: "France",
      alpha2Code: "FR",
      alpha3Code: "FRA",
      timezones: [
         "UTC-10:00",
         "UTC-09:30",
         "UTC-09:00",
         "UTC-08:00",
         "UTC-04:00",
         "UTC-03:00",
         "UTC+01:00",
         "UTC+03:00",
         "UTC+04:00",
         "UTC+05:00",
         "UTC+11:00",
         "UTC+12:00"
      ],
      flag: "https://restcountries.eu/data/fra.svg",
      callingCodes: ["33"],
      numericCode: "250",
      subregion: "Western Europe"
   },
   {
      name: "French Guiana",
      alpha2Code: "GF",
      alpha3Code: "GUF",
      timezones: ["UTC-03:00"],
      flag: "https://restcountries.eu/data/guf.svg",
      callingCodes: ["594"],
      numericCode: "254",
      subregion: "South America"
   },
   {
      name: "French Polynesia",
      alpha2Code: "PF",
      alpha3Code: "PYF",
      timezones: ["UTC-10:00", "UTC-09:30", "UTC-09:00"],
      flag: "https://restcountries.eu/data/pyf.svg",
      callingCodes: ["689"],
      numericCode: "258",
      subregion: "Polynesia"
   },
   {
      name: "French Southern Territories",
      alpha2Code: "TF",
      alpha3Code: "ATF",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/atf.svg",
      callingCodes: [""],
      numericCode: "260",
      subregion: "Southern Africa"
   },
   {
      name: "Gabon",
      alpha2Code: "GA",
      alpha3Code: "GAB",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/gab.svg",
      callingCodes: ["241"],
      numericCode: "266",
      subregion: "Middle Africa"
   },
   {
      name: "Gambia",
      alpha2Code: "GM",
      alpha3Code: "GMB",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/gmb.svg",
      callingCodes: ["220"],
      numericCode: "270",
      subregion: "Western Africa"
   },
   {
      name: "Georgia",
      alpha2Code: "GE",
      alpha3Code: "GEO",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/geo.svg",
      callingCodes: ["995"],
      numericCode: "268",
      subregion: "Western Asia"
   },
   {
      name: "Germany",
      alpha2Code: "DE",
      alpha3Code: "DEU",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/deu.svg",
      callingCodes: ["49"],
      numericCode: "276",
      subregion: "Western Europe"
   },
   {
      name: "Ghana",
      alpha2Code: "GH",
      alpha3Code: "GHA",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/gha.svg",
      callingCodes: ["233"],
      numericCode: "288",
      subregion: "Western Africa"
   },
   {
      name: "Gibraltar",
      alpha2Code: "GI",
      alpha3Code: "GIB",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/gib.svg",
      callingCodes: ["350"],
      numericCode: "292",
      subregion: "Southern Europe"
   },
   {
      name: "Greece",
      alpha2Code: "GR",
      alpha3Code: "GRC",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/grc.svg",
      callingCodes: ["30"],
      numericCode: "300",
      subregion: "Southern Europe"
   },
   {
      name: "Greenland",
      alpha2Code: "GL",
      alpha3Code: "GRL",
      timezones: ["UTC-04:00", "UTC-03:00", "UTC-01:00", "UTC+00:00"],
      flag: "https://restcountries.eu/data/grl.svg",
      callingCodes: ["299"],
      numericCode: "304",
      subregion: "Northern America"
   },
   {
      name: "Grenada",
      alpha2Code: "GD",
      alpha3Code: "GRD",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/grd.svg",
      callingCodes: ["1473"],
      numericCode: "308",
      subregion: "Caribbean"
   },
   {
      name: "Guadeloupe",
      alpha2Code: "GP",
      alpha3Code: "GLP",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/glp.svg",
      callingCodes: ["590"],
      numericCode: "312",
      subregion: "Caribbean"
   },
   {
      name: "Guam",
      alpha2Code: "GU",
      alpha3Code: "GUM",
      timezones: ["UTC+10:00"],
      flag: "https://restcountries.eu/data/gum.svg",
      callingCodes: ["1671"],
      numericCode: "316",
      subregion: "Micronesia"
   },
   {
      name: "Guatemala",
      alpha2Code: "GT",
      alpha3Code: "GTM",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/gtm.svg",
      callingCodes: ["502"],
      numericCode: "320",
      subregion: "Central America"
   },
   {
      name: "Guernsey",
      alpha2Code: "GG",
      alpha3Code: "GGY",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/ggy.svg",
      callingCodes: ["44"],
      numericCode: "831",
      subregion: "Northern Europe"
   },
   {
      name: "Guinea",
      alpha2Code: "GN",
      alpha3Code: "GIN",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/gin.svg",
      callingCodes: ["224"],
      numericCode: "324",
      subregion: "Western Africa"
   },
   {
      name: "Guinea-Bissau",
      alpha2Code: "GW",
      alpha3Code: "GNB",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/gnb.svg",
      callingCodes: ["245"],
      numericCode: "624",
      subregion: "Western Africa"
   },
   {
      name: "Guyana",
      alpha2Code: "GY",
      alpha3Code: "GUY",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/guy.svg",
      callingCodes: ["592"],
      numericCode: "328",
      subregion: "South America"
   },
   {
      name: "Haiti",
      alpha2Code: "HT",
      alpha3Code: "HTI",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/hti.svg",
      callingCodes: ["509"],
      numericCode: "332",
      subregion: "Caribbean"
   },
   {
      name: "Heard Island and McDonald Islands",
      alpha2Code: "HM",
      alpha3Code: "HMD",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/hmd.svg",
      callingCodes: [""],
      numericCode: "334",
      subregion: ""
   },
   {
      name: "Holy See",
      alpha2Code: "VA",
      alpha3Code: "VAT",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/vat.svg",
      callingCodes: ["379"],
      numericCode: "336",
      subregion: "Southern Europe"
   },
   {
      name: "Honduras",
      alpha2Code: "HN",
      alpha3Code: "HND",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/hnd.svg",
      callingCodes: ["504"],
      numericCode: "340",
      subregion: "Central America"
   },
   {
      name: "Hong Kong",
      alpha2Code: "HK",
      alpha3Code: "HKG",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/hkg.svg",
      callingCodes: ["852"],
      numericCode: "344",
      subregion: "Eastern Asia"
   },
   {
      name: "Hungary",
      alpha2Code: "HU",
      alpha3Code: "HUN",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/hun.svg",
      callingCodes: ["36"],
      numericCode: "348",
      subregion: "Eastern Europe"
   },
   {
      name: "Iceland",
      alpha2Code: "IS",
      alpha3Code: "ISL",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/isl.svg",
      callingCodes: ["354"],
      numericCode: "352",
      subregion: "Northern Europe"
   },
   {
      name: "India",
      alpha2Code: "IN",
      alpha3Code: "IND",
      timezones: ["UTC+05:30"],
      flag: "https://restcountries.eu/data/ind.svg",
      callingCodes: ["91"],
      numericCode: "356",
      subregion: "Southern Asia"
   },
   {
      name: "Indonesia",
      alpha2Code: "ID",
      alpha3Code: "IDN",
      timezones: ["UTC+07:00", "UTC+08:00", "UTC+09:00"],
      flag: "https://restcountries.eu/data/idn.svg",
      callingCodes: ["62"],
      numericCode: "360",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Côte d'Ivoire",
      alpha2Code: "CI",
      alpha3Code: "CIV",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/civ.svg",
      callingCodes: ["225"],
      numericCode: "384",
      subregion: "Western Africa"
   },
   {
      name: "Iran (Islamic Republic of)",
      alpha2Code: "IR",
      alpha3Code: "IRN",
      timezones: ["UTC+03:30"],
      flag: "https://restcountries.eu/data/irn.svg",
      callingCodes: ["98"],
      numericCode: "364",
      subregion: "Southern Asia"
   },
   {
      name: "Iraq",
      alpha2Code: "IQ",
      alpha3Code: "IRQ",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/irq.svg",
      callingCodes: ["964"],
      numericCode: "368",
      subregion: "Western Asia"
   },
   {
      name: "Ireland",
      alpha2Code: "IE",
      alpha3Code: "IRL",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/irl.svg",
      callingCodes: ["353"],
      numericCode: "372",
      subregion: "Northern Europe"
   },
   {
      name: "Isle of Man",
      alpha2Code: "IM",
      alpha3Code: "IMN",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/imn.svg",
      callingCodes: ["44"],
      numericCode: "833",
      subregion: "Northern Europe"
   },
   {
      name: "Israel",
      alpha2Code: "IL",
      alpha3Code: "ISR",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/isr.svg",
      callingCodes: ["972"],
      numericCode: "376",
      subregion: "Western Asia"
   },
   {
      name: "Italy",
      alpha2Code: "IT",
      alpha3Code: "ITA",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/ita.svg",
      callingCodes: ["39"],
      numericCode: "380",
      subregion: "Southern Europe"
   },
   {
      name: "Jamaica",
      alpha2Code: "JM",
      alpha3Code: "JAM",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/jam.svg",
      callingCodes: ["1876"],
      numericCode: "388",
      subregion: "Caribbean"
   },
   {
      name: "Japan",
      alpha2Code: "JP",
      alpha3Code: "JPN",
      timezones: ["UTC+09:00"],
      flag: "https://restcountries.eu/data/jpn.svg",
      callingCodes: ["81"],
      numericCode: "392",
      subregion: "Eastern Asia"
   },
   {
      name: "Jersey",
      alpha2Code: "JE",
      alpha3Code: "JEY",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/jey.svg",
      callingCodes: ["44"],
      numericCode: "832",
      subregion: "Northern Europe"
   },
   {
      name: "Jordan",
      alpha2Code: "JO",
      alpha3Code: "JOR",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/jor.svg",
      callingCodes: ["962"],
      numericCode: "400",
      subregion: "Western Asia"
   },
   {
      name: "Kazakhstan",
      alpha2Code: "KZ",
      alpha3Code: "KAZ",
      timezones: ["UTC+05:00", "UTC+06:00"],
      flag: "https://restcountries.eu/data/kaz.svg",
      callingCodes: ["76", "77"],
      numericCode: "398",
      subregion: "Central Asia"
   },
   {
      name: "Kenya",
      alpha2Code: "KE",
      alpha3Code: "KEN",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/ken.svg",
      callingCodes: ["254"],
      numericCode: "404",
      subregion: "Eastern Africa"
   },
   {
      name: "Kiribati",
      alpha2Code: "KI",
      alpha3Code: "KIR",
      timezones: ["UTC+12:00", "UTC+13:00", "UTC+14:00"],
      flag: "https://restcountries.eu/data/kir.svg",
      callingCodes: ["686"],
      numericCode: "296",
      subregion: "Micronesia"
   },
   {
      name: "Kuwait",
      alpha2Code: "KW",
      alpha3Code: "KWT",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/kwt.svg",
      callingCodes: ["965"],
      numericCode: "414",
      subregion: "Western Asia"
   },
   {
      name: "Kyrgyzstan",
      alpha2Code: "KG",
      alpha3Code: "KGZ",
      timezones: ["UTC+06:00"],
      flag: "https://restcountries.eu/data/kgz.svg",
      callingCodes: ["996"],
      numericCode: "417",
      subregion: "Central Asia"
   },
   {
      name: "Lao People's Democratic Republic",
      alpha2Code: "LA",
      alpha3Code: "LAO",
      timezones: ["UTC+07:00"],
      flag: "https://restcountries.eu/data/lao.svg",
      callingCodes: ["856"],
      numericCode: "418",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Latvia",
      alpha2Code: "LV",
      alpha3Code: "LVA",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/lva.svg",
      callingCodes: ["371"],
      numericCode: "428",
      subregion: "Northern Europe"
   },
   {
      name: "Lebanon",
      alpha2Code: "LB",
      alpha3Code: "LBN",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/lbn.svg",
      callingCodes: ["961"],
      numericCode: "422",
      subregion: "Western Asia"
   },
   {
      name: "Lesotho",
      alpha2Code: "LS",
      alpha3Code: "LSO",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/lso.svg",
      callingCodes: ["266"],
      numericCode: "426",
      subregion: "Southern Africa"
   },
   {
      name: "Liberia",
      alpha2Code: "LR",
      alpha3Code: "LBR",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/lbr.svg",
      callingCodes: ["231"],
      numericCode: "430",
      subregion: "Western Africa"
   },
   {
      name: "Libya",
      alpha2Code: "LY",
      alpha3Code: "LBY",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/lby.svg",
      callingCodes: ["218"],
      numericCode: "434",
      subregion: "Northern Africa"
   },
   {
      name: "Liechtenstein",
      alpha2Code: "LI",
      alpha3Code: "LIE",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/lie.svg",
      callingCodes: ["423"],
      numericCode: "438",
      subregion: "Western Europe"
   },
   {
      name: "Lithuania",
      alpha2Code: "LT",
      alpha3Code: "LTU",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/ltu.svg",
      callingCodes: ["370"],
      numericCode: "440",
      subregion: "Northern Europe"
   },
   {
      name: "Luxembourg",
      alpha2Code: "LU",
      alpha3Code: "LUX",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/lux.svg",
      callingCodes: ["352"],
      numericCode: "442",
      subregion: "Western Europe"
   },
   {
      name: "Macao",
      alpha2Code: "MO",
      alpha3Code: "MAC",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/mac.svg",
      callingCodes: ["853"],
      numericCode: "446",
      subregion: "Eastern Asia"
   },
   {
      name: "Macedonia (the former Yugoslav Republic of)",
      alpha2Code: "MK",
      alpha3Code: "MKD",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/mkd.svg",
      callingCodes: ["389"],
      numericCode: "807",
      subregion: "Southern Europe"
   },
   {
      name: "Madagascar",
      alpha2Code: "MG",
      alpha3Code: "MDG",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/mdg.svg",
      callingCodes: ["261"],
      numericCode: "450",
      subregion: "Eastern Africa"
   },
   {
      name: "Malawi",
      alpha2Code: "MW",
      alpha3Code: "MWI",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/mwi.svg",
      callingCodes: ["265"],
      numericCode: "454",
      subregion: "Eastern Africa"
   },
   {
      name: "Malaysia",
      alpha2Code: "MY",
      alpha3Code: "MYS",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/mys.svg",
      callingCodes: ["60"],
      numericCode: "458",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Maldives",
      alpha2Code: "MV",
      alpha3Code: "MDV",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/mdv.svg",
      callingCodes: ["960"],
      numericCode: "462",
      subregion: "Southern Asia"
   },
   {
      name: "Mali",
      alpha2Code: "ML",
      alpha3Code: "MLI",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/mli.svg",
      callingCodes: ["223"],
      numericCode: "466",
      subregion: "Western Africa"
   },
   {
      name: "Malta",
      alpha2Code: "MT",
      alpha3Code: "MLT",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/mlt.svg",
      callingCodes: ["356"],
      numericCode: "470",
      subregion: "Southern Europe"
   },
   {
      name: "Marshall Islands",
      alpha2Code: "MH",
      alpha3Code: "MHL",
      timezones: ["UTC+12:00"],
      flag: "https://restcountries.eu/data/mhl.svg",
      callingCodes: ["692"],
      numericCode: "584",
      subregion: "Micronesia"
   },
   {
      name: "Martinique",
      alpha2Code: "MQ",
      alpha3Code: "MTQ",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/mtq.svg",
      callingCodes: ["596"],
      numericCode: "474",
      subregion: "Caribbean"
   },
   {
      name: "Mauritania",
      alpha2Code: "MR",
      alpha3Code: "MRT",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/mrt.svg",
      callingCodes: ["222"],
      numericCode: "478",
      subregion: "Western Africa"
   },
   {
      name: "Mauritius",
      alpha2Code: "MU",
      alpha3Code: "MUS",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/mus.svg",
      callingCodes: ["230"],
      numericCode: "480",
      subregion: "Eastern Africa"
   },
   {
      name: "Mayotte",
      alpha2Code: "YT",
      alpha3Code: "MYT",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/myt.svg",
      callingCodes: ["262"],
      numericCode: "175",
      subregion: "Eastern Africa"
   },
   {
      name: "Mexico",
      alpha2Code: "MX",
      alpha3Code: "MEX",
      timezones: ["UTC-08:00", "UTC-07:00", "UTC-06:00"],
      flag: "https://restcountries.eu/data/mex.svg",
      callingCodes: ["52"],
      numericCode: "484",
      subregion: "Central America"
   },
   {
      name: "Micronesia (Federated States of)",
      alpha2Code: "FM",
      alpha3Code: "FSM",
      timezones: ["UTC+10:00", "UTC+11"],
      flag: "https://restcountries.eu/data/fsm.svg",
      callingCodes: ["691"],
      numericCode: "583",
      subregion: "Micronesia"
   },
   {
      name: "Moldova (Republic of)",
      alpha2Code: "MD",
      alpha3Code: "MDA",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/mda.svg",
      callingCodes: ["373"],
      numericCode: "498",
      subregion: "Eastern Europe"
   },
   {
      name: "Monaco",
      alpha2Code: "MC",
      alpha3Code: "MCO",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/mco.svg",
      callingCodes: ["377"],
      numericCode: "492",
      subregion: "Western Europe"
   },
   {
      name: "Mongolia",
      alpha2Code: "MN",
      alpha3Code: "MNG",
      timezones: ["UTC+07:00", "UTC+08:00"],
      flag: "https://restcountries.eu/data/mng.svg",
      callingCodes: ["976"],
      numericCode: "496",
      subregion: "Eastern Asia"
   },
   {
      name: "Montenegro",
      alpha2Code: "ME",
      alpha3Code: "MNE",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/mne.svg",
      callingCodes: ["382"],
      numericCode: "499",
      subregion: "Southern Europe"
   },
   {
      name: "Montserrat",
      alpha2Code: "MS",
      alpha3Code: "MSR",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/msr.svg",
      callingCodes: ["1664"],
      numericCode: "500",
      subregion: "Caribbean"
   },
   {
      name: "Morocco",
      alpha2Code: "MA",
      alpha3Code: "MAR",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/mar.svg",
      callingCodes: ["212"],
      numericCode: "504",
      subregion: "Northern Africa"
   },
   {
      name: "Mozambique",
      alpha2Code: "MZ",
      alpha3Code: "MOZ",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/moz.svg",
      callingCodes: ["258"],
      numericCode: "508",
      subregion: "Eastern Africa"
   },
   {
      name: "Myanmar",
      alpha2Code: "MM",
      alpha3Code: "MMR",
      timezones: ["UTC+06:30"],
      flag: "https://restcountries.eu/data/mmr.svg",
      callingCodes: ["95"],
      numericCode: "104",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Namibia",
      alpha2Code: "NA",
      alpha3Code: "NAM",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/nam.svg",
      callingCodes: ["264"],
      numericCode: "516",
      subregion: "Southern Africa"
   },
   {
      name: "Nauru",
      alpha2Code: "NR",
      alpha3Code: "NRU",
      timezones: ["UTC+12:00"],
      flag: "https://restcountries.eu/data/nru.svg",
      callingCodes: ["674"],
      numericCode: "520",
      subregion: "Micronesia"
   },
   {
      name: "Nepal",
      alpha2Code: "NP",
      alpha3Code: "NPL",
      timezones: ["UTC+05:45"],
      flag: "https://restcountries.eu/data/npl.svg",
      callingCodes: ["977"],
      numericCode: "524",
      subregion: "Southern Asia"
   },
   {
      name: "Netherlands",
      alpha2Code: "NL",
      alpha3Code: "NLD",
      timezones: ["UTC-04:00", "UTC+01:00"],
      flag: "https://restcountries.eu/data/nld.svg",
      callingCodes: ["31"],
      numericCode: "528",
      subregion: "Western Europe"
   },
   {
      name: "New Caledonia",
      alpha2Code: "NC",
      alpha3Code: "NCL",
      timezones: ["UTC+11:00"],
      flag: "https://restcountries.eu/data/ncl.svg",
      callingCodes: ["687"],
      numericCode: "540",
      subregion: "Melanesia"
   },
   {
      name: "New Zealand",
      alpha2Code: "NZ",
      alpha3Code: "NZL",
      timezones: ["UTC-11:00", "UTC-10:00", "UTC+12:00", "UTC+12:45", "UTC+13:00"],
      flag: "https://restcountries.eu/data/nzl.svg",
      callingCodes: ["64"],
      numericCode: "554",
      subregion: "Australia and New Zealand"
   },
   {
      name: "Nicaragua",
      alpha2Code: "NI",
      alpha3Code: "NIC",
      timezones: ["UTC-06:00"],
      flag: "https://restcountries.eu/data/nic.svg",
      callingCodes: ["505"],
      numericCode: "558",
      subregion: "Central America"
   },
   {
      name: "Niger",
      alpha2Code: "NE",
      alpha3Code: "NER",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/ner.svg",
      callingCodes: ["227"],
      numericCode: "562",
      subregion: "Western Africa"
   },
   {
      name: "Nigeria",
      alpha2Code: "NG",
      alpha3Code: "NGA",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/nga.svg",
      callingCodes: ["234"],
      numericCode: "566",
      subregion: "Western Africa"
   },
   {
      name: "Niue",
      alpha2Code: "NU",
      alpha3Code: "NIU",
      timezones: ["UTC-11:00"],
      flag: "https://restcountries.eu/data/niu.svg",
      callingCodes: ["683"],
      numericCode: "570",
      subregion: "Polynesia"
   },
   {
      name: "Norfolk Island",
      alpha2Code: "NF",
      alpha3Code: "NFK",
      timezones: ["UTC+11:30"],
      flag: "https://restcountries.eu/data/nfk.svg",
      callingCodes: ["672"],
      numericCode: "574",
      subregion: "Australia and New Zealand"
   },
   {
      name: "Korea (Democratic People's Republic of)",
      alpha2Code: "KP",
      alpha3Code: "PRK",
      timezones: ["UTC+09:00"],
      flag: "https://restcountries.eu/data/prk.svg",
      callingCodes: ["850"],
      numericCode: "408",
      subregion: "Eastern Asia"
   },
   {
      name: "Northern Mariana Islands",
      alpha2Code: "MP",
      alpha3Code: "MNP",
      timezones: ["UTC+10:00"],
      flag: "https://restcountries.eu/data/mnp.svg",
      callingCodes: ["1670"],
      numericCode: "580",
      subregion: "Micronesia"
   },
   {
      name: "Norway",
      alpha2Code: "NO",
      alpha3Code: "NOR",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/nor.svg",
      callingCodes: ["47"],
      numericCode: "578",
      subregion: "Northern Europe"
   },
   {
      name: "Oman",
      alpha2Code: "OM",
      alpha3Code: "OMN",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/omn.svg",
      callingCodes: ["968"],
      numericCode: "512",
      subregion: "Western Asia"
   },
   {
      name: "Pakistan",
      alpha2Code: "PK",
      alpha3Code: "PAK",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/pak.svg",
      callingCodes: ["92"],
      numericCode: "586",
      subregion: "Southern Asia"
   },
   {
      name: "Palau",
      alpha2Code: "PW",
      alpha3Code: "PLW",
      timezones: ["UTC+09:00"],
      flag: "https://restcountries.eu/data/plw.svg",
      callingCodes: ["680"],
      numericCode: "585",
      subregion: "Micronesia"
   },
   {
      name: "Palestine, State of",
      alpha2Code: "PS",
      alpha3Code: "PSE",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/pse.svg",
      callingCodes: ["970"],
      numericCode: "275",
      subregion: "Western Asia"
   },
   {
      name: "Panama",
      alpha2Code: "PA",
      alpha3Code: "PAN",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/pan.svg",
      callingCodes: ["507"],
      numericCode: "591",
      subregion: "Central America"
   },
   {
      name: "Papua New Guinea",
      alpha2Code: "PG",
      alpha3Code: "PNG",
      timezones: ["UTC+10:00"],
      flag: "https://restcountries.eu/data/png.svg",
      callingCodes: ["675"],
      numericCode: "598",
      subregion: "Melanesia"
   },
   {
      name: "Paraguay",
      alpha2Code: "PY",
      alpha3Code: "PRY",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/pry.svg",
      callingCodes: ["595"],
      numericCode: "600",
      subregion: "South America"
   },
   {
      name: "Peru",
      alpha2Code: "PE",
      alpha3Code: "PER",
      timezones: ["UTC-05:00"],
      flag: "https://restcountries.eu/data/per.svg",
      callingCodes: ["51"],
      numericCode: "604",
      subregion: "South America"
   },
   {
      name: "Philippines",
      alpha2Code: "PH",
      alpha3Code: "PHL",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/phl.svg",
      callingCodes: ["63"],
      numericCode: "608",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Pitcairn",
      alpha2Code: "PN",
      alpha3Code: "PCN",
      timezones: ["UTC-08:00"],
      flag: "https://restcountries.eu/data/pcn.svg",
      callingCodes: ["64"],
      numericCode: "612",
      subregion: "Polynesia"
   },
   {
      name: "Poland",
      alpha2Code: "PL",
      alpha3Code: "POL",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/pol.svg",
      callingCodes: ["48"],
      numericCode: "616",
      subregion: "Eastern Europe"
   },
   {
      name: "Portugal",
      alpha2Code: "PT",
      alpha3Code: "PRT",
      timezones: ["UTC-01:00", "UTC"],
      flag: "https://restcountries.eu/data/prt.svg",
      callingCodes: ["351"],
      numericCode: "620",
      subregion: "Southern Europe"
   },
   {
      name: "Puerto Rico",
      alpha2Code: "PR",
      alpha3Code: "PRI",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/pri.svg",
      callingCodes: ["1787", "1939"],
      numericCode: "630",
      subregion: "Caribbean"
   },
   {
      name: "Qatar",
      alpha2Code: "QA",
      alpha3Code: "QAT",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/qat.svg",
      callingCodes: ["974"],
      numericCode: "634",
      subregion: "Western Asia"
   },
   {
      name: "Republic of Kosovo",
      alpha2Code: "XK",
      alpha3Code: "KOS",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/kos.svg",
      callingCodes: ["383"],
      numericCode: null,
      subregion: "Eastern Europe"
   },
   {
      name: "Réunion",
      alpha2Code: "RE",
      alpha3Code: "REU",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/reu.svg",
      callingCodes: ["262"],
      numericCode: "638",
      subregion: "Eastern Africa"
   },
   {
      name: "Romania",
      alpha2Code: "RO",
      alpha3Code: "ROU",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/rou.svg",
      callingCodes: ["40"],
      numericCode: "642",
      subregion: "Eastern Europe"
   },
   {
      name: "Russian Federation",
      alpha2Code: "RU",
      alpha3Code: "RUS",
      timezones: [
         "UTC+03:00",
         "UTC+04:00",
         "UTC+06:00",
         "UTC+07:00",
         "UTC+08:00",
         "UTC+09:00",
         "UTC+10:00",
         "UTC+11:00",
         "UTC+12:00"
      ],
      flag: "https://restcountries.eu/data/rus.svg",
      callingCodes: ["7"],
      numericCode: "643",
      subregion: "Eastern Europe"
   },
   {
      name: "Rwanda",
      alpha2Code: "RW",
      alpha3Code: "RWA",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/rwa.svg",
      callingCodes: ["250"],
      numericCode: "646",
      subregion: "Eastern Africa"
   },
   {
      name: "Saint Barthélemy",
      alpha2Code: "BL",
      alpha3Code: "BLM",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/blm.svg",
      callingCodes: ["590"],
      numericCode: "652",
      subregion: "Caribbean"
   },
   {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      alpha2Code: "SH",
      alpha3Code: "SHN",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/shn.svg",
      callingCodes: ["290"],
      numericCode: "654",
      subregion: "Western Africa"
   },
   {
      name: "Saint Kitts and Nevis",
      alpha2Code: "KN",
      alpha3Code: "KNA",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/kna.svg",
      callingCodes: ["1869"],
      numericCode: "659",
      subregion: "Caribbean"
   },
   {
      name: "Saint Lucia",
      alpha2Code: "LC",
      alpha3Code: "LCA",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/lca.svg",
      callingCodes: ["1758"],
      numericCode: "662",
      subregion: "Caribbean"
   },
   {
      name: "Saint Martin (French part)",
      alpha2Code: "MF",
      alpha3Code: "MAF",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/maf.svg",
      callingCodes: ["590"],
      numericCode: "663",
      subregion: "Caribbean"
   },
   {
      name: "Saint Pierre and Miquelon",
      alpha2Code: "PM",
      alpha3Code: "SPM",
      timezones: ["UTC-03:00"],
      flag: "https://restcountries.eu/data/spm.svg",
      callingCodes: ["508"],
      numericCode: "666",
      subregion: "Northern America"
   },
   {
      name: "Saint Vincent and the Grenadines",
      alpha2Code: "VC",
      alpha3Code: "VCT",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/vct.svg",
      callingCodes: ["1784"],
      numericCode: "670",
      subregion: "Caribbean"
   },
   {
      name: "Samoa",
      alpha2Code: "WS",
      alpha3Code: "WSM",
      timezones: ["UTC+13:00"],
      flag: "https://restcountries.eu/data/wsm.svg",
      callingCodes: ["685"],
      numericCode: "882",
      subregion: "Polynesia"
   },
   {
      name: "San Marino",
      alpha2Code: "SM",
      alpha3Code: "SMR",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/smr.svg",
      callingCodes: ["378"],
      numericCode: "674",
      subregion: "Southern Europe"
   },
   {
      name: "Sao Tome and Principe",
      alpha2Code: "ST",
      alpha3Code: "STP",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/stp.svg",
      callingCodes: ["239"],
      numericCode: "678",
      subregion: "Middle Africa"
   },
   {
      name: "Saudi Arabia",
      alpha2Code: "SA",
      alpha3Code: "SAU",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/sau.svg",
      callingCodes: ["966"],
      numericCode: "682",
      subregion: "Western Asia"
   },
   {
      name: "Senegal",
      alpha2Code: "SN",
      alpha3Code: "SEN",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/sen.svg",
      callingCodes: ["221"],
      numericCode: "686",
      subregion: "Western Africa"
   },
   {
      name: "Serbia",
      alpha2Code: "RS",
      alpha3Code: "SRB",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/srb.svg",
      callingCodes: ["381"],
      numericCode: "688",
      subregion: "Southern Europe"
   },
   {
      name: "Seychelles",
      alpha2Code: "SC",
      alpha3Code: "SYC",
      timezones: ["UTC+04:00"],
      flag: "https://restcountries.eu/data/syc.svg",
      callingCodes: ["248"],
      numericCode: "690",
      subregion: "Eastern Africa"
   },
   {
      name: "Sierra Leone",
      alpha2Code: "SL",
      alpha3Code: "SLE",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/sle.svg",
      callingCodes: ["232"],
      numericCode: "694",
      subregion: "Western Africa"
   },
   {
      name: "Singapore",
      alpha2Code: "SG",
      alpha3Code: "SGP",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/sgp.svg",
      callingCodes: ["65"],
      numericCode: "702",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Sint Maarten (Dutch part)",
      alpha2Code: "SX",
      alpha3Code: "SXM",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/sxm.svg",
      callingCodes: ["1721"],
      numericCode: "534",
      subregion: "Caribbean"
   },
   {
      name: "Slovakia",
      alpha2Code: "SK",
      alpha3Code: "SVK",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/svk.svg",
      callingCodes: ["421"],
      numericCode: "703",
      subregion: "Eastern Europe"
   },
   {
      name: "Slovenia",
      alpha2Code: "SI",
      alpha3Code: "SVN",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/svn.svg",
      callingCodes: ["386"],
      numericCode: "705",
      subregion: "Southern Europe"
   },
   {
      name: "Solomon Islands",
      alpha2Code: "SB",
      alpha3Code: "SLB",
      timezones: ["UTC+11:00"],
      flag: "https://restcountries.eu/data/slb.svg",
      callingCodes: ["677"],
      numericCode: "090",
      subregion: "Melanesia"
   },
   {
      name: "Somalia",
      alpha2Code: "SO",
      alpha3Code: "SOM",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/som.svg",
      callingCodes: ["252"],
      numericCode: "706",
      subregion: "Eastern Africa"
   },
   {
      name: "South Africa",
      alpha2Code: "ZA",
      alpha3Code: "ZAF",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/zaf.svg",
      callingCodes: ["27"],
      numericCode: "710",
      subregion: "Southern Africa"
   },
   {
      name: "South Georgia and the South Sandwich Islands",
      alpha2Code: "GS",
      alpha3Code: "SGS",
      timezones: ["UTC-02:00"],
      flag: "https://restcountries.eu/data/sgs.svg",
      callingCodes: ["500"],
      numericCode: "239",
      subregion: "South America"
   },
   {
      name: "Korea (Republic of)",
      alpha2Code: "KR",
      alpha3Code: "KOR",
      timezones: ["UTC+09:00"],
      flag: "https://restcountries.eu/data/kor.svg",
      callingCodes: ["82"],
      numericCode: "410",
      subregion: "Eastern Asia"
   },
   {
      name: "South Sudan",
      alpha2Code: "SS",
      alpha3Code: "SSD",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/ssd.svg",
      callingCodes: ["211"],
      numericCode: "728",
      subregion: "Middle Africa"
   },
   {
      name: "Spain",
      alpha2Code: "ES",
      alpha3Code: "ESP",
      timezones: ["UTC", "UTC+01:00"],
      flag: "https://restcountries.eu/data/esp.svg",
      callingCodes: ["34"],
      numericCode: "724",
      subregion: "Southern Europe"
   },
   {
      name: "Sri Lanka",
      alpha2Code: "LK",
      alpha3Code: "LKA",
      timezones: ["UTC+05:30"],
      flag: "https://restcountries.eu/data/lka.svg",
      callingCodes: ["94"],
      numericCode: "144",
      subregion: "Southern Asia"
   },
   {
      name: "Sudan",
      alpha2Code: "SD",
      alpha3Code: "SDN",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/sdn.svg",
      callingCodes: ["249"],
      numericCode: "729",
      subregion: "Northern Africa"
   },
   {
      name: "Suriname",
      alpha2Code: "SR",
      alpha3Code: "SUR",
      timezones: ["UTC-03:00"],
      flag: "https://restcountries.eu/data/sur.svg",
      callingCodes: ["597"],
      numericCode: "740",
      subregion: "South America"
   },
   {
      name: "Svalbard and Jan Mayen",
      alpha2Code: "SJ",
      alpha3Code: "SJM",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/sjm.svg",
      callingCodes: ["4779"],
      numericCode: "744",
      subregion: "Northern Europe"
   },
   {
      name: "Swaziland",
      alpha2Code: "SZ",
      alpha3Code: "SWZ",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/swz.svg",
      callingCodes: ["268"],
      numericCode: "748",
      subregion: "Southern Africa"
   },
   {
      name: "Sweden",
      alpha2Code: "SE",
      alpha3Code: "SWE",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/swe.svg",
      callingCodes: ["46"],
      numericCode: "752",
      subregion: "Northern Europe"
   },
   {
      name: "Switzerland",
      alpha2Code: "CH",
      alpha3Code: "CHE",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/che.svg",
      callingCodes: ["41"],
      numericCode: "756",
      subregion: "Western Europe"
   },
   {
      name: "Syrian Arab Republic",
      alpha2Code: "SY",
      alpha3Code: "SYR",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/syr.svg",
      callingCodes: ["963"],
      numericCode: "760",
      subregion: "Western Asia"
   },
   {
      name: "Taiwan",
      alpha2Code: "TW",
      alpha3Code: "TWN",
      timezones: ["UTC+08:00"],
      flag: "https://restcountries.eu/data/twn.svg",
      callingCodes: ["886"],
      numericCode: "158",
      subregion: "Eastern Asia"
   },
   {
      name: "Tajikistan",
      alpha2Code: "TJ",
      alpha3Code: "TJK",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/tjk.svg",
      callingCodes: ["992"],
      numericCode: "762",
      subregion: "Central Asia"
   },
   {
      name: "Tanzania, United Republic of",
      alpha2Code: "TZ",
      alpha3Code: "TZA",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/tza.svg",
      callingCodes: ["255"],
      numericCode: "834",
      subregion: "Eastern Africa"
   },
   {
      name: "Thailand",
      alpha2Code: "TH",
      alpha3Code: "THA",
      timezones: ["UTC+07:00"],
      flag: "https://restcountries.eu/data/tha.svg",
      callingCodes: ["66"],
      numericCode: "764",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Timor-Leste",
      alpha2Code: "TL",
      alpha3Code: "TLS",
      timezones: ["UTC+09:00"],
      flag: "https://restcountries.eu/data/tls.svg",
      callingCodes: ["670"],
      numericCode: "626",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Togo",
      alpha2Code: "TG",
      alpha3Code: "TGO",
      timezones: ["UTC"],
      flag: "https://restcountries.eu/data/tgo.svg",
      callingCodes: ["228"],
      numericCode: "768",
      subregion: "Western Africa"
   },
   {
      name: "Tokelau",
      alpha2Code: "TK",
      alpha3Code: "TKL",
      timezones: ["UTC+13:00"],
      flag: "https://restcountries.eu/data/tkl.svg",
      callingCodes: ["690"],
      numericCode: "772",
      subregion: "Polynesia"
   },
   {
      name: "Tonga",
      alpha2Code: "TO",
      alpha3Code: "TON",
      timezones: ["UTC+13:00"],
      flag: "https://restcountries.eu/data/ton.svg",
      callingCodes: ["676"],
      numericCode: "776",
      subregion: "Polynesia"
   },
   {
      name: "Trinidad and Tobago",
      alpha2Code: "TT",
      alpha3Code: "TTO",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/tto.svg",
      callingCodes: ["1868"],
      numericCode: "780",
      subregion: "Caribbean"
   },
   {
      name: "Tunisia",
      alpha2Code: "TN",
      alpha3Code: "TUN",
      timezones: ["UTC+01:00"],
      flag: "https://restcountries.eu/data/tun.svg",
      callingCodes: ["216"],
      numericCode: "788",
      subregion: "Northern Africa"
   },
   {
      name: "Turkey",
      alpha2Code: "TR",
      alpha3Code: "TUR",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/tur.svg",
      callingCodes: ["90"],
      numericCode: "792",
      subregion: "Western Asia"
   },
   {
      name: "Turkmenistan",
      alpha2Code: "TM",
      alpha3Code: "TKM",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/tkm.svg",
      callingCodes: ["993"],
      numericCode: "795",
      subregion: "Central Asia"
   },
   {
      name: "Turks and Caicos Islands",
      alpha2Code: "TC",
      alpha3Code: "TCA",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/tca.svg",
      callingCodes: ["1649"],
      numericCode: "796",
      subregion: "Caribbean"
   },
   {
      name: "Tuvalu",
      alpha2Code: "TV",
      alpha3Code: "TUV",
      timezones: ["UTC+12:00"],
      flag: "https://restcountries.eu/data/tuv.svg",
      callingCodes: ["688"],
      numericCode: "798",
      subregion: "Polynesia"
   },
   {
      name: "Uganda",
      alpha2Code: "UG",
      alpha3Code: "UGA",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/uga.svg",
      callingCodes: ["256"],
      numericCode: "800",
      subregion: "Eastern Africa"
   },
   {
      name: "Ukraine",
      alpha2Code: "UA",
      alpha3Code: "UKR",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/ukr.svg",
      callingCodes: ["380"],
      numericCode: "804",
      subregion: "Eastern Europe"
   },
   {
      name: "United Arab Emirates",
      alpha2Code: "AE",
      alpha3Code: "ARE",
      timezones: ["UTC+04"],
      flag: "https://restcountries.eu/data/are.svg",
      callingCodes: ["971"],
      numericCode: "784",
      subregion: "Western Asia"
   },
   {
      name: "United Kingdom of Great Britain and Northern Ireland",
      alpha2Code: "GB",
      alpha3Code: "GBR",
      timezones: [
         "UTC-08:00",
         "UTC-05:00",
         "UTC-04:00",
         "UTC-03:00",
         "UTC-02:00",
         "UTC",
         "UTC+01:00",
         "UTC+02:00",
         "UTC+06:00"
      ],
      flag: "https://restcountries.eu/data/gbr.svg",
      callingCodes: ["44"],
      numericCode: "826",
      subregion: "Northern Europe"
   },
   {
      name: "United States of America",
      alpha2Code: "US",
      alpha3Code: "USA",
      timezones: [
         "UTC-12:00",
         "UTC-11:00",
         "UTC-10:00",
         "UTC-09:00",
         "UTC-08:00",
         "UTC-07:00",
         "UTC-06:00",
         "UTC-05:00",
         "UTC-04:00",
         "UTC+10:00",
         "UTC+12:00"
      ],
      flag: "https://restcountries.eu/data/usa.svg",
      callingCodes: ["1"],
      numericCode: "840",
      subregion: "Northern America"
   },
   {
      name: "Uruguay",
      alpha2Code: "UY",
      alpha3Code: "URY",
      timezones: ["UTC-03:00"],
      flag: "https://restcountries.eu/data/ury.svg",
      callingCodes: ["598"],
      numericCode: "858",
      subregion: "South America"
   },
   {
      name: "Uzbekistan",
      alpha2Code: "UZ",
      alpha3Code: "UZB",
      timezones: ["UTC+05:00"],
      flag: "https://restcountries.eu/data/uzb.svg",
      callingCodes: ["998"],
      numericCode: "860",
      subregion: "Central Asia"
   },
   {
      name: "Vanuatu",
      alpha2Code: "VU",
      alpha3Code: "VUT",
      timezones: ["UTC+11:00"],
      flag: "https://restcountries.eu/data/vut.svg",
      callingCodes: ["678"],
      numericCode: "548",
      subregion: "Melanesia"
   },
   {
      name: "Venezuela (Bolivarian Republic of)",
      alpha2Code: "VE",
      alpha3Code: "VEN",
      timezones: ["UTC-04:00"],
      flag: "https://restcountries.eu/data/ven.svg",
      callingCodes: ["58"],
      numericCode: "862",
      subregion: "South America"
   },
   {
      name: "Viet Nam",
      alpha2Code: "VN",
      alpha3Code: "VNM",
      timezones: ["UTC+07:00"],
      flag: "https://restcountries.eu/data/vnm.svg",
      callingCodes: ["84"],
      numericCode: "704",
      subregion: "South-Eastern Asia"
   },
   {
      name: "Wallis and Futuna",
      alpha2Code: "WF",
      alpha3Code: "WLF",
      timezones: ["UTC+12:00"],
      flag: "https://restcountries.eu/data/wlf.svg",
      callingCodes: ["681"],
      numericCode: "876",
      subregion: "Polynesia"
   },
   {
      name: "Western Sahara",
      alpha2Code: "EH",
      alpha3Code: "ESH",
      timezones: ["UTC+00:00"],
      flag: "https://restcountries.eu/data/esh.svg",
      callingCodes: ["212"],
      numericCode: "732",
      subregion: "Northern Africa"
   },
   {
      name: "Yemen",
      alpha2Code: "YE",
      alpha3Code: "YEM",
      timezones: ["UTC+03:00"],
      flag: "https://restcountries.eu/data/yem.svg",
      callingCodes: ["967"],
      numericCode: "887",
      subregion: "Western Asia"
   },
   {
      name: "Zambia",
      alpha2Code: "ZM",
      alpha3Code: "ZMB",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/zmb.svg",
      callingCodes: ["260"],
      numericCode: "894",
      subregion: "Eastern Africa"
   },
   {
      name: "Zimbabwe",
      alpha2Code: "ZW",
      alpha3Code: "ZWE",
      timezones: ["UTC+02:00"],
      flag: "https://restcountries.eu/data/zwe.svg",
      callingCodes: ["263"],
      numericCode: "716",
      subregion: "Eastern Africa"
   }
];
