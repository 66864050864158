export default class Utils {
  constructor() {}

  getObjectValue(o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  format(formatString, data) {
    let _data = "",
      count = 0;
    for (let i = 0; i < formatString.length; i++) {
      const letter = formatString[i];

      if (letter === "#") {
        _data += data[count];
        count++;
      } else {
        _data += letter;
      }
    }

    return _data;
  }

  appendOptions(data, value, label, id) {
    let options = [];
    for (let i = 0; i < data.length; i++) {
      const _data = data[i];
      options.push({
        val: _data[value],
        label: _data[label],
        id: _data[id],
      });
    }

    return options;
  }

  addScript(src) {
    var s = document.createElement("script");
    s.setAttribute("src", src);
    document.body.appendChild(s);
  }

  printAppInfo() {
    let primary = getComputedStyle(document.documentElement).getPropertyValue(
      "--primary"
    );
    let secondary = getComputedStyle(document.documentElement).getPropertyValue(
      "--secondary"
    );

    console.log(
      "%cDeveloped by:" + "%cgeoffreykariithi@gmail.com",
      `background: ${primary}; color: #FFF; padding: 1px 5px;border-radius: 3px 0 0 3px;`,
      `background: ${secondary}; color: #FFF; padding: 1px 5px;border-radius: 0 3px 3px 0;`
    );
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  isValid(errorBag, keyword) {
    let errMsg = "",
      status = true,
      count = 0;
    for (let i = 0; i < errorBag.length; i++) {
      const err = errorBag[i];

      if (err.field.includes(keyword + " ")) {
        count++;
        errMsg += err.msg + "<br/>";
        status = false;
      }
    }

    if (count > 3) {
      errMsg = "You have validation errors on a number of fields";
    }
    notification.notify("warning", "Validation Errors", errMsg);
    return status;
  }

  cleanAndStoreCountries(countries) {
    return new Promise((resolve, reject) => {
      try {
        let _countries = [];

        for (let i = 0; i < countries.length; i++) {
          const country = countries[i];

          _countries.push({
            name: country.name,
            alpha2Code: country.alpha2Code,
            alpha3Code: country.alpha3Code,
            timezones: country.timezones,
            // currencies: country.currencies,
            flag: country.flag,
            // latlng: country.latlng,
            callingCodes: country.callingCodes,
            // region: country.region,
            numericCode: country.numericCode,
            subregion: country.subregion,
          });
        }

        $session.set("countries", _countries);
        resolve(_countries);
      } catch (err) {
        reject(err);
      }
    });
  }

  sleep(duration) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, duration * 1000);
    });
  }

  getMapStyles() {
    var style = `[{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"stylers":[{"hue":"#00aaff"},{"saturation":-100},{"gamma":2.15},{"lightness":12}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"lightness":24}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":57}]}]`;

    return JSON.parse(style);
  }

  getDummyData(Model, noOfRecords) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await gen.fetchData(
          Model.name,
          Model.gen_template,
          noOfRecords
        );
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  async generateTemplate(Model) {
    return new Promise((resolve, reject) => {
      try {
        let template = {};

        if (Model.wizard.pages) {
          for (let i = 0; i < Model.wizard.pages.length; i++) {
            const page = Model.wizard.pages[i];

            if (page && page.forms) {
              for (let j = 0; j < page.forms.length; j++) {
                const form = page.forms[j];

                if (form.type === "mobile") {
                  template[form.model] = {
                    country_code: "",
                    number: "",
                  };
                } else if (form.type === "date") {
                  template[form.model] = {
                    date: "",
                    time: "",
                  };
                } else if (form.type === "label") {
                  // do nothing
                } else {
                  template[form.model] = "";
                }
              }
            }
          }
        }

        resolve(template);
      } catch (err) {
        reject(err);
      }
    });
  }
}
