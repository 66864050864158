import Request from '../requests'
import countries from './countries';
let req = new Request()

export default class ApiRequests {
   constructor(Event,Vue) {
      this.Event = Event
      this.fetchCountries()
   }

   getReq(){
      return req;
   }
   
   //Fetch available countries from the api
   fetchCountries() {
      let _this = this;
      return new Promise(async (resolve, reject) => {
         try {
            await $session.set("countries", countries);
            resolve(countries)
         } catch (error) {
            reject(error)
         }
      });
   }

   fetchClients() {
      return new Promise(async (resolve,reject) => {
         try {
            let res = await req.callServerSecure({
               action: "list",
               resource: "client",
               data: {},
               meta_data: {},
            });
            
           // Event.$emit('updatedClients', res.data.result)
            resolve(res.data.result)
         } catch (err) {
            reject(err)
         }
      });
   }

   fetchInsuranceClasses() {
      return new Promise(async (resolve,reject) => {
         try {
            let res = await req.callServerSecure({
               resource: "insurance_class",
               action: "details",
               data: {},
               meta_data: {},
            });
            
           // Event.$emit('updatedInsuranceClassess', res.data.result)
            resolve(res.data.result)
         } catch (err) {
            reject(err)
         }
      });
   }

   fetchUsers() {
      return new Promise(async (resolve,reject) => {
         try {
            let res = await req.callServerSecure({
               action: "list",
               resource: "user",
               data: {},
               meta_data: {},
            });
            
           // Event.$emit('users:updated', res.data.result)
            resolve(res.data.result)
         } catch (err) {
            reject(err)
         }
      });
   }
}