module.exports = {
    name: 'bank',
    wizard: {
        add_bank: {
            actionBtn: 'Save',
            action: 'add_bank',
            pages: [{
                title: 'Bank',
                subtitle: 'Add a new bank',
                forms: [{
                        type: 'text',
                        form_type: 'text',
                        label: 'Name',
                        model: 'name',
                        validation: 'required|min:2|max:50',
                        class: 'col-12'
                    },
                    {
                        type: 'text',
                        form_type: 'text',
                        label: 'Code',
                        model: 'code',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'text',
                        label: 'Bank Code',
                        model: 'bank_code',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'textarea',
                        form_type: 'text',
                        label: 'Description',
                        model: 'description',
                        validation: 'required|min:50|max:300',
                        class: 'col-12'
                    }
                ],
            }]
        },
        add_super_user: {
            actionBtn: 'Save',
            action: 'bank_super_user',
            pages: [{
                title: 'Superadmin Details',
                subtitle: 'Confgure the bank superadmin',
                forms: [{
                        type: 'text',
                        form_type: 'text',
                        label: 'First Name',
                        model: 'first_name',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'text',
                        label: 'Last Name',
                        model: 'last_name',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'text',
                        label: 'Employee Ref',
                        model: 'employee_ref',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'email',
                        label: 'Email',
                        model: 'email',
                        validation: 'required|min:2|max:150|email',
                        class: 'col-12 col-md-6'
                    }
                ],
            }, ]
        },
        add_director: {
            actionBtn: 'Save',
            action: 'director',
            pages: [{
                title: 'Director',
                subtitle: 'Add Director',
                forms: [{
                        type: 'text',
                        form_type: 'text',
                        label: 'First Name',
                        model: 'first_name',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'text',
                        label: 'Last Name',
                        model: 'last_name',
                        validation: 'required|min:2|max:50',
                        class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text',
                        form_type: 'email',
                        label: 'Email',
                        model: 'email',
                        validation: 'required|min:2|max:150',
                        class: 'col-12 col-lg-6'
                    },
                    {
                        type: 'mobile',
                        class: 'col-12 col-lg-6',
                        model: 'contact',
                        validation: 'required|digits:9',
                        label: 'Contacts',
                        placeholder: 'Enter the phone number'
                    },
                ],
            }]
        }
    },
    table: {
        bank: {
            title: 'Banks',
            subtitle: 'A list of configured banks',
            table_fields: [
                { name: 'Code', align: 'l' },
                { name: 'Bank Code', align: 'l' },
                { name: 'Name', align: 'l' },
                { name: 'actions', align: 'c' }
            ],
            searchFields: ["names", "contact", "email"],
            rowData: [
                { type: 'text', field: 'code', class: 'left small bolder' },
                { type: 'text', field: 'bank_code', class: 'left small bolder' },
                { type: 'text', field: 'name', class: 'left' },
                {
                    type: 'action',
                    field: 'name',
                    class: 'center',
                    icon: '',
                    actions: [{
                            type: 'menu',
                            title: 'Add Super User',
                            event: 'bank:add_super_user',
                            icon: 'edit-3',
                            params: [
                                { key: 'bank', field: '*' }
                            ]
                        },
                        {
                            type: 'menu',
                            title: 'Edit',
                            event: 'bank:edit',
                            icon: 'edit-3',
                            params: [
                                { key: 'bank', field: '*' }
                            ]
                        },
                        {
                            type: 'menu',
                            title: 'View',
                            event: 'bank:view',
                            icon: 'eye',
                            params: [
                                { key: 'code', field: 'code' }
                            ]
                        },
                        {
                            type: 'menu',
                            title: 'Delete',
                            event: 'bank:delete',
                            icon: 'delete',
                            params: [
                                { key: 'email', field: 'email' }
                            ]
                        },
                    ]
                },
            ]
        },
        super_user: {
            title: 'Superusers',
            subtitle: 'A list of configured super users',
            table_fields: [
                { name: 'Profile', align: 'l' },
                { name: 'Names', align: 'l' },
                { name: 'Email', align: 'l' },
                { name: 'Contact', align: 'l' },
                { name: 'actions', align: 'c' }
            ],
            searchFields: ["names", "contact", "email"],
            rowData: [
                { type: 'image', field: 'image', class: 'left' },
                { type: 'text', field: 'names', class: 'left' },
                { type: 'text', field: 'email', class: 'left' },
                { type: 'text', field: 'contact', class: 'left' },
                {
                    type: 'action',
                    field: 'name',
                    class: 'center',
                    icon: '',
                    actions: [
                        // {type: 'title', title: 'Actions'},
                        {
                            type: 'menu',
                            title: 'Edit',
                            event: 'client:edit',
                            icon: 'ico-right',
                            params: [
                                { key: 'client', field: '*' }
                            ]
                        },
                        {
                            type: 'menu',
                            title: 'View',
                            event: 'client:view',
                            icon: 'ico-reply',
                            params: [
                                { key: 'email', field: 'email' },
                                { key: 'name', field: 'name' }
                            ]
                        },
                        // {type: 'divider'},
                        {
                            type: 'menu',
                            title: 'Delete',
                            event: 'client:delete',
                            icon: 'ico-mail-a',
                            params: [
                                { key: 'email', field: 'email' }
                            ]
                        },
                    ]
                },
            ]
        },
        directors: {
            title: 'Directors',
            subtitle: 'A list of configured company directors',
            table_fields: [
                { name: 'Profile', align: 'l' },
                { name: 'Names', align: 'l' },
                { name: 'Email', align: 'l' },
                { name: 'Contact', align: 'l' },
                { name: 'actions', align: 'c' }
            ],
            searchFields: ["names", "contact", "email"],
            rowData: [
                { type: 'image', field: 'image', class: 'left' },
                { type: 'text', field: 'names', class: 'left' },
                { type: 'text', field: 'email', class: 'left' },
                { type: 'text', field: 'contact', class: 'left' },
                {
                    type: 'action',
                    field: 'name',
                    class: 'center',
                    icon: '',
                    actions: [
                        // {type: 'title', title: 'Actions'},
                        {
                            type: 'menu',
                            title: 'Edit',
                            event: 'client:edit',
                            icon: 'ico-right',
                            params: [
                                { key: 'client', field: '*' }
                            ]
                        },
                        {
                            type: 'menu',
                            title: 'View',
                            event: 'client:view',
                            icon: 'ico-reply',
                            params: [
                                { key: 'email', field: 'email' },
                                { key: 'name', field: 'name' }
                            ]
                        },
                        // {type: 'divider'},
                        {
                            type: 'menu',
                            title: 'Delete',
                            event: 'client:delete',
                            icon: 'ico-mail-a',
                            params: [
                                { key: 'email', field: 'email' }
                            ]
                        },
                    ]
                },
            ]
        }
    },
}