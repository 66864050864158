<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Sub General Ledger</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add Sub General Ledger</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="code">Subgl Code</label>
      <input type="text" class="form-control" id="code" placeholder="Code" v-model="subgl.sub_gl_header_code">
    </div>
    <div class="form-group col-md-6">
      <label for="name">Subgl Name</label>
      <input type="text" class="form-control" id="name" v-model="subgl.sub_gl_header_name" placeholder="Name">
    </div>
  </div>
    <div class="form-group">
       <label for="description">Subgl Description</label>
       <input type="text" class="form-control" id="description" v-model="subgl.sub_gl_header_description" placeholder="Description">
     </div>
    <div class="form-group">
        <label for="description">Select General Ledger</label>
        <select class="custom-select" v-model="gl_header_system_ref">
        <option v-for="gl in gls" v-bind:value="gl.gl_header_system_ref">
            {{ gl.gl_header_name }}
        </option>
        </select>
    </div>
 <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveSubGl()" class="btn btn-primary">Add Sub General Ledger</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             subgl:{
                sub_gl_header_code:"",
                sub_gl_header_name:"",
                sub_gl_header_description:""
             },
             gls:[],
             gl_header_system_ref:""
         }

      },
    methods: {
       async saveSubGl () {
          try {
                await req.callServerSecure({
                     resource: "sub_gl",
                     action: "create",
                     data: {
                        gl_header_system_ref: this.gl_header_system_ref,
                        sub_gl_header_name: this.subgl.sub_gl_header_name,
                        sub_gl_header_code: this.subgl.sub_gl_header_code,
                        sub_gl_header_description: this.subgl.sub_gl_header_description,
                     },
                     meta_data: {},
                  });
                notification.notify('success', 'Sub GL Added', `The gl ${this.subgl.sub_gl_header_name} was successfully added`)
                this.$router.push({ name: 'accounting.subgl.listSubGl' })
  
          }
          catch (err) {
              console.log(err)
                  notification.notify('error', err)
          }
        },
     async fetchGl () {
         try {
               let resp= await req.callServerSecure({
                     resource: "gl",
                     action: "list",
                     data: {}
                  });
                  this.gls=resp.data.result.gl_headers
         }
         catch (err) {
         console.log(err)
         }
     
    },
    }, 
    async mounted(){
        await this.fetchGl()
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>