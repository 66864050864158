<template lang="html">
    <div class="security-context">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddSecurityContext()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Security Context
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="SecurityContext.table.table_fields" 
              :tableData="securityContexts" 
              :title="SecurityContext.table.title" 
              :subtitle="SecurityContext.table.subtitle" 
              :searchFields="SecurityContext.table.searchFields"
              :rowData="SecurityContext.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.SecurityContext = Models.SecurityContext;

    Event.$on("form:submit", async (payload) => {
      console.log(payload);
    });

    Event.$on("securityContext:view", async (payload) => {
      console.log("pyalo", payload.SecurityContext);
      this.$router.push({
        name: "view_security_context",
        params: {
          id: payload.SecurityContext.security_context_id
        },
      });
    });
  },
  data() {
    return {
      SecurityContext: {},
      securityContexts: [],
    };
  },
  methods: {
    openAddSecurityContext() {
      this.$router.push({
        name: "add_security_context",
        params: {},
      });
    },
    async listSecurityContexts() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "security_context",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.securityContexts = res.data.result.security_contexts;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
  },
  async mounted() {
    await this.listSecurityContexts();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
