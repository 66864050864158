<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Product Config</li>
                    </ol>
                </nav>
         
            </div>
       
        </div>

      
        <div class="row">
         <div class="col-sm-8 offset-sm-2">
            <div v-if="accountConfig ===null">
                <legend>Setup Account Opening templates for Products</legend>

                    <div class="form-row">
                    <div class="form-group col-md-5">
                    <label>Select Template for Main Product Account</label> 
                        <select class="custom-select"  id="main" name="main" v-model="mainActTemplate" v-validate.continues="'required'">
                            <option v-for="actt in accTemplates" v-bind:value="actt.template_code" > {{actt.template_name }}</option>
                        
                        </select>
                      <div v-if="errors.has('main')" class="text-danger">{{ errors.first('main') }}</div>
                    </div>
                    </div>

                        <div class="form-row">
                    <div class="form-group col-md-5">
                    <label>Select Template forProduct Charges Account</label> 
                        <select class="custom-select" id="charges" name="charges" v-model="chargesActTemplate" v-validate.continues="'required'" >
                            <option  v-for="actt in accTemplates" v-bind:value="actt.template_code" > {{actt.template_name }}</option>
                        </select>
                         <div v-if="errors.has('charges')" class="text-danger">{{ errors.first('charges') }}</div>
                    </div>
                    </div>



                    <button  class="btn btn-primary" @click="saveConfig()">Save Configuration</button>

                </div>
                   <div v-else>
                       <fieldset class="form-fieldset">
                        <legend>Account Opening templates for Products</legend>
                        <div class="form-group">
                            <label class="d-block">Template for Main Product Account</label>
                            <input type="text" class="form-control" v-model="accountConfig.account_template_code" disabled>
                        </div>
                        <div class="form-group">
                            <label class="d-block">Template forProduct Charges Account </label>
                            <input type="text" class="form-control" v-model="accountConfig.product_charges_template_code" disabled>
                        </div>


                         <button  class="btn btn-primary" @click="editConfig()">Edit</button>

                        </fieldset>
                    </div>
            </div>  
        
        
        
        
        
        
            </div>
    </div>
</template>

<script>
export default {
	created(){

	},
	data() {
			return {
			    accTemplates:[],
                mainActTemplate:'',
                chargesActTemplate:'',
                accountConfig:''
            }
	},
	methods: {
	    fetchAccountOpeningTemplate() {
         return new Promise(async (resolve, reject) => {
            try {
               let res = await req.callServerSecure({
                  resource: "entity_account_template",
                  action: "get",
                  data: {
                      entity_type:"PRODUCT"
                  },
                  meta_data: {}
               });

               this.accTemplates = res.data.result.templates;
               resolve(true)
            } catch (err) {
                 reject(err)
            }
         });
      },
          getProductAccountConfig() {
                return new Promise(async (resolve, reject) => {
                    try {
                    let res = await req.callServerSecure({
                        resource: "product",
                        action: "get_config",
                        data: {
                        },
                        meta_data: {}
                    });

                    this.accountConfig = res.data.result.entity_config;
                    resolve(true)
                    } 
                    catch (err) {
                        reject(err)
                    }
                });
      },
      async saveConfig () {
          try {
                   let valii= await this.$validator.validate();
                     if(!valii) {
                       return reject(" Validation Error ");
                     }
              Event.$emit('loader:show', true)
                let res = await req.callServerSecure({
                     resource: "product",
                     action: "add_config",
                     data: {
                         account_template_code:this.mainActTemplate,
                         product_charges_template_code:this.chargesActTemplate,
                         is_configured:true
                     },
                     meta_data: {},
                  });
                    this.accountConfig = res.data.result.entity_config;
                    Event.$emit('loader:show', false)
                    notification.notify('success', 'Product Account Configured', 'Product Account Configured Successfully')
          }
          catch (err) {
              console.log(err)
 notification.notify('error', 'Product Account Configured', err)
    Event.$emit('loader:show', false)
          }

      }
      ,
      editConfig () {
          this.accountConfig=null
      }
	},
	async mounted(){
        await this.getProductAccountConfig()
	    await this.fetchAccountOpeningTemplate()
		Event.$emit('loader:show', false)
	}
};
</script>

<style lang="less">
.user-list {
}
</style>