module.exports = {
    name: 'Insurance Services',

    wizard: {
        actionBtn: 'Save',
        action: 'add_insurance_service',
        pages: [
            {
                title: 'Insurance Services',
                subtitle: 'Configure new Insurance Services',
                forms: [
                    
                    {
                        type: 'text', form_type: 'text', label: 'Code', model: 'service_code',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'service_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Type', model: 'service_type',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Category', model: 'service_category',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'select', class: 'col-12 col-md-12', label: "Image", model: 'service_image_path',
                        validation: 'required', options: []
                    },
                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'service_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },

                ],
            }
        ]
    },
    table: {
        title: 'Insurance Services',
        subtitle: 'A list of configured Insurance services',
        table_fields: [
            { name: 'Icon', align: 'c'},
            { name: 'Code', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["service_code", "service_name"],
        rowData: [
            { type: 'image', field: 'service_image_path', class: 'left bolder'},
            { type: 'text', field: 'service_code', class: 'left bolder' },
            { type: 'text', field: 'service_name', class: 'left bolder small' },
            { type: 'text', field: 'service_description', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},

                    {
                        type: 'menu', title: 'View', event: 'InsuranceService:view', icon: 'ico-reply', params: [
                            { key: 'service', field: '*' }
                        ],
                    },

                ]
            },
        ]
    },
}