module.exports = {
  name: "Chat Users",
  table: {
    title: "Chat Users",
    subtitle: "Chat Users List",
    table_fields: [
      { name: "Image", align: "r" },
      { name: "Chat User Desc", align: "r" },
      { name: "Staff Ref", align: "r" },
      { name: "Name", align: "c" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["name", "ref", "description", "status"],
    rowData: [
      { type: "image", field: "image", class: "small center" },
      { type: "text", field: "description", class: "small center" },
      { type: "text", field: "ref", class: "small center" },
      { type: "text", field: "name", class: "small center" },
      { type: "text", field: "status", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "chat-user:view",
            icon: "ico-right",
            params: [{ key: "chat-user", field: "*" }],
          },
          {
            type: "menu",
            title: "Remove",
            event: "chat-user:remove",
            icon: "ico-right",
            params: [{ key: "chat-user", field: "*" }],
          },
        ],
      },
    ],
  },
  wizard: {
    actionBtn: "Save",
    action: "add_user",
    pages: [
      {
        title: "Chat Users",
        subtitle: "Add user to chat group",
        forms: [
          {
            type: "select",
            class: "col-12 col-md-12",
            model: "user",
            options: [
              { val: "Robert Kamau", label: "Robert Kamau" },
              { val: "Jane Doe", label: "Jane Doe" },
              { val: "Thomas Edison", label: "Thomas Edison" },
            ],
            validation: "required",
            label: "Select User",
          },
          {
            type: "file",
            form_type: "file",
            label: "Upload Image",
            model: "image",
            validation: "required",
            class: "col-12 col-md-12",
          },
          {
            type: "textarea",
            form_type: "textarea",
            label: "Chat Description",
            model: "description",
            validation: "required|min:20|max:500",
            class: "col-12 col-md-12",
          },
        ],
      },
    ],
  },
};
