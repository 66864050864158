<template lang="html">
    <div class="role">
    <div class="content content-fixed content-profile">
      <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <div class="media d-block d-lg-flex">
          <div class="media-body mg-t-40 mg-lg-t-0 pd-lg-x-10">
         
            <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Roles</h6>
                
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="row">
                  <div class="col-md-6">
                    <div class="media mg-b-20">
                      <div class="">Code :</div>
                      <div class="media-body pd-l-15">
                        <h6 class="">{{role.role_code}}</h6>
                      </div>
                    </div><!-- media -->
                    <div class="media mg-b-20">
                      <!-- <div class="">Name :</div>
                      <div class="media-body pd-l-15">
                        <h6 class="">{{role.name}}</h6>
                      </div> -->
                    </div><!-- media -->
               
                  </div>
                  <div class="col-md-6">
                    <div class="media mg-b-20">
                      <div class="">Name :</div>
                      <div class="media-body pd-l-15">
                        <h6 class="">{{role.name}}</h6>
                      </div>
                      <!-- <div class="">Status :</div>
                      <div class="media-body pd-l-15">
                        <h6 class="">{{role.name}}</</h6>
                      </div> -->
                    </div><!-- media -->
                    <div class="media mg-b-20">
                      <!-- <div class="">Created Date :</div>
                      <div class="media-body pd-l-15">
                        <h6 class="">12/07/2020</h6>
                      </div> -->
                    </div><!-- media -->
                  </div>
                  <div class="col-md-10">
                    <div class="mdia mg-b-20">
                      <div class="">Description :</div>
                      <div class="mdia-body d-block pd-t-10 ">
                        <h6 class="">{{role.description}}</h6>
                      </div>
                    </div><!-- media -->
                  </div>
                </div>
         

             
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                  <a href="" class="nav-link"><i data-feather="thumbs-up"></i> Approve</a>
                  <a href="" class="nav-link"><i data-feather="edit"></i> Edit</a>
                  <a href="" class="nav-link"><i data-feather="activity"></i> Activate</a>
                  <a href="" class="nav-link"><i data-feather="file"></i> Audit Trail</a>

                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->
          </div><!-- media-body -->
        </div><!-- media -->
        <div class="media d-block d-lg-flex">
          <div class="media-body mg-t-40 mg-lg-t-0 pd-lg-x-10">
         
            <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">resources</h6>
                <nav class="nav nav-icon-only">
                  <div class="dropdown">
                    <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     Grant Permisssion
                    </button>
                    <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                     
                        <form class=" pd-15" action="">
                         <div class="form-group" v-for="item in resources">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-model="checkedResources" :value="item.resource_code" :id="item.resource_code" @click="grantResource">
                            <label class="custom-control-label" :for="item.resource_code">{{item.name}}</label>
                          </div>
                         </div>
                         <!-- <button type="submit" class="btn btn-xs btn-primary pd-y-1 ">Save</button> -->
                        </form>
                
  
                    </div>
                  </div>
                  <!-- <a href="" class="nav-link">Grant Resource<i data-feather="more-horizontal"></i></a> -->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Resource Code</th>
                      <th scope="col">Permissions</th>
                      <th scope="col">Action</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in role.grants">
                      <th scope="row">{{item.resource_code}}</th>
                      <td><ul class="list-inline list-inline-skills mb-0">
                        <li class="list-inline-item" v-for="permission in item.permissions"><span class="badge pd-y-5 pd-l-10 pd-r-0 badge-primary" />{{permission}}<span><i data-feather="x" class="cancel" ></i></span></li>
                      </ul></td>
                      <td>
                        <a class="pd-x-6 text-danger" type="button" @click="revokeResource(item.resource_code)">
                         Revoke
                        </a>
                        <div class="dropdown d-inline-block pd-x-6" v-if="filterAddPermissions(item.permissions).length > 0">
                          <a class="dropdown-toggle text-primary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Add Permission
                          </a>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    
                            <form class="wd-150 pd-15" action="">
                              <div class="form-group" v-for="permission in filterAddPermissions(item.permissions)">
                               <div class="custom-control custom-checkbox">
                                 <input type="checkbox" class="custom-control-input" :value="permission" :id="`add-${item.grant_id}-${permission}`" @click="addPermission">
                                 <label class="custom-control-label" :for="`add-${item.grant_id}-${permission}`">{{permission}}</label>
                               </div>
                              </div>
                            
                              <!-- <button type="submit" class="btn btn-xs btn-primary pd-y-2 btn-block">Save</button> -->
                             </form>
                          </div>
                        </div>
        
                        <div class="dropdown d-inline-block pd-x-6" v-if="filterRemovePermissions(item.permissions).length > 0">
                          <a class="dropdown-toggle text-primary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Remove Permission
                          </a>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    
                            <form class="wd-150 pd-15" action="">
                              <div class="form-group" v-for="permission in filterRemovePermissions(item.permissions)">
                               <div class="custom-control custom-checkbox">
                                 <input type="checkbox" class="custom-control-input" :value="permission" :id="`remove-${item.grant_id}-${permission}`" @click="removePermission">
                                 <label class="custom-control-label" :for="`remove-${item.grant_id}-${permission}`">{{permission}}</label>
                               </div>
                              </div>
                             </form>
                          </div>
                        </div>
        
                   
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div><!-- card -->
          </div><!-- media-body -->
        </div><!-- media -->
      </div><!-- container -->
    </div><!-- content -->


    </div>
</template>

<script>
export default {
  async mounted() {
    this.req = req;
    await this.getRoleDetails()
    await this.getResources()
    Event.$emit("loader:show", false);
  },
  data() {
    return {
      Role: {},
      role: {},
      resources: [],
      grants: [],
      checkedResources: []
    };
  },
  computed: {
  },
  methods: {
    filterAddPermissions(permissions) {
      const allPermissions = ["CREATE","VIEW","VERIFY"];
      if(!permissions.length) {
        return allPermissions;
      }
      return allPermissions.filter(item => !permissions.includes(item));
    },
    filterRemovePermissions(permissions) {
      const allPermissions = ["CREATE","VIEW","VERIFY"];
      if(!permissions.length) {
        return allPermissions;
      }
      return allPermissions.filter(item => permissions.includes(item));
    },
    async getRoleDetails() {
      try {
          let res = await req.callServerSecure({
          resource: "role",
          action: "get",
          data: {
              role_code: this.$route.params.id
          },
          meta_data: {},
          });

          console.log("res", res)
          this.role = res.data.result.role_details
      } catch (err) {
          console.log(err);
      }
    },
    async getResources() {
      try {
        let res = await req.callServerSecure({
            resource: "system_resource",
            action: "list",
            data: {},
            meta_data: {},
          });

          const resources = res.data.result.system_resources;
          const newResources = [];
          for(let i = 0; i < resources.length; i++) {
              const filteredGrants = this.role.grants.filter(item => item.resource_code === resources[i].resource_code)
              if(filteredGrants.length === 0) {
                newResources.push(resources[i])
              }
          }
          this.resources = newResources;
      } catch (err) {
        console.log(err);
      }
    },
    async grantResource(e) {
      try {
        Event.$emit("loader:show", true);
        let res = await req.callServerSecure({
        resource: "role",
        action: "grant_resource",
        data: {
          resource_code: e.target.value,
          role_code: this.$route.params.id,
          permissions: ["CREATE","VIEW","VERIFY"],
          tags:["CREATE","VIEW"]
        },
        meta_data: {},
        });

        this.checkedResources = []
        await this.getRoleDetails()
        await this.getResources()
        notification.notify('success', 'Success', 'Resource granted successfully!')
        Event.$emit("loader:show", false);
      } catch (err) {
        console.log(err);
        Event.$emit("loader:show", false);
      }
    },
    async revokeResource(resource_code) {
      try {
        Event.$emit("loader:show", true);
        let res = await req.callServerSecure({
        resource: "role",
        action: "revoke_resource",
        data: {
          resource_code,
          role_code: this.$route.params.id
        },
        meta_data: {},
        });

        await this.getRoleDetails()
        await this.getResources()
        notification.notify('success', 'Success', 'Resource revoked successfully!')
        Event.$emit("loader:show", false);
      } catch (err) {
        console.log(err);
        Event.$emit("loader:show", false);
      }
    },
    async addPermission(e) {
      try {
        const grandId = e.target.id.split('-')[1];
        Event.$emit("loader:show", true);
        let res = await req.callServerSecure({
        resource: "role",
        action: "grant_permission",
        data: {
          grant_id: grandId,
          permission: e.target.value
        },
        meta_data: {},
        });

        await this.getRoleDetails()
        notification.notify('success', 'Success', 'Permission added successfully!')
        Event.$emit("loader:show", false);
      } catch (err) {
        console.log(err);
        Event.$emit("loader:show", false);
      }
    },
    async removePermission(e) {
      try {
        Event.$emit("loader:show", true);
        const grandId = e.target.id.split('-')[1];
        let res = await req.callServerSecure({
        resource: "role",
        action: "revoke_permission",
        data: {
          grant_id: grandId,
          permission: e.target.value
        },
        meta_data: {},
        });

        await this.getRoleDetails()
        notification.notify('success', 'Success', 'Permission removed successfully!')
        Event.$emit("loader:show", false);
      } catch (err) {
        console.log(err);
        Event.$emit("loader:show", false);
      }
    }
  }
};
</script>

<style lang="less">
.role {
  .content-fixed {
    margin-top: 5px;
  }
}
.cancel {
  height: 10px;
  width: 10px;
  margin-right: 4px;
  margin-left: 3px;
  margin-top: -1px;
  border-radius: 50%;
  color: black;
  background-color: white;
  cursor: pointer;
}
</style>