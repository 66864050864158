<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="SubClass.wizard.add_subclass.action">
            <template slot="form">
               <CustomForm 
                  :pages="SubClass.wizard.add_subclass.pages" 
                  :action="SubClass.wizard.add_subclass.action" 
                  :buttonText="SubClass.wizard.add_subclass.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddSubClassModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Sub Class
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="SubClass.table.table_fields" 
            :tableData="sub_classes" 
            :title="SubClass.table.title" 
            :subtitle="SubClass.table.subtitle" 
            :searchFields="SubClass.table.searchFields"
            :rowData="SubClass.table.rowData">
		
         </CustomTable>
		
      </div>
   </div>
</template>

<script>
export default {
  async created() {
    this.SubClass = Models.SubClass;
    let _this = this;

    this.sub_classes = [];

    Event.$on("view_subclass:view", (payload) => {
      this.$router.push({
        name: "Configs-Insurance-ViewSubclass",
        params: { id: payload.subclass.code },
      });
    });

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.SubClass.wizard.add_subclass.action) {
        await this.addSubClass(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      console.log("Closing modal");
      this.showModal = false;
    });

    Event.$on("product:view", (payload) => {
      console.log("Payload", payload);
      this.$router.push({
        name: "Configs-SubClasss-ViewSubClass",
        params: { id: payload.code },
      });
    });
  },
  data() {
    return {
      SubClass: {},
      sub_classes: [],
      formData: {},
      showModal: false,
      currencies: ["KES", "USD"],
      selectedCurrency: [],
    };
  },
  methods: {
    fetchClasses() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "insurance_class",
            action: "list",
            data: {},
            meta_data: {},
          });

          /*  let pages = this.SubClass.wizard.add_subclass.pages,options = [];
               await res.data.result.insurance_classes.forEach(element => {
						options.push({ val: element.code, label: element.name });
               });
               
               pages[0].forms[2].options = options;
					this.SubClass.wizard.add_subclass.pages = pages;
					*/
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchSubClasses() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "insurance_sub_class",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.sub_classes = res.data.result.insurance_sub_classes;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    openAddSubClassModal() {
      // this.$router.push({
      //   name: "Configs-addInsuranceClass",
      //   params: {},
      // });
      this.showModal = true;
    },
    addSubClass(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "insurance_sub_class",
            action: "add",
            data: {
              name: data.name,
              code: data.code,
              ira_code: data.ira_code,
              class_code: data.insurance_class,
              description: data.description,
              icon: "/static/images/insurance/general-insurance.png",
            },
          });

          await this.fetchSubClasses();
          notification.notify(
            "success",
            "Sub Class Added",
            `Sub Class ${data.name} successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.SubClass.wizard.add_subclass.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          console.log(err);
          Event.$emit("form:action-error", {
            action: this.SubClass.wizard.add_subclass.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.fetchClasses();
    await this.fetchSubClasses();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>