module.exports = {
  name: "Form Fields",
  gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
  wizard: {
    actionBtn: "Update",
    action: "update_form_field",
    pages: [
      {
        title: "System Form Fields",
        subtitle: "Configure new Form Field field",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Field Code",
            model: "field_code",
            validation: "required|min:1|max:50",
            class: "col-12 col-md-4",
          },
          {
            type: "text",
            form_type: "text",
            label: "field name",
            model: "field_name",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-4",
          },
          {
            type: "text",
            form_type: "text",
            label: "Field Label",
            model: "field_label",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-4",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Field Hint",
            model: "hint",
            validation: "required|min:1|max:150",
            class: "col-12 col-md-4",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "field_description",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-4",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "help Text",
            model: "help_text",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-4",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "type",
            options: [
              { val: "text", label: "Text" },
              { val: "option", label: "Option" },
              { val: "attachement", label: "Attachement" },
              { val: "search", label: "Search" },
            ],
            validation: "required",
            label: "Type",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "sub_type",
            options: [
              { val: "text", label: "Text" },
              { val: "textarea", label: "Textarea" },
              { val: "number", label: "Number" },
              { val: "email", label: "Email" },
              { val: "contact", label: "Contact" },
              { val: "calender", label: "Calender" },
              { val: "mobile", label: "Mobile" },
              { val: "image", label: "Image" },
              { val: "document", label: "Document" },
              { val: "select", label: "select" },
              { val: "radio", label: "Radio" },
              { val: "checkbox", label: "Checkbox" },
              { val: "date", label: "Date Only" },
              { val: "time", label: "Time Only" },
              { val: "date_time", label: "Date and Time" },
              { val: "color", label: "Color" },
              { val: "autocomplete", label: "Autocomplete" },
              { val: "slider", label: "Slider" },
              { val: "director", label: "Director" },
              { val: "location", label: "Location" },
              { val: "country", label: "Country" },
              { val: "crsp_Make", label: "Car Make" },
              { val: "crsp_Model", label: "Car Model" },
              { val: "pdf", label: "Pdf" },
              { val: "search_industry", label: "Search Industry" },
              { val: "search_crsp", label: "Search Motor CRSP" },
            ],
            validation: "required",
            label: "Sub Type",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Field Values comma separated(,) ",
            model: "values",
            validation: "required",
            class: "col-12 col-md-12",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Is the field Unique ?",
            model: "is_unique",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No" },
            ],
            validation: "required",
            class: "col-2 col-md-4",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Allow decimal ?",
            model: "allow_decimal",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No" },
            ],
            validation: "required",
            class: "col-2 col-md-4",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Is field Required ?",
            model: "is_required",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No" },
            ],
            validation: "required",
            class: "col-2 col-md-4",
          },
          {
            type: "text",
            form_type: "text",
            label: "Regular expression #(num) $(string)",
            model: "regex",
            validation: "min:1|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Field Length",
            model: "length",
            validation: "required",
            class: "col-12 col-md-6",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "All Capitalised?",
            model: "allCaps",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No" },
            ],
            validation: "required",
            class: "col-2 col-md-4",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Allow Small Letters?",
            model: "allSmall",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No" },
            ],
            validation: "required",
            class: "col-2 col-md-4",
          },
          {
            type: "text",
            form_type: "text",
            label: "Field Minimum Length (for select min selectable)",
            model: "min",
            validation: "required",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Field Maximum Length (eg for date  2020)",
            model: "max",
            validation: "required",
            class: "col-12 col-md-6",
          },

          {
            type: "text",
            form_type: "number",
            label: "position",
            model: "position",
            validation: "required",
            class: "col-12 col-md-6",
          },

          {
            type: "select",
            class: "col-12 col-md-6",
            model: "class",
            options: [
              { val: "col-12", label: "Full" },
              { val: "col-6", label: "Half" },
              { val: "col-3", label: "Quater" },
              { val: "col-4", label: "Third" },
              { val: "col-2", label: "Sixth" },
              { val: "col-1", label: "Twelveth" },
            ],
            validation: "required",
            label: "Size",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "verification_type",
            options: [
              { val: "AUTO", label: "Auto" },
              { val: "MANUAL", label: "Manual" },
              { val: "THIRDPARTY", label: "Thirdparty" },
            ],
            validation: "required",
            label: "Verification Type",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            model: "thirdparty",
            options: [
              { val: "IPRS", label: "Iprs" },
              { val: "NTSA", label: "Ntsa" },
              { val: "WORLDCHECK", label: "Worldcheck" },
              { val: "n/a", label: "Not Applicable" },
            ],
            validation: "required",
            label: "Thirdparty",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Is Child of? ignore if not nested",
            model: "is_child_of",
            options: [
              { id: "Yes", val: true, label: "Yes" },
              { id: "No", val: false, label: "No", checked: "yes" },
            ],
            class: "col-6 col-md-3",
            validation: "required",
          },
          {
            type: "select",
            class: "col-6 col-md-3 disabled",
            model: "parent",
            isDisabled: true,
            options: [],
            label: "Parent",
          },
          {
            type: "text",
            form_type: "text",
            label: "Parent Code (If field is child if not put n/a)",
            model: "parent_code",
            class: "col-2 col-md-6",
            validation: "required",
          },
          {
            type: "text",
            form_type: "text",
            label: "show_on_option_value ? ignore if not nested",
            model: "show_on_option_value",
            class: "col-2 col-md-6",
            validation: "required",
          },
        ],
      },
    ],
  },  
};
