<template lang="html">
    <div class="account-types">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Types</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Account Types</h4>
            </div>
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail" class="wd-10 mg-r-5"></i> Email</button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer" class="wd-10 mg-r-5"></i> Print</button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file" class="wd-10 mg-r-5"></i> Generate Report</button>
            </div>
        </div>
        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
                <div class="card mg-b-10">
                    <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                        <div>
                            <h6 class="mg-b-5">Account Types</h6>
                            <p class="tx-13 tx-color-03 mg-b-0">Account Type List</p>
                        </div>
                        <div class="d-flex mg-t-20 mg-sm-t-0">
                            <!-- <div class="btn-group flex-fill">
                                <button class="btn btn-white btn-xs active">Range</button>
                                <button class="btn btn-white btn-xs">Period</button>
                            </div> -->
                        </div>
                    </div><!-- card-header -->
                    <div class="card-body pd-y-30">
                        <!-- <div class="d-sm-flex">
                            <div class="media">
                                <div
                                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                                    <i data-feather="bar-chart-2"></i>
                                </div>
                                <div class="media-body">
                                    <h6
                                        class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                                        Amount Sent</h6>
                                    <h4 class="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">Ksh
                                        120,000<small>.00</small></h4>
                                </div>
                            </div>
                            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                                <div
                                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                                    <i data-feather="bar-chart-2"></i>
                                </div>
                                <div class="media-body">
                                    <h6
                                        class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                                        Excise Duty Tax</h6>
                                    <h4 class="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">Ksh 1011<small>.60</small>
                                    </h4>
                                </div>
                            </div>
                            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                                <div
                                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                                    <i data-feather="bar-chart-2"></i>
                                </div>
                                <div class="media-body">
                                    <h6
                                        class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                                        Transfer Fee</h6>
                                    <h4 class="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">Ksh 7,200<small>.00</small>
                                    </h4>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="table-responsive">
                        <table class="table table-dashboard table-hover mg-b-0">
                            <thead>
                                <tr>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Currency</th>
                                    <th class="text-left">Limit</th>
                                    <th class="text-left">Category</th>
                                    <th>Account Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="tx-medium text-left">System Suspence</td>
                                    <td class="text-left">KES</td>
                                    <td class="text-left tx-medium">System Limit</td>
                                    <td class="tx-small text-left">System</td>
                                    <td class="tx-small text-left">Default</td>
                                </tr>
                                <tr>
                                    <td class="tx-medium text-left">System Escrow</td>
                                    <td class="text-left">KES</td>
                                    <td class="text-left tx-medium">System Limit</td>
                                    <td class="tx-small text-left">System</td>
                                    <td class="tx-small text-left">Default</td>
                                </tr>
                                <tr>
                                    <td class="tx-medium text-left">System Income</td>
                                    <td class="text-left">KES</td>
                                    <td class="text-left tx-medium">System Limit</td>
                                    <td class="tx-small text-left">System</td>
                                    <td class="tx-small text-left">Default</td>
                                </tr>
                                <tr>
                                    <td class="tx-medium text-left">System Asset</td>
                                    <td class="text-left">KES</td>
                                    <td class="text-left tx-medium">System Limit</td>
                                    <td class="tx-small text-left">System</td>
                                    <td class="tx-small text-left">Default</td>
                                </tr>
                            </tbody>
                        </table>
                    </div><!-- table-responsive -->
                </div><!-- card -->

            </div>
        </div>
    </div>
</template>

<script>
	export default {
		mounted(){
			Event.$emit('loader:show', false)
		}
	}
</script>

<style lang="less">
    .account-types row row-xs{
        
    }
</style>