<template lang="html">
   <div class="wallet">
      <ModalForm :showModal="showModal" :action="Wallet.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Wallet.wizard.pages" 
               :action="Wallet.wizard.action" 
               :buttonText="Wallet.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createDirector()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Wallet
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Wallet.table.table_fields"
         :icon="Wallet.table.icon"
         :title="Wallet.table.title"
         :subtitle="Wallet.table.subtitle"
         :tableData="wallets" 
         :searchFields="Wallet.table.searchFields"
         :rowData="Wallet.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.Wallet = Models.Wallet
         this.wallets = await utils.getDummyData(Models.Wallet,2)

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.Wallet.wizard.action){
               await this.saveDirector(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            Wallet: {},
            wallets: [],
            showModal: false
         }
      },
      methods: {
         createDirector() {
            this.showModal = true
         },
         saveDirector(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "profile",
                     action: "director",
                     data: {
                        names: data.first_name + ' '+ data.last_name,
                        contact: `${data.contact.country_code.callingCodes[0]}:${data.contact.number}`,
                        email: data.email
                     },
                     meta_data: {},
                  });

                  notification.notify('success', 'Director Added', `Company director successfully added`)
                  Event.$emit('form:action-complete',{action: this.Wallet.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  console.log(err)
                  Event.$emit('form:action-error',{action: this.Wallet.wizard.action})
               }
            })
         }
      },
      mounted(){
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .wallet{
      
   }
</style>