<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="CoverType.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="CoverType.wizard.pages" 
                  :action="CoverType.wizard.action" 
                  :buttonText="CoverType.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddCoverTypeModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Cover Type
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="CoverType.table.table_fields" 
            :tableData="coverTypes" 
            :title="CoverType.table.title" 
            :subtitle="CoverType.table.subtitle" 
            :searchFields="CoverType.table.searchFields"
            :rowData="CoverType.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.CoverType = Models.CoverType;
		let _this = this;

		this.coverTypes = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.CoverType.wizard.action) {
				await this.addCoverType(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("product:view", payload => {
			console.log("Payload", payload);
			this.$router.push({
				name: "Configs-CoverTypes-ViewCoverType",
				params: { id: payload.code }
			});
		});
	},
	data() {
		return {
			CoverType: {},
			coverTypes: [],
			formData: {},
			showModal: false
		};
	},
	methods: {

      fetchCoverTypes () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "covertype",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.coverTypes = res.data.result.covertypes
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
		openAddCoverTypeModal() {
			this.showModal = true;
		},
		addCoverType(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "covertype",
						action: "add",
						data: {
							name: data.name,
							code: data.code,
							ira_code: data.ira_code,
							sub_class_code: data.insurance_sub_class,
							description: data.description,
						}
					});

               await this.fetchCoverTypes()
					notification.notify("success","Cover Type Added",`Cover Type ${data.name} successfully added`);
					Event.$emit("form:action-complete", {action: this.CoverType.wizard.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.CoverType.wizard.action});
				}
			});
		}
	},
	async mounted() {
      await this.fetchCoverTypes()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>