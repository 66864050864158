module.exports = {
    name: 'Fees',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_accounting_fee',
        pages: [
            {
                title: 'Fees',
                subtitle: 'Configure new Fees',
                forms: [
                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'fee_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Fee Ref', model: 'entity_fee_ref',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'select', label: 'Fee Account', model: 'entity_fee_account',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'select', label: 'Fee Currency', model: 'entity_fee_currency',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },

                    {
                        type: "radio",
                        class: "col-12 col-md-4",
                        label: "Is Taxed",
                        model: "is_taxed",
                        options: [
                            { id: "yes", label: "Yes", val: "true" },
                            { id: "no", label: "No", val: "false" },
                        ],
                    },


                    {
                        type: 'select', label: 'Tax', model: 'system_tax_ref',
                        validation: '', class: 'col-12 col-md-8'
                    },

                   

                    {
                        type: 'select', label: 'Fee Management', model: 'fee_management',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-12',
                        options: [
                            { id: 'ENTITY', val: 'ENTITY', label: 'ENTITY' },
                            { id: "SYSTEM", val: 'SYSTEM', label: 'SYSTEM' },
                            { id: "percentage", val: 'percentage', label: 'Percantage' },

                        ],
                    },
                    
                    {
                        type: 'select', label: 'Fee Type', model: 'fee_type',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-12',
                        options: [
                            { id: 'slab', val: 'slab', label: 'Slab' },
                            { id: "fixed", val: 'fixed', label: 'Fixed' },
                            { id: "percentage", val: 'percentage', label: 'Percantage' },

                        ],
                    },

                    {
                        type: 'text', form_type: 'number', label: 'Fee Value', model: 'fee_value',
                        validation: 'required|min:1|max:150', class: 'col-12 col-md-12'
                    },

                  /*  {
                        type: 'text', form_type: 'number', label: 'Min', model: 'min',
                        validation: '', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Max', model: 'max',
                        validation: '', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Value Type', model: 'value_type',
                        validation: '', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Value', model: 'value',
                        validation: '', class: 'col-12 col-md-6'
                    },
                    */

                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'fee_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },

                    {
                        type: 'textarea', form_type: 'text', label: 'Remarks', model: 'remarks',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                    
                ],
            } 

        ]
    },
    table: {
        title: 'Fees',
        subtitle: 'A list of configured fees',
        table_fields: [
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'Fee Account', align: 'l' },
            { name: 'Fee Currency', align: 'l' },
            { name: 'Fee Type', align: 'l' },
            // { name: 'Fee Value', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["system_tax_ref","entity_fee_currency", "entity_fee_account", "fee_name", "fee_description", "fee_type", "fee_value"],
        rowData: [
            { type: 'text', field: 'fee_name', class: 'left bolder' },
            { type: 'text', field: 'fee_description', class: 'left bolder small' },
            { type: 'text', field: 'entity_fee_account', class: 'left bolder' },
            { type: 'text', field: 'entity_fee_currency', class: 'left bolder' },
            { type: 'text', field: 'fee_type', class: 'left bolder' },
            // { type: 'text', field: 'fee_value', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'View', event: 'fees:view', icon: 'ico-reply', params: [
                            { key: 'taxCategory', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },
}