<template lang="html">
  <div class="addClass">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Country
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="form-row">
          <div class="form-group col-md-5">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="fileSelector"
                name="files[]"
                @change="onFileSelected"
                accept="image/*"
              />
              <label class="custom-file-label" for="customFile"
                >Upload Icon</label
              >
            </div>
            <figure class="pos-relative col-md-4" v-if="showImage">
              <img
                :src="form.url"
                class="img wd-60 ht-60 rounded-circle img-thumbnail"
                alt="form.placeholder"
              />
            </figure>
          </div>
          <div class="form-group col-md-5">
            <img
              v-bind:src="country_image"
              class="img wd-60 ht-60 rounded-circle"
              alt=""
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Country code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Country code"
              name="countryCode"
              id="classCode"
              v-model="Country.country_code"
              v-validate="'required'"
            />
            <div v-if="errors.has('countryCode')" class="text-danger">
              {{ errors.first("countryCode") }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label>Time Zone</label>
            <input
              type="text"
              class="form-control"
              placeholder="Timezone"
              name="timezone"
              id="timezone"
              v-model="Country.timezone"
              v-validate="'required'"
            />
            <div v-if="errors.has('timezone')" class="text-danger">
              {{ errors.first("timezone") }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label>Country Iso Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Country Iso Code"
              name="isocode"
              id="isocode"
              v-model="Country.iso_code"
              v-validate="'required'"
            />
            <div v-if="errors.has('isocode')" class="text-danger">
              {{ errors.first("isocode") }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label>Country name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Country name"
              name="countryname"
              id="countryname"
              v-model="Country.name"
              v-validate="'required'"
            />
            <div v-if="errors.has('countryname')" class="text-danger">
              {{ errors.first("countryname") }}
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group-3">
            <label>Coordinates - lattitude</label>
            <input
              type="number"
              class="form-control"
              placeholder="Lattitude"
              name="Lattitude"
              id="Lattitude"
              v-model="Country.Lattitude"
              v-validate="'required'"
            />
            <div v-if="errors.has('Lattitude')" class="text-danger">
              {{ errors.first("Lattitude") }}
            </div>
          </div>

          <div class="form-group-3">
            <label>Coordinates-longitute</label>
            <input
              type="number"
              class="form-control"
              placeholder="Longitude"
              name="Longitude"
              id="Longitude"
              v-model="Country.Longitude"
              v-validate="'required'"
            />
            <div v-if="errors.has('Longitude')" class="text-danger">
              {{ errors.first("Longitude") }}
            </div>
          </div>

          <div class="form-group-3">
            <label>Contact - prefix</label>
            <input
              type="text"
              class="form-control"
              placeholder="Contact Prefix"
              name="ContactPrefix"
              id="ContactPrefix"
              v-model="Country.country_contact.prefix"
              v-validate="'required'"
            />
            <div v-if="errors.has('ContactPrefix')" class="text-danger">
              {{ errors.first("ContactPrefix") }}
            </div>
          </div>

          <div class="form-group-3">
            <label>Contact-length</label>
            <input
              type="number"
              class="form-control"
              placeholder="Contact Length"
              name="contactLength"
              id="contactLength"
              v-model="Country.country_contact.length"
              v-validate="'required'"
            />
            <div v-if="errors.has('contactLength')" class="text-danger">
              {{ errors.first("contactLength") }}
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveCountry()"
            >
              Save
            </button>
          </div>
          <div class="form-group col-md-6">
            <button type="button" class="btn btn-warning" @click="clearClass()">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {},
  data() {
    return {
      country_image: "",
      file: "",
      Country: {
        country_contact: {},
      },
      showImage: false,
      form: {
        placeholder: "",
        model: "",
        url: "",
      },
    };
  },
  methods: {
    onFileSelected(event) {
      Array.from(event.target.files).forEach((file) => {
        this.file = file;
        this.form.url = URL.createObjectURL(file);
        this.form.placeholder = file.name;
        this.showImage = true;
      });
    },
    getImg(path) {
      return req.getParsedUrl(path);
    },
    getImg(path) {
      return req.getParsedUrl(path);
    },
    iconSelected(icon) {
      this.Class.icon = req.getParsedUrl(icon);
    },
    selectIcon() {
      alert(" selecting");
    },
    async uploadimage() {
      return new Promise(async (resolve, reject) => {
        try {
          var formDataToUpload = new FormData();
          formDataToUpload.append("image", this.file);
          let img = await req.callServerMultipart(
            "/api/upload/v1/admin/country",
            formDataToUpload
          );
          return resolve(img.data);
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      });
    },
    async saveCountry() {
      try {
        let valii = await this.$validator.validate();
        if (!valii) {
          return reject(" Validation Error ");
        }
        let country_img = await this.uploadimage();
        let coordinates = [];
        coordinates[0] = this.Country.Lattitude;
        coordinates[1] = this.Country.Longitude;
        let res = await req.callServerSecure({
          resource: "country",
          action: "add",
          data: {
            country_code: this.Country.country_code,
            country_iso_code: this.Country.iso_code,
            country_name: this.Country.name,
            image_path: country_img.file_path,
            coordinates: coordinates,
            timezone: this.Country.timezone,
            country_contact: this.Country.country_contact,
            default_language: "English",

            country_contact: this.Country.country_contact,
          },
        });
        notification.notify(
          "success",
          "Country",
          `Country ${this.Country.name} successfully added`
        );
        this.$router.push({
          name: "listCountry",
          params: {},
        });
      } catch (err) {
        console.log(err);
        Event.$emit("form:action-error", "An Error Has Occured");
      }
    },
    clearClass() {
      this.Class = {};
    },
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>
<style lang="less">
.addClass {
}
</style>
