
<template lang="html">
   <div class="viewServiceCategory">
       <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Service Category </li>
                    </ol>
                </nav>
            </div>
        </div>


   </div>
</template>
<script>
export default {	
    async created() {
        	
    },
      data() {
		return {
         serviceCategory:{}
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        
    },
    async mounted() {
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.viewServiceCategory {
}
</style>