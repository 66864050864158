module.exports = {
   name: 'Payment Queue',
   wizard: {},
   table: {
      title: 'Payment Queues',
      subtitle: 'Payment queues in the system',
      table_fields: [
         { name: 'Amount', align: 'l' },
         { name: 'Channel', align: 'l' },
         { name: 'Account Number', align: 'l' },
         { name: 'Remarks', align: 'c' },
         { name: 'Tran Type', align: 'l' },
         { name: 'Created Date', align: 'l' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["account_number", "account_name", "available_balance", "currency_code"],
      rowData: [
         { type: 'text', field: 'tran_amount', class: 'left bolder' },
         { type: 'text', field: 'channel', class: 'left bolder small' },
         { type: 'text', field: 'topup_account_number', class: 'left bolder small' },
         { type: 'text', field: 'tran_desc', class: 'left bolder small' },
         { type: 'text', field: 'type', class: 'center bolder small' },
         { type: 'text', field: 'created_date', class: 'center bolder small' },
         {
            type: 'action', field: 'account_number', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'transaction:view', icon: 'ico-reply', params: [
                     { key: 'tran', field: '*' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'account', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
}