<template lang="html">
    <div class="Resources">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddResource()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Resource
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="Resource.table.table_fields" 
              :tableData="resources" 
              :title="Resource.table.title" 
              :subtitle="Resource.table.subtitle" 
              :searchFields="Resource.table.searchFields"
              :rowData="Resource.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.Resource = Models.Resource;

    Event.$on("form:submit", async (payload) => {
      console.log(payload);
    });

    Event.$on("Resource:view", async (payload) => {
      this.$router.push({
        name: "view_Resource",
        params: {},
      });
    });

    Event.$on("Resource:approve", async (payload) => {
      console.log(payload);
    });

    Event.$on("Resource:edit", async (payload) => {
      console.log(payload);
    });

    Event.$on("ResourceUsers:view", async (payload) => {
      console.log("Payload: ", payload);
    });
  },
  data() {
    return {
      Resource: {},
      resources: [],
    };
  },
  methods: {
    openAddResource() {
      // this.$router.push({
      //   name: "add_Resource",
      //   params: {},
      // });
    },
    async listResources() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "system_resource",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.resources = res.data.result.system_resources;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
  },
  async mounted() {
    await this.listResources();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
