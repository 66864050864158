<template lang="html">
   <div class="chargableservices">
      <ModalForm :showModal="showModal" :action="ChargableService.wizard.action">
         <template slot="form">
            
            <div v-if="is_configured">
                  <button class="btn btn-sm pd-x-15 btn-white btn-uppercase m-2">Update</button>
                  <button class="btn btn-sm pd-x-15 btn-white btn-uppercase m-2">Activate</button>
                  <button class="btn btn-sm pd-x-15 btn-white btn-uppercase m-2">Deactivate</button>
                     <button class="btn btn-sm pd-x-15 btn-white btn-uppercase m-2">Delete</button>
            </div>
            <div v-else>
                  <div class="row" v-if="service.is_configured">
                    <div class="col-sm-6">
                      Module: {{ service.service_module || "" }}
                    </div>
                    <div class="col-sm-6">
                      Code: {{ service.service_code || "" }}
                    </div>

                    <div class="col-sm-6">
                      Name: {{ service.service_name || "" }}
                    </div>
                    <div class="col-sm-6">
                      Description: {{ service.service_description || "" }}
                    </div>

                    <div class="col-sm-6">
                      Transaction Type: {{ service.tran_type || "" }}
                    </div>
                    <div class="col-sm-6">
                      Transaction Subtype: {{ service.tran_sub_type || "" }}
                    </div>
                    <div class="col-12">
                      <div class="my-2">
                        <button class="m-2 btn btn-sm pd-x-15 btn-white btn-uppercase" >
                            Update
                        </button>
                        <button class="m-2 btn btn-sm pd-x-15 btn-white btn-uppercase" >
                            Activate
                        </button>
                        <button class="m-2 btn btn-sm pd-x-15 btn-white btn-uppercase" >
                            Deactivate
                        </button>
                        <button class="m-2 btn btn-sm pd-x-15 btn-white btn-uppercase" >
                            Verify
                        </button>
                      </div>
                    </div>
                    
                  </div>

                  <CustomForm 
                      v-else
                     :pages="ChargableService.wizard.pages" 
                     :action="ChargableService.wizard.action" 
                     :buttonText="ChargableService.wizard.actionBtn" 
                     :showTitle="false">
                  </CustomForm>
            </div>
         </template>
      </ModalForm>

     

      <CustomTable :tableFields="ChargableService.table.table_fields"
         :icon="ChargableService.table.icon"
         :title="ChargableService.table.title"
         :subtitle="ChargableService.table.subtitle"
         :tableData="chargableservices" 
         :searchFields="ChargableService.table.searchFields"
         :rowData="ChargableService.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.ChargableService = Models.ChargableService;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.ChargableService.wizard.action) {
        await this.saveChargableService(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    Event.$on('chargableservice:configure',(payload) => {
      this.createChargableService()
      this.service = payload.service;
      console.log(payload.service);
      
    })
  },
  data() {
    return {
      ChargableService: {},
      chargableservices: [],
      showModal: false,
      service: {},
      is_configured: false,
    };
  },
  async mounted() {
    await this.fetchFees()
    await this.fetchchargableservices();
    Event.$emit("loader:show", false);
  },
  methods: {
    fetchchargableservices() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "service_charges",
            action: "list_chargeable",
            data: {},
            meta_data: {},
          });
          console.log(res.data.result)
          this.chargableservices = res.data.result.services;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchFees() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fees",
            action: "list_fees",
            data: {},
            meta_data: {},
          });
          
          this.ChargableService.wizard.pages[0].forms[4].options = utils.appendOptions(
              res.data.result.entity_fees,
              "system_fee_ref",
              "fee_name",
            );
          console.log("fees input", this.ChargableService.wizard.pages[0].forms[5]);
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    createChargableService() {
      this.showModal = true;
      console.log("test");
    },
    saveChargableService(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource:"service_charges",
            action:"create_charges",
            data : {
               service_code : data.service_code,
               system_fee_ref : data.system_fee_ref,
               service_name: data.service_name,
               service_module: this.service.service_module,
               trans_type: data.trans_type,
               trans_subtype: data.trans_subtype,
               service_description: data.service_description,
            },
            meta_data: {},
          });

          await this.fetchchargableservices();  
          notification.notify(
            "success",
            "ChargableService Added",
            `The ChargableService ${data.service_name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.ChargableService.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.ChargableService.wizard.action,
          });
        }
      });
    }, },
  
};
</script>

<style lang="less">
</style>