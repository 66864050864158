module.exports = {
    name: "Uploaded Underwriters",
    table: {
        title: "Uploaded Underwriters",
        subtitle: "Uploaded Banks List",
        table_fields: [
            { name: "Underwriter Code", align: "r" },
            { name: "Underwriter Name", align: "c" },
            { name: "Underwriter Emails", align: "r" },
        ],
        searchFields: ["code", "name"],
        rowData: [
            { type: "text", field: "code", class: "small center" },
            { type: "text", field: "name", class: "small center" },
            { type: "text", field: "emails", class: "small center" },

        ],
    }
};