module.exports = {
  name: 'Insurance Services',

  wizard: {
    actionBtn: 'Save',
    action: 'add_insurance_service',
    pages: [
      {
        title: 'Nexus School Config',
        subtitle: 'School account opening template',
        forms: [
          {
            type: 'select',
            form_type: 'select',
            label: 'Main Account',
            model: 'main_account',
            validation: 'required|min:1|max:50',
            class: 'col-12 col-md-6',
          },
          {
            type: 'select',
            form_type: 'select',
            label: 'Operating',
            model: 'operating',
            validation: 'required|min:3|max:150',
            class: 'col-12 col-md-6',
          },

          {
            type: 'text',
            form_type: 'text',
            label: 'No of day to release',
            model: 'no_of_days',
            validation: 'required|min:3|max:150',
            class: 'col-12',
          },
          {
            type: 'select',
            form_type: 'select',
            label: 'Select DRSA account',
            model: 'rsa_account',
            validation: 'required|min:3|max:150',
            class: 'col-12',
          },

          {
            type: 'select',
            class: 'col-12 col-md-12',
            label: 'Client Loan Application Fee',
            model: 'application_fee',
            validation: 'required',
            options: [],
          },

          {
            type: 'select',
            class: 'col-12 col-md-12',
            label: 'School Release Fee',
            model: 'release_fee',
            validation: 'required',
            options: [],
          },
        ],
      },
    ],
  },
  table: {
    title: 'Nexus Schools',
    subtitle: 'A list of Nexus Schools',
    table_fields: [
      { name: 'Image', align: 'c' },
      { name: 'Name', align: 'l' },
      { name: 'Create Date    ', align: 'l' },
      { name: 'actions', align: 'c' },
    ],
    searchFields: ['name'],
    rowData: [
      { type: 'image', field: 'school_image', class: 'left bolder' },
      { type: 'text', field: 'school_name', class: 'left bolder small' },
      { type: 'text', field: 'created_date', class: 'left bolder' },
      {
        type: 'action',
        field: 'name',
        class: 'center',
        icon: '',
        actions: [
          // {type: 'title', title: 'Actions'},

          {
            type: 'menu',
            title: 'View',
            event: 'Schools:view',
            icon: 'ico-reply',
            params: [{ key: 'school', field: '*' }],
          },
        ],
      },
    ],
  },

  releaseQueueTable: {
    title: 'Release Queue',
    subtitle: 'A list of nexus school release queue',
    table_fields: [
      // { name: 'Image', align: 'c' },
      { name: 'Name', align: 'l' },
      { name: 'Amount', align: 'l' },
      { name: 'Payment Status', align: 'l' },
      { name: 'Release Status', align: 'l' },
      { name: 'Expected Release Date', align: 'l' },
      { name: 'Create Date', align: 'l' },
      { name: 'actions', align: 'c' },
    ],
    searchFields: ['name', 'status'],
    rowData: [
      // { type: 'image', field: 'school_image', class: 'left bolder' },
      {
        type: 'text',
        field: 'school_details.school_name',
        class: 'left bolder small',
      },
      {
        type: 'text',
        field: 'release_details.expected_release_amount',
        class: 'left bolder',
      },
      {
        type: 'text',
        field: 'release_status.status',
        class: 'left bolder',
      },
      {
        type: 'text',
        field: 'fee_release_status',
        class: 'left bolder small',
      },
      {
        type: 'text',
        field: 'release_details.expected_release_date',
        class: 'left bolder small',
      },
      { type: 'text', field: 'created_date', class: 'left bolder small' },
      {
        type: 'action',
        field: 'name',
        class: 'center',
        icon: '',
        actions: [
          // {type: 'title', title: 'Actions'},

          {
            type: 'menu',
            title: 'View',
            event: 'releaseQueues:view',
            icon: 'ico-reply',
            params: [{ key: 'releaseQueue', field: '*' }],
          },
        ],
      },
    ],
  },
};
