<template>
  <div class="view-topup">
    <div
      class="
        d-sm-flex
        align-items-center
        justify-content-between
        mg-b-20 mg-lg-b-25 mg-xl-b-30
      "
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>

            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Entities-ParentsList' }">
                Parents List
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              View Parent
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div>
      <div class="media">
        <img
          class="mr-3"
          :src="getImg(parent.school_image)"
          :alt="parent.school_name"
        />
        <div class="media-body">
          <h5 class="text-capitalize">{{ parent.parent_name || "" }}</h5>

          <div class="row">
            <div class="col-12 py-2">
              ID: <strong>{{ parent.parent_id || "" }}</strong>
            </div>
            <div class="col-12 col-sm-3 py-2">
              STATUS:
              <strong class="badge badge-secondary mb-2">{{
                parent.parent_status || ""
              }}</strong>
            </div>
            
            <div class="col-12 col-sm-3 py-2">
              Email: <strong>{{ parent.primary_email || "" }}</strong>
            </div>
            <div class="col-12 col-sm-3 py-2">
              Contact: <strong>{{ parent.primary_contact || "" }}</strong>
            </div>
            <div class="col-12 col-sm-3 py-2">
              Parent Status:
              <strong class="badge badge-secondary mb-2">{{
                parent.parent_status || ""
              }}</strong>
            </div>
            <div class="col-12 col-sm-3 py-2">
              Create Date:
              <strong>{{ parent.created_date || "" }}</strong>
            </div>
            <div class="col-12 py-2">
              <button class="mr-2 btn btn-danger">Deactivate</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs">
      <div class="col-lg-12 col-xl-12 mg-t-10">
        <CustomTable
          :tableFields="table_fields"
          :tableData="students"
          :title="`Students`"
          :subtitle="`Parents Students List`"
          :searchFields="searchFields"
          :rowData="rowData"
        >
        </CustomTable>
      </div>
    </div>

    <div class="row row-xs">
      <div class="col-lg-12 col-xl-12 mg-t-10">
        <CustomTable
          :tableFields="school_table_fields"
          :tableData="parent_schools"
          :title="`Schools`"
          :subtitle="`Parent Schools List`"
          :searchFields="schoolSearchFields"
          :rowData="schoolRowData"
        >
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parent: {},
      students: [],
      parent_schools: [],
      searchFields: ["name", "grade", "age"],
      table_fields: [
        { name: 'Image', align: 'l' },
        { name: "ID", align: "c" },
        { name: "Name", align: "c" },
        { name: "Grade", align: "c" },
        { name: "Age", align: "c" },
        { name: "Create Date", align: "c" },
      ],
      rowData: [
        { type: 'image', field: 'image_path', class: 'left' },
        { type: "text", field: "student_id", class: "small center" },
        { type: "text", field: "name", class: "small center" },
        { type: "status", field: "grade", class: "small center" },
        { type: "text", field: "age", class: "small center" },
        { type: "text", field: "created_date", class: "small center" },
      ],

      schoolSearchFields: ["school_name", "school_external_ref", "school_status"],
      school_table_fields: [
        { name: 'Image', align: 'l' },
        { name: "ID", align: "c" },
        { name: "Name", align: "c" },
        { name: "External Ref", align: "c" },
        { name: "Status", align: "c" },
        { name: "Create Date", align: "c" },
      ],
      schoolRowData: [
        { type: 'image', field: 'school_image', class: 'left' },
        { type: "text", field: "school_id", class: "small center" },
        { type: "text", field: "school_name", class: "small center" },
        { type: "status", field: "school_external_ref", class: "small center" },
        { type: "text", field: "school_status", class: "small center" },
        { type: "text", field: "created_date", class: "small center" },
      ],
    };
  },
  methods: {
    fetchParent() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "parent",
            action: "get_details",
            data: {
              parent_system_ref: this.$route.params.id,
            },
          });

          this.parent = res.data.result.parent_details;
          this.students = this.parent.parent_students.map(item => {
            item.image_path = domain + item.image_path
            return item
          })
          this.parent_schools = this.parent.parent_schools.map(item => {
            item.school_image = domain + item.school_image
            return item
          })
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },

    getImg(path) {
      return `${domain}/${path}`;
    },
  },
  async mounted() {
    await this.fetchParent();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>