<template>
  <div
    class="modal fade"
    id="addResourceModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel d-block">
            Add Resource to Role
          </h5>
          <button
            @click="close()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card" style="width: 29rem">
            <div class="card-body">
              <div class="container-fluid">
                <h5 class="card-title">Select {{selected_resource.toUpperCase()}} Permissions</h5>
                <form class="wd-150 pd-15" action="">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input 
                        type="checkbox" 
                        class="custom-control-input" 
                        id="CREATE"
                        value="CREATE"
                        v-model="selected_permissions">
                      <label class="custom-control-label" for="CREATE">Create</label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input 
                        type="checkbox" 
                        class="custom-control-input" 
                        id="VIEW"
                        value="VIEW"
                        v-model="selected_permissions">
                      <label class="custom-control-label" for="VIEW">View</label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input 
                        type="checkbox" 
                        class="custom-control-input" 
                        id="UPDATE"
                        value="UPDATE"
                        v-model="selected_permissions">
                      <label class="custom-control-label" for="UPDATE">Update</label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input 
                        type="checkbox" 
                        class="custom-control-input" 
                        id="VERIFY"
                        value="VERIFY"
                        v-model="selected_permissions">
                      <label class="custom-control-label" for="VERIFY">Verify</label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input 
                        type="checkbox" 
                        class="custom-control-input" 
                        id="DELETE"
                        value="DELETE"
                        v-model="selected_permissions">
                      <label class="custom-control-label" for="DELETE">Delete</label>
                    </div>
                  </div>                 
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="addResource()"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Add Resource
          </button>
          <button
            @click="close()"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {    
    this.serviceProvider = Models.ServiceProviders;
  },
  props: {
    selected_resource: {
      default: "",
    }, 
    role_code: {
      default: "",
    },   
    showAddResource: {
      default: false,
    },    
  },
  watch: {
    showAddResource: function (to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $("#addResourceModal").modal(action);
    },
  },
  data() {
    return {      
      selected_permissions: [],      
    };
  },
  methods: {    
    addResource() {      
      let resource_data = {};
      resource_data.resource_code = this.selected_resource;
      resource_data.role_code = this.role_code;
      resource_data.permissions = this.selected_permissions;
      resource_data.tags = this.selected_permissions;

      Event.$emit("resourceData:add", {resource_data});      
    },
    close() {
      Event.$emit("addResourceModal:close", {});
    },
  },
};
</script>

<style>
</style>