<template lang="html">
    <div class="Chat-ChatUsers">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddChatChatUser()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Chat User
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="ChatUser.table.table_fields" 
              :tableData="chatUsers" 
              :title="ChatUser.table.title" 
              :subtitle="ChatUser.table.subtitle" 
              :searchFields="ChatUser.table.searchFields"
              :rowData="ChatUser.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.ChatUser = Models.ChatUser;

    Event.$on("form:submit", async (payload) => {
      console.log(payload);
    });

    Event.$on("chat-user:view", async (payload) => {
      this.$router.push({
        name: "view_chat_user",
        params: {},
      });
    });

    Event.$on("chat-user:history", async (payload) => {
      console.log(payload);
    });

    Event.$on("chat-user:remove", async (payload) => {
      console.log(payload);
    });
  },
  data() {
    return {
      ChatUser: {},
      chatUsers: [
        {
          image: "",
          description: "Best in motor",
          ref: "RK003",
          name: "Robert Kamau",
          status: "Online",
        },
      ],
    };
  },
  methods: {
    openAddChatChatUser() {
      this.$router.push({
        name: "add_chat_user",
        params: {},
      });
    },
    async listChatUsers() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "ChatUsers",
            action: "list",
            data: {},
            meta_data: {},
          });

          //console.log(res.data.result.intermediaries)
          this.chatUsers = res.data.result.ChatUsers;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
  },
  async mounted() {
    //await this.listChatUsers();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
