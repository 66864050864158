<template lang="html">
   <div class="transactions">
		<div class="modal fade classic-modal" 
			id="viewTransactionsModal" 
			data-backdrop="static"
			data-keyboard="false"
			tabindex="-1"
			role="dialog"
			aria-labelledby="viewTransactionsModal" 
			aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<div class="row-contents">
									<div class="ci ci-info">
										<i class="fa fa-group-tie"></i>
									</div>
									<div class="text" id="formModalLabel">
										<div class="title">Transactions</div>
										<div class="small">Part Transation Details for {{ref}}.</div>
									</div>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
							</div>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-12">	
									<div class="table-responsive">
										<table class="table ml-table ml-responsive-md">
											<thead>
												<slot name="thead" v-if="hasHead"></slot>
												<tr v-else>
													<th scope="col">Tran Category</th>
													<th scope="col">Amount</th>
													<th scope="col">Tran Sub-Category</th>
													<th scope="col">Account No.</th>
													<th scope="col">Tran Type</th>
													<th scope="col">Third Party Ref</th>
													<th scope="col">Entity Type</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="t in trans">
													<td><div class="small bolder mt-2">{{t.tran_category}}</div></td>
													<td><div class="small bolder mt-2">{{t.currency_code}} <span class="bigger">{{t.tran_amount}}</span></div></td>
													<td><div class="small bolder mt-2">{{t.tran_category_code}}</div></td>
													<td><div class="bolder bigger mt-2">{{t.account_number}}</div></td>
													<td><div class="bolder small mt-2">{{t.tran_type}}</div></td>
													<td><div class="bolder small mt-2">{{t.thirdparty_ref}}</div></td>
													<td><div class="bolder small mt-2">{{t.entity_type}}</div></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="modal-footer">
							<button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
								Close
							</button>
						</div>
					</div>
				</div>
		</div>
      <CustomTable :tableFields="Transactions.table.table_fields"
         :icon="Transactions.table.icon"
         :title="Transactions.table.title"
         :subtitle="Transactions.table.subtitle"
         :tableData="transactions" 
         :searchFields="Transactions.table.searchFields"
         :rowData="Transactions.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
	async created() {
		this.Transactions = Models.Transactions;

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.Transactions.wizard.action) {
				await this.saveAccount(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			this.showModal = false;
		});

		Event.$on('transaction:view',async (payload) => {
			await this.fetchTransactionDetails(payload.tran)
		})
		// Event.$on('client:view',(payload) => {})
	},
	data() {
		return {
			Transactions: {},
			transactions: [],
			showModal: false,
			entity_type: "CLIENT",
			loading: false,
			trans: [],
			ref: ''
		};
	},
	methods: {
		addAccount() {
			this.showModal = true;
		},
		fetchTransactionDetails(tran){
			console.log(tran)
			return new Promise(async (resolve, reject) => {
				try {
					this.ref = tran.tran_ref
					let res = await req.callServerSecure({
						resource: "transaction",
						action: "tran_ref",
						data: {
							tran_ref: tran.tran_ref
						},
						meta_data: {}
					});

					this.trans = res.data.result.transaction
					$("#viewTransactionsModal").modal("show")
					resolve(true)
				} catch (err) {
					this.loading = false;
				}
			});
		},
		fetchTransactions() {
			return new Promise(async (resolve, reject) => {
				try {
					this.loading = true;

					let res = await req.callServerSecure({
						resource: "account",
						action: "get",
						data: {
							account_number: this.$route.params.id
						},
						meta_data: {}
					});

					this.transactions = res.data.result.transaction;
               this.loading = false;
               resolve(true)
				} catch (err) {
					this.loading = false;
				}
			});
		}
	},
	async mounted() {
      await this.fetchTransactions()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.transactions {
	
}
</style>