import Vue from 'vue';
import Router from 'vue-router';

//Layouts
import Layout from '@/components/layout';

//Components
import LandingPage from '@/components/pages/landing';
import LogIn from '@/components/pages/log-in';
import Dashboard from '@/components/pages/dashboard';
import AccountTypes from '@/components/pages/account-type';
import Users from '@/components/pages/configs/users';

/*Entities */
import UsersList from '@/components/pages/entities/users/Entities-UsersList'; 
import UsersView from '@/components/pages/entities/users/Entities-UsersView';
import ClientList from '@/components/pages/entities/clients/client-list';


import ClientConfig from '@/components/pages/entities/clients/client.configuration';

/* bank */

import BankConfigurations from '@/components/pages/entities/bank/pages/bank.configuration';

/*Service Provider */
import ServiceProviderConfig from '@/components/pages/entities/serviceproviders/pages/serviceprovider.configuration';
/*intermediary */

import IntermediaryList from '@/components/pages/entities/intermediaries/intermediary-list';
import IntermediaryConfig from '@/components/pages/entities/intermediaries/intermediaries.configuration';
import AddIntermediaryConfig from '@/components/pages/entities/intermediaries/pages/intermediary.configuration';

/*Underwriter */
import UnderwrittersConfig from '@/components/pages/entities/underwritters/underwritters.configuration';
import AddUnderwriterConfig from '@/components/pages/entities/underwritters/pages/underwriter.configuration';
import BlacklistConfig from '@/components/pages/entities/blacklist/list-blacklist';
import ListCoverAreas from '@/components/pages/entities/underwritters/list-cover-areas';
import AddCoverArea from '@/components/pages/entities/underwritters/add-cover-area';
import ViewCoverArea from '@/components/pages/entities/underwritters/view-cover-area';
import ProductConfig from '@/components/pages/entities/products/product-config';
import UnderwritterGroup from '@/components/pages/entities/underwritters/underwritter-group';
import UnderwritterWallet from '@/components/templates/pages/entities/underwritters/wallet';

import addUnderwriterGroup from '@/components/pages/entities/underwritters/underwriter-config-addgroup';

//import AddUnderwriter from '@/components/pages/entities/underwritters/pages/add.underwriter'; // New 
import AddUnderwriter from '@/components/pages/entities/underwritters/underwritters-config'; // OLD
import ViewUnderwritter from '@/components/pages/entities/underwritters/view-underwritter'; // OLD update this to new Underwriter

import ListUnderwriter from '@/components/pages/entities/underwritters/pages/list.underwriter';

Vue.component('UnderwritterWallet', UnderwritterWallet);
import UnderwritterReviews from '@/components/templates/pages/entities/underwritters/reviews';
Vue.component('UnderwritterReviews', UnderwritterReviews);
import UnderwritterSuperUsers from '@/components/templates/pages/entities/underwritters/super-users';
Vue.component('UnderwritterSuperUsers', UnderwritterSuperUsers);
import UnderwritterDirectors from '@/components/templates/pages/entities/underwritters/directors';
Vue.component('UnderwritterDirectors', UnderwritterDirectors);
import UnderwritterProducts from '@/components/templates/pages/entities/underwritters/products';
Vue.component('UnderwritterProducts', UnderwritterProducts);

// Underwritters


import ListPurchaseException from '@/components/pages/entities/purchase/list';
import ViewPurchaseException from '@/components/pages/entities/purchase/view';



import ListBanks from '@/components/pages/entities/bank/bank-configuration';

import ViewBank from '@/components/pages/entities/bank/view-bank';
import ViewIntermediary from '@/components/pages/entities/intermediaries/view-intermediary.vue';
// benefit category
import ConfigBenefitCategoryList from "@/components/templates/pages/entities/benefit/list";
import ConfigBenefitCategoryView from "@/components/templates/pages/entities/benefit/view";



/*Reports*/
// Queues
import PaymentQueues from '@/components/pages/reports/queues/payment-queue';
import NotificationQueues from '@/components/pages/reports/queues/notification-queue';

/*Configs*/
// accounting
import Accounts from '@/components/pages/configs/accounting/accounts';



import AccountEntity from '@/components/pages/configs/accounting/entity';

 // gl
import addGl from '@/components/pages/configs/accounting/gl/pages/add.gl';
import listGl from '@/components/pages/configs/accounting/gl/pages/list.gl';
//import GL from '@/components/pages/configs/accounting/gl/main.gl';
import listSubGl from "@/components/pages/configs/accounting/subgl/pages/list.subgl";
import addSubGl from "@/components/pages/configs/accounting/subgl/pages/add.subgl";
// currency
import AddCurrency from '@/components/pages/configs/accounting/currency/pages/add.currency';
import ListCurrency from '@/components/pages/configs/accounting/currency/pages/list.currency';
// system Accounts

import listSystemAccounts from '@/components/pages/configs/accounting/system/pages/list.account';
import addSystemAccounts from '@/components/pages/configs/accounting/system/pages/add.account';

import AccountLimit from '@/components/pages/configs/accounting/limit';
import Statement from '@/components/pages/configs/accounting/statement';

// account status
import AddAccountStatus from '@/components/pages/configs/accounting/status/pages/add.status';
import ListAccountStatus from '@/components/pages/configs/accounting/status/pages/list.status';
//import ViewAccountStatus from '@/components/pages/configs/accounting/status/pages/view.status';

//import Status from '@/components/pages/configs/accounting/status';
import ViewAccount from '@/components/pages/configs/accounting/view-account';

//act Opn Template
import AccountTemplate from '@/components/pages/configs/accounting/account-opening-template';

import listActOpnTemplate from '@/components/pages/configs/accounting/accounttemplate/pages/list.actopntempl';
import addActOpnTemplate from '@/components/pages/configs/accounting/accounttemplate/pages/add.actopntempl';

import TaxCategories from '@/components/pages/configs/accounting/tax-categories';
import TaxList from '@/components/pages/configs/accounting/tax/list';
import TaxView from '@/components/pages/configs/accounting/tax/view';
import FeesList from '@/components/pages/configs/accounting/fees/list';
import FeesView from '@/components/pages/configs/accounting/fees/view';

//transactions
import Channels from '@/components/pages/configs/transactions/channel';
import ViewChannel from '@/components/pages/configs/transactions/view-channel';
import AddChannel from '@/components/pages/configs/transactions/add-channel';
import Charges from '@/components/pages/configs/transactions/charge';
import AddCharge from '@/components/pages/configs/transactions/add-charge';
import Limits from '@/components/pages/configs/transactions/limit';
import AddLimit from '@/components/pages/configs/transactions/add-limit';
import TopUps from '@/components/pages/configs/transactions/topup';
import ViewTopup from '@/components/pages/configs/transactions/view-topup';
import AddTopup from '@/components/pages/configs/transactions/add-topup';
import TransactionConfig from '@/components/pages/configs/transactions/transaction-config';
import PurchasePolicy from '@/components/pages/configs/transactions/purchase-policy-config';

//calendar
import Holidays from '@/components/pages/configs/calendar/holidays';
import AddHoliday from '@/components/pages/configs/calendar/add-holiday';
import ViewHoliday from '@/components/pages/configs/calendar/view-holiday';

// system
import FormfieldClass from '@/components/pages/configs/system/form-fields';
import AddFormField from '@/components/pages/configs/system/add-form-field';
import EditFormField from '@/components/pages/configs/system/edit-form-field';
import recomendationClass from '@/components/pages/configs/system/recomendation';
import uploadClass from '@/components/pages/configs/system/uploads';
import ViewReccomendation from '@/components/pages/configs/system/view-reccomendation';
import viewploadclass from '@/components/pages/configs/system/view-upload';
import icons from '@/components/pages/configs/system/icons';
import addCountry from '@/components/pages/configs/system/country/add-country';
import listCountry from '@/components/pages/configs/system/country/list-country';

/*UserManagement*/
import view_role from '@/components/pages/configs/usermanagement/role/view_role';
import list_roles from '@/components/pages/configs/usermanagement/role/list_roles';
import add_role from '@/components/pages/configs/usermanagement/role/add_role';
import AddResourceModal from '@/components/pages/configs/usermanagement/role/add-resource-modal';
Vue.component('AddResourceModal', AddResourceModal);
import list_departments from '@/components/pages/configs/usermanagement/departments/list_departments';
import add_department from '@/components/pages/configs/usermanagement/departments/add_department';
import view_department from '@/components/pages/configs/usermanagement/departments/view_department';
import list_users from '@/components/pages/configs/usermanagement/users/list_users';
import add_users from '@/components/pages/configs/usermanagement/users/add_users';
import view_user from '@/components/pages/configs/usermanagement/users/view_user';
import list_chat_users from '@/components/pages/configs/usermanagement/chat-users/list_chat_users';
import add_chat_user from '@/components/pages/configs/usermanagement/chat-users/add_chat_user';
import view_chat_user from '@/components/pages/configs/usermanagement/chat-users/view_chat_user';
import list_user_groups from '@/components/pages/configs/usermanagement/user-group/list_user_groups';
import add_user_group from '@/components/pages/configs/usermanagement/user-group/add_user_group';
import view_user_group from '@/components/pages/configs/usermanagement/user-group/view_user_group';
import UserModal from '@/components/pages/configs/usermanagement/user-group/user-modal';
Vue.component('UserModal', UserModal);
import list_resources from '@/components/pages/configs/usermanagement/resources/resources';
import list_security_context from '@/components/pages/configs/usermanagement/security/list_security_context';
import add_security_context from '@/components/pages/configs/usermanagement/security/add_security_context';
import Forbidden from '@/components/pages/alerts/forbidden';
import Badrequest from '@/components/pages/alerts/badrequest';

import ForgotPassword from '@/components/pages/forgot-password';
import resetpassword from '@/components/pages/reset-password';

//uploads
import bankUploads from '@/components/pages/configs/uploads/bank';
import CrspMakeUploads from '@/components/pages/configs/uploads/crsp_make';
import CrspModelUploads from '@/components/pages/configs/uploads/crsp_model';
import intermediariesUploads from '@/components/pages/configs/uploads/crsp_model';
import postalcodesUploads from '@/components/pages/configs/uploads/postalcodes';
import sic_industryUploads from '@/components/pages/configs/uploads/sic_industry';
import sic_jobsUploads from '@/components/pages/configs/uploads/sic_jobs';
import underwriterUploads from '@/components/pages/configs/uploads/underwriter';

//import lorriesUploads from '@/components/pages/configs/uploads/lorries';

//System configs
import InsuranceClass from '@/components/pages/configs/insurance/class';


// nexus TnC
import TnC from "@/components/pages/nexus/legal/tnc";
import ViewTnC from "@/components/pages/nexus/legal/view-tnc";
import TnCModal from "@/components/modals/tnc/tnc-modal";
Vue.component("TnCModal", TnCModal);
import TnCEditModal from "@/components/modals/tnc/tncEdit-modal";
Vue.component("TnCEditModal", TnCEditModal);
import TnCDeleteModal from "@/components/modals/tnc/tncDelete-modal";
Vue.component("TnCDeleteModal", TnCDeleteModal);



// nexus
import ConfiguredServices from "@/components/pages/nexus/services/configured-services";
import ChargebleServices from "@/components/pages/nexus/services/chargable-services";

// nexus notifications
import NotificationsConfigList from "@/components/pages/nexus/notifications/list"
import NotificationsConfigConfigure from "@/components/pages/nexus/notifications/configure"
import NotifcationsConfigQueue from "@/components/pages/nexus/notifications/queue"

import EntityNotificationConfig from "@/components/pages/nexus/notifications/entity-notifications-list"
import NotificationsEventConfig from "@/components/pages/nexus/notifications/entity-notification-view.vue"


// Entities
/* clients */

import AddClientEntityConfig from "@/components/pages/entities/clients/pages/client.configuration.vue"

// schools entity
//import EntitiesSchoolsConfigure from "@/components/pages/entities/school/Configure.vue"
import EntitiesSchoolsConfigure from "@/components/pages/entities/school/school.configuration.vue"
import EntitiesSchoolsList from "@/components/pages/entities/school/List.vue"
import ViewSchool from "@/components/pages/entities/school/ViewSchool.vue"
import EntitiesSchoolsReleaseQueue from "@/components/pages/entities/school/ReleaseQueue.vue"
import ViewSchoolsReleaseQueue from "@/components/pages/entities/school/ViewReleaseQueue.vue"
import EntitiesAccountReportSummary from "@/components/pages/entities/school/AccountReportSummary.vue"


import EntitiesSchoolJobsList from "@/components/pages/entities/schooljobs/Entities-SchoolJobsList.vue"
import EntitiesSchoolJobsView from "@/components/pages/entities/schooljobs/Entities-SchoolJobsView.vue"

import EntitiesParentsList from "@/components/pages/entities/parents/Entities-ParentsList.vue"
import EntitiesParentsView from "@/components/pages/entities/parents/Entities-ParentsView.vue"


// client types
import ListClientTypes from '@/components/pages/configs/insurance/clientType/list-client-types';
import ViewClientType from '@/components/pages/configs/insurance/clientType/view-client-type';
import AddClientType from '@/components/pages/configs/insurance/clientType/add-client-type';
import editClientType from '@/components/pages/configs/insurance/clientType/edit-client-type';

// serviceCategories
import ListServiceCategories from '@/components/pages/configs/insurance/servicecategory/servicecategory-list';
import AddServiceCategories from '@/components/pages/configs/insurance/servicecategory/servicecategory-add';
import ViewServiceCategories from '@/components/pages/configs/insurance/servicecategory/view-servicecategory';

// insurance services
import InsuranceListService from '@/components/pages/configs/insurance/services/service-list';
import AccountingWithdrawal from '@/components/pages/configs/accounting/withdrawal';
import AccountingSettlement from '@/components/pages/configs/accounting/settlement';

import ClientSubType from '@/components/pages/configs/insurance/clientSubType/list-client-subtype';
import ViewClientSubType from '@/components/pages/configs/insurance/clientSubType/view-client-subtype';
import EditClientSubType from '@/components/pages/configs/insurance/clientSubType/edit-client-subtype';
import AddClientSubType from '@/components/pages/configs/insurance/clientSubType/add-client-subtype';

import InsuranceSubClass from '@/components/pages/configs/insurance/classes/list-class';
import viewSubClass from '@/components/pages/configs/insurance/classes/view-class';
import addSubClass from '@/components/pages/configs/insurance/classes/add-class';
import editSubClass from '@/components/pages/configs/insurance/classes/edit-class';

import KycField from '@/components/pages/configs/insurance/kyc-fields';
import Assessment from '@/components/pages/configs/insurance/assessment';
import AddAssessment from '@/components/pages/configs/insurance/add_assesment';
import CoverTypes from '@/components/pages/configs/insurance/cover-types';
import ProductRequirements from '@/components/pages/configs/insurance/product-requirements';
import ProductCharges from '@/components/pages/configs/insurance/product-charge';
import Templates from '@/components/pages/configs/insurance/templates';
import ClaimtypeClass from '@/components/pages/configs/insurance/claimtypes';
import ViewClaimtypeClass from '@/components/pages/configs/insurance/view-claimtype';
// claim categories
import ClaimCategories from '@/components/pages/configs/insurance/claimcategories';

// Child Components
import ViewClient from '@/components/pages/entities/clients/view-client';

import CustomFooter from '@/components/templates/footer';
Vue.component('CustomFooter', CustomFooter);
import CustomNav from '@/components/templates/nav';
Vue.component('CustomNav', CustomNav);
import Breadcrumb from '@/components/templates/breadcrumb';
Vue.component('Breadcrumb', Breadcrumb);

// Templates
import Pagination from '@/components/templates/pagination/pagination';
Vue.component('Pagination', Pagination);
import CustomTable from '@/components/templates/table/custom-table';
Vue.component('CustomTable', CustomTable);
import ModalForm from '@/components/modals/modal-form';
Vue.component('ModalForm', ModalForm);
import ModalView from '@/components/modals/modal-view';
Vue.component('ModalView', ModalView);
import VerifyModal from '@/components/modals/verify-modal';
Vue.component('VerifyModal', VerifyModal);
import ModalForm2 from '@/components/modals/modal-form';
Vue.component('ModalForm2', ModalForm2);
import CustomForm from '@/components/templates/forms/custom-forms';
Vue.component('CustomForm', CustomForm);
import EditCustomForm from '@/components/templates/forms/edit-custom-form';
Vue.component('EditCustomForm', EditCustomForm);
import SelectForm from '@/components/templates/forms/select-form';
Vue.component('SelectForm', SelectForm);
import TableForm from '@/components/templates/forms/table-form';
Vue.component('TableForm', TableForm);
import FileUploader from '@/components/modals/upload-file';
Vue.component('FileUploader', FileUploader);
import MapLocation from '@/components/modals/map-location';
Vue.component('MapLocation', MapLocation);
import MapShowLocation from '@/components/modals/map-show-location';
Vue.component('MapShowLocation', MapShowLocation);
import UploadImage from '@/components/modals/image-upload/upload-image';
Vue.component('UploadImage', UploadImage);
import PickColor from '@/components/modals/color-picker';
Vue.component('PickColor', PickColor);
import ViewForm from '@/components/templates/forms/view-form';
Vue.component('ViewForm', ViewForm);

// Page Templates


// Banks
import BankSuperUsers from '@/components/templates/pages/entities/banks/super-users';
Vue.component('BankSuperUsers', BankSuperUsers);
import BankDirectors from '@/components/templates/pages/entities/banks/directors';
Vue.component('BankDirectors', BankDirectors);

// Notification Components
import Notify from '@/components/templates/notification/notify';
Vue.component('Notify', Notify);
import NotificationAlert from '@/components/templates/notification/notifications-alert';
Vue.component('NotificationAlert', NotificationAlert);

// LoanProductAccountOpening
import LoanProductAccountOpening from "@/components/pages/configs/insurance/loans/loan-product-account";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "LogIn",
      component: LogIn,
    },
    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    ,
    {
      path: "/reset-password",
      name: "reset-password",
      component: resetpassword,
    },

    {
      path: "/dashboard",
      component: Layout,
      children: [
        { path: "/", name: "Dashboard", component: Dashboard },

        {
          path: "/uploads/bank-upload",
          name: "bankUploads",
          component: bankUploads,
        },

        {
          path: "/uploads/crs-make-upload",
          name: "CrspMakeUploads",
          component: CrspMakeUploads,
        },

        {
          path: "/uploads/crs-model-upload",
          name: "CrspModelUploads",
          component: CrspModelUploads,
        },
        {
          path: "/uploads/sic-industry-upload",
          name: "sic_industryUploads",
          component: sic_industryUploads,
        },
        {
          path: "/uploads/sic-jobs-upload",
          name: "sic_jobsUploads",
          component: sic_jobsUploads,
        },
        {
          path: "/uploads/postal-codes-upload",
          name: "postalcodesUploads",
          component: postalcodesUploads,
        },
        {
          path: "/uploads/underwriter-upload",
          name: "underwriterUploads",
          component: underwriterUploads,
        },
        {
          path: "/uploads/intermediary-upload",
          name: "intermediariesUploads",
          component: intermediariesUploads,
        },

        // Configs-accounts
        {
          path: "/configs/accounting/account-opening-template",
          name: "accounting.actopntemplate.list",
          component: listActOpnTemplate,
        },
        {
          path: "/configs/accounting/account-opening-template/add",
          name: "accounting.actopntemplate.add",
          component: addActOpnTemplate,
        },
        {
          path: "/configs/accounting/accounts",
          name: "Configs-Accounting-Accounts",
          component: Accounts,
        },
        {
          path: "/configs/accounting/system-accounts",
          name: "accounting.system.listSystemAccounts",
          component: listSystemAccounts,
        },
        {
          path: "/configs/accounting/system-accounts/add",
          name: "accounting.system.addSystemAccounts",
          component: addSystemAccounts,
        },
   
        {
          path: "/configs/accounting/currency",
          name: "accounting.currency.ListCurrency",
          component: ListCurrency,
        },
        {
          path: "/configs/accounting/currency/add",
          name: "accounting.currency.AddCurrency",
          component: AddCurrency,
        },
        {
          path: "/configs/accounting/entity",
          name: "Configs-Accounting-AccountEntity",
          component: AccountEntity,
        },

        {
          path: "/configs/accounting/listgl",
          name:"accounting.gl.listGl",
          component: listGl,
        },
        {
          path: "/configs/accounting/addgl",
          name:"accounting.gl.addGl",
          component: addGl,
        },
        {
          path: "/configs/accounting/listsubledgers",
          name: "accounting.subgl.listSubGl",
          component: listSubGl,
        },
        {
          path: "/configs/accounting/addsubledger",
          name: "accounting.subgl.addSubGl",
          component: addSubGl,
        },
        {
          path: "/configs/accounting/addaccountstatus",
          name: "accounting.accountstatus.addStatus",
          component: AddAccountStatus,
        },
        {
          path: "/configs/accounting/listaccountstatus",
          name: "accounting.accountstatus.listStatus",
          component: ListAccountStatus,
        },

        {
          path: "/configs/accounting/limit",
          name: "Configs-Accounting-AccountLimit",
          component: AccountLimit,
        },
        {
          path: "/configs/accounting/statement",
          name: "Configs-Accounting-Statement",
          component: Statement,
        },

        {
          path: "/configs/accounting/view-transactions/:id",
          name: "Configs-Accounting-ViewAccount",
          component: ViewAccount,
        },
        { path: "/users", name: "Configs-Users", component: Users },
        {
          path: "/view-user/:id",
          name: "Configs-Users-ViewClient",
          component: ViewClient,
        },
        {
          path: "/account-types",
          name: "AccountTypes",
          component: AccountTypes,
        },
        {
          path: "/configs/accounting/tax-categories",
          name: "Configs-Accounting-TaxCategories",
          component: TaxCategories,
        },

        {
          path: "/configs/accounting/tax/list",
          name: "Configs-Accounting-Tax-List",
          component: TaxList,
        },

        {
          path: "/configs/accounting/tax/view/:id",
          name: "Configs-Accounting-Tax-View",
          component: TaxView,
        },

        {
          path: "/configs/accounting/fees",
          name: "Configs-Fees",
          component: FeesList,
        },

        //urmg
        {
          path: "/usermanagement/role/:id",
          name: "view_role",
          component: view_role,
        },
        {
          path: "/usermanagement/list_role",
          name: "list_roles",
          component: list_roles,
        },
        {
          path: "/usermanagement/add_role",
          name: "add_role",
          component: add_role,
        },
        {
          path: "/usermanagement/list_department",
          name: "list_departments",
          component: list_departments,
        },
        {
          path: "/usermanagement/add_department",
          name: "add_department",
          component: add_department,
        },
        {
          path: "/usermanagement/view_department/:id",
          name: "view_department",
          component: view_department,
        },
        {
          path: "/usermanagement/list_users",
          name: "list_users",
          component: list_users,
        },
        {
          path: "/usermanagement/add_user",
          name: "add_users",
          component: add_users,
        },
        {
          path: "/usermanagement/view_user/:id",
          name: "view_user",
          component: view_user,
        },
        {
          path: "/usermanagement/list_chat_users",
          name: "list_chat_users",
          component: list_chat_users,
        },
        {
          path: "/usermanagement/add_chat_user",
          name: "add_chat_user",
          component: add_chat_user,
        },
        {
          path: "/usermanagement/view_chat_user",
          name: "view_chat_user",
          component: view_chat_user,
        },
        {
          path: "/usermanagement/list_user_groups",
          name: "list_user_groups",
          component: list_user_groups,
        },
        {
          path: "/usermanagement/add_user_group",
          name: "add_user_group",
          component: add_user_group,
        },
        {
          path: "/usermanagement/view_user_group",
          name: "view_user_group",
          component: view_user_group,
        },
        {
          path: "/usermanagement/list_security_context",
          name: "list_security_context",
          component: list_security_context,
        },
        {
          path: "/usermanagement/resources",
          name: "list_resources",
          component: list_resources,
        },
        {
          path: "/usermanagement/add_security_context",
          name: "add_security_context",
          component: add_security_context,
        },
        {
          path: "/usermanagement/view_security_context/:id",
          name: "view_security_context",
          component: add_security_context,
        },
        // country
        {
          path: "/config/add_country",
          name: "addCountry",
          component: addCountry,
        },
        {
          path: "/config/list_country",
          name: "listCountry",
          component: listCountry,
        },

        // Alerts
        {
          path: "/forbidden",
          name: "forbidden",
          component: Forbidden,
        },
        {
          path: "/badrequest",
          name: "badrequest",
          component: Badrequest,
        },

        // Configs-transactions
        {
          path: "/configs/transactions/channels",
          name: "Configs-Transactions-Channels",
          component: Channels,
        },
        {
          path: "/configs/transactions/channels/view-channel",
          name: "Configs-Transactions-ViewChannel",
          component: ViewChannel,
        },
        {
          path: "/configs/transactions/channels/add-channel",
          name: "Configs-Transactions-AddChannel",
          component: AddChannel,
        },
        {
          path: "/configs/transactions/charges",
          name: "Configs-Transactions-Charges",
          component: Charges,
        },
        {
          path: "/configs/transactions/charges/add-charge",
          name: "Configs-Transactions-AddCharge",
          component: AddCharge,
        },
        {
          path: "/configs/transactions/limits",
          name: "Configs-Transactions-Limits",
          component: Limits,
        },
        {
          path: "/configs/transactions/limits/add-limit",
          name: "Configs-Transactions-AddLimit",
          component: AddLimit,
        },
        {
          path: "/configs/transactions/topups",
          name: "Configs-Transactions-Topups",
          component: TopUps,
        },
        {
          path: "/configs/transactions/topups/view-topup",
          name: "Configs-Transactions-ViewTopup",
          component: ViewTopup,
        },
        {
          path: "/configs/transactions/topups/add-topup",
          name: "Configs-Transactions-AddTopup",
          component: AddTopup,
        },
        {
          path: "/configs/transactions/transaction-config",
          name: "Configs-Transactions-TransactionConfig",
          component: TransactionConfig,
        },
        {
          path: "/configs/transactions/transaction-config/purchase-policy",
          name: "Configs-Transactions-PurchasePolicy",
          component: PurchasePolicy,
        },

        // Configs-calendar
        {
          path: "/configs/calendar/holidays",
          name: "Configs-Calendar-Holidays",
          component: Holidays,
        },
        {
          path: "/configs/calendar/holidays/add-holiday",
          name: "Configs-Calendar-AddHoliday",
          component: AddHoliday,
        },
        {
          path: "/configs/calendar/holidays/:id",
          name: "Configs-Calendar-ViewHoliday",
          component: ViewHoliday,
        },

        // config-system
        {
          path: "/configs/system/form-fields",
          name: "Configs-System-formFields",
          component: FormfieldClass,
        },
        {
          path: "/configs/system/form-fields/add-form-field",
          name: "Configs-System-AddFormField",
          component: AddFormField,
        },
        {
          path: "/configs/system/form-fields/edit-form-field/:id",
          name: "Configs-System-EditFormField",
          component: EditFormField,
        },
        {
          path: "/configs/system/reccomendation",
          name: "Configs-Insurance-recomendation",
          component: recomendationClass,
        },
        {
          path: "/configs/system/uploads",
          name: "Configs-Insurance-upload",
          component: uploadClass,
        },
        {
          path: "/configs/system/view-reccomendation/:id",
          name: "ViewReccomendation",
          component: ViewReccomendation,
        },
        {
          path: "/configs/system/view-upload/:id",
          name: "Configs-Insurance-ViewUpload",
          component: viewploadclass,
        },
        {
          path: "/configs/system/icons",
          name: "Configs-icons",
          component: icons,
        },

        // nexus config
        {
          path: "/nexus/services/chargable-services",
          name: "Services-Chargable-Services",
          component: ChargebleServices,
        },

        // TnC
        {
          path: "/legal/tnc",
          name: "TnC",
          component: TnC,
        },
        {
          path: "/legal/tnc/:id",
          name: "ViewTnC",
          component: ViewTnC,
        },

        // nexus notifications
        {
          path: "/nexus/notifications",
          name: "Notifications-Config",
          component: NotificationsConfigList,
        },

        {
          path: "/nexus/notifications/event/:event_ref",
          name: "Notifications-Event-Config",
          component: NotificationsEventConfig,
        },

        {
          path: "/nexus/notifications/configure",
          name: "Notifications-Configuration",
          component: NotificationsConfigConfigure,
        },
        {
          path: "/nexus/notifications/queue",
          name: "Notifications-Queue",
          component: NotifcationsConfigQueue,
        },

        {
          path: "/nexus/entity-notifications/list",
          name: "Entity-Notifications-Configuration",
          component: EntityNotificationConfig,
        },

        {
          path: "/nexus/services/configured-services",
          name: "Services-Configured-Services",
          component: ConfiguredServices,
        },

        // entity schools config
        {
          path: "/nexus/schools/list",
          name: "Entities-SchoolsList",
          component: EntitiesSchoolsList,
        },
        {
          path: "/nexus/schools/Configure",
          name: "Entities-SchoolsConfigure",
          component: EntitiesSchoolsConfigure,
        },
        {
          path: "/nexus/schools/view/:id",
          name: "View-School",
          component: ViewSchool,
        },

        {
          path: "/nexus/schools/release-queue",
          name: "Entities-SchoolsReleaseQueue",
          component: EntitiesSchoolsReleaseQueue,
        },
        {
          path: "/nexus/schools/release-queue/:id",
          name: "View-ReleaseQueue",
          component: ViewSchoolsReleaseQueue,
        },

        {
          path: "/nexus/schools/parents/",
          name: "Entities-ParentsList",
          component: EntitiesParentsList,
        },

        {
          path: "/nexus/schools/parents/:id",
          name: "Entities-ParentsView",
          component: EntitiesParentsView,
        },
        {
          path: "/nexus/schools/account-summary",
          name: "Entities-AccountReportSummary",
          component: EntitiesAccountReportSummary,
        },
        {
          path: "/nexus/schools/jobs",
          name: "Entities-SchoolJobsList",
          component: EntitiesSchoolJobsList,
        },

        {
          path: "/nexus/schools/jobs/:id",
          name: "Entities-SchoolJobsView",
          component: EntitiesSchoolJobsView,
        },

        //Client  Configs
        {
          path: "/configs/insurance/list-client-types",
          name: "Configs-Insurance-ClientType",
          component: ListClientTypes,
        },
        {
          path: "/configs/insurance/add-client-type",
          name: "Configs-add-client-type",
          component: AddClientType,
        },
        {
          path: "/configs/insurance/edit-client-type:/id",
          name: "Configs-edit-client-type",
          component: editClientType,
        },

        {
          path: "/configs/insurance/view-client-type/:id",
          name: "Configs-Insurance-ViewClientType",
          component: ViewClientType,
        },
        {
          path: "/configs/insurance/list-client-subtypes",
          name: "Configs-Insurance-ClientSubType",
          component: ClientSubType,
        },
        {
          path: "/configs/insurance/add-client-subtype",
          name: "Configs-add-client-subtype",
          component: AddClientSubType,
        },
        {
          path: "/configs/insurance/edit-client-subtype/:id",
          name: "Configs-Insurance-EditClientSubType",
          component: EditClientSubType,
        },

        {
          path: "/configs/insurance/view-client-subtype/:id",
          name: "Configs-Insurance-ViewClientSubType",
          component: ViewClientSubType,
        },
        {
          path: "/configs/insurance/view-sub-class/:id",
          name: "Configs-Insurance-ViewSubclass",
          component: viewSubClass,
        },
        {
          path: "/configs/insurance/view-claimtype/:id",
          name: "Configs-Insurance-ViewClaimtype",
          component: ViewClaimtypeClass,
        },
        {
          path: "/configs/insurance/claimcategories",
          name: "Configs-Insurance-ClaimCategories",
          component: ClaimCategories,
        },
        {
          path: "/configs/insurance/kyc-fields",
          name: "Configs-Insurance-KycField",
          component: KycField,
        },

        {
          path: "/configs/insurance/sub-class",
          name: "Configs-Insurance-InsuranceSubClass",
          component: InsuranceSubClass,
        },
        {
          path: "/configs/insurance/add-class",
          name: "Configs-addInsuranceClass",
          component: addSubClass,
        },
        {
          path: "/configs/insurance/edit-class/:id",
          name: "Configs-edit-subclass",
          component: editSubClass,
        },

        {
          path: "/configs/insurance/assessment",
          name: "Configs-Insurance-Assessment",
          component: Assessment,
        },
        {
          path: "/configs/insurance/add-assessment",
          name: "Add-Assessment",
          component: AddAssessment,
        },
        {
          path: "/configs/insurance/cover-types",
          name: "Configs-Insurance-CoverTypes",
          component: CoverTypes,
        },
        {
          path: "/configs/insurance/templates",
          name: "Configs-Insurance-Templates",
          component: Templates,
        },
        {
          path: "/configs/insurance/product-charges",
          name: "Configs-Insurance-ProductCharges",
          component: ProductCharges,
        },
        {
          path: "/configs/insurance/product-requirements",
          name: "Configs-Insurance-ProductRequirements",
          component: ProductRequirements,
        },
        {
          path: "/configs/insurance/claimtypes",
          name: "Configs-Insurance-ClaimtypeClass",
          component: ClaimtypeClass,
        },

        {
          path: "/configs/insurance/services",
          name: "Configs-Insurance-Services",
          component: InsuranceListService,
        },

        {
          path: "/configs/accounting/withdrawal",
          name: "Configs-Accounting-Withdrawal",
          component: AccountingWithdrawal,
        },
        {
          path: "/configs/accounting/settlement",
          name: "Configs-Accounting-Settlement",
          component: AccountingSettlement,
        },

        // Entities
        {
          path: "/reports/queues/payment-queues",
          name: "Reports-Queues-PaymentQueues",
          component: PaymentQueues,
        },
        {
          path: "/reports/queues/notification-queues",
          name: "Reports-Queues-NotificationQueues",
          component: NotificationQueues,
        },

        // blacklist
        {
          path: "/entities/blacklist",
          name: "Entities-BlacklistConfig",
          component: BlacklistConfig,
        },

        // Entities
		  // underwiters
		{
		  path: "/entities/underwriter/add",
		  name: "entity.underwriter.add",
		  component: AddUnderwriter,
		},

		{
		  path: "/entities/underwriter/addgroup:id",
		  name: "entity.underwriter.addgroup",
		  component: addUnderwriterGroup,
		},
    
		{
		  path: "/entities/underwriter/list",
		  name: "entity.underwriter.list",
		  component: ListUnderwriter,
		},
        {
          path: "/entities/underwritters",
          name: "Entities-UnderwrittersConfig",
          component: UnderwrittersConfig,
        },
        {
          path: "/entities/underwriter/underwriter-config",
          name: "entity.underwriter.addConfig",
          component: AddUnderwriterConfig,
        },
        {
          path: "/entities/underwritter/list-cover-areas",
          name: "ListCoverAreas",
          component: ListCoverAreas,
        },
        {
          path: "/entities/underwritter/add-cover-area",
          name: "AddCoverArea",
          component: AddCoverArea,
        },
        {
          path: "/entities/underwritter/view-cover-area/:id",
          name: "ViewCoverArea",
          component: ViewCoverArea,
        },
        {
          path: "/entities/underwritters-group",
          name: "Entities-UnderwritterGroup",
          component: UnderwritterGroup,
        },
        {
          path: "/entities/users/list",
          name: "Entities-UsersList",
          component: UsersList,
        },

        {
          path: "/entities/users/view/:id",
          name: "Entities-UsersView",
          component: UsersView,
        },
        {
          path: "/entites/client-list",
          name: "ClientList",
          component: ClientList,
        },
        {
          path: "/entites/intermediary-list",
          name: "IntermediaryList",
          component: IntermediaryList,
        },
        {
          path: "/entites/view-intermediary/:id",
          name: "ViewIntermediary",
          component: ViewIntermediary,
        },
        {
          path: "/entites/intermediary-config",
          name: "IntermediaryConfig",
          component: IntermediaryConfig,
        },

        {
          path: "/entites/intermediary/intermediary-config",
          name: "AddIntermediaryConfig",
          component: AddIntermediaryConfig,
        }, 
        {
          path: "/entites/serviceprovider/serviceprovider-config",
          name: "serviceProviderConfig",
          component: ServiceProviderConfig,
        },

          // to deprecate with below
        {
          path: "/entites/client-config",
          name: "ClientConfig",
          component: ClientConfig,
        },

        {
          path: "/entites/client/client-config",
          name: "entity.client.addConfig",
          component: AddClientEntityConfig,
        },


        {
          path: "/entites/product-config",
          name: "Entities-ProductConfig",
          component: ProductConfig,
        },

        {
          path: "/entites/purchace-exception",
          name: "Entities-PurchaceException",
          component: ListPurchaseException,
        },
        {
          path: "/entites/purchace-exception/:id",
          name: "Entities-PurchaceException-View",
          component: ViewPurchaseException,
        },
        {
          path: "/entities/view-underwritter/:id",
          name: "Entities-Underwritters-ViewUnderwritter",
          component: ViewUnderwritter,
        },
        {
          path: "/entities/bank/bank-config",
          name: "Entities-BankConfig",
          component: BankConfigurations,
        },
        {
           path: "/entities/bank/list",
           name: "entity.bank.list",
           component: ListBanks,
         },
 
        {
          path: "/entities/view-bank/:id",
          name: "Entities-Bank-ViewBank",
          component: ViewBank,
        },

        {
          path: "/entities/benefit-categories",
          name: "Config-BenefitCategory-List",
          component: ConfigBenefitCategoryList,
        },
        {
          path: "/entities/benefit-categories/:id",
          name: "Config-BenefitCategory-View",
          component: ConfigBenefitCategoryView,
        },

        // Entities
        // {
        //   path: "/reports/queues/payment-queues",
        //   name: "Reports-Queues-PaymentQueues",
        //   component: PaymentQueues,
        // },
        // {
        //   path: "/reports/queues/notification-queues",
        //   name: "Reports-Queues-NotificationQueues",
        //   component: NotificationQueues,
        // },

        // // Entities
        // {
        //   path: "/entities/underwritters",
        //   name: "Entities-UnderwrittersConfig",
        //   component: UnderwrittersConfig,
        // },
        // {
        //   path: "/entities/underwritter-config",
        //   name: "UnderwrittersConfig",
        //   component: UnderwrittersConfiguration,
        // },
        // {
        //   path: "/entities/underwritters-group",
        //   name: "Entities-UnderwritterGroup",
        //   component: UnderwritterGroup,
        // },
        // {
        //   path: "/entites/client-list",
        //   name: "ClientList",
        //   component: ClientList,
        // },
        // {
        //   path: "/entites/intermediary-list",
        //   name: "IntermediaryList",
        //   component: IntermediaryList,
        // },
        // {
        //   path: "/entites/view-intermediary/:id",
        //   name: "ViewIntermediary",
        //   component: ViewIntermediary,
        // },
        // {
        //   path: "/entites/intermediary-config",
        //   name: "IntermediaryConfig",
        //   component: IntermediaryConfig,
        // },
        // {
        //   path: "/entites/serviceprovider-config",
        //   name: "serviceProviderConfig",
        //   component: ServiceProviderConfig,
        // },
        // {
        //   path: "/entites/client-config",
        //   name: "ClientConfig",
        //   component: ClientConfig,
        // },
        // {
        //   path: "/entites/product-config",
        //   name: "Entities-ProductConfig",
        //   component: ProductConfig,
        // },
        // {
        //   path: "/entities/view-underwritter/:id",
        //   name: "Entities-Underwritters-ViewUnderwritter",
        //   component: ViewUnderwritter,
        // },
        // {
        //   path: "/entities/bank-config",
        //   name: "Entities-BankConfig",
        //   component: BankConfig,
        // },
        // {
        //   path: "/entities/bank-configurations",
        //   name: "Entities-BankConfigurations",
        //   component: BankConfigurations,
        // },
        // {
        //   path: "/entities/view-bank/:id",
        //   name: "Entities-Bank-ViewBank",
        //   component: ViewBank,
        // },

        {
          path: "/entites/service-categories",
          name: "serviceCategories",
          component: ListServiceCategories,
        },
        {
          path: "/entites/service-categories/add",
          name: "AddServiceCategories",
          component: AddServiceCategories,
        },
        {
          path: "/entities/service-category/:id",
          name: "ViewServiceCategories",
          component: ViewServiceCategories,
        },

        // loan product account opening
        {
          path: "/config/loan-product-account-opening",
          name: "LoanProductAccountOpening",
          component: LoanProductAccountOpening,
        },
      ],
    },
  ],
});
