<template lang="html">
    <div class="account-types">
        <ModalForm :showModal="showAddModal" :action="Bank.wizard.add_bank.action">
            <template >
                <CustomForm 
                    :pages="Bank.wizard.add_bank.pages" 
                    :action="Bank.wizard.add_bank.action" 
                    :buttonText="Bank.wizard.add_bank.actionBtn" 
                    :showTitle="false">
                </CustomForm>
            </template>
        </ModalForm>

        <ModalForm :showModal="showAddSuperUser" :action="Bank.wizard.add_super_user.action">
            <template >
                <CustomForm 
                    :pages="Bank.wizard.add_super_user.pages" 
                    :action="Bank.wizard.add_super_user.action" 
                    :buttonText="Bank.wizard.add_super_user.actionBtn" 
                    :showTitle="false">
                </CustomForm>
            </template>
        </ModalForm>
        
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb bg-">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10 bg-red">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Bank List</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Configured Banks</h4>
            </div>
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5" @click="addBank()">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Add Bank
                </button>
            </div>
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
                <CustomTable 
                    :icon="Bank.table.bank.icon"
                    :title="Bank.table.bank.title"
                    :subtitle="Bank.table.bank.subtitle"
                    :tableFields="Bank.table.bank.table_fields" 
                    :tableData="banks" 
                    :searchFields="Bank.table.bank.searchFields"
                    :rowData="Bank.table.bank.rowData">
                </CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	created() {
        this.Bank = Models.Bank
            
        Event.$on('modal:close',async (payload) => {
            if(payload.action === 'add_bank'){this.showAddModal = false}
            if(payload.action === 'bank_super_user'){this.showAddSuperUser = false}
        })

        Event.$on('form:submit',async (payload) => {
            if(payload.action === this.Bank.wizard.add_bank.action){
                await this.saveBank(payload.formData)
            }else if(payload.action === this.Bank.wizard.add_super_user.action){
                await this.addBankSuperUser(payload.formData)
            }
        })

        Event.$on('bank:add_super_user',(payload) => {
            this.selectedBank = payload.bank
            this.showAddSuperUser = true
        })

        Event.$on('bank:view',(payload) => {
            this.$router.push({name: 'Entities-Bank-ViewBank',params: {id: payload.code}})
        })
	},
	data() {
		return {
         selectedBank: {},
         created: false,
			Bank: {},
         banks: [],
			showAddSuperUser: false,
			showAddModal: false,
		};
	},
	methods: {
      addBankSuperUser(formData){
         return new Promise(async (resolve,reject) => {
               try {
         
               // alert(" bank code "+this.$route.params.id);
                  let res = await req.callServerSecure({
                     resource: "bank_employee",
                     action: "add",
                     data: {
                        code: this.$route.params.id || this.selectedBank.code,
                        email: formData.email,
                        employee_ref: formData.employee_ref,
                        contact: '254'+ new Date().getMilliseconds(),//`${formData.mobile_number.country_code.callingCodes[0]}:${formData.mobile_number.number}`,
                        department: 'IT',
                        names: formData.first_name+' '+formData.last_name,
                     },
                     meta_data: {},
                  });

                  notification.notify('success', 'Super User Created', `${formData.email} has been successfully added`)
                  Event.$emit('form:action-complete',{action: this.Bank.wizard.add_super_user.action, clear: true})
                  this.showAddSuperUser = false
                  resolve(true)
               }catch (err) {
                  Event.$emit('form:action-error',{action: this.Bank.wizard.add_super_user.action})
                  console.log(err)
                  reject(err)
               }
         });
      },
      fetchBanks(){
         return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "bank",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.banks = res.data.result.banks
                  resolve(true)
               }catch (err) {
                  console.log(err)
                  reject(err)
               }
         });
      },
		addBank () {
         this.showAddModal = true
      },
      saveBank(formData){
         return new Promise(async (resolve,reject) => {
            try {
               let bank = await req.callServerSecure({
                  resource: "bank",
                  action: "add",
                  data: {
                        code: formData.code,
                        bank_code: formData.bank_code,
                        name: formData.name,
                        description: formData.description
                  },
                  meta_data: {},
               });

               await this.fetchBanks()
               notification.notify('success', 'Bank Created', `${formData.name} has been successfully saved`)
               Event.$emit('form:action-complete',{action: this.Bank.wizard.add_bank.action, clear: true})
               this.showAddModal = false
            }catch (err) {
               Event.$emit('form:action-error',{action: this.Bank.wizard.add_bank.action})
               reject(err)
            }
         });
      },
	},
	async mounted() {
      await this.fetchBanks()
      Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.account-types row row-xs {
}
</style>