
<template lang="html">
    <div class="addClass"> 
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Notifications-Config'}">
                                Notifications
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">View</li>
                    </ol>
                </nav>    
            </div>  
        </div>

        <div class="row text-capitalize">
            <div class="col-12 col-sm-6">
                <h4 class="mb-1">
                    Event: {{notificationEvent.event_name || ""}}
                </h4>
                <p class="mb-1">event description: {{notificationEvent.event_description || ""}}</p>
                <p class="mb-1">Service Name: {{notificationEvent.service_name || ""}}</p>
                <p class="mb-1">Module: {{notificationEvent.module || ""}}</p>
                <p class="mb-1">Status: {{ notificationEvent.notification_event_status || "" }}</p>
                <!-- {{notificationEvent}} -->
                
            </div>
            <div class="col-12 col-sm-6">
                <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div></div>
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createNotificationChannel" >
                      <i data-feather="mail" class="wd-10 mg-r-5"></i> Create Notification Channel
                    </button>
               </div>
               
            </div>

            <div class="col-12">
                 <CustomTable :tableFields="NotificationsConfig.table.channel_table_fields"
                    :icon="NotificationsConfig.table.icon"
                    :title="'Notification Channels'"
                    :subtitle="'A list of entity notification Channels'"
                    :tableData="entityNotifications" 
                    :searchFields="NotificationsConfig.table.channelSearchFields"
                    :rowData="NotificationsConfig.table.channelRowData">
                </CustomTable>

            </div>
        </div>


        <div class="modal fade classic-modal" 
        :id="`${this.action}Modal`" 
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1" 
        role="dialog" 
        aria-labelledby="exampleModalLabel" 
        aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <div class="row-contents">
                        <div class="ci ci-info">
                            <i class="icon ico" :class="icon"></i>
                        </div>
                        <div class="text" id="formModalLabel">
                            <div class="fx-h5">Notification channel</div>
                            <div class="text-muted">Create Entity Notification Channel</div>
                        </div>
                        <button type="button" class="close" @click="closeModal()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    
                    <div>
                <div class="col-12">
                    <div class="form-row">
                        <label for="module" class="col-12">Select Receiving Entity</label>
                        <div  class="form-group col-12">

                            <div class="custom-control custom-radio custom-control-inline" v-for="(radio, index) in receiving_entity_options" :key="index + 'sadd'">
                                <input
                                    type="radio" 
                                    class="custom-control-input"
                                    :name="radio.label+' '+formData.receiving_entity" 
                                    :id="radio.label+' '+formData.receiving_entity+radio.id" 
                                    v-model="formData.receiving_entity" 
                                    :value="radio.val"
                                    v-validate="form.validation" 
                                    :checked="radio.checked==='yes' ? true : false"
                                    >
                                <label class="custom-control-label" :for="radio.label+' '+formData.receiving_entity+radio.id">{{radio.label}} </label>
                            </div>
                            <div v-if="errors.has('insurance')" class="text-danger">
                                {{ errors.first('insurance') }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <label for="module" class="col-12">Select Channel</label>
                        <div  class="form-group col-12">

                            <div class="custom-control custom-radio custom-control-inline" v-for="(radio, index) in channel_options" :key="index + 'sd'">
                                <input
                                    type="radio" 
                                    class="custom-control-input"
                                    :name="radio.label+' '+formData.channel" 
                                    :id="radio.label+' '+formData.channel+radio.id" 
                                    v-model="formData.channel" 
                                    :value="radio.val"
                                    v-validate="form.validation" 
                                    :checked="radio.checked==='yes' ? true : false"
                                    >
                                <label class="custom-control-label" :for="radio.label+' '+formData.channel+radio.id">{{radio.label}} </label>
                            </div>
                            <div v-if="errors.has('insurance')" class="text-danger">
                                {{ errors.first('insurance') }}
                            </div>
                        </div>
                    </div>
                    <!-- sms -->
                    <div class="form-row border p-2" v-show="formData.channel === 'SMS'">
                        <div  class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Notification Subject</label> 
                                <input type="text" class="form-control" placeholder="Notification Subject" name="notification_subject" id="classCode" v-model="formData.notification_subject"   v-validate="'required'">
                                <div v-if="errors.has('notification_subject')" class="text-danger">{{ errors.first('notification_subject') }}</div>
                            </div>

                            <div class="form-group">

                                <label>Template</label> 
                                <textarea type="text" class="form-control" placeholder="Template" name="template" id="classCode" v-model="formData.template"   v-validate="'required'" rows="6"></textarea>
                                <div v-if="errors.has('template')" class="text-danger">{{ errors.first('template') }}</div>
                            </div>
                        </div>

                        <div  class="col-12 col-md-6" >
                          <div class="row">
                            <label class="col-12">Placeholders</label> 
                            <div class="form-group col-12" v-for="(placeholder, index) in Object.keys(notificationEvent.event_placeholders)" :key="index" >
                                <button class="btn btn-block" @click="addToTemplate(notificationEvent.event_placeholders[placeholder])">{{notificationEvent.event_placeholders[placeholder] }}</button>
                            </div>
                          </div>
                            
                        </div>
                        <div class="form-group col-md-6 my-2">
                          <button type="button" class="btn btn-primary" @click="saveNotificationChannel()">Save</button>
                        </div>

                    </div>
                    <!-- sms -->


                  <!-- Mobile -->
                    <div class="form-row border p-2" v-show="formData.channel === 'Mobile'">
                        <div  class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Notification Subject</label> 
                                <input type="text" class="form-control" placeholder="Notification Subject" name="notification_subject" id="classCode" v-model="formData.notification_subject"   v-validate="'required'">
                                <div v-if="errors.has('notification_subject')" class="text-danger">{{ errors.first('notification_subject') }}</div>
                            </div>

                            <div class="form-group">

                                <label>Template</label> 
                                <textarea type="text" class="form-control" placeholder="Template" name="template" id="classCode" v-model="formData.template"   v-validate="'required'" rows="6"></textarea>
                                <div v-if="errors.has('template')" class="text-danger">{{ errors.first('template') }}</div>
                            </div>
                        </div>

                        <div  class="col-12 col-md-6" >
                          <div class="row">
                            <label class="col-12">Placeholders</label> 
                            <div class="form-group col-12" v-for="(placeholder, index) in Object.keys(notificationEvent.event_placeholders)" :key="index" >
                                <button class="btn btn-block" @click="addToTemplate(notificationEvent.event_placeholders[placeholder])">{{notificationEvent.event_placeholders[placeholder] }}</button>
                            </div>
                          </div>
                            
                        </div>
                        <div class="form-group col-md-6 my-2">
                          <button type="button" class="btn btn-primary" @click="saveNotificationChannel()">Save</button>
                        </div>

                    </div>
                    <!-- Mobile -->



                    <!-- email -->
                    <div class="form-row border p-2" v-show="formData.channel === 'Email'">
                        <div  class="col-12 col-md-6">

                          <div class="form-group">
                              <label>Subject</label> 
                              <input type="text" class="form-control" placeholder="Subject" name="subject" id="classCode" v-model="formData.subject"   v-validate="'required'">
                              <div v-if="errors.has('subject')" class="text-danger">{{ errors.first('subject') }}</div>
                          </div>

                          <div class="form-group">
                            <label>Template</label> 
                            <textarea type="text" class="form-control" placeholder="Template" name="template" id="classCode" v-model="formData.template"   v-validate="'required'" rows="4"></textarea>
                            <div v-if="errors.has('template')" class="text-danger">{{ errors.first('template') }}</div>
                          </div>

                          <div class="form-group">
                              <label>CC</label> 
                              <input type="text" class="form-control" placeholder="CC" name="cc" id="classCode" v-model="formData.cc"   v-validate="'required'">
                              <div v-if="errors.has('cc')" class="text-danger">{{ errors.first('cc') }}</div>
                          </div>

                          <div class="form-group">
                              <label>BCC</label> 
                              <input type="text" class="form-control" placeholder="BCC" name="bcc" id="classCode" v-model="formData.bcc"   v-validate="'required'">
                              <div v-if="errors.has('bcc')" class="text-danger">{{ errors.first('bcc') }}</div>
                          </div>


                        </div>

                        <div  class="col-12 col-md-6" >
                          <div class="row">
                            <label class="col-12">Placeholders</label> 
                            <div class="form-group col-12" v-for="(placeholder, index) in Object.keys(notificationEvent.event_placeholders)" :key="index" >
                                <button class="btn btn-block" @click="addToTemplate(notificationEvent.event_placeholders[placeholder])">{{notificationEvent.event_placeholders[placeholder] }}</button>
                            </div>
                          </div>
                            
                        </div>
                        <div class="form-group col-md-6 my-2">
                          <button type="button" class="btn btn-primary" @click="saveNotificationChannel()">Save</button>
                        </div>
                    

                    </div>
                    <!-- email -->


                </div>
                </div>
                </div>
                </div>
            </div>
        </div>
        

        
    </div>
</template>


<script>
export default {
  name: "View",
  async created() {
    this.NotificationsConfig = Models.NotificationsConfig;


    // Event.$on("entityNotificationChanel:view", async ({ entity }) => {
    //   this.formData.entity_notification_event_ref =
    //     entity.entity_notification_event_ref;
    // });
  },

  data() {
    return {
      action: "addChannel",
      notificationEvent: {},
      entityNotificationChannels: [],
      NotificationsConfig: {},
      entityNotifications: [],
      formData: {
        template: "",
      },
      showModal: false,
      receiving_entity_options: [
        { id: "client", val: "client", checked: "yes", label: "Client" },
        {
          id: "underwriter",
          val: "underwriter",
          checked: "",
          label: "Underwriter",
        },
        {
          id: "service_provider",
          val: "service_provider",
          checked: "",
          label: "Service Provider",
        },
        {
          id: "intermediary",
          val: "intermediary",
          checked: "",
          label: "Intermediary",
        },
      ],
      channel_options: [
        { id: "SMS", val: "SMS", checked: "yes", label: "SMS" },
        { id: "Email", val: "Email", checked: "", label: "Email" },
        { id: "Mobile", val: "Mobile", checked: "", label: "Mobile" },
      ],

      form: {
        placeholder: "",
        model: "",
        url: "",
      },

      date_options: {
        date: true,
        datePattern: ["d", "m", "Y"],
        delimiter: "-",
      },
    };
  },

  watch: {
    showModal: async function (to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $(`#${this.action}Modal`).modal(action);
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
    createNotificationChannel() {
      this.showModal = true;
    },
    addToTemplate(template) {
      this.formData.template += template || "";
    },
    async saveNotificationChannel() {
      try {
        let res = await req.callServerSecure({
          resource: "entity_notification",
          action: "notification_channel",
          data: {
            entity_notification_event_ref: this.$route.params.event_ref,
            notification: {
              notified_entity: this.formData.receiving_entity,
              channel: this.formData.channel,
              template: this.formData.template,
              data: {
                notification_subject: this.formData.notification_subject,
                cc: this.formData.cc,
                subject: this.formData.subject,
                bcc: this.formData.bcc,
              },
            },
          },
        });
        notification.notify(
          "success",
          "Notification channel",
          `Notification channel successfully added`
        );
        this.showModal = false;
        this.fetchEntityNotificationsChannels()
      } catch (err) {
        Event.$emit("form:action-error", "An Error Has Occured");
      }
    },
    fetchNotificationEvent() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_notification",
            action: "get",
            data: {
              entity_notification_event_ref: this.$route.params.event_ref,
            },
          });
          this.notificationEvent = res.data.result;
          this.fetchPlaceholders(res.data.result.event_name)
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchPlaceholders(event_name) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "notification_event",
            action: "get",
            data: {
              event_name: event_name,
            },
          });
          Object.assign(this.notificationEvent, res.data.result)
          this.notificationEvent = res.data.result;
        } catch (error) {
          reject(error);
        }
      });
    },

    fetchEntityNotificationsChannels() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_notification",
            action: "list",
            data: {},
          });

          let notifications = res.data.result.find(
            ref => ref.entity_notification_event_ref == this.$route.params.event_ref
          )

          this.entityNotifications = notifications.notifications

        } catch (error) {
          reject(error);
        }
      });
    },
  
  },

  async mounted() {
    this.fetchNotificationEvent();
    this.fetchEntityNotificationsChannels();
    Event.$emit("loader:show", false);
  },
}; 
</script>