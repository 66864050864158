<template lang="html">
   <div class="limits">
      <!-- <ModalForm :showModal="showModal" :action="Limit.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Limit.wizard.pages" 
               :action="Limit.wizard.action" 
               :buttonText="Limit.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm> -->

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createLimit()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Limit
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Limit.table.table_fields"
         :icon="Limit.table.icon"
         :title="Limit.table.title"
         :subtitle="Limit.table.subtitle"
         :tableData="limits" 
         :searchFields="Limit.table.searchFields"
         :rowData="Limit.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Limit = Models.Limit;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Limit.wizard.action) {
        await this.saveLimit(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Limit: {},
      limits: [],
      showModal: false,
    };
  },
  methods: {
    fetchLimits() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_limit",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.limits = res.data.result.transaction_limits;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createLimit() {
      this.$router.push({
        name: "Configs-Transactions-AddLimit",
        params: {},
      });
      // this.showModal = true
    },
    saveLimit(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_limit",
            action: "add",
            data: {
              name: data.name,
              code: data.code,
              description: data.description,
              daily_limit: data.daily,
              weekly_limit: data.weekly,
              monthly_limit: data.monthly,
            },
            meta_data: {},
          });

          await this.fetchLimits();
          notification.notify(
            "success",
            "Limit Added",
            `The limit ${data.name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.Limit.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.Limit.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.fetchLimits();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.limits {
}
</style>