



<template lang="html">

    <div class="icons">
        <div class="custom-file">
        <input type="file" class="custom-file-input" id="fileSelector" name="files[]" @change="onFileSelected" accept="image/*">
        <label class="custom-file-label" for="customFile">Upload Icon</label>
        </div>
        <button type="button" class="btn btn-primary active" @click="uploadIcon()">Save</button>
            <figure class="pos-relative col-md-4" v-if="showImage" >
                <img :src="form.url" class="img wd-60 ht-60 rounded-circle img-thumbnail" alt="form.placeholder">
            </figure>

    <div class="container">
        <img v-for="(icon, index) in icons" :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail m-1"  :alt="icon.originalname" >
    </div>

</div>
</template>

<script>
export default {
    async created(){
         
     },
      data() {
		 return {
          icons:[],
           file:'',
           showImage:false,
           form: {
                placeholder: '',
                model: '',
                url:''
            },
		 }
	},
    methods: {
        onFileSelected(event){
              Array.from(event.target.files).forEach(file => {
                this.file=file
                 this.form.url=URL.createObjectURL(file);
                  this.form.placeholder = file.name
                  this.showImage=true;  
              });
            },
         getImg(path) {
            return req.getParsedUrl(path)
        },
       async uploadIcon () {
                  try {
                        var formDataToUpload = new FormData();
                        formDataToUpload.append("icon", this.file);                                    
                        await req.callServerMultipart('/api/upload/v1/admin/icon',formDataToUpload);
                         this.showImage=false;  
                        this.form={}
                         await this.getIcons()
                        }
                        catch (err) {
                            console.log(err)
                        }
            
        },
         getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  this.icons = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
    },
	async mounted(){
        await this.getIcons()
		Event.$emit('loader:show', false)
	}
  
    };
</script>

<style lang="less">
.reccomendation {
}
</style>  