<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Status</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">List Of Account Status</h4>
            </div>
            <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="add()"
                >Add</button>
        </div>
                <div class="table-responsive">
                     <table class="table ml-table ml-responsive-md">
                        <thead  class="thead-primary">
                                <tr>
                             <th scope="col">No.</th>
                            <th scope="col">System Ref</th>
                            <th scope="col">Code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                             <th scope="col">Allow DR</th>
                            <th scope="col">Allow CR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in status">
                                <th scope="row">{{ index +1 }}</th>          
                                 <td>{{ item.account_status_system_ref }}</td>
                                <td>{{ item.account_status_code }}</td>
                                <td>{{ item.account_status_name }}</td>
                                <td>{{ item.account_status_description }}</td>
                                <td>{{ item.allow_dr }}</td>
                                  <td>{{ item.allow_cr }}</td>
                                
                            </tr>
                            
                     
                        </tbody>
                        </table>
         </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             status:[]
         }

      },
    methods: {
     add() {
        this.$router.push({ name: 'accounting.accountstatus.addStatus' })
     },
        fetchStatus(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_status",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.status = res.data.result.account_statuses
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },

    }, 
    async mounted(){
         await this.fetchStatus();
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>