<template lang="html">
   <div class="notifications">

      <div class="py-2">
            <div class="row">
                <div class="col-12">
                    <h4>Total Notifications</h4>
                </div>

                <div class="col-12 col-md-4">
                    <div class="card bg-light">
                        <div class="card-body align-items-center justify-content-center text-center">
                            <h5>SMS</h5>
                            <h6>100</h6> 
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4">
                    <div class="card bg-light">
                        <div class="card-body align-items-center justify-content-center text-center">
                            <h5>EMail</h5>
                            <h6>100</h6> 
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4">
                    <div class="card bg-light">
                        <div class="card-body align-items-center justify-content-center text-center">
                            <h5>Mobile</h5>
                            <h6>100</h6> 
                        </div>
                    </div>
                </div>
            </div>
        
      </div>

      <CustomTable :tableFields="NotificationQueue.table.table_fields"
         :icon="NotificationQueue.table.icon"
         :title="NotificationQueue.table.title"
         :subtitle="NotificationQueue.table.subtitle"
         :tableData="notifications" 
         :searchFields="NotificationQueue.table.searchFields"
         :rowData="NotificationQueue.table.rowData">
         
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.NotificationQueue = Models.NotificationQueue;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.NotificationQueue.wizard.action) {
        await this.saveNotificationQueue(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      NotificationQueue: {},
      notifications: [],
    };
  },
  methods: {
    fetchNotificationQueue() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "tax_category",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.notifications = res.data.result.tax_categories;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createNotificationQueue() {
      this.showModal = true;
    },
  },
  async mounted() {
    await this.fetchNotificationQueue();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
</style>