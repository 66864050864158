<template lang="html">
   <div class="purchaseexceptions">
      <ModalForm :showModal="showModal" :action="PurchaseException.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="PurchaseException.wizard.pages" 
               :action="PurchaseException.wizard.action" 
               :buttonText="PurchaseException.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createPurchaseException()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Exception Rule
               </button>
         </div>
      </div>

      <CustomTable :tableFields="PurchaseException.table.table_fields"
         :icon="PurchaseException.table.icon"
         :title="PurchaseException.table.title"
         :subtitle="PurchaseException.table.subtitle"
         :tableData="purchaseexceptions" 
         :searchFields="PurchaseException.table.searchFields"
         :rowData="PurchaseException.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.PurchaseException = Models.PurchaseException

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.PurchaseException.wizard.action){
               await this.savePurchaseException(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

        Event.$on("PurchaseException:view", async (payload) => {
            this.$router.push({
                name: "Entities-PurchaceException-View",
                params: { id: payload.PurchaseException.rule_system_ref },
            });
        });
        Event.$on("PurchaseException:verify", async (payload) => {
        });
      },
      data () {
         return {
            PurchaseException: {},
            purchaseexceptions: [],
            showModal: false
         }
      },
      async mounted(){
         await this.fetchpurchaseexceptions()
         await this.fetchFormFields();
         Event.$emit('loader:show', false)
      },
      methods: {
         fetchpurchaseexceptions(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "entity_exception",
                     action: "list_rules",
                     data: {},
                     meta_data: {},
                  });
                  this.purchaseexceptions = res.data.result.rules
                   
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createPurchaseException() {
            this.showModal = true
         },
         savePurchaseException(data){
           
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "entity_exception",
                     action: "create_rule",
                     data: {
                        rule_name : data.rule_name,
                        rule_description : data.rule_description,
                        field_code : data.field_code,
                        rule_operand : data.rule_operand
                     },
                     meta_data: {},
                  });

                  await this.fetchpurchaseexceptions()
                  notification.notify('success', 'PurchaseException Added', `The PurchaseException ${data.purchase_exception_name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.PurchaseException.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.PurchaseException.wizard.action})
               }
            })
         },
         

         fetchFormFields() {
            return new Promise(async (resolve, reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "form_field",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                  let pages = this.PurchaseException.wizard.pages;
                  pages[0].forms[0].options = utils.appendOptions(
                     res.data.result.form_fields,
                     "field_code",
                     "field_name"
                  );
                  resolve(true);
               } catch (err) {
                  reject(err);
               }
               });
            },
         },
      
   }
</script>

<style lang="less">
   .purchaseexceptions{
      
   }
</style>