module.exports = {
    name: 'Reccomendation',

    wizard: {
       actionBtn: 'Save',
       action: 'add_reccomendation',
       pages: [
          {
             title: 'System Reccomendation',
             subtitle: 'Configure System Reccomendations',
             forms: [
                {
                   type: 'text', form_type: 'text', label: 'Code', model: 'reccomendation_code',
                   validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                },
                {
                    type: 'text', form_type: 'text', label: 'Name', model: 'reccomendation_name',
                    validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                 },
                 {
                    type: 'text', form_type: 'text', label: 'Label', model: 'reccomendation_label',
                    validation: 'required|min:1|max:50', class: 'col-6 col-md-6'
                 },
                 {
                    type: 'textarea', form_type: 'text', label: 'Description', model: 'reccomendation_description',
                    validation: 'required|min:10|max:500', class: 'col-6 col-md-6'
                 },
                
                
              
               
             ],
          }
       ]
    },
    table: {
       title: 'System Reccomendation',
       subtitle: 'Configure System Reccomendations',
       table_fields: [
          { name: 'Code', align: 'l' },
          { name: 'Name', align: 'l' },
          { name: 'Label', align: 'l' },
          { name: 'Description', align: 'l' },
          { name: 'actions', align: 'c' }
       ],
       searchFields: ["code", "name", "min_account_bal", "max_account_bal"],
       rowData: [
          { type: 'text', field: 'reccomendation_code', class: 'left bolder small' },
          { type: 'text', field: 'reccomendation_name', class: 'left bolder small' },
          { type: 'text', field: 'reccomendation_label', class: 'left bolder' },
          { type: 'text', field: 'reccomendation_description', class: 'left bolder small' },
          {
             type: 'action', field: 'name', class: 'center', icon: '', actions: [
                // {type: 'title', title: 'Actions'},
                {
                   type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                      { key: 'client', field: '*' }
                   ]
                },
                {
                   type: 'menu', title: 'View', event: 'view_reccomendation:view', icon: 'ico-reply', params: [
                     { key: 'reccomendation', field: '*' }
                   ]
                },
              
             ]
          },
       ]
    },
 }


