<template lang="html">
    <div>
        <CustomForm 
                pages="Insurance.wizard.group.pages" 
                action="Insurance.wizard.group.action" 
                buttonText="Insurance.wizard.group.actionBtn" 
                showTitle="false">
            </CustomForm>


    </div>

</template>
<script>
export default {
    created() {
        this.Insurance = Models.Insurance
    },
	data() {
		return {
            selectedUnderwritter: {},
            created: false,
			Insurance: {},
            underwritters: [],
            group_options: [],
			showAddSuperUser: false,
			showAddGroup: false,
			showAddModal: false,
			showUploadUnderwritters: false,
                  file:'',
          showImage:false,
           form: {
                placeholder: '',
                model: '',
                url:''
            },
		};
	},
    methods: {
        fetchGroups(){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "underwritter_group",
                        action: "list",
                        data: {},
                        meta_data: {},
                    });

                    let pages = this.Insurance.wizard.group.pages
                    let groups = res.data.result.underwritter_groups
                    this.group_options = []
                    for (let i = 0; i < groups.length; i++) {
                        const group = groups[i];

                        pages[0].forms[0].options.push({val: group.group_code, label: group.names})
                    }

                    this.Insurance.wizard.group.pages = pages
                    resolve(true)
                }catch (err) {
                    console.log(err)
                    reject(err)
                }
            });
        },
        getUnderwritters(){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "underwritter",
                        action: "list",
                        data: {},
                        meta_data: {},
                    });

                    this.underwritters = res.data.result.underwritters
                    resolve(true)
                }catch (err) {
                    console.log(err)
                    reject(err)
                }
            });
        },
    },
	async mounted() {
        await this.fetchGroups()

      /*  if(this.Insurance.setup.pages[0].forms.length <= 3){
            await this.fetchIsuranceClasses()
        }
        */
        await this.getUnderwritters()
		Event.$emit("loader:show", false);
	}
}; 
</script>

<style lang="less">
.account-types row row-xs {
}
</style>