module.exports = {
    name: 'Settlement',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_accounting_tax_category',
        pages: [
            {
                title: 'Settlement',
                subtitle: 'Configure new settlement',
                forms: [
                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'tax_category_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Code', model: 'tax_category_code',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'tax_category_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ],
            }
        ]
    },
    table: {
        title: 'Settlement',
        subtitle: 'A list of configured settlements',
        table_fields: [
            { name: 'Code', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["tax_category_description", "tax_category_name", "tax_category_code"],
        rowData: [
            { type: 'text', field: 'tax_category_code', class: 'left bolder small' },
            { type: 'text', field: 'tax_category_name', class: 'left bolder' },
            { type: 'text', field: 'tax_category_description', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'View', event: 'taxCategory:view', icon: 'ico-reply', params: [
                            { key: 'taxCategory', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },
}