module.exports = {
    name: 'Notifications Config',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'configure_notification',
        pages: [
            {
                title: 'Notifications Queue',
                subtitle: 'Configure new Notifications Queue',
               
            }
        ]
    },
    table: {
        title: 'Notifications Queue',
        subtitle: 'A list of Notifications in Queue',
        table_fields: [
            { name: 'Channel', align: 'l' },
            { name: 'Send Date', align: 'l' },
            { name: 'Receiving Entity', align: 'l' },
            { name: 'Direction', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["name", "description", "service", "event", 'entity',],
        rowData: [
            { type: 'text', field: 'name', class: 'left bolder small' },
            { type: 'text', field: 'description', class: 'left bolder' },
            { type: 'text', field: 'service', class: 'left bolder' },
            { type: 'text', field: 'event', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    {
                        type: 'menu', title: 'View', event: 'taxCategory:view', icon: 'ico-reply', params: [
                            { key: 'taxCategory', field: '*' },
                        ]
                    },
                ]
            },
        ]
    },
  
}