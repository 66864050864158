<template lang="html">
   <div class="limits">
      <ModalForm :showModal="showModal" :action="AccountLimit.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="AccountLimit.wizard.pages" 
               :action="AccountLimit.wizard.action" 
               :buttonText="AccountLimit.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createAccountLimit()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Account Limit
               </button>
         </div>
      </div>

      <CustomTable :tableFields="AccountLimit.table.table_fields"
         :icon="AccountLimit.table.icon"
         :title="AccountLimit.table.title"
         :subtitle="AccountLimit.table.subtitle"
         :tableData="limits" 
         :searchFields="AccountLimit.table.searchFields"
         :rowData="AccountLimit.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.AccountLimit = Models.AccountLimit

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.AccountLimit.wizard.action){
               await this.saveGroup(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            AccountLimit: {},
            limits: [],
            showModal: false
         }
      },
      methods: {
         fetchAccountLimits(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_limit",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.limits = res.data.result.account_limits
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createAccountLimit() {
            this.showModal = true
         },
         saveGroup(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_limit",
                     action: "add",
                     data: {
                        code: data.code,
                        name: data.name,
                        description: data.description,  
                        min_account_bal: data.min_bal,  
                        max_account_bal: data.max_bal,  
                     },
                     meta_data: {},
                  });

                  await this.fetchAccountLimits()
                  notification.notify('success', 'Account Limit Added', `The limit ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.AccountLimit.wizard.action, clear: true})
                  this.showModal = false  
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.AccountLimit.wizard.action})
               }
            })
         }
      },
      async mounted(){
         await this.fetchAccountLimits()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .limits{
      
   }
</style>