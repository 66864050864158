<template lang="html">
   <div class="taxes">
      <ModalForm :showModal="showModal" :action="Tax.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Tax.wizard.pages" 
               :action="Tax.wizard.action" 
               :buttonText="Tax.wizard.actionBtn"
               @selectFieldChanged="handleSelectChange" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createTax()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Tax
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Tax.table.table_fields"
         :icon="Tax.table.icon"
         :title="Tax.table.title"
         :subtitle="Tax.table.subtitle"
         :tableData="taxes" 
         :searchFields="Tax.table.searchFields"
         :rowData="Tax.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Tax = Models.Tax;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Tax.wizard.action) {
        await this.saveTax(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    Event.$on("tax:view", (payload) => {
      console.log("payload", payload);
    });
  },
  data() {
    return {
      Tax: {},
      taxes: [],
      showModal: false,
      min: {},
      max: {},
      value: {},
      tax_value: {},
    };
  },
  async mounted() {
    await this.fetchCurrencies();
    await this.fetchTaxCategories();
    await this.fetchAccounts();
    await this.fetchtaxes();

    Event.$emit("loader:show", false);
  },
  methods: {
    handleSelectChange({ form, data }) {
      if (form.model === "tax_type") {
        var myform = this.Tax.wizard.pages[0].forms;
        console.log(myform);
        this.min = myform[9];
        this.max = myform[10];
        this.value = myform[11];
        this.tax_value = myform[8];
        if (data !== "slab") {
          if (this.min.model === "min") {
            myform.splice(9, 1);
          }
          if (this.max.model === "max") {
            myform.splice(9, 1);
          }
          if (this.value.model === "value") {
            myform.splice(9, 1);
          }
          myform.insert(this.tax_value)
        } else {
          if (this.tax_value.model === "tax_value") {
            myform.splice(8, 1);
          }
          myform.insert(9, this.value)
          myform.insert(9, this.max)
          myform.insert(9, this.min)
        }
      }
    },
    fetchtaxes() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "taxes",
            action: "list",
            data: {},
            meta_data: {},
          });
          console.log("taxes", res.data.result.entity_taxes);
          this.taxes = res.data.result.entity_taxes;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchCurrencies() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "currency",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Tax.wizard.pages[0].forms[2].options = utils.appendOptions(
            res.data.result.currencies,
            "currency_code",
            "name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchAccounts() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Tax.wizard.pages[0].forms[5].options = utils.appendOptions(
            res.data.result.accounts,
            "account_number",
            "account_name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchTaxCategories() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "tax_category",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Tax.wizard.pages[0].forms[4].options = utils.appendOptions(
            res.data.result.tax_categories,
            "system_tax_category_ref",
            "tax_category_name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    createTax() {
      this.showModal = true;
    },
    saveTax(data) {
      return new Promise(async (resolve, reject) => {
        try {
          await req.callServerSecure({
            resource: "taxes",
            action: "add_system_tax",
            data: {
              entity_tax_ref: data.entity_tax_ref,
              entity_tax_account: data.entity_tax_account,
              tax_name: data.tax_name,
              tax_description: data.tax_description,
              entity_tax_currency: data.entity_tax_currency,
              tax_management: data.tax_management, //or SYSTEM
              tax_category: data.tax_category,
              system_tax_category_ref: data.system_tax_category_ref,
              tax_type: data.tax_type,
              tax_value: data.tax_value,
              remarks: data.remarks,
              tax_slab: [
                {
                  min: data.min,
                  max: data.max,
                  value: data.value,
                },
              ],
            },

            meta_data: {},
          });

          await this.fetchtaxes();
          notification.notify(
            "success",
            "Tax Added",
            `The Tax ${data.tax_name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.Tax.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          console.log(err);
          Event.$emit("form:action-error", { action: this.Tax.wizard.action });
        }
      });
    },
  },
};
</script>

<style lang="less">

</style>