<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="KycField.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="KycField.wizard.pages" 
                  :action="KycField.wizard.action" 
                  :buttonText="KycField.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddKycFieldModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Kyc Field
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="KycField.table.table_fields" 
            :tableData="kycFields" 
            :title="KycField.table.title" 
            :subtitle="KycField.table.subtitle" 
            :searchFields="KycField.table.searchFields"
            :rowData="KycField.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.KycField = Models.KycField;
		let _this = this;

		this.kycFields = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.KycField.wizard.action) {
				await this.addKycField(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("product:view", payload => {
			console.log("Payload", payload);
			this.$router.push({
				name: "Configs-KycFields-ViewKycField",
				params: { id: payload.code }
			});
		});
	},
	data() {
		return {
			KycField: {},
			kycFields: [],
			formData: {},
			showModal: false
		};
	},
	methods: {
      fetchKycFields () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "kyc_field",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.kycFields = res.data.result.kyc_fields.kyc_fields
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
		openAddKycFieldModal() {
			this.showModal = true;
		},
		addKycField(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "kyc_field",
						action: "add",
						data: {
							code: data.code,
							label: data.label,
							type: data.type,
							form_type: data.form_type,
							model: data.code,
							verification_type: data.verification_type,
							status: 'active',
							third_party: data.third_party,
							validation: data.validation,
							class: data.size,
							options: [],
							placeholder: data.placeholder
						}
					});

               await this.fetchKycFields()
					notification.notify("success","Kyc Field Added",`Kyc Field ${data.name} successfully added`);
					Event.$emit("form:action-complete", {action: this.KycField.wizard.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.KycField.wizard.action});
				}
			});
		}
	},
	async mounted() {
      await this.fetchKycFields()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>