<template>
  <div class="view-topup">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Topup Config</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.name}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Transaction Limit :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.limit_code}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Supported Currency :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.currency_code}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Category :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.category}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Created Date :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.created_date}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{topup.status}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-10">
            <div class="mdia mg-b-20">
              <div class>Description :</div>
              <div class="mdia-body d-block pd-t-10">
                <h6 class>{{topup.description}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <button class="btn btn-sm">
            <i data-feather="thumbs-up"></i> Approve
          </button>
          <button class="btn btn-sm mg-l-20">
            <i data-feather="file"></i> Reject
          </button>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->

    <div class="table-responsive">
      <h5>Charges</h5>
      <div class="d-flex mg-b-10">
        <button class="btn btn-sm ml-auto">
          <i data-feather="file"></i> Add
        </button>
      </div>
      <table class="table ml-table ml-responsive-md">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col">Type</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(charge, index) in charges" :key="index">
            <td>{{charge.charge_name}}</td>
            <td>{{charge.charge_code}}</td>
            <td>{{charge.type}}</td>
            <td class="text-center">
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="viewCharge(charge.charge_code)"
                >View</button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="removeCharge(charge.charge_code)"
                >Remove</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topup: {},
      charges: [],
    };
  },
  methods: {
    approveCharge() {
      console.log("approveCharge");
    },
    editCharge() {
      console.log("editCharge");
    },
    activateCharge() {
      console.log("activateCharge");
    },
    deactivateCharge() {
      console.log("deactivateCharge");
    },
    viewCharge() {
      console.log("viewCharge");
    },
    removeCharge() {
      console.log("removeCharge");
    },
    fetchTopup() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "topup_config",
            action: "get",
            data: {
              topup_config_id: this.$route.params.id,
            },
          });
          // alert(res)

          this.topup = res.data.result.topup_config_details;

          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    fetchCharges() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_charges",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.charges = res.data.result.charges;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchTopup();
    await this.fetchCharges();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>