<template lang="html">

    <div class="formfield">
      

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                      <li class="breadcrumb-item">
                          <router-link :to="{name: 'Dashboard'}">
                              Admin
                          </router-link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">Form Fields</li>
                  </ol>
              </nav>
              <h4 class="mg-b-0 tx-spacing--1">Form Fields</h4>
          </div>
          <div class="d-none d-md-block">                
              <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" @click="createFormField()">
                  <i data-feather="file" class="wd-10 mg-r-5"></i> Add Form Field
              </button>
          </div>
      </div>

      <CustomTable :tableFields="formField.table.table_fields"
				:icon="formField.table.icon"
				:title="formField.table.title"
				:subtitle="formField.table.subtitle"
				:tableData="formFields" 
				:searchFields="formField.table.searchFields"
				:rowData="formField.table.rowData">
			</CustomTable>
      <div>
        <VerifyModal 
          :showVerifyModal="showVerifyModal"          
        />
      </div>
    </div>
</template>

<script>
export default {
  async created() {
    this.formField = Models.formField;
    // Event.$on("verifyModal:close", async (payload) => {
    //   this.showVerifyModal = false;
    // });
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.formField.wizard.action) {
        try {
          let forms = await this.validateFormFields(payload);
          // Event.$emit("loader:show", true);
          console.log(forms);
          this.saveFormField(forms);
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.formField.wizard.action,
          });
        }
      }
    });
    Event.$on("formField:edit", payload => {            
      this.$router.push({
        name: "Configs-System-EditFormField",
        params: {id: payload.formField.field_code }
      });
    });    
  },
  data() {
    return {
      formFields: [],
      formField: {},
      showVerifyModal: false,
    };
  },
  methods: {
    fetchFormFields() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "form_field",
            action: "list",
            data: {},
            meta_data: {},
          });
          let pages = this.formField.wizard.pages;
          pages[0].forms[24].options = utils.appendOptions(
            res.data.result.form_fields,
            "field_code",
            "field_name"
          );

          this.formField.wizard.pages = pages;

          this.formFields = res.data.result.form_fields;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createFormField() {
      this.$router.push({
        name: "Configs-System-AddFormField",
        params: {},
      });
      //   this.showModal = true;
    },
    validateFormFields(field_data) {
      return new Promise((resolve, reject) => {
        try {
          let fields = field_data.formData;
          console.log(fields);
          let field_server_data = {};
          if (fields.type == "select") {
            if (fields.values) {
              field_server_data.values = fields.values.split(",");
            } else {
              throw "Values is Required";
            }
          }
          if (fields.type == "option") {
            if (fields.values) {
              field_server_data.values = fields.values.split(",");
            } else {
              throw "Values is Required";
            }
          }
          field_server_data.field_code = fields.field_code;
          field_server_data.field_name = fields.field_name;
          field_server_data.field_label = fields.field_label;
          field_server_data.hint = fields.hint;
          field_server_data.class = fields.field_size;
          field_server_data.position = Number(fields.position);
          field_server_data.help_text = fields.help_text;
          (field_server_data.type_info = {
            type: fields.type,
            sub_type: fields.sub_type,
          }),
            (field_server_data.field_description = fields.field_description);
          // "icon":"/icon/default.png",
          (field_server_data.formating = {
            allCaps: fields.allCaps,
            allSmall: fields.allSmall,
            is_decimal: fields.allow_decimal,
            min: fields.min,
            max: fields.max,
          }),
            (field_server_data.validation = {
              is_unique: fields.is_unique,
              allow_decimal: fields.allow_decimal,
              length: fields.length,
              regex: fields.regex,
              is_required: fields.is_required,
            }),
            (field_server_data.isNested = {
              is_child_of: fields.is_child_of || -1,
              show_on_option_value: fields.show_on_option_value || "",
            });

          resolve(field_server_data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    async saveFormField(data) {
      console.log(data);
      let res = await req.callServerSecure({
        resource: "form_field",
        action: "add",
        data: data,
        meta_data: {},
      });

      await this.fetchFormFields();
      notification.notify(
        "success",
        "Form Field Added",
        `The Form field was successfully added`
      );
      Event.$emit("form:action-complete", {
        action: this.formField.wizard.action,
        clear: true,
      });
      this.showModal = false;
    },
  },
  async mounted() {
    await this.fetchFormFields();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.formfield {
}
</style>