<template lang="html">
   <div class="Holidays">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createHoliday()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Holiday
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Holiday.table.table_fields"
         :icon="Holiday.table.icon"
         :title="Holiday.table.title"
         :subtitle="Holiday.table.subtitle"
         :tableData="holidays" 
         :searchFields="Holiday.table.searchFields"
         :rowData="Holiday.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Holiday = Models.Holiday;

    Event.$on("holiday:view", async (payload) => {
      console.log("payload", payload)
      this.$router.push({
        name: "Configs-Calendar-ViewHoliday",
        params: {id:payload.holiday.holiday_system_ref},
      });
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Holiday: {},
      holidays: [],
    };
  },
  methods: {
    fetchData() {
      let pages = this.Holiday.wizard.pages;
      const days = [...Array(31).keys()].map((i) => i + 1);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const years = this.getYears();

      const days_options = this.getOptions(days);
      const month_options = this.getOptions(months);
      const year_options = this.getOptions(years);

      pages[0].forms[2].options = days_options;
      pages[0].forms[3].options = month_options;
       pages[0].forms[5].options = year_options;

      this.Holiday.wizard.pages = pages;
    },
    getOptions(arr) {
      let options = [];
      for (let i = 0; i < arr.length; i++) {
        options.push({
          val: arr[i],
          label: arr[i],
        });
      }
      return options;
    },
    getYears() {
      const years = [];
      const dateStart = moment();
      const dateEnd = moment().add(10, "y");
      while (dateEnd.diff(dateStart, "years") >= 0) {
        years.push(dateStart.format("YYYY"));
        dateStart.add(1, "year");
      }
      return years;
    },
    fetchHolidays() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
         "resource":"holiday",
	        "action":"list",
          "data" : {},
            meta_data: {},
          });

          this.holidays = res.data.result.holidays;
         
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createHoliday() {
      this.$router.push({
        name: "Configs-Calendar-AddHoliday",
        params: {},
      });
    },
  },
   async mounted() {
     this.fetchData();
     await this.fetchHolidays();
    Event.$emit("loader:show", false);
  },
};
</script>

