<template lang="html">
   <div class="fees">
    </div>
</template>

<script>
export default {
  mounted() {
    this.getFee(this.$route.params.id);
  },

  method: {
    fetchfees(id) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "taxes",
            action: "get",
            data: {
                entity_tax_ref: id
            },
            meta_data: {},
          });
         // console.log("fee res", res);
          this.fee = res.data.result.fee;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};
</script>
       