module.exports = {
    name: 'Notifications Config',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'configure_notification',
        pages: [
            {
                title: 'Notification Config',
                subtitle: 'Configure new Notifications Config',
                forms: [
                    {
                        type: 'select', label: 'Select Module', model: 'module',
                        validation: 'required|min:1|max:50', class: 'col-12',
                        options: [
                            { id: 'Insurance', val: 'Insurance', label: 'Insurance' },
                            { id: "Client", val: 'Client', label: 'Client' },
                            { id: "Bank", val: 'Bank', label: 'Bank' }
                        ]
                    },
                    {
                        type: 'select', label: 'Select Service', model: 'service',
                        validation: 'required|min:1|max:50', class: 'col-12',
                        options: [
                            { id: 'Claim', val: 'Claim', label: 'Claim' },
                            { id: "Policy", val: 'Policy', label: 'Policy' },
                            { id: "Quotation", val: 'Quotation', label: 'Quotation' }
                        ]
                    },

                    {
                        type: 'select', label: 'Select Event', model: 'event',
                        validation: 'required|min:1|max:50', class: 'col-12',
                        options: [
                            { id: 'Claim', val: 'Claim', label: 'Claim' },
                        ]
                    },
                    {
                        type: 'form', form_type: 'text', label: 'Name', model: 'service_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Code', model: 'service_ref',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Transaction Type', model: 'transaction_type',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Transaction Subtype', model: 'transaction_subtype',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'select', label: 'Select fees', model: 'system_tax_ref',
                        validation: 'required|min:1|max:50', class: 'col-12'
                    },

                    {
                        type: 'textarea', form_type: 'text', label: 'Remarks', model: 'service_remarks',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ],
            }
        ]
    },
    table: {
        title: 'Notifications Events',
        subtitle: 'A list of Notifications Events',
        table_fields: [
            { name: 'Module', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'Service', align: 'l' },
            { name: 'Event', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["module", "event_description", "service_name", "event_name",],
        rowData: [
            { type: 'text', field: 'module', class: 'left bolder small' },
            { type: 'text', field: 'event_description', class: 'left bolder' },
            { type: 'text', field: 'service_name', class: 'left bolder' },
            { type: 'text', field: 'event_name', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                   
                    {
                        type: 'menu', title: 'Configure', event: 'notification:configure', icon: 'ico-reply', params: [
                            { key: 'notification', field: '*' },
                        ]
                    },
                    

                ]
            },
        ],
        entity_table_fields: [
            { name: 'Entity Id', align: 'l' },
            { name: 'Entity Type', align: 'l' },
            { name: 'Event Name', align: 'l' },
            { name: 'Status', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        entitySearchFields: ["entity_id", "entity_type", "event_name"],
        entityRowData: [
            { type: 'text', field: 'entity_id', class: 'left bolder small' },
            { type: 'text', field: 'entity_type', class: 'left bolder' },
            { type: 'text', field: 'event_name', class: 'left bolder' },
            { type: 'text', field: 'entity_notification_event_status', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},

                    // {
                    //     type: 'menu', title: 'Add Channel', event: 'entityNotificationChanel:create', icon: 'ico-reply', params: [
                    //         { key: 'entity', field: '*' },
                    //     ]
                    // },

                    {
                        type: 'menu', title: 'View', event: 'entityNotificationChanel:view', icon: 'ico-reply', params: [
                            { key: 'notification', field: '*' },
                        ]
                    },


                ]
            },
        ],
        channel_table_fields: [
            { name: 'Channel', align: 'l' },
            { name: 'Notification Ref', align: 'l' },
            { name: 'Notified Entity', align: 'l' },
            { name: 'Template', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        channelSearchFields: ["channel", "notification_ref", "notified_entity"],
        channelRowData: [
            { type: 'text', field: 'channel', class: 'left bolder small' },
            { type: 'text', field: 'notification_ref', class: 'left bolder' },
            { type: 'text', field: 'notified_entity', class: 'left bolder' },
            { type: 'text', field: 'template', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},

                    // {
                    //     type: 'menu', title: 'Add Channel', event: 'entityNotificationChanel:create', icon: 'ico-reply', params: [
                    //         { key: 'entity', field: '*' },
                    //     ]
                    // },

                    // {
                    //     type: 'menu', title: 'View', event: 'entityNotificationChanel:view', icon: 'ico-reply', params: [
                    //         { key: 'notification', field: '*' },
                    //     ]
                    // },


                ]
            },
        ]
    },

}