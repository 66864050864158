module.exports = {
    name: 'Tax',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_accounting_tax',
        pages: [
            {
                title: 'Tax',
                subtitle: 'Configure new tax',
                forms: [

                    {
                        type: 'text', form_type: 'text', label: 'Name', model: 'tax_name',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'select', label: 'Tax Management', model: 'tax_management',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6',
                        options: [
                            { id: 'SYSTEM', val: 'SYSTEM', label: 'SYSTEM' },
                            { id: "ENTITY", val: 'ENTITY', label: 'ENTITY' }
                        ],
                    },
                    {
                        type: 'select', label: 'Tax Currency', model: 'entity_tax_currency',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Tax Category', model: 'tax_category',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'select', label: 'Tax Category Ref', model: 'system_tax_category_ref',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'select', label: 'Tax Account', model: 'entity_tax_account',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Tax Ref', model: 'entity_tax_ref',
                        validation: 'required|min:3|max:50', class: 'col-12 col-md-6'
                    },


                    {
                        type: 'select', label: 'Tax Type', model: 'tax_type',
                        validation: 'required|min:3|max:150', class: 'col-12 col-md-12',
                        options: [
                            { id: 'slab', val: 'slab', label: 'Slab' },
                            { id: "fixed", val: 'fixed', label: 'Fixed' },
                            { id: "percentage", val: 'percentage', label: 'Percantage' },

                        ],
                    },

                    {
                        type: 'text', form_type: 'number', label: 'Tax Value', model: 'tax_value',
                        validation: 'required|min:1|max:150', class: 'col-12 col-md-12'
                    },

                    {
                        type: 'text', form_type: 'number', label: 'Min', model: 'min',
                        validation: 'required', class: 'col-12 col-md-4'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Max', model: 'max',
                        validation: 'required', class: 'col-12 col-md-4'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Value', model: 'value',
                        validation: 'required', class: 'col-12 col-md-4'
                    },

                    {
                        type: 'textarea', form_type: 'text', label: 'Remarks', model: 'remarks',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'tax_description',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },

                ],
                
            }
        ]
    },
    table: {
        title: 'Tax',
        subtitle: 'A list of configured taxes',
        table_fields: [
            { name: 'Tax Relief', align: 'l', },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["tax_description", "tax_name", "entity_tax_ref"],
        rowData: [
            { type: 'text', field: 'entity_tax_ref', class: 'left bolder small' },
            { type: 'text', field: 'tax_name', class: 'left bolder' },
            { type: 'text', field: 'tax_description', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'View', event: 'tax:view', icon: 'ico-reply', params: [
                            { key: 'tax', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },
}