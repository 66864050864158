module.exports = {
  name: "subledger",
  gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "",
    pages: [
      {
        title: "SubLedger Config",
        subtitle: "Configure new subledgers",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-6",
          },
          {
            type: "select",
            class: "col-12",
            model: "general_ledger",
            options: [],
            validation: "required",
            label: "Select General Ledger",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "SubLedgers Configs",
    subtitle: "A list of configured  subledgers",
    table_fields: [
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "GL Code", align: "l" },
      { name: "Created Date", align: "l" },
      { name: "Status", align: "l" },
      { name: "actions", align: "c" },
    ],
    searchFields: ["gl_code", "name", "sub_gl_code"],
    rowData: [
      { type: "text", field: "sub_gl_header_system_ref", class: "left small" },
      { type: "text", field: "sub_gl_header_name", class: "left" },
      { type: "text", field: "gl_header_ref", class: "left small" },
      { type: "text", field: "created_date", class: "left small" },
      { type: "text", field: "status", class: "left small" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Edit",
            event: "client:edit",
            icon: "ico-right",
            params: [{ key: "client", field: "*" }],
          },
          {
            type: "menu",
            title: "View",
            event: "client:view",
            icon: "ico-reply",
            params: [
              { key: "email", field: "email" },
              { key: "name", field: "name" },
            ],
          },
          // {type: 'divider'},
          {
            type: "menu",
            title: "Delete",
            event: "client:delete",
            icon: "ico-mail-a",
            params: [{ key: "email", field: "email" }],
          },
        ],
      },
    ],
  },
};
