module.exports = {
   name: 'Client KYC Fields',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Attach',
      action: 'add_client_kyc',
      pages: [
         {
            title: 'Client KYC Fields',
            subtitle: 'Attach kyc field to a client type',
            forms: [
               // {
               //    type: 'select', class: 'col-12 col-md-6', model: 'client_type',
               //    options: [], validation: 'required', label: 'Client Type'
               // },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'kyc_field',
                  options: [], validation: 'required', label: 'KYC Field'
               },
            ],
         }
      ]
   },
   table: {
      title: 'KYC Fields',
      subtitle: 'attched kyc fields',
      table_fields: [
         { name: 'Label', align: 'l' },
         { name: 'Type', align: 'l' },
         { name: 'Size', align: 'l' },
         { name: 'Status', align: 'l' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["code", "name", "min_account_bal", "max_account_bal"],
      rowData: [
         { type: 'text', field: 'label', class: 'left bolder' },
         { type: 'text', field: 'type', class: 'left bolder small' },
         { type: 'text', field: 'size', class: 'left bolder small' },
         { type: 'status', field: 'status', class: 'left bolder small' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'email', field: 'email' },
                     { key: 'name', field: 'name' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}