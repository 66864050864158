<template>
  <div class="view-department">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Department</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Code :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{department.department_code}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{department.department_name}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Entity :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{department.entity_type}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>ACTIVE</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Created Date :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{department.created_date}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-10">
            <div class="media mg-b-20">
              <div class>Description :</div>
              <div class="media-body pd-l-15">
                <h6
                  class
                >{{department.department_description}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <a href class="nav-link">
            <i data-feather="thumbs-up"></i> Approve
          </a>
          <a href class="nav-link">
            <i data-feather="edit"></i> Edit
          </a>
          <a href class="nav-link">
            <i data-feather="activity"></i> View Users
          </a>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
          <div class="card-body pd-20 pd-lg-25">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Names</th>
                      <th scope="col">Email</th>
                       <th scope="col">Contact</th>
                      <th scope="col">Status</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                       <tr v-for="item in users">

                    
                    </tr>
                  </tbody>
                </table>
              </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      department: {},   
      users:[]   
    };
  },
  methods: {
    getdepartmentusers (){
        return new Promise(async (resolve, reject) => {
          try {
          let res = await req.callServerSecure({
            resource: "department",
            action: "users",
            data: {
              department_id: this.$route.params.id
            },
            meta_data: {},
          });
          console.log(res.data.result)
          this.users=res.data.result.department_users
          resolve()
          }
          catch (err) {
            return reject(err)
          }
        })

    },
    getDepartment() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "department",
            action: "get",
            data: {
              department_id: this.$route.params.id
            },
            meta_data: {},
          });

          this.department = res.data.result.department_details;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    approveDepartment() {
      console.log("approveDepartment");
    },
    editDepartment() {
      console.log("editDepartment");
    },
    viewDepartmentUsers() {
      console.log("viewDepartmentUsers");
    },
  },
  async mounted() {
    await this.getDepartment();
    await this.getdepartmentusers();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>