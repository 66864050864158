module.exports = {
    name: "SIC Job Upload",
    table: {
        title: "SIC Job Upload",
        subtitle: "SIC Job Upload List",
        table_fields: [
            { name: "ISCO-08", align: "r" },
            { name: "ISCO-88", align: "c" },
            { name: "Title", align: "r" },
        ],
        searchFields: ["code", "name"],
        rowData: [
            { type: "text", field: "ISCO-08", class: "small center" },
            { type: "text", field: "ISCO-88", class: "small center" },
            { type: "text", field: "English title", class: "small center" },

        ],
    }
};