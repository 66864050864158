<template lang="html">
   <div class="orange">
  
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddAssessmentModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Assessment
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="Assessment.table.table_fields" 
            :tableData="assessmentQuestions" 
            :title="Assessment.table.title" 
            :subtitle="Assessment.table.subtitle" 
            :searchFields="Assessment.table.searchFields"
            :rowData="Assessment.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.Assessment = Models.Assessment;
		let _this = this;

		this.assessmentQuestions = [];


		Event.$on("product:view", payload => {
			console.log("Payload", payload);
			this.$router.push({
				name: "Configs-Assessments-ViewAssessment",
				params: { id: payload.code }
			});
		});
	},
	data() {
		return {
			Assessment: {},
			assessmentQuestions: [],
			formData: {},
			showModal: false
		};
	},
	methods: {
      fetchAssessments () {
		console.log('assess')
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "assesment",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.assessmentQuestions = res.data.result.assesments
			   console.log('assess qxtns', this.assessmentQuestions)
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
		openAddAssessmentModal() {
			this.$router.push({
				name: "Add-Assessment",
				params: {}
			});
			console.log("test assessment")
			//this.showModal = true;
		},
		fetchReccomendations() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "reccomendation",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.recomendations = res.data.result.recomendations;

			
				  let pages = this.Assessment.wizard.pages,options = [];
						await res.data.result.recomendations.forEach(element => {
									options.push({ val: element.reccomendation_code, label: element.reccomendation_name });
						});
					        pages[0].forms[3].options = options;
					       pages[0].forms[4].options = options;
					this.Assessment.wizard.pages = pages;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		},
		addAssessment(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "assesment",
						action: "add",
						data: {
                     code: data.code,
							question: data.question,
							description: data.description,
							yes_ans_reccomendation:data.yes_ans_reccomendation,
							no_ans_reccomendation:data.no_ans_reccomendation,
							created_by:"SYSTEM" 
						}
					});


               await this.fetchAssessments()
					notification.notify("success","Assessment Added",`Assessment ${data.code} successfully added`);
					Event.$emit("form:action-complete", {action: this.Assessment.wizard.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.Assessment.wizard.action});
				}
			});
		}
	},
	async mounted() {
        await this.fetchAssessments()
		await this.fetchReccomendations()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>