<template>
  <div class="overlay">
    <div class="modall">
      <div class="modal__title">
        <b class="p-3 text-center">Session Expired</b>
      </div>
      <div class="modall_body">
        <p class="">You have left this browser idle for 10 minutes.</p>
        <p>10 second left</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	data() {
		return {
			time: 10000
		}
	},
	created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        	this.$router.push({name: 'LogIn'})
        //alert(' logged you out cause of idle ');
      }
    }, 1000);
  }
};
</script>

<style lang="postcss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modall {
   padding: 20px 30px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
 background-color: white
}

.modal__title {
  color: #38404f;
  display: flex;
  justify-content:center;
  font-weight:bold
}
.modall_body {
   text-align: center
}
</style>