module.exports = {
    name: 'Intermediary',
    table: {
        icon: 'icon-suit',
        title: 'Active Intermediaries',
        subtitle: 'Active Intermediaries List',
        table_fields: [
           { name: 'Ira Code', align: 'c' },
           { name: 'Name', align: 'c' },
           { name: 'Category', align: 'c' },
           { name: 'Profile Status', align: 'c' },
           { name: 'Registration Status', align: 'c' },
           { name: 'Actions', align: 'c' }
        ],
        searchFields: ["name","code","ira_code", "status"],
        rowData: [
            { type: 'text', field: 'ira_code', class: 'center' },
           { type: 'text', field: 'intermediary_names', class: 'center' },
           { type: 'text', field: 'category', class: 'small center' },
           { type: 'status', field: 'profile_status', class: 'small center pt-3' },
           { type: 'status', field: 'registration_status', class: 'small center pt-3' },
           {
              type: 'action', field: 'name', class: 'center', icon: '', actions: [
                 // {type: 'title', title: 'Actions'},
             
                 {
                    type: 'menu', title: 'Add Super User', event: 'underwritter:add_super_user', icon: 'edit-3', params: [
                       { key: 'underwritter', field: '*' }
                    ]
                 },
               
                
              
                 {type: 'divider'},
                 {
                    type: 'menu', title: 'View Intermediary', event: 'view_intermediary:view', icon: 'eye', params: [
                       { key: 'intermediary', field: '*' }
                    ]
                 },
              ]
            }
        ]
    }  
        
}