<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Clients</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Clients List</h4>
            </div>
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase">
                    <i data-feather="mail" class="wd-10 mg-r-5"></i> Email
                </button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Add Client
                </button>
            </div>
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
					<CustomTable :tableFields="table_fields" 
						:tableData="clients" 
						:searchFields="searchFields"
						:rowData="rowData">
					</CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	created(){
		Event.$on('client:view',async (payload) => {
			console.log('Payload: ',payload)
			this.$router.push({name: 'Configs-Users-ViewClient', params: {id: payload.client.client_id}})
		})
	},
	data() {
		return {
			clients: [],
			searchFields: ["name", "phone_number", "email", "subtype"],
			table_fields: [
				// { name: 'Image', align: 'l' },
				{ name: 'Client ID', align: 'c' },
				{ name: 'Sub Type', align: 'c' },
				{ name: 'KYC Status', align: 'c' },
				{ name: 'Assessment Status', align: 'c' },
				{ name: 'Created Date', align: 'c' },
				{ name: 'actions', align: 'c' }
			],
			rowData: [
				// { type: 'image', field: 'image', class: 'left' },
				{ type: 'text', field: 'client_id', class: 'small center' },
				{ type: 'text', field: 'client_sub_type', class: 'small center' },
				{ type: 'status', field: 'kyc_status', class: 'small center' },
				{ type: 'status', field: 'self_assessment_status', class: 'small center' },
				{ type: 'text', field: 'created_date', class: 'small center' },
				{
					type: 'action', field: 'name', class: 'center', icon: '', actions: [
						// {type: 'title', title: 'Actions'},
						{
							type: 'menu', title: 'Edit', event: 'client:edit', icon: 'edit-3', params: [
								{ key: 'client', field: '*' }
							]
						},
						{
							type: 'menu', title: 'View', event: 'client:view', icon: 'eye', params: [
								{ key: 'client', field: '*' }
							]
						},
						// {type: 'divider'},
						{
							type: 'menu', title: 'Delete', event: 'client:delete', icon: 'delete', params: [
								{ key: 'email', field: 'email' }
							]
						},
					]
				},
			]
		};
	},
	methods: {
		fetchClients() {
			return new Promise(async (resolve,reject) => {
				let res = await get_req.fetchClients()
				this.clients = res.client_list
				
				resolve(true)
			})
		}
	},
	async mounted(){
		await this.fetchClients()
		Event.$emit('loader:show', false)
	}
};
</script>

<style lang="less">
.user-list {
}
</style>