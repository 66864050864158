<template>
  <div class="view-topup">
    <div
      class="
        d-sm-flex
        align-items-center
        justify-content-between
        mg-b-20 mg-lg-b-25 mg-xl-b-30
      "
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>

            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Entities-SchoolsReleaseQueue' }">
                Release Queue
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Release Details
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div>
      <div class="media">
        <img v-if="fund_release.school_details.school_image"
          :src="getImg(fund_release.school_details.school_image)"
          :alt="fund_release.school_details.school_name"
          class="mr-3"
        />
        <div class="media-body">
          <h5 class="text-capitalize">
            School: {{ fund_release.school_details.school_name || "" }}
          </h5>
          <p class="text-muted">
            {{ fund_release.school_details.school_description || "" }}
          </p>
          <div class="row">
            <div class="col-12 col-sm-3">
              REF:
              <span class="badge badge-secondary mb-2">{{
                fund_release.fee_release_system_ref || ""
              }}</span>
            </div>
            <div class="col-12 col-sm-3">
              Status:
              <span class="badge badge-secondary mb-2">{{
                fund_release.release_status.status  || ""
              }}</span>
            </div>
            <div class="clearfix mb-2"></div>
       
           <div class="col-12 col-sm-3 py-2">
              Created Date: <strong>{{ fund_release.created_date || "" }}</strong>
            </div> 
            <div class="col-12 col-sm-3">
             Total Fee Amount:
              <strong  >{{ fund_release.currency || "" }} {{ fund_release.total_fee_amount || "" }}</strong>
            </div>

            <div class="col-12 py-2" v-if="fund_release.release_status.status==='pending:release'">
               <div class="row">
               <div class="col-12 col-sm-4">
                 <label>Release Remarks</label>     
                 </div> 
                  <div class="col-12 col-sm-4">
                     <textarea v-model="release_remarks"></textarea>
                   </div>  
                  <div class="col-12 col-sm-4">
              <button class="mr-2 btn btn-success" @click="forceRelease">
                Force Release
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
                   </div>
            </div>
             </div>
           </div>
        </div>
      </div>

    </div>
         <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">Release Details</div>
               
            </div>
             <div class="col-3 col-sm-3">
                 <p> status : {{fund_release.release_status.status}} </p>
                <p> expected Release Date : {{fund_release.release_details.expected_release_date}} </p>
              </div>
                 <div class="col-3 col-sm-3">
                  Released By : {{fund_release.release_status.released_by}} 
                   <p> expected Release Amount : {{fund_release.release_details.expected_release_amount}} </p>
                </div>
                    <div class="col-3 col-sm-3">
                      Released Remarks : {{fund_release.release_status.release_remarks}} 
                </div>
                    <div class="col-3 col-sm-3">
                  1
                </div>
         </div>   
          <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">Parent Details</div>

            </div>
               <div class="col-3 col-sm-3">
                 <p> Names : {{fund_release.parent_details.parent_name}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                <p> Email : {{fund_release.parent_details.primary_email}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                 <p> Contact : {{fund_release.parent_details.primary_contact}} </p>
              </div>
         </div>

          <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">Student Details</div>
            </div>
              <div class="col-3 col-sm-3">
                 <p> Names : {{fund_release.student_details.student_name}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                <p> Admision Date : {{fund_release.student_details.admission_date}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                 <p> Admision Number : {{fund_release.student_details.admission_no}} </p>
              </div>
         </div>
           <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">Term Details</div>
            </div>
              <div class="col-3 col-sm-3">
                 <p> Names : {{fund_release.term_details.term_name}} </p>
              </div>
              <div class="col-3 col-sm-3">
                 <p> Description : {{fund_release.term_details.term_description}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                <p> Start Date : {{fund_release.term_details.start_date}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                 <p> End Date : {{fund_release.term_details.end_date}} </p>
              </div>
         </div>

          <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">Academic Details</div>
            </div>
                <div class="col-3 col-sm-3">
                 <p> Names : {{fund_release.academic_details.academic_name}} </p>
              </div>
              <div class="col-3 col-sm-3">
                 <p> Description : {{fund_release.academic_details.academic_description}} </p>
              </div>

              
                  <div class="col-3 col-sm-3">
                <p> Start Date : {{fund_release.academic_details.start_date}} </p>
              </div>
                  <div class="col-3 col-sm-3">
                 <p> End Date : {{fund_release.academic_details.end_date}} </p>
              </div>
         </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fund_release: {},
      loading: false,
      release_remarks:''
    };
  },
  methods: {
    getImg(path) {
      return `${domain}/${path}`;
    },
    fetchReleaseQueue() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fund_release",
            action: "fund_release_details",
            data: {
              fee_release_system_ref: this.$route.params.id,
            },
          });
        //  console.log("res.data.result", res.data.result.fee_release_details);
          this.fund_release = res.data.result.fee_release_details;
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },

    async forceRelease() {
      this.loading = true;
      try {
        let res = await req.callServerSecure({
          
          resource: "fund_release",
          action: "force_release",
          data: {
              release_system_ref:this.$route.params.id,
              release_remarks:this.release_remarks
          },
        });

        this.fetchReleaseQueue();
        this.loading = false;
        notification.notify(
          "success",
          "Fund release forced",
          "Forced fund release successfully"
        );
      } catch (err) {
        this.loading = false;
        console.log(err);
        notification.notify("error", "Could not force release", err);
        Event.$emit("loader:show", false);
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchReleaseQueue();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>