<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Parents</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Parents List</h4>
            </div>
            <!-- <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase">
                    <i data-feather="mail" class="wd-10 mg-r-5"></i> Email
                </button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Add Client
                </button>
            </div> -->
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
					<CustomTable :tableFields="table_fields" 
						:tableData="parents" 
						:title="`Entity Parents`"
						:subtitle="`Entity Parents List`" 
						:searchFields="searchFields"
						:rowData="rowData">
					</CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  created() {
    Event.$on("parent:view", async (payload) => {
      console.log("Payload: ", payload);
      this.$router.push({
        name: "Entities-ParentsView",
        params: { id: payload.parent.parent_system_ref },
      });
    });
  },
  data() {
    return {
      parents: [],
      searchFields: ["name", "phone_number", "email", "subtype"],
      table_fields: [
        // { name: 'Image', align: 'l' },
        { name: "ID", align: "c" },
        { name: "Name", align: "c" },
        { name: "Email", align: "c" },
        { name: "Contact", align: "c" },
        { name: "Category", align: "c" },
        { name: "Status", align: "c" },
        { name: "Created Date", align: "c" },
        { name: "actions", align: "c" },
      ],
      rowData: [
        // { type: 'image', field: 'image', class: 'left' },
        { type: "text", field: "parent_id", class: "small center" },
        { type: "text", field: "parent_name", class: "small center" },
        { type: "status", field: "primary_email", class: "small center" },
        { type: "status", field: "primary_contact", class: "small center" },
        { type: "status", field: "parent_category", class: "small center" },
        { type: "status", field: "parent_status", class: "small center" },
        { type: "text", field: "created_date", class: "small center" },
        {
          type: "action",
          field: "name",
          class: "center",
          icon: "",
          actions: [
            // {type: 'title', title: 'Actions'},
            // {
            // 	type: 'menu', title: 'Edit', event: 'parent:edit', icon: 'edit-3', params: [
            // 		{ key: 'client', field: '*' }
            // 	]
            // },
            {
              type: "menu",
              title: "View",
              event: "parent:view",
              icon: "eye",
              params: [{ key: "parent", field: "*" }],
            },
            // {type: 'divider'},
            // {
            // 	type: 'menu', title: 'Delete', event: 'parent:delete', icon: 'delete', params: [
            // 		{ key: 'email', field: 'email' }
            // 	]
            // },
          ],
        },
      ],
    };
  },
  methods: {
    fetchParents() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "parent",
            action: "list",
            data: {},
            meta_data: {},
          });
		  console.log("res.data.result", res);
          this.parents = res.data.result.parents;
          resolve(true);
        } catch (err) {
          notification.notify(
            "error",
            "Error while fetching parents list",
            `Error while fetching parents list`
          );
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchParents();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.user-list {
}
</style>