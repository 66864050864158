module.exports = {
    name: 'Blacklists',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_blacklist',
        pages: [
            {
                title: 'Blacklist',
                subtitle: 'Configure new Blacklist',
                forms: [
                    {
                        type: 'select',  label: 'Client', model: 'client_id',
                        validation: 'required', class: 'col-12 col-md-6', options: []
                    },

                    {
                        type: 'text', form_type: 'text', label: 'Remarks', model: 'blacklist_remarks',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'textarea', form_type: 'text', label: 'Details', model: 'blacklist_details',
                        validation: 'required|min:10|max:500', class: 'col-12'
                    },
                ],
            }
        ]
    },
    table: {
        title: 'Blacklists',
        subtitle: 'A list of configured tax categories',
        table_fields: [
            { name: 'Client', align: 'l' },
            { name: 'Blacklist Remarks', align: 'l' },
            { name: 'Blacklist Details', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["blacklist_details", "blacklist_remarks"],
        rowData: [
            { type: 'text', field: 'client_id', class: 'left bolder small' },
            { type: 'text', field: 'blacklist_remarks', class: 'left bolder' },
            { type: 'text', field: 'blacklist_details', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'View', event: 'blacklist:view', icon: 'ico-reply', params: [
                            { key: 'blacklist', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },
}