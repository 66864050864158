<template lang="html">
	<div class="reg_template">
      <div class="app-loader" v-show="showLoader">
         <div class="center-text">
            <div class="df-logo pulsate-css">
               <div class="f-l">Nexus |</div>
               <div class="f-l tx-primary smaller pl-2">Admin</div>
            </div>
         </div>
      </div>

		<div class="content content-fixed content-auth">
			<div class="container">
				<div class="media align-items-stretch justify-content-center ht-100p pos-relative">
					<div class="media-body align-items-center">
						<div class="max-expand">
							<img src="img/email.svg" class="img-fluid" alt="">
						</div>
					</div>

					<div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
						<div class="wd-100p">
						<h3 class="tx-color-01 mg-b-5">Forgot Password Email Verification</h3>
						<p class="tx-color-03 tx-16 mg-b-40">Welcome to Nexus! Fill out the form below.</p>

					
						<div class="form-group">
							<div class="d-flex justify-content-between mg-b-5">
							<label class="mg-b-0-f">Password</label>
							
							</div>
							<input 
								type="password" 
								class="form-control" 
								placeholder="Enter your password"
								name="verify password" 
								v-model="reg_password"
								v-validate="'required|min:5|max:150'">
							<small class="text-danger" v-show="errors.has('verify password')">{{errors.first('verify password')}}</small>
						</div>
						<div class="form-group">
							<div class="d-flex justify-content-between mg-b-5">
							<label class="mg-b-0-f">Confirm Password</label>
							
							</div>
							<input 
								type="password" 
								class="form-control" 
								placeholder="Confirm your password"
								name="verify confirm password" 
								v-model="reg_confirm_password"
								v-validate="'required|min:5|max:150'"
								@keyup.enter="saveDetails()">
							<small class="text-danger" v-show="errors.has('verify confirm password')">{{errors.first('verify confirm password')}}</small>
						</div>
			
						<button class="btn btn-brand-02 btn-block" @click="saveDetails()">
							Change Password
							<div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
						</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<CustomFooter />
	</div>
</template>

<script>
export default {
	data () {
		return {
         showLoader: true,
			loading: false,
			reg_password: '',
         reg_username: '',
         reg_confirm_password: '',
         reg_id: ''
		}
	},
	methods: {
      async verifyToken(){
         return new Promise(async (resolve,reject) => {
            try {
               let token = this.$route.query.token
               
               if(!token){
                  notification.notify('error', 'Token Not Found', `Token has not been found`)
                  resolve(true)
                  return null;
               }

               let res = await req.callServer({
                  resource: "forgot_password",
                  action: "validation",
                  data: {
                     email_token: token
                  }
					});

               this.showLoader = false
               //console.log(" forgot passwor response " ,res.data.result)
               this.reg_id = res.data.result.password_change_request_id
              // $session.set('reg_id',this.reg_id)
               resolve(true)
            } catch (err) {
               this.$router.push({name: 'LogIn'})
            }
         })
      },
		async saveDetails(){
         this.loading = true
         
         // check why validator is not validating
         await this.$validator.validate('verify password', this.reg_password)
         await this.$validator.validate('verify confirm password', this.reg_confirm_password)

         await this.$nextTick()
			if(utils.isValid(this.errors.items,'reg') && this.reg_password === this.reg_confirm_password){
				try {
              // alert(" Changing password ",this.reg_id)
					let res = await req.callServer({
						resource: "forgot_password",
						action: "change",
						data :{
							password: this.reg_confirm_password,
                     password_change_request_id: this.reg_id
						}
					})

					this.reg_password = ''
               this.reg_confirm_password = ''
               
               notification.notify('success', 'Setup Complete', `Password Change Success Login to continue`)
					this.loading = false
					this.$router.push({name: 'LogIn'})
				} catch (err) {
					this.loading = false
           
                   notification.notify('error', 'Unknown Error', `kindly retry later`)
                       	this.$router.push({name: 'LogIn'})
				}
			}else{
            if(this.reg_password !== this.reg_confirm_password){
               notification.notify('error', 'Password Mismatch', `confirmation password does not match the password`)
            }

				this.loading = false
			}
      },
	},
	async mounted(){
      await this.verifyToken()
	}
};
</script>

<style lang="less">
@media (min-width: 992px){
	.reg_template{
      .max-expand{
         max-width: 600px;
         height: auto;
      }
		.content-fixed {
			margin-top: 0 !important;
		}
		.app{
			position: fixed;
			bottom: 0px;
			left: 0;
			right: 0;
		}
	}
}
</style>