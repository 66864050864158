<template lang="html">
   <div class="benefitcategories">
      <ModalForm :showModal="showModal" :action="BenefitCategory.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="BenefitCategory.wizard.pages" 
               :action="BenefitCategory.wizard.action" 
               :buttonText="BenefitCategory.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createBenefitCategory()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Benefit Category
               </button>
         </div>
      </div>

      <CustomTable :tableFields="BenefitCategory.table.table_fields"
         :icon="BenefitCategory.table.icon"
         :title="BenefitCategory.table.title"
         :subtitle="BenefitCategory.table.subtitle"
         :tableData="benefitcategories" 
         :searchFields="BenefitCategory.table.searchFields"
         :rowData="BenefitCategory.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.BenefitCategory = Models.BenefitCategory

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.BenefitCategory.wizard.action){
               await this.saveBenefitCategory(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

        Event.$on("benefitCategory:view", async (payload) => {
            this.$router.push({
                name: "Config-BenefitCategory-View",
                params: { id: payload.BenefitCategory.benefit_category_ref },
            });
        });
        Event.$on("benefitCategory:verify", async (payload) => {
            console.log( "verify", payload);
        });
      },
      data () {
         return {
            BenefitCategory: {},
            benefitcategories: [],
            showModal: false
         }
      },
      async mounted(){
         await this.fetchbenefitcategories()
         await this.getIcons();
         Event.$emit('loader:show', false)
      },
      methods: {
         fetchbenefitcategories(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "benefit_category",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                //   console.log(res);
                  this.benefitcategories = res.data.result.benefit_categories
                    .map(item => {
                        item.benefit_category_image_path = domain + item.benefit_category_image_path;
                        return item;
                    })
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createBenefitCategory() {
            this.showModal = true
         },
         saveBenefitCategory(data){
           
            //    console.log("data", data);
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "benefit_category",
                     action: "create",
                     data: {
                        benefit_category_name: data.benefit_category_name,
                        benefit_category_image_path: data.benefit_category_image_path,
                        benefit_category_description: data.benefit_category_description,
                     },
                     meta_data: {},
                  });

                  await this.fetchbenefitcategories()
                  notification.notify('success', 'BenefitCategory Added', `The BenefitCategory ${data.benefit_category_name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.BenefitCategory.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.BenefitCategory.wizard.action})
               }
            })
         },
         getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let pages = this.BenefitCategory.wizard.pages;
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  pages[0].forms[1].options = utils.appendOptions(
                      res.data.result,
                      "file_path",
                      "originalname",
                  ),
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
      },
      
   }
</script>

<style lang="less">
   .benefitcategories{
      
   }
</style>