<template>
	<div class="notifications-alert" v-show="notifications.length > 0">
		<Notify
			v-for="notification in notifications"
			:key="notification.id"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
			:id="notification.id"
		/>
	</div>
</template>

<script>
export default {
	methods: {},
	created() {
		//Logic to push the most recent notification to the top of the notifications
		Event.$on("addNotification", async payload => {
			let id = Math.round(Math.random() * 10000000);

			payload.id = id;
			if (payload.message.length > 0) {
				this.notifications.push(payload);
				await utils.sleep(50);
				this.closeNotification(id)
			}
		});

		Event.$on("notification:close", payload => {
			this.closeNotification(payload.id)
		});
	},
	data() {
		return {
			notifications: []
		};
	},
	methods: {
		closeNotification(id) {
			for (let i = 0; i < this.notifications.length; i++) {
				const notification = this.notifications[i];

				if(notification.id === id){
					this.notifications.splice(i, 1);
				}
			}
		}
	}
};
</script>

<style lang="less">
.notifications-alert {
	position: fixed;
	right: 15px;
	top: 20px;
	z-index: 1500;
	width: 450px;
	// overflow-y: scroll;
	max-height: 98vh;
}
</style>