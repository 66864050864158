<template>
  <div class="add-purchase-policy">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Purchase Policy Config</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new purchase policy transaction</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="PurchasePolicy.wizard.pages"
          :action="PurchasePolicy.wizard.action"
          :buttonText="PurchasePolicy.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.PurchasePolicy = Models.PurchasePolicy;

    Event.$on("form:submit", async (payload) => {
      console.log(payload);
      this.$router.push({
        name: "Configs-Transactions-TransactionConfig",
        params: {},
      });
    });
  },

  data() {
    return {
      PurchasePolicy: {},
      purchasePolicys: [],
    };
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>