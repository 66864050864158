<template lang="html">
   <div class="groups">
      <ModalForm :showModal="showModal" :action="UnderwritterGroup.wizard.action">
         <template >
            <CustomForm 
               :pages="UnderwritterGroup.wizard.pages" 
               :action="UnderwritterGroup.wizard.action" 
               :buttonText="UnderwritterGroup.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createSuperUser()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Group
               </button>
         </div>
      </div>

      <CustomTable :tableFields="UnderwritterGroup.table.table_fields"
         :icon="UnderwritterGroup.table.icon"
         :title="UnderwritterGroup.table.title"
         :subtitle="UnderwritterGroup.table.subtitle"
         :tableData="groups" 
         :searchFields="UnderwritterGroup.table.searchFields"
         :rowData="UnderwritterGroup.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.UnderwritterGroup = Models.UnderwritterGroup
         this.groups = await utils.getDummyData(Models.UnderwritterGroup,3)

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.UnderwritterGroup.wizard.action){
               console.log(" === Payload === ",payload)
               await this.saveGroup(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            UnderwritterGroup: {},
            groups: [],
            showModal: false
         }
      },
      methods: {
         fetchGroups(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "underwritter_group",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.groups = res.data.result.underwritter_groups
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createSuperUser() {
            this.showModal = true
         },
         saveGroup(data){
            return new Promise(async (resolve,reject) => {
               try {
                     console.log(" group data ",data)
                  let res = await req.callServerSecure({
                     resource: "underwritter_group",
                     action: "add",
                     data: {
                        group_code: data.code,
                        names: data.name,
                        physical_location: JSON.stringify(data.physical_location),
                        address: data.address,
                        contacts:[
                           { names:"main" ,contact: data.contact}
                        ],
                        emails:[
                           { names:"main" ,email: data.email}
                        ]   
                     },
                     meta_data: {},
                  });

                  notification.notify('success', 'Underwritter Group Added', `The group ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.UnderwritterGroup.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.UnderwritterGroup.wizard.action})
               }
            })
         }
      },
      async mounted(){
         let obj = {
            items: [
               {
                  name: 'Hello'
               }
            ]
         }
         await this.fetchGroups()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .groups{
      
   }
</style>