module.exports = {
    name: 'Form Fields',
    table: {
      title: 'System Uploads',
      subtitle: 'View Uploads',
      table_fields: [
         { name: 'Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'Type', align: 'l' },
         { name: 'Details', align: 'l' },
    
      ],
      searchFields: ["type", "details"],
      rowData: [
         { type: 'text', field: 'code', class: 'left bolder small' },
         { type: 'text', field: 'name', class: 'left bolder small' },
         { type: 'text', field: 'type', class: 'left bolder' },
         { type: 'text', field: 'details', class: 'left bolder' },
      ]
   },
  }