
<template lang="html">
   <div class="addServiceCategory">
       <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Service Category Configuration</li>
                    </ol>
                </nav>
            </div>
        </div>

            <div class="row">
               <div class="col-sm-8 offset-sm-2">  
                            <div class="form-row">
                                    <div class="form-group col-md-5">
                                    <nav class="nav nav-icon-only">
                                    <div class="dropdown">
                                    <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Icon</button>
                               
                                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                            <img :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail"  :alt="icon.originalname" v-for="(icon, index) in icons" @click="iconSelected(icon.file_path)">
                                        </div>
                                            </div>  
                                    </nav> 
                                    </div>
                                        <div class="form-group col-md-5">
                                        <img  v-bind:src="serviceCategory.category_image" class="img wd-60 ht-60 rounded-circle" alt="">
                                        </div>
                                   </div>                  
                      
                            
                   <div class="form-row">
                      <div class="form-group col-md-6">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Service Category code" name="CategoryCode" id="CategoryCode" v-model="serviceCategory.category_code"  />

                     </div>
                      <div class="form-group col-md-6">
                       <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Service Category name" name="Categoryname" id="Categoryname" v-model="serviceCategory.category_name"  />

                     </div>
                   </div>


                    <div class="form-row">
                      <div class="form-group col-md-12">
                       <label>Description</label> 
                      <textarea class="form-control" rows="2" placeholder="Service Category Description " name="Categorydesc" id="Categorydesc" v-model="serviceCategory.category_description" ></textarea>

                     </div>
                   </div> 

                        <div class="form-row">
                      <div class="form-group col-md-6">
                        <button type="button" class="btn btn-primary" @click="saveServiceCategory()">Save</button>
                     </div>
                      <div class="form-group col-md-6">
                          <button type="button" class="btn btn-warning" @click="clearServiceCategory()">Clear</button>
                     </div>
                   </div>

                            </div>
                         </div>      

   </div>
</template>
<script>
export default {
	async created() {
    },
    data() {
		return {
            icons:[],
            imageSelected: false,
            serviceCategory:{
                category_code:'',
                category_name:'',
                category_description:'',
                category_image:''
            }
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        iconSelected (icon) {
          this.serviceCategory.category_image=req.getParsedUrl(icon)
        },
         clearServiceCategory () {
          this.serviceCategory={}
        },
        getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  this.icons = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
        async saveServiceCategory () {
            try {
             
                  /*  let valii= await this.$validator.validate();
                     if(!valii) {
                       return reject(" Validation Error ");
                     }
                   */  
                   	let res = await req.callServerSecure({
						resource: "serviceprovider",
						action: "add_category",
						data: {
							category_code: this.serviceCategory.category_code,
							category_name: this.serviceCategory.category_name,
							category_description: this.serviceCategory.category_description,
                            category_image:this.serviceCategory.category_image,
						}
					});
					notification.notify("success","Service Category Added",`Service Category ${this.serviceCategory.category_name} successfully added`);
                    this.$router.push({
                        name: "serviceCategories",
                        params: {}
                    });

            }
            catch (err){
         	console.log(err);
					Event.$emit("form:action-error", 'An Error Has Occured');
            }

        },
    },
    async mounted() {
        await this.getIcons()
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.addServiceCategory {
}
</style>