
<template lang="html">
   <div class="listserviceCategory">

         <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addServiceCategory()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Service Category
               </button>     
         </div>
      </div>

       <div class="contents">
         <CustomTable :tableFields="ServiceCategory.table.table_fields" 
            :tableData="serviceCategories" 
            :title="ServiceCategory.table.title" 
            :subtitle="ServiceCategory.table.subtitle" 
            :searchFields="ServiceCategory.table.searchFields"
            :rowData="ServiceCategory.table.rowData">
         </CustomTable>
      </div>
              
   </div>
</template>
<script>
export default {
	async created() {
        	this.ServiceCategory = Models.ServiceCategories;
            Event.$on("servicecategory:view", payload => {
			this.$router.push({
				name: "ViewServiceCategories",
				params: { id: payload.service_category.category_code }
			});
		});
    },
      data() {
		return {
         serviceCategories:[]
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        addServiceCategory () {
                     this.$router.push({
                            name: "AddServiceCategories",
                            params: {}
                        });
        },
              fetchServiceCategories () {
         return new Promise(async (resolve, reject) => {
				try {
					
					let res = await req.callServerSecure({
						resource: "serviceprovider",
						action: "list_category",
						data: {},
						meta_data: {}
					});
               this.serviceCategories = res.data.result.categories
					resolve(true);
				} catch (err) {
					notification.notify("error","Fetching Service Categories",`Fetching Service Categories`);
					reject(err);
				}
			});
      },
     },
    async mounted() {
           await this.fetchServiceCategories();
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.listserviceCategory {
}
</style>