<template>
  <div class="add-provider">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">UserGroup</div>
            <p class="tx-13 tx-color-03 mg-b-0">Add User Group</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="UserGroup.wizard.pages"
          :action="UserGroup.wizard.action"
          :buttonText="UserGroup.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.UserGroup = Models.UserGroup;
  },
  data() {
    return {
      UserGroup: {},
      userGroups: [],
    };
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>