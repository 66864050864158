<template lang="html">
   <div class="view-client">
      <div class="no-content" v-show="client.kyc_status === 'PENDING'">
         <div class="box">
            <span data-feather="slash"></span> 
         </div>
         <div class="title">KYC Details Not Provided</div>
      </div>


      <div class="content" v-show="client.kyc_status !== 'PENDING'">
         <div class="row">
            <div class="f-left txt-box col-12">
               <div class="mg-b-5 fx-h5">{{client.client_type}}</div>
               <p class="tx-13 tx-color-03 mg-b-0">{{client.client_sub_type}}</p>
            </div>
            
            <div class="col-12 form-data">
               <div class="title fx-h5">KYC Data</div>
               <ViewForm 
                  :data="formData"
                  :pages="page_forms.pages" 
                  :action="page_forms.action" 
                  :showTitle="false">
               </ViewForm>
            </div>

            <div class="col-12 form-data">
               <div class="title fx-h5">Assessment Questions</div>
               <ViewForm 
                  :data="questionData"
                  :pages="question_forms.pages" 
                  :action="question_forms.action" 
                  :showTitle="false">
               </ViewForm>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
   export default {
      data () {
         return {
            client: {
               client_type: '',
               client_sub_type: '',
               kyc_status: ''
            },
            page_forms: {
               action: 'view_provided_kyc',
               pages: []
            },
            question_forms: {
               action: 'view_assessment_questions',
               pages: []
            },
            formData: {},
            questionData: {},
         }
      },
      methods: {
         fetchClientInfo () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "client", 
                     action: "get", 
                     data: {
                        client_id: this.$route.params.id
                     }, 
                  })

                  this.client = res.data.result.client_details
                  this.page_forms.pages = [{title: '',subtitle: '',forms: res.data.result.client_kyc}]
                  this.getFormData(res.data.result.client_kyc)
                  this.getFormQuestions(res.data.result.client_self_assesments)
                  resolve(true)
               }catch(err){
                  console.log(err)
                  reject(err)
               }
            })
         },
         getFormData(kyc_data){
            let formData = {}
            for (let i = 0; i < kyc_data.length; i++) {
               const data = kyc_data[i];
               
               if(data.type === 'date'){
                  let date = data.kyc.value.split(' ')
                  let realDate = utils.format('##-##-####',date[0])
                  formData[data.model] = realDate
               }else if(data.type === 'mobile'){
                  let mobile = data.kyc.value.split(':')
                  formData[data.model] = data.kyc.value.replace(':','-')
               }else{
                  formData[data.model] = data.kyc.value
               }
            }

            this.formData = formData
         },
         getFormQuestions(questions){
            let forms = []
            for (let i = 0; i < questions.length; i++) {
               const question = questions[i];
               
               forms.push({
                  type: 'radio', class: 'col-12', model: question.code,label: question.question,
                  classes:{answer_class: 'tx-primary'}
               })
               this.questionData[question.code] = question.self_assessment.answer
            }

            this.question_forms.pages = [{
               title: 'Assesment Questions',
               subtitle: 'Assesment question subtitle',
               forms
            }]
         }
      },
      async mounted() {
         await this.fetchClientInfo()
         Event.$emit('loader:show', false)
      },
   }
</script>

<style lang="less">
   .view-client{
      .content{
         .title{
            margin-bottom: 20px;
            font-size: 20px;
            color: #8392a5;
         }
   
         .form-data{
            margin-top: 30px;
         }
      }

      .no-content{
         text-align: center;
         border: solid 1px #efefef;
         border-radius: 3px;
         padding: 40px 0 !important;
         margin-top: 100px;

         .box svg{
            width: 120px;
            height: 120px;
            fill: rgba(0, 23, 55, 0.08);
         }
         .title{
            font-weight: 600;
            font-size: 20px;
            margin-top: 40px;
         }
      }
   }
</style>