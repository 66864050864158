<template lang="html">
   <div class="orange">

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addCountry()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Country
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

	  
		

      <div class="contents">
         <CustomTable :tableFields="Country.table.table_fields" 
            :tableData="countries" 
            :title="Country.table.title" 
            :subtitle="Country.table.subtitle" 
            :searchFields="Country.table.searchFields"
            :rowData="Country.table.rowData">
		
         </CustomTable>
		
      </div>
   </div>
</template>

<script>
export default {
  async created() {
    this.Country = Models.Country;
    let _this = this;
    Event.$on("country:view", (payload) => {
      this.$router.push({
        name: "ViewCountry",
        params: { id: payload.country.country_code },
      });
    });

  },
  data() {
    return {
      countries: [],
      showModal: false,
    };
  },
  methods: {
    fetchCountries() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "country",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.countries=res.data.result.countries;
          console.log(" countries " , this.countries )

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    addCountry() {
      this.$router.push({
        name: "addCountry",
        params: {},
      });
    }

  },
  async mounted() {
    await this.fetchCountries();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>