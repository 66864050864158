<template>
  <div class="view-user-group">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View User Group</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <UserModal :showUserModal="showUserModal"></UserModal>
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Code :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{userGroup.group_code}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{userGroup.group_name}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Entity :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{userGroup.entity_type}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>Pending Approval</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Created Date :</div>
              <div class="media-body pd-l-15">
                <h6 class>16/08/2020</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-10">
            <div class="media mg-b-20">
              <div class>Description :</div>
              <div class="media-body pd-l-15">
                <h6
                  class
                >{{userGroup.description}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <a href class="nav-link">
            <i data-feather="thumbs-up"></i> Approve
          </a>
          <a href class="nav-link">
            <i data-feather="edit"></i> Edit
          </a>          
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <div class="d-flex align-items-center justify-content-between mg-b-10">
            <h5>Users</h5>
            <button class="btn btn-sm ml-auto" @click="addUser()">
              <i data-feather="users"></i> Add User
            </button>
          </div>          
          <table class="table ml-table ml-responsive-md">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Staff Ref</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in users">
                <td>Robert Kamau</td>
                <td>RK004</td>
                <td class="text-center">
                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="viewUser(item.user_code)"
                    >View</button>
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      @click="removeUser(item.user_code)"
                    >Remove</button>
                  </div>
                </td>
              </tr>
     
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    Event.$on("userModal:close", async () => {
      this.showUserModal = false;
    });
  },
  data() {
    return {
      users: [],
      userGroup: {},
      showUserModal: false,
    };
  },
  methods: {
    getUserGroup() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "user_group",
            action: "get",
            data: {
              group_id: this.$route.params.id
            },
            meta_data: {},
          });

          this.userGroup = res.data.result.entity_group_details;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    approveUserGroup() {
      console.log("approveUser Group");
    },
    editUserGroup() {
      console.log("editUser Group");
    },
    addUser() {      
      this.showUserModal = true;
    },
    viewUser() {
      console.log("Add User");
    },
    removeUser() {
      console.log("Add User");
    },
  },
  async mounted() {
    await this.getUserGroup();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>