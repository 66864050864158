<template lang="html">
   <div class="accounts">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div class="query">

			<ModalForm :showModal="showModal" :action="acc.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="acc.wizard.pages" 
                  :action="acc.wizard.action" 
                  :buttonText="acc.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>

            </template>
           </ModalForm>
			<!-- v-validate="'required'"  -->
            <select class="custom-select" 
               v-model="entity_type"
               name="account query entity">
                  <option value="CLIENT">Client</option>
                  <option value="UNDERWRITTER">Underwritter</option>
            </select>
            <button class="btn btn-primary"
               @click="queryAccounts()"
               :disabled="loading">
                  Query <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
            </button>
         </div>
         <!-- <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addAccount()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Account Template
               </button>
         </div> -->
      </div>

      <CustomTable :tableFields="Accounts.table.table_fields"
         :icon="Accounts.table.icon"
         :title="Accounts.table.title"
         :subtitle="Accounts.table.subtitle"
         :tableData="configuredTemplateAccounts" 
         :searchFields="Accounts.table.searchFields"
         :rowData="Accounts.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
	async created() {
		this.Accounts = Models.Accounts;

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			console.log('submitting form acc', payload)
			if (payload.action === this.Accounts.wizard.action) {
				let res = await this.saveAccount(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			this.showModal = false;
		});

		Event.$on('account:view',(payload) => {
			this.$router.push({name: 'Configs-Accounting-ViewAccount',params: {id: payload.account.account_number}})
		})
	},
	data() {
		return {
			Accounts: {},
			accounts: [],
			showModal: false,
			entity_type: "CLIENT",
			loading: false,
			gls:[],
			currencies: [],
			limits: [],
			Acountstatus:[],
			entities: [],
			Accountstatements: [],
			Accountsubtypes: [],
			configuredTemplateAccounts: [],		
		};
	},
	methods: {
		// addAccount() {
		// 	this.showModal = true;
		// },
		// saveAccount(data) {
		// 	return new Promise(async (resolve, reject) => {
		// 		try {
		// 			let res = await req.callServerSecure({
		// 				resource: "entity_account_template",
		// 				action: "add",
		// 				data: {
		// 					template_code: data.code,
		// 					template_name: "Template one",
		// 					template_description: "Template description",
		// 					sub_gl_header_system_ref: data.gl,
		// 					account_limit_system_ref: data.limit,
		// 					account_status_system_ref: data.status,
		// 					account_statement_system_ref: data.statement,
		// 					currency_system_ref: data.currency,
		// 					entity_type: data.entity,
		// 					entity_sub_type: data.entity_subtype,
		// 					account_type: data.type
		// 				},
		// 				meta_data: {}
		// 			});

        //        notification.notify('success', 'Accounts Template Added', `The account template ${data.name} was successfully added`)
        //        Event.$emit('form:action-complete',{action: this.Accounts.wizard.action, clear: true})
        //        this.showModal = false
		// 		} catch (err) {
		// 			console.log(err);
		// 			Event.$emit("form:action-error", {
		// 				action: this.Accounts.wizard.action
		// 			});
		// 		}
		// 	});
		// },
		queryAccounts() {
			return new Promise(async (resolve, reject) => {
				try {
					this.loading = true;

					let res = await req.callServerSecure({
						resource: "accounts",
						action: "entity_accounts",
						data: {
							entity_type: this.entity_type
						},
						meta_data: {}
					});

					this.accounts = res.data.result.accounts;
					this.loading = false;
				} catch (err) {
					this.loading = false;
				}
			});
		},
		
	// 	async fetchGl () {
    //      try {
    //            let resp= await req.callServerSecure({
    //                  resource: "gl",
    //                  action: "list",
    //                  data: {}
    //               });
    //               this.gls=resp.data.result.gl_headers

	// 			  console.log('res gl', this.gls)
    //      }
    //      catch (err) {
    //      console.log(err)
    //      }
    //  },
	//    async fetchSubGl () {
    //     return new Promise(async (resolve,reject) => {
    //      try {
    //      let resp = await req.callServerSecure({
    //         resource: "sub_gl",
    //         action: "list",
    //         data: {},
    //         meta_data: {},
    //       });

    //               this.gls=resp.data.result.sub_gl_headers
	// 			//   console.log('gls sub', this.gls)
    //               resolve()
    //      }
    //      catch (err) {
    //      console.log(err)
    //      resolve()
    //      }
    //     })
    //  },
	//  fetchCurrencies(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //               let res = await req.callServerSecure({
    //                  resource: "currency",
    //                  action: "list",
    //                  data: {},
    //                  meta_data: {},
    //               });

    //               this.currencies = res.data.result.currencies
    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },
	// 	 fetchAccountLimits(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //               let res = await req.callServerSecure({
    //                  resource: "account_limit",
    //                  action: "list",
    //                  data: {},
    //                  meta_data: {},
    //               });

    //               this.limits = res.data.result.account_limits
	// 			//   console.log('limits', this.limits)
    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },
	// 	 fetchAccountStatus(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //               let res = await req.callServerSecure({
    //                  resource: "account_status",
    //                  action: "list",
    //                  data: {},
    //                  meta_data: {},
    //               });

    //               this.Acountstatus = res.data.result.account_statuses
    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },

	// 	 fetchAccountStatements(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //             let res = await req.callServerSecure({
	// 					resource: "account_statement",
	// 					action: "list",
	// 					data: {},
	// 					meta_data: {}
	// 				});
            
    //               this.Accountstatements = res.data.result.account_statements
    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },
		
	// 	 fetchAccountEntities(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //               let res = await req.callServerSecure({
    //                  resource: "entity",
    //                  action: "list",
    //                  data: {},
    //                  meta_data: {},
    //               });

    //               this.entities = res.data.result.entities
	// 			//   console.log('entities', this.entities)
    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },
    //      fetchSubTypes(){
    //         return new Promise(async (resolve,reject) => {
    //            try {
    //               let res = await req.callServerSecure({
    //                  resource: "client",
    //                  action: "client_sub_types",
    //                  data: {},
    //                  meta_data: {}
    //               });

	// 			  this.Accountsubtypes = res.data.result.client_subtypes

    //             //   let pages = this.AccountEntity.wizard.pages,options = [];
    //             //   await res.data.result.client_subtypes.forEach(element => {
    //             //      options.push({ val: element.subtype_code, label: element.subtype_name});
    //             //   });
    //             //   pages[0].forms[3].options = options;
    //             //   this.AccountEntity.wizard.pages = pages

    //               resolve(true)
    //            } catch (err) {
    //               reject(err)
    //            }
    //         })
    //      },


		 fetchConfiguredAccountTemplates(){
           return new Promise (async (resolve, reject) => {
			try {	
				let res = await req.callServerSecure({
					resource: "accounts",
					action: "list",
					data: {},
					meta_data: {},
				}); 
				console.log("list accs", res)

				this.configuredTemplateAccounts = [];

			} catch {

			}
		   })

		
		 }
	},
	computed: {	
	  acc: {
		get() {
			return {
				...this.Accounts,
				wizard: {
					...this.Accounts.wizard,
					pages: this.Accounts.wizard.pages.map(page => ({
						...page,
						forms: page.forms.map((item) => {
							switch (item.label) {
								case "Currency":
									return { 
										...item, 
										options: this.currencies.map(currency => ({
											val: currency.currency_system_ref,
											label: currency.name
										}))
									};
									case "Entity Type":
									return {
										...item,
										options: [
										{
										   label :"Underwriter",
											val  :"UNDERWRITER"
										},
										{
											label :"Client",
											val :"CLIENT"
										},
										{
											label :"Bank",
											val :"BANK"
										},
										{
											label :"Intermediary",
											val :"INTERMEDIARY"
										},
										{
											label :"ServiceProvider",
											val :"SERVICEPROVIDER"
										},
											{
											label :"System",
											val :"SYSTEM"
										},
											{
											label :"School",
											val :"SCHOOL"
										}
									]
									};
								case "Entity Subtype":
									return {
										...item,
										options: this.Accountsubtypes.map(subtype => ({
											val: subtype.client_subtype_id,
											label: subtype.subtype_name
										}))
									};
								case "Account Limit":
									return { 
										...item, 
										options: this.limits.map(limit => ({
											val: limit.account_limit_system_ref,
											label: 	`${limit.max_account_bal} ${limit.name}`
										}))
									};
								case "Account Type":
									return { 
										...item, 
										options: [{val: "MAIN", label: "Main" }]
									};
								case "Account Status":
									return {
										...item,
										options: this.Acountstatus.map(acc => ({
											val: acc.account_status_system_ref,
											label: acc.account_status_name
										}))
									};
								case "Account Statement":
									return { 
										...item, 
										options: this.Accountstatements.map(acc => ({
											val: acc.account_statement_system_ref,											
											label: acc.name
										}))
									};
								case "General Ledger":
									return {
										...item,
										options: this.gls.map(ledger => ({
											val: ledger.sub_gl_header_system_ref,
											label: ledger.sub_gl_header_name
										}))
									};
								default:
									return item; // No change for other labels
							}
						})
					}))
				}
			};
		}
	  }
	},
	async mounted() {
		//this.fetchGl()
		// this.fetchSubGl()
		// this.fetchCurrencies()
		// this.fetchAccountLimits()
		// this.fetchAccountStatus()
		// await this.fetchAccountEntities()
		// this.fetchAccountStatements()
		// this.fetchSubTypes()
		this.fetchConfiguredAccountTemplates()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.accounts {
	.query {
		display: flex;
		justify-content: left;

		.btn {
			margin: 0 0 0 20px;
			text-align: center;
			display: flex;
			justify-content: center;
		}
		.custom-select {
			float: left;
			min-width: 200px;
		}
	}
}
</style>