module.exports = {
   name: 'underwritter_group',
   gen_template: `
      {
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}",
         "name": "{{name.jobTitle}}",
         "email": "{{internet.email}}", 
         "desc": "{{name.jobDescriptor}}", 
         "contact": "{{phone.phoneNumber}}",
         "location": {
            "lat": "{{address.latitude}}",
            "lng": "{{address.longitude}}"
         },
         "address": "{{address.streetName}} {{address.streetAddress}} {{address.city}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'insurance',
      pages: [
         {
            title: 'Underwritter Group',
            subtitle: 'Add an underwritter group',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'Code', model: 'code',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Name', model: 'name',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'location', class: 'col-12 col-md-6', model: 'physical_location',
                  validation: 'required', label: 'Physical Location', placeholder: 'Provide your physical location'
               },
               {
                  type: 'text', form_type: 'email', label: 'Email', model: 'email',
                  validation: 'required|min:2|max:150|email', class: 'col-12 col-md-6'
               },
               {
                  type: 'mobile', class: 'col-12', model: 'contact',
                  validation: 'required|digits:9', label: 'Contacts', placeholder: 'Enter the phone number'
               },
               {
                  type: 'text', form_type: 'text', label: 'Address', model: 'address',
                  validation: 'required|min:2|max:50', class: 'col-12'
               },
               // {
               //    type: 'text', form_type: 'text', label: 'Description', model: 'desc',
               //    validation: 'required|min:2|max:50', class: 'col-12'
               // }
            ],
         }
      ]
   },
   table: {
      title: 'Underwritter Groups',
      subtitle: 'A list of configured underwritter groups',
      table_fields: [
         { name: 'Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'Email', align: 'l' },
         { name: 'Contact', align: 'l' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["names", "contact", "email"],
      rowData: [
         { type: 'text', field: 'group_code', class: 'left' },
         { type: 'text', field: 'names', class: 'left' },
         { type: 'text', field: 'emails[0].email', class: 'left' },
         { type: 'text', field: `contacts[0].contact`, class: 'left' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'email', field: 'email' },
                     { key: 'name', field: 'name' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}