<template lang="html">
   <div class="card mg-b-10">
      <slot></slot>
         <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
            <div class="table-title-icon">
                  <div class="f-left icon-box">
                     <i class="icon" :class="icon"></i>
                  </div>
                  <div class="f-left txt-box">
                     <div class="mg-b-5 fx-h5">{{title}}</div>
                     <p class="tx-13 tx-color-03 mg-b-0">{{subtitle}}</p>
                  </div>
            </div>
            <div class="d-flex mg-t-20 mg-sm-t-0">
                  <div class="form">
                     <div class="input-group mg-b-10">
                        <input 
                           type="text" 
                           class="form-control" 
                           placeholder="Search" 
                           aria-label="Search" 
                           v-model="query"
                           aria-describedby="basic-addon2" 
                           @keyup="search()">
                        <div class="input-group-append">
                           <span class="input-group-text" id="basic-addon2"><i data-feather="search"></i></span>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
         <div class="card-body pd-y-30"></div>

         <div class="table-responsive">
            <table class="table ml-table ml-responsive-md">
               <thead>
                  <slot name="thead" v-if="hasHead"></slot>
                  <tr v-else>
                     <th scope="col" 
                        v-for="field in tableFields"
                        :class="{'center': field.align === 'c','right' : field.align === 'r'}">
                           {{field.name}}
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <slot name="tbody" v-if="hasBody"></slot>
                  <tr v-else v-for="d in data">
                     <td v-for="i in rowData.length" :data-label="tableFields[i-1].name">
                        <span v-show="false">{{i -= 1}}</span>
                        <!-- Images -->
                        <div v-if="rowData[i].type === 'image'"
                           class="profile-img" 
                           :class="rowData[i].class">
                              <img :src="d[rowData[i].field]" class="tbl-img" alt="">
                        </div>
                        
                        <!-- Single field text -->
                        <div v-if="rowData[i].type === 'text'" 
                           class="bolder bigger mt-2" 
                           :class="rowData[i].class">
                              {{utils.getObjectValue(d,rowData[i].field) || '-'}}
                        </div>

                        <!-- Single field text -->
                        <div v-if="rowData[i].type === 'number'" 
                           class="bolder bigger mt-2" 
                           :class="rowData[i].class">
                              {{d[rowData[i].field]}}
                        </div>

                        <!-- Nested field text -->
                        <div v-if="rowData[i].type === 'nested'" :class="rowData[i].class">
                           <div v-for="row in rowData[i].rows" :class="rowData[i].class">{{d[row.field] || '-'}}</div>
                        </div>

                        <!-- Status -->
                        <div 
                           class="txt-primary status small bolder" 
                           v-if="rowData[i].type === 'status'" 
                           :class="rowData[i].class">
                              <i class="fa fa-circle"></i> 
                              <span>{{d[rowData[i].field] || 'Active'}}</span>
                        </div> 

                        <!-- Action -->
                        <div class="dropdown fx-dropdown" 
                           v-if="rowData[i].type === 'action'" 
                           :class="rowData[i].class">
                           <button class="btn icon-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                 <i class="ico" :class="rowData[i].icon ||'icon icon-bold icon-menu-grid'"></i>
                           </button>
                           <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <div v-for="action in rowData[i].actions"
                                 :class="{
                                    'dropdown-item' : action.type === 'menu',
                                    'title': action.type === 'title',
                                    'dropdown-divider': action.type === 'divider',
                                 }"
                                 @click="clickAction(action,d)">

                                 <div v-if="action.type === 'menu'">
                                    <div class="menu-icon">
                                       <i :data-feather="action.icon || 'edit-3'"></i> 
                                    </div>
                                    <div class="menu-text">{{action.title}}</div> 
                                 </div>

                                 <span v-if="action.type === 'title'">{{action.title}}</span>
                              </div>
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         <div class="card-footer">
            <Pagination :total="total" :current="current" @on-change="changePage" show-total/>
         </div>
   </div>
</template>

<script>
export default {
  props: {
    icon: {
      default: "icon-user",
    },
    title: {
      default: "Table Title",
    },
    subtitle: {
      default: "Table long description",
    },
    hasHead: {
      default: false,
    },
    hasBody: {
      default: false,
    },
    tableFields: {
      default: function () {
        return [];
      },
    },
    tableData: {
      default: function () {
        return [];
      },
    },
    rowData: {
      default: function () {
        return [];
      },
    },
    searchFields: {
      default: function () {
        return [];
      },
    },
  },
  watch: {
    tableData: function (to, from) {
      this.data = to;

      if (this.data.length > 0) {
        this.store = new PageStore(this.data);
        this.data = this.store.paginateRecords(10);
        this.total = this.store.getTotalRecords();
      }
    },
  },
  data() {
    return {
      data: [],
      utils: "",
      total: 0,
      current: 1,
      store: null,
      query: "",
    };
  },
  methods: {
    clickAction(action, row) {
      let payload = {};

      for (let i = 0; i < action.params.length; i++) {
        const param = action.params[i];

        if (param.field === "*") {
          payload[param.key] = row;
          break;
        } else {
          payload[param.key] = row[param.field];
        }
      }

      Event.$emit(action.event, payload);
    },
    changePage(p) {
      if (this.data.length > 0) {
        this.data = this.store.getPage(p);
      }
    },
    search() {
      if (this.data.length > 0 && this.searchFields.length > 0) {
        this.data = this.store.searchRecords(this.searchFields, this.query);
        this.current = this.store.getCurrent();
        this.total = this.store.getTotalRecords();
      }
    },
  },
  async mounted() {
    this.utils = utils;
    this.data = this.tableData;

    if (this.data.length > 0) {
      this.store = new PageStore(this.data);
      this.data = this.store.paginateRecords(10);
      this.total = this.store.getTotalRecords();
    }
  },
};
</script>

<style lang="less">
.table-box {
  th {
    text-transform: capitalize;
  }
  th.center,
  td .center {
    text-align: center;
  }
  th.right,
  td .right {
    text-align: right;
  }
}
</style>