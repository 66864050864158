<template>
  <div class="orange">
    <ConfigModal
      :showAccountOpeningConfigModal="showModal"
      title="Add Configuration"
      @account-opening-modal-close="closeModal"
      @account-opening-modal-submit="submitConfig"
      :templates="accTemplates"
      :currencies="currencies"
      :loading ="loading"
    />
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div></div>
      <div class="d-none d-md-block">
        <button
          class="btn btn-sm pd-x-15 btn-white btn-uppercase"
          @click="addConfig()"
        >
          Add Configuration
        </button>
      </div>
    </div>

    <h4 class="mb-5">Loan Product account opening Config</h4>
    <h5 class="">Configurations</h5>
    <div class="contents">
      <div
        class="card mb-4"
        v-for="(value, name, index) in configs"
        :key="index"
      >
        <div class="card-body">
          <div
            class="card-header d-flex flex-wrap justify-content-between align-items-center"
          >
            <div class="font-weight-bold">
              Currency: <span>{{ name }}</span>
            </div>
            <div><a href="#">Edit</a></div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="config-group">
                <div class="config-title">Asset</div>
                <div class="mb-3">
                  Loan Portfolio:
                  <span class="font-weight-bold">{{
                    value.asset.loan_portfolio
                  }}</span>
                  <a href="#" class="float-right">view</a>
                </div>

               <div class="mb-3">
                  Cash fund Source:
                  <span class="font-weight-bold">{{
                    value.asset.cash_fund_source
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
              </div>
              <div class="config-group">
                <div class="config-title">Liability</div>
                  <div class="mb-3">
                  Overpayment:
                  <span class="font-weight-bold">{{
                    value.liability.overpayment
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
              </div>
              <div class="config-group">
                <div class="config-title">Expense</div>
                <div class="mb-3">
                  Write-off:
                  <span class="font-weight-bold">{{
                    value.expense.writeoff
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="config-group">
                <div class="config-title">Income</div>
                  <div class="mb-3">
                  Interest:
                  <span class="font-weight-bold">{{
                    value.income.interest
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
                 <div class="mb-3">
                  Fees:
                  <span class="font-weight-bold">{{ value.income.fees }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
                 <div class="mb-3">
                  Penalties:
                  <span class="font-weight-bold">{{
                    value.income.penalties
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
              </div>
              <div class="config-group">
                <div class="config-title">Receivables</div>
                 <div class="mb-3">
                  Interest:
                  <span class="font-weight-bold">{{
                    value.receivable.interest
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
                 <div class="mb-3">
                  Fees:
                  <span class="font-weight-bold">{{
                    value.receivable.fees
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
                 <div class="mb-3">
                  Penalties:
                  <span class="font-weight-bold">{{
                    value.receivable.penalties
                  }}</span
                  >   <a href="#" class="float-right">view</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigModal from "./acccount-opening-config-modal.vue";
export default {
  data() {
    return {
      accTemplates: [],
      currencies: [],
      showModal: false,
      configs: {},
      loading:false
    };
  },
  components: {
    ConfigModal,
  },
  methods: {
    fetchAccountOpeningTemplate() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_account_template",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.accTemplates = res.data.result.templates;
          resolve(true);
        } catch (err) {}
      });
    },
    fetchCurrencies() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "currency",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.currencies = res.data.result.currencies;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchConfigs() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "loanProduct_lender_config",
            action: "get",
            data: {},
          });
         

          this.configs = res.data.result.account_opening_template;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    submitConfig({ payload }) {
      return new Promise(async (resolve, reject) => {
        this.loading= true
        try {
          let currency = payload.currency;
          let res = await req.callServerSecure({
            resource: "loanProduct_lender_config",
            action: "create",
            data: {
              account_opening_template: {
                [currency]: {
                  asset: {
                    loan_portfolio: payload.loan_portfolio,
                    cash_fund_source: payload.source_fund,
                  },
                  income: {
                    interest: payload.income_interest,
                    fees: payload.income_fees,
                    penalties: payload.income_penalties,
                  },
                  receivable: {
                    interest: payload.receivables_interest,
                    fees: payload.receivables_fees,
                    penalties: payload.receivables_penalties,
                  },
                  liability: {
                    overpayment: payload.overpayment,
                  },
                  expense: {
                    writeoff: payload.writeoff,
                  },
                },
              },
            },
            meta_data: {},
          });
    this.loading= false
          this.showModal = false;
          await this.fetchConfigs();
          notification.notify(
            "success",
            "Configuration Added",
            `The configuration was successfully added`
          );
          resolve(true);
        } catch (err) {
          reject(err);
              this.loading= false
        }
      });
    },

    addConfig() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  async mounted() {
    await this.fetchAccountOpeningTemplate();
    await this.fetchCurrencies();
    await this.fetchConfigs();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.config-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}
</style>
