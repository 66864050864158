<template>
  <div
    class="modal fade"
    id="accountOpeningConfigModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel d-block">
            {{ title }}
          </h5>
          <button
            @click="close()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submit()">
          <div class="modal-body">
            <div class="container-fluid">
              <h5 class="card-title">
                {{ subTitle }}
              </h5>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="currency">Currency</label>
                    <select
                      name="currency"
                      id="currency"
                      v-model="form.currency"
                      class="custom-select"
                    >
                      <option
                        v-for="currency in currencies"
                        :value="currency.symbol"
                        :key="currency.currency_code"
                      >
                        {{ currency.name }}
                      </option>
                    </select>
                    <small class="text-danger" v-if="errors.has('currency')">{{
                      errors.first("currency")
                    }}</small>
                  </div>
                </div>

                <div class="col-md-6">
                  <h6 class="mb-3 border-bottom pb-2">Income</h6>

                  <div class="form-group">
                    <label for="income_interest">Interest</label>
                    <select
                      name="income_interest"
                      id="income_interest"
                      v-model="form.income_interest"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('income_interest')"
                      >{{ errors.first("income_interest") }}</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="income_fees">Fees</label>
                    <select
                      name="income_fees"
                      id="income_fees"
                      v-model="form.income_fees"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('income_fees')"
                      >{{ errors.first("income_fees") }}</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="income_penalties">Penalties</label>
                    <select
                      name="income_penalties"
                      id="income_penalties"
                      v-model="form.income_penalties"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('income_penalties')"
                      >{{ errors.first("income_penalties") }}</small
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="mb-3 border-bottom pb-2">Receivables</h6>

                  <div class="form-group">
                    <label for="receivables_interest">Interest</label>
                    <select
                      name="receivables_interest"
                      id="receivables_interest"
                      v-model="form.receivables_interest"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('receivables_interest')"
                      >{{ errors.first("receivables_interest") }}</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="receivables_fees">Fees</label>
                    <select
                      name="receivables_fees"
                      id="receivables_fees"
                      v-model="form.receivables_fees"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('receivables_fees')"
                      >{{ errors.first("receivables_fees") }}</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="receivables_penalties">Penalties</label>
                    <select
                      name="receivables_penalties"
                      id="receivables_penalties"
                      v-model="form.receivables_penalties"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('receivables_penalties')"
                      >{{ errors.first("receivables_penalties") }}</small
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="mb-3 border-bottom pb-2">Assets</h6>

                  <div class="form-group">
                    <label for="loan_portfolio">Loan Portfolio</label>
                    <select
                      name="loan_portfolio"
                      id="loan_portfolio"
                      v-model="form.loan_portfolio"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <div
                      class="text-danger"
                      v-if="errors.has('loan_portfolio')"
                    >
                      {{ errors.first("loan_portfolio") }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="source_fund">Cash Source Fund</label>
                    <select
                      name="source_fund"
                      id="source_fund"
                      v-model="form.source_fund"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <div class="text-danger" v-if="errors.has('source_fund')">
                      {{ errors.first("source_fund") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="mb-3 border-bottom pb-2">Liability and Expense</h6>

                  <div class="form-group">
                    <label for="overpayment">OverPayment</label>
                    <select
                      name="overpayment"
                      id="overpayment"
                      v-model="form.overpayment"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="errors.has('overpayment')"
                      >{{ errors.first("overpayment") }}</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="write-off">Write-off</label>
                    <select
                      name="write-off"
                      id="write-off"
                      v-model="form.writeoff"
                      class="custom-select"
                    >
                      <option
                        v-for="template in templates"
                        :value="template.template_name"
                        :key="template.template_code"
                      >
                        {{ template.template_name }}
                      </option>
                    </select>
                    <small class="text-danger" v-if="errors.has('write-off')">{{
                      errors.first("write-off")
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="loading">
              <div class="lds-ring mr-2" v-show="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              Save
            </button>
            <button
              @click="close()"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {},
  props: {
    showAccountOpeningConfigModal: {
      default: false,
    },
    title: {
      default: "",
    },
    subTitle: {
      default: "",
    },
    loading: {
      default: false,
    },
    templates: {
      default: [],
    },
    currencies: {
      default: [],
    },
  },
  watch: {
    showAccountOpeningConfigModal: function (to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $("#accountOpeningConfigModal").modal(action);
    },
  },
  data() {
    return {
      form: {
        currency: "",
        income_interest: "",
        income_fees: "",
        income_penalties: "",
        receivables_penalties: "",
        receivables_fees: "",
        receivables_interest: "",
        writeoff: "",
        overpayment: "",
        source_fund: "",
        loan_portfolio: "",
      },
    };
  },
  methods: {
    async submit() {
      try {
        let valid = await this.$validator.validate();
        if (!valid) {
          return " Validation Error ";
        } else {
          this.$emit("account-opening-modal-submit", { payload: this.form });
        }
      } catch (err) {
        console.log("error", err);
      }
    },

    close() {
      (this.form.currency = ""),
        (this.form.income_interest = ""),
        (this.form.income_fees = ""),
        (this.form.income_penalties = ""),
        (this.form.receivables_penalties = ""),
        (this.form.receivables_fees = ""),
        (this.form.receivables_interest = ""),
        (this.form.writeoff = "");
      this.form.overpayment = "";
      this.form.source_fund = "";
      this.form.loan_portfolio = "";
      Event.$emit("accountOpeningConfigModal:close", {});
    },
    close() {
      this.$emit("account-opening-modal-close", {});
    },
  },
};
</script>

<style>
.option-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 350px;
  height: 100px;
  position: relative;
}
.option {
  flex-grow: 1;
  display: block;
  padding: 0.5em;
  background: #f5f6fa;
  border-radius: 0.25em;
  font-size: 3rem;
  position: relative;
  margin: 0.1em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.8s, 0.6s;
  transition-property: flex-grow, opacity;
  transition-timing-function: cubic-bezier(0.98, 0, 0.22, 0.98), ease-in-out;
}
.option:before,
.option:after {
  content: "";
  display: block;
  border: solid 2px #6f85ff;
  visibility: visible;
}
.option:before {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border-radius: 50%;
  width: 0.25em;
  height: 0.25em;
}
.option:after {
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0.2);
  transition: inherit;
  transition-property: transform, opacity;
}
.option-input {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
}
.option__label {
  display: inline-block;
  font-size: 0.4em;
  font-weight: bold;
  transform-origin: left bottom;
  transform: scale(0.7);
  transition: inherit;
  transition-property: transform, opacity;
}

.option-input:checked + .option {
  flex-grow: 4;
  opacity: 1;
}
.option-input:checked + .option::after {
  transform: scaleX(1);
}
.option-input:checked + .option::before {
  background: #6f85ff;
}
.option-input:checked + .option .option__label {
  transform: scale(1);
}
</style>
