<template>
  <div class="add-Charge">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Transaction Charge</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new transaction charges</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="Charge.wizard.pages"
          :action="Charge.wizard.action"
          :buttonText="Charge.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.Charge = Models.Charge;
  },
  data() {
    return {
      Charge: {},
      charges: [],
    };
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>