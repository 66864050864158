<template lang="html">
   <div class="claimtype">
          <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Claim Type  Details for   : {{claimType.claim_type_name}} </h6>
                <nav class="nav nav-icon-only">
                  <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="media align-items-center mg-b-20">
                  <div class="media-body pd-l-15">
                    <h6 class="mg-b-3">Code :{{claimType.claim_type_code}}</h6>
                    <span class="d-block tx-13 tx-color-03">Description : {{claimType.claim_type_description}}</span>
                    <span class="d-block tx-13 tx-color-03">Status : {{claimType.status}}</span>
                    
                  </div>
                  <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">Created Date : {{claimType.created_date}}</span>
                </div><!-- media -->
    
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                  <a href="" class="nav-link"><i data-feather="edit"></i> Edit</a>
                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->


           <div class="row">
                <div class="col-sm">
                   <button type="button" class="btn btn-outline-dark pull-right" @click="attachClaimForm()">Attach Claim Form</button>
                    <h4 id="section1" class="mg-b-10">Claim Form</h4>
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                 <th scope="col">Description</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in claimForms">
                                <th scope="row">{{ index }}</th>
                                <td>{{item.field_code}}</td>
                                <td>{{item.field_name}}</td>
                                <td>{{item.field_description}}</td>
                                <td><button type="button" class="btn btn-outline-danger" @click="removeClaimForm(item)">Remove</button></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
        </div>

             <ModalForm :showModal="showModal" >
              <template slot="form"  >
                      <div class="custom-control custom-checkbox form-group">
					        <li  v-for="field in formFields">
                        <input
						               type="checkbox"
                           class="custom-control-input"
                           :id="field.field_code" 
						               :value="field.field_code"
						               v-model="selectClaimFields">    
                        <label  :for="field.field_code" class="custom-control-label label-checkbox" >{{field.field_name}}</label>
					        	</li>
                     </div>
					   <div class="d-none d-md-block">
							<button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="SaveClaimForm()">
							<i data-feather="mail" class="wd-10 mg-r-5"></i> Save Claim Form(s)
							</button>
				   	</div>		
            </template>
                  </ModalForm>
 </div>

</template>
<script>
export default {
	async created() {
        Event.$on("modal:close", async payload => {
          this.showModal = false;
        });

	},
	data() {
		return {
          showModal:false,
          claimType:{},
          claimForms:[],
          formFields:[],
          selectClaimFields:[]
        }  
    },
     methods: {
         attachClaimForm () {
          this.showModal=true;
         },
        async SaveClaimForm () {
           try
              {
              let res = await req.callServerSecure({
                resource: "claim_type",
                action: "add_field",
                data: {
                  claim_type_code:  this.$route.params.id,
                  field_codes : this.selectClaimFields
                },
                meta_data: {}
              });

              await this.claimTypeSummary()
                this.showModal=false;
            notification.notify("success","Claim Form Fields Attached ",`The Claim Form Fields(s) Successfully Attached `);
            
          }
          catch (err) {
            console.log(err)
                 notification.notify('error', 'Error Saving Claim Form   ', err)
          }
         },
        fetchFormFields() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "form_field",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.formFields = res.data.result.form_fields;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		    },
      claimTypeSummary () {
          return new Promise(async (resolve,reject) => {
             try {
                  let res = await req.callServerSecure({
                    resource: "claim_type",
                    action: "summary",
                    data: {
                           claim_type_code:  this.$route.params.id,

                      }
               });
               this.claimType=res.data.result.claim_summary;
               this.claimForms =this.claimType.claim_form;

              
             
                 resolve()
             }
             catch(err){
                notification.notify('error', 'Error Fetching Claim Type Summary  ', `Kindly Retry Later`)
                 reject(err)
             }
             }) 
         }
        },
    	async mounted() {
            await this.claimTypeSummary();
            await this.fetchFormFields()
            Event.$emit("loader:show", false);
        }
}   
</script>

<style lang="less">
.claimtype {
}
</style>
        
