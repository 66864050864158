<template>
  <div class="view-benefit">

    <ModalForm :showModal="showModal" :action="PurchaseException.updateWizard.action">
        <template slot="form">
          <CustomForm 
              :pages="PurchaseException.updateWizard.pages" 
              :action="PurchaseException.updateWizard.action" 
              :buttonText="PurchaseException.updateWizard.actionBtn" 
              :showTitle="false">
          </CustomForm>
        </template>
    </ModalForm>

    <ModalForm :showModal="showVerifyModal" :action="PurchaseException.verifyWizard.action">
        <template slot="form">
          <CustomForm 
              :pages="PurchaseException.verifyWizard.pages" 
              :action="PurchaseException.verifyWizard.action" 
              :buttonText="PurchaseException.verifyWizard.actionBtn" 
              :showTitle="false">
          </CustomForm>
        </template>
    </ModalForm>

    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Exception Rule</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Ref :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ rule.rule_system_ref || ''}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ rule.rule_name || "" }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Description :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ rule.rule_description || ''}}</h6>
              </div>
            </div>
            <!-- media -->
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Operand :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ rule.rule_operand || ''}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ rule.status || ''}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <button class="btn btn-sm" @click="editrule">
            <i data-feather="edit"></i> Edit
          </button>
          <button class="btn btn-sm mg-l-20" @click="verifyrule">
            <i data-feather="thumbs-up"></i> Verify
          </button>
          
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
  </div>
</template>

<script>
export default {
  created() {
    this.PurchaseException = Models.PurchaseException
    Event.$on('form:submit', async (payload) => {
      if(payload.action === this.PurchaseException.updateWizard.action){
          await this.updatePurchaseException(payload.formData)
          this.showModal = false
      } else if(payload.action === this.PurchaseException.verifyWizard.action){
        await this.verifyPurchaseException(payload.formData)
        this.showVerifyModal = false
      } 
    })
  },
  data() {
    return {
      rule: {},
      showModal: false,
      showVerifyModal: false,
    };
  },
  methods: {
    verifyrule() {
      this.showVerifyModal = true
    },
    editrule() {
      // let pages = this.PurchaseException.updateWizard.pages[0]
      // pages.forms[0].value = this.rule.rule_description
      // pages.forms[0].model = this.rule.rule_description
      this.showModal = true
    },

    
    updatePurchaseException(data) {

      return new Promise(async (resolve,reject) => {
          try {
            let res = await req.callServerSecure({
                resource: "rule_category",
                action: "update",
                data: {
                  rule_system_ref: this.$route.params.id,
                  data: {
                    rule_name: data.rule_name,
                    rule_category_image_path: data.rule_category_image_path,
                    rule_description: data.rule_description,
                  }
                },
                meta_data: {},
            });

            await this.fetchrule()
            notification.notify('success', 'Rule Category Updated', `The PurchaseException ${this.rule.rule_name} was successfully updated`)
            Event.$emit('form:action-complete',{action: this.PurchaseException.updateWizard.action, clear: true})
            this.showModal = false
          } catch (err) {
            Event.$emit('form:action-error',{action: this.PurchaseException.updateWizard.action})
          }
      })
    },

    verifyPurchaseException(data) {

      return new Promise(async (resolve,reject) => {
          try {
            let res = await req.callServerSecure({
                resource: "benefit_category",
                action: "verify",
                data: {
                  rule_system_ref: this.$route.params.id,
                  remarks: data.remarks,
                  audit_id: 1113
                },
                meta_data: {},
            });


            await this.fetchRule()
            notification.notify('success', 'Benefit Category Updated', `The PurchaseException ${this.benefit.rule_name} was successfully verified`)
            Event.$emit('form:action-complete',{action: this.PurchaseException.verifyWizard.action, clear: true})
            this.showVerifyModal = false
          } catch (err) {
            Event.$emit('form:action-error',{action: this.PurchaseException.verifyWizard.action})
          }
      })
    },

    fetchRule() {
        return new Promise(async (resolve, reject) => {
            try {
            let res = await req.callServerSecure({
                resource: "entity_exception",
                action: "view",
                data: {
                    rule_system_ref: this.$route.params.id,
                },
            });

            this.benefit = res.data.result.benefit_category;

            resolve();
            } catch (err) {
            console.log(err);
            reject(err);
            }
        });
    },

    getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let pages = this.PurchaseException.wizard.pages;
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  pages[0].forms[1].options = utils.appendOptions(
                      res.data.result,
                      "file_path",
                      "originalname",
                  ),
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
  },
  async mounted() {
    await this.fetchRule();
    await this.getIcons();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>