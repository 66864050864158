module.exports = {
   name: 'KYC Fields',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'add_insurace_class',
      pages: [
         {
            title: 'KYC Fields',
            subtitle: 'Configure new kyc field',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'Label', model: 'label',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Code', model: 'code',
                  validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'type',
                  options: [
                     { val: 'text', label: 'Text' },
                     { val: 'select', label: 'Select' },
                     { val: 'image', label: 'Image' },
                     { val: 'location', label: 'Location' },
                     { val: 'color', label: 'Color' },
                     { val: 'textarea', label: 'Textarea' },
                     { val: 'file', label: 'File' },
                     { val: 'date', label: 'Date' },
                     { val: 'country', label: 'Country' },
                     { val: 'mobile', label: 'Mobile' },
                     { val: 'check', label: 'Check' },
                  ], validation: 'required', label: 'Type'
               },
               {
                  type: 'text', form_type: 'text', label: 'Form Type', model: 'form_type',
                  validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Validation', model: 'validation',
                  validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'size',
                  options: [
                     { val: 'col-12', label: 'Full' },
                     { val: 'col-6', label: 'Half' },
                     { val: 'col-3', label: 'Quater' },
                     { val: 'col-4', label: 'Third' },
                     { val: 'col-2', label: 'Sixth' },
                     { val: 'col-1', label: 'Twelveth' },
                  ], validation: 'required', label: 'Size'
               },
               {
                  type: 'text', form_type: 'text', label: 'Placeholder', model: 'placeholder',
                  validation: 'min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Third Party', model: 'third_party',
                  validation: 'min:1|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'verification_type',
                  options: [
                     { val: 'AUTO', label: 'Auto' },
                     { val: 'MANUAL', label: 'Manual' },
                  ], validation: 'required', label: 'Verification Type'
               },
            ],
         }
      ]
   },
   table: {
      title: 'KYC Fields',
      subtitle: 'A list of configured kyc fields',
      table_fields: [
         { name: 'Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'Label', align: 'l' },
         { name: 'Description', align: 'l' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["code", "name", "min_account_bal", "max_account_bal"],
      rowData: [
         { type: 'text', field: 'field_code', class: 'left bolder' },
         { type: 'text', field: 'field_name', class: 'left bolder small' },
         { type: 'text', field: 'field_label', class: 'left bolder small' },
         { type: 'status', field: 'field_description', class: 'left bolder small' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
           
               {
                  type: 'menu', title: 'Remove', event: 'client:remove', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}