<template lang="html">
  <header class="navbar navbar-header navbar-header-fixed">
    <a href="javascript:void()" id="mainMenuOpen" class="burger-menu"
      ><i data-feather="menu"></i
    ></a>
    <div class="navbar-brand">
      <router-link :to="{ name: 'Dashboard' }" class="df-logo">
        <div class="f-l">Nexus</div>
        <div class="f-l tx-primary smaller">Admin</div>
      </router-link>
    </div>
    <!-- navbar-brand -->
    <div id="navbarMenu" class="navbar-menu-wrapper">
      <div class="navbar-menu-header">
        <a href="#" class="df-logo">
          <div class="f-l">Nexus</div>
          <div class="f-l tx-primary smaller">Admin</div>
        </a>
        <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
      </div>
      <!-- navbar-menu-header -->
      <ul class="nav navbar-menu">
        <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
        <li class="nav-item">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            Dashboard
          </router-link>
        </li>

        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Nexus</span
            >
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">Charges</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Services-Chargable-Services' }"
                    >
                      <i data-feather="log-in"></i> All Chargable Services
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Services-Configured-Services' }"
                    >
                      <i data-feather="user-plus"></i> Configured Services
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li class="nav-label">Notifications</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Notifications-Config' }"
                    >
                      <i data-feather="log-in"></i> Notification Events
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entity-Notifications-Configuration' }"
                    >
                      <i data-feather="log-in"></i> Entity Notifications
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Notifications-Queue' }"
                    >
                      <i data-feather="log-in"></i> Queue
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li class="nav-label">SLA</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Notifications-Config' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Notifications-Configuration' }"
                    >
                      <i data-feather="log-in"></i> Configure
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li class="nav-label">Legal</li>
                  <li class="nav-sub-item">
                    <router-link class="nav-sub-link" :to="{ name: 'TnC' }">
                      <i data-feather="log-in"></i> Terms & Conditions
                    </router-link>
                  </li>
                  <!-- <li class="nav-sub-item">
										<router-link class="nav-sub-link" :to="{name: 'Notifications-Configuration'}">
											<i data-feather="log-in"></i> Configure
										</router-link>
									</li> -->
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Entities</span
            >
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">Users</li>
                  <li class="nav-sub-item mb-2">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-UsersList' }"
                    >
                      <i data-feather="log-in"></i> List Users
                    </router-link>
                  </li>

                  <li class="nav-label">Clients</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'entity.client.addConfig' }"
                    >
                      <i data-feather="user-plus"></i> Configurations
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> Summary
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="shield-off"></i> KYC Verification Pending
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Service Providers</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'serviceProviderConfig' }"
                    >
                      <i data-feather="user-plus"></i>Configuration
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> Summary
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'serviceCategories' }"
                    >
                      <i data-feather="user-plus"></i>Categories
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Underwritters</li>
				  <li class="nav-sub-item">
				    <router-link
				      class="nav-sub-link"
				      :to="{ name: 'entity.underwriter.add' }"
				    >
				      <i data-feather="user-plus"></i> Add Underwriter
				    </router-link>
				  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'entity.underwriter.list' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'entity.underwriter.addConfig' }"
                    >
                      <i data-feather="user-plus"></i> Configurations
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> Summary
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-UnderwritterGroup' }"
                    >
                      <i data-feather="user"></i> Underwritter Groups
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ListCoverAreas' }"
                    >
                      <i data-feather="user"></i> Cover Areas
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Config-BenefitCategory-List' }"
                    >
                      <i data-feather="user"></i> Benefit Category
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Banks</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'entity.bank.list' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-BankConfig' }"
                    >
                      <i data-feather="user-plus"></i> Configuarations
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> Summary
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="shield-off"></i> KYC Verification Pending
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Intermediaries</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'IntermediaryList' }"
                    >
                      <i data-feather="log-in"></i> List
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'AddIntermediaryConfig' }"
                    >
                      <i data-feather="user-plus"></i> Configuration
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> Summary
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="shield-off"></i> KYC Verification Pending
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Products</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-ProductConfig' }"
                    >
                      <i data-feather="user-plus"></i> Configurations
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-PurchaceException' }"
                    >
                      <i data-feather="user-plus"></i> Purchace Exception
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li class="nav-label">Schools</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-SchoolsConfigure' }"
                    >
                      <i data-feather="log-in"></i> School Configuarations
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-SchoolsList' }"
                    >
                      <i data-feather="log-in"></i> Nexus Schools
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-SchoolsReleaseQueue' }"
                    >
                      <i data-feather="log-in"></i> Release Queue
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-ParentsList' }"
                    >
                      <i data-feather="log-in"></i> Parents
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-AccountReportSummary' }"
                    >
                      <i data-feather="log-in"></i> Account Report Summary
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-SchoolJobsList' }"
                    >
                      <i data-feather="log-in"></i> School Jobs
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li class="nav-label">Blacklist</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Entities-BlacklistConfig' }"
                    >
                      <i data-feather="shield"></i> List
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Uploads</span
            >
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">CRSP</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'CrspMakeUploads' }"
                    >
                      <i data-feather="file-text"></i> Make
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'CrspModelUploads' }"
                    >
                      <i data-feather="file-text"></i> Model
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">SIC</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'sic_industryUploads' }"
                    >
                      <i data-feather="file-text"></i> Industry
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'sic_jobsUploads' }"
                    >
                      <i data-feather="file-text"></i> Jobs
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">Postal Code</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'postalcodesUploads' }"
                    >
                      <i data-feather="file-text"></i> Postal Code
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">Banks</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'bankUploads' }"
                    >
                      <i data-feather="file-text"></i> Banks
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Insurance</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'underwriterUploads' }"
                    >
                      <i data-feather="file-text"></i> Underwriters
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Configs</span
            >
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">System</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'listCountry' }"
                    >
                      <i data-feather="file-text"></i> Countries
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-icons' }"
                    >
                      <i data-feather="file-text"></i> System Icons
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-upload' }"
                    >
                      <i data-feather="file-text"></i> Uploads
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-System-formFields' }"
                    >
                      <i data-feather="file-text"></i> Form Fields
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-recomendation' }"
                    >
                      <i data-feather="user-plus"></i> Reccomendations
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-Assessment' }"
                    >
                      <i data-feather="user-plus"></i> Assessment Questions
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">Client</li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-ClientType' }"
                    >
                      <i data-feather="calendar"></i> Client Type
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-ClientSubType' }"
                    >
                      <i data-feather="calendar"></i> Client Sub-Type
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">Insurance</li>

                  <!--	<li class="nav-sub-item">
										<router-link class="nav-sub-link" :to="{name: 'Configs-Insurance-InsuranceClass'}">
											<i data-feather="users"></i> Classes
										</router-link>
									</li>  -->

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-InsuranceSubClass' }"
                    >
                      <i data-feather="users"></i> Classes
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-CoverTypes' }"
                    >
                      <i data-feather="users"></i> Cover Types
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-ClaimtypeClass' }"
                    >
                      <i data-feather="users"></i> Claim Types
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-ClaimCategories' }"
                    >
                      <i data-feather="users"></i> Claim Categories
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-ProductCharges' }"
                    >
                      <i data-feather="user-plus"></i> Product Charges
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-Templates' }"
                    >
                      <i data-feather="user-plus"></i> Templates
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Insurance-Services' }"
                    >
                      <i data-feather="user-plus"></i> Services
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">Transactions</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Transactions-TransactionConfig' }"
                    >
                      <i data-feather="user-plus"></i> Transaction Config
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Transactions-Channels' }"
                    >
                      <i data-feather="user-plus"></i> Channels
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Transactions-Limits' }"
                    >
                      <i data-feather="user-plus"></i> Limits
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Transactions-Charges' }"
                    >
                      <i data-feather="user-plus"></i> Charges
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Transactions-Topups' }"
                    >
                      <i data-feather="user-plus"></i> Top Ups
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Accounting</li>
          
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.gl.listGl' }"
                    >
                      <i data-feather="file"></i> GL Configs
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.subgl.listSubGl' }"
                    >
                      <i data-feather="file"></i> SubLedgers Configs
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.currency.ListCurrency' }"
                    >
                      <i data-feather="file"></i> Currency
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-Statement' }"
                    >
                      <i data-feather="file"></i> Account Statements
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.accountstatus.listStatus' }"
                    >
                      <i data-feather="file"></i> Account Status
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-AccountLimit' }"
                    >
                      <i data-feather="file"></i> Account Limits
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.actopntemplate.list' }"
                    >
                      <i data-feather="file"></i> Account Opening Template
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-Accounts' }"
                    >
                      <i data-feather="file"></i> Accounts
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'accounting.system.listSystemAccounts' }"
                    >
                      <i data-feather="file"></i> System Accounts
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-Tax-List' }"
                    >
                      <i data-feather="file"></i> Tax
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-TaxCategories' }"
                    >
                      <i data-feather="file"></i> Tax Categories
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-Withdrawal' }"
                    >
                      <i data-feather="file"></i> Withdrawal
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Accounting-Settlement' }"
                    >
                      <i data-feather="file"></i> Settlement
                    </router-link>
                  </li>

                  <li class="nav-label mg-t-20">System</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Fees' }"
                    >
                      <i data-feather="file"></i> Fees
                    </router-link>
                  </li>

                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Details
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Monitoring
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Performance
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Logs
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Report Configs
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Others</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user"></i> Advertisements
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user"></i> Notifications
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="calendar"></i> Terms &amp; Conditions
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="calendar"></i> Country
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Calendar</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Configs-Calendar-Holidays' }"
                    >
                      <i data-feather="calendar"></i> Holidays
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Loan Config</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'LoanProductAccountOpening' }"
                    >
                      <i data-feather="file"></i> Loan Product
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Usermanagement
            </span>
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">Roles</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_roles' }"
                    >
                      <i data-feather="shield"></i> Roles
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <hr />
                  </li>
                  <li class="nav-label">Departments</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_departments' }"
                    >
                      <i data-feather="shield"></i> Departments
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <hr />
                  </li>
                  <li class="nav-label">Resources</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_resources' }"
                    >
                      <i data-feather="shield"></i> System Resources
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Users</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_user_groups' }"
                    >
                      <i data-feather="users"></i>User Group
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_chat_users' }"
                    >
                      <i data-feather="users"></i>Chat User Group
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_users' }"
                    >
                      <i data-feather="users"></i>Admin Users
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_users' }"
                    >
                      <i data-feather="users"></i>All System Users
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <hr />
                  </li>
                  <li class="nav-label">Security</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'list_security_context' }"
                    >
                      <i data-feather="shield"></i> Security Context
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item with-sub">
          <div class="dropdown">
            <span
              class="nav-link pointer"
              data-toggle="dropdown"
              data-display="static"
              ><i data-feather="layers"></i> Reports</span
            >
            <div class="dropdown-menu navbar-menu-sub">
              <div class="d-lg-flex">
                <ul>
                  <li class="nav-label">Queues</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="log-in"></i> IPRS
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-plus"></i> NTSA
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user-check"></i> M-Pesa
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="shield-off"></i> Ministry of Lands
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Reports-Queues-NotificationQueues' }"
                    >
                      <i data-feather="shield-off"></i> Notification Queue
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'Reports-Queues-PaymentQueues' }"
                    >
                      <i data-feather="shield-off"></i> Payment Queue
                    </router-link>
                  </li>
                  <li class="nav-label mg-t-20">Others</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="user"></i> Subscribers
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="users"></i> Notifications
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="users"></i> Blogs
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li class="nav-label">Summaries</li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file"></i> Users
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file"></i> Advertisements
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file"></i> Income
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file"></i> Tax
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Charges
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Performance
                    </router-link>
                  </li>
                  <li class="nav-sub-item">
                    <router-link
                      class="nav-sub-link"
                      :to="{ name: 'ClientList' }"
                    >
                      <i data-feather="file-text"></i> Growth
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="navbar-right">
      <span id="navbarSearch" class="search-link pointer"
        ><i data-feather="search"></i
      ></span>
      <div class="dropdown dropdown-notification">
        <a href="" class="dropdown-link new-indicator" data-toggle="dropdown">
          <i data-feather="bell"></i>
          <span>2</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <div class="dropdown-header">Notifications</div>
          <a href="" class="dropdown-item">
            <div class="media">
              <div class="avatar avatar-sm avatar-online">
                <img
                  src="https://via.placeholder.com/350"
                  class="rounded-circle"
                  alt=""
                />
              </div>
              <div class="media-body mg-l-15">
                <p>
                  Congratulate <strong>Socrates Itumay</strong> for work
                  anniversaries
                </p>
                <span>Mar 15 12:32pm</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
          </a>
          <a href="" class="dropdown-item">
            <div class="media">
              <div class="avatar avatar-sm avatar-online">
                <img
                  src="https://via.placeholder.com/500"
                  class="rounded-circle"
                  alt=""
                />
              </div>
              <div class="media-body mg-l-15">
                <p><strong>Joyce Chua</strong> just created a new blog post</p>
                <span>Mar 13 04:16am</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
          </a>
          <a href="" class="dropdown-item">
            <div class="media">
              <div class="avatar avatar-sm avatar-online">
                <img
                  src="https://via.placeholder.com/600"
                  class="rounded-circle"
                  alt=""
                />
              </div>
              <div class="media-body mg-l-15">
                <p>
                  <strong>Althea Cabardo</strong> just created a new blog post
                </p>
                <span>Mar 13 02:56am</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
          </a>
          <a href="" class="dropdown-item">
            <div class="media">
              <div class="avatar avatar-sm avatar-online">
                <img
                  src="https://via.placeholder.com/500"
                  class="rounded-circle"
                  alt=""
                />
              </div>
              <div class="media-body mg-l-15">
                <p>
                  <strong>Adrian Monino</strong> added new comment on your photo
                </p>
                <span>Mar 12 10:40pm</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
          </a>
          <div class="dropdown-footer">
            <a href="">View all Notifications</a>
          </div>
        </div>
      </div>

      <div class="dropdown dropdown-profile">
        <span
          class="dropdown-link"
          data-toggle="dropdown"
          data-display="static"
        >
          <div class="avatar avatar-sm">
            <img
              src="https://via.placeholder.com/500"
              class="rounded-circle"
              alt=""
            />
          </div>
        </span>
        <div class="dropdown-menu dropdown-menu-right tx-13">
          <div class="avatar avatar-lg mg-b-15">
            <img
              src="https://via.placeholder.com/500"
              class="rounded-circle"
              alt=""
            />
          </div>
          <h6 class="tx-semibold mg-b-5">{{ details.names }}</h6>
          <p class="mg-b-25 tx-12 tx-color-03">{{ details.user_type }}</p>

          <a href="" class="dropdown-item"
            ><i data-feather="edit-3"></i> Edit Profile</a
          >
          <a href="page-profile-view.html" class="dropdown-item"
            ><i data-feather="user"></i> View Profile</a
          >
          <div class="dropdown-divider"></div>
          <a href="page-help-center.html" class="dropdown-item"
            ><i data-feather="help-circle"></i> Help Center</a
          >
          <a href="" class="dropdown-item"
            ><i data-feather="life-buoy"></i> Forum</a
          >
          <a href="" class="dropdown-item"
            ><i data-feather="settings"></i>Account Settings</a
          >
          <a href="" class="dropdown-item"
            ><i data-feather="settings"></i>Privacy Settings</a
          >
          <a href="" class="dropdown-item" @click.prevent="logout()"
            ><i data-feather="log-out"></i>Sign Out</a
          >
          <!-- <router-link class="dropdown-item" :to="{name: 'LogIn'}">
						<i data-feather="log-out"></i>Sign Out
					</router-link> -->
        </div>
      </div>
    </div>

    <div class="navbar-search">
      <div class="navbar-search-header">
        <input
          type="search"
          class="form-control"
          placeholder="Type and hit enter to search..."
        />
        <button class="btn"><i data-feather="search"></i></button>
        <a id="navbarSearchClose" href="" class="link-03 mg-l-5 mg-lg-l-10"
          ><i data-feather="x"></i
        ></a>
      </div>

      <div class="navbar-search-body">
        <label
          class="tx-10 tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-10 d-flex align-items-center"
          >Recent Searches</label
        >
        <ul class="list-unstyled">
          <li><a href="dashboard-one.html">modern dashboard</a></li>
          <li><a href="app-calendar.html">calendar app</a></li>
          <li><a href="../../collections/modal.html">modal examples</a></li>
          <li><a href="../../components/el-avatar.html">avatar</a></li>
        </ul>

        <hr class="mg-y-30 bd-0" />

        <label
          class="tx-10 tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-10 d-flex align-items-center"
          >Search Suggestions</label
        >

        <ul class="list-unstyled">
          <li><a href="dashboard-one.html">cryptocurrency</a></li>
          <li><a href="app-calendar.html">button groups</a></li>
          <li><a href="../../collections/modal.html">form elements</a></li>
          <li><a href="../../components/el-avatar.html">contact app</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  created() {
    this.details = $session.get("user");
  },
  data() {
    return {
      details: {},
    };
  },
  methods: {
    logout() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "entity_logout",
            action: "logout",
            data: {},
            meta_data: {},
          });

          this.$router.push({
            name: "LogIn",
            params: {},
          });
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
  },
};
</script>

<style lang="less"></style>
