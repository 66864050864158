<template lang="html">
 
    <div class="account-types">
        <ModalForm :showModal="showAddModal" :action="Insurance.setup.action">
            <template >
                <CustomForm 
                    :pages="Insurance.setup.pages" 
                    :action="Insurance.setup.action" 
                    :buttonText="Insurance.setup.actionBtn" 
                    showTitle="false">
                </CustomForm>
            </template>
        </ModalForm>

        <ModalForm :showModal="showAddGroup" :action="Insurance.wizard.group.action">
            <template >
                <CustomForm 
                    :pages="Insurance.wizard.group.pages" 
                    :action="Insurance.wizard.group.action" 
                    :buttonText="Insurance.wizard.group.actionBtn" 
                    showTitle="false">
                </CustomForm>
            </template>
        </ModalForm>

        <ModalForm :showModal="showAddSuperUser" :action="Insurance.wizard.super_user.action">
            <template >
                <CustomForm 
                    :pages="Insurance.wizard.super_user.pages" 
                    :action="Insurance.wizard.super_user.action" 
                    :buttonText="Insurance.wizard.super_user.actionBtn" 
                    showTitle="false">
                </CustomForm>
            </template>
        </ModalForm>

        <ModalForm :showModal="showUploadUnderwritters" :action="Insurance.wizard.upload_underwritters.action">
            <template >
                     <div class="custom-file">
                                 <input type="file" class="custom-file-input" id="fileSelector" name="files[]" @change="onFileSelected" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                                 <label class="custom-file-label" for="customFile">Upload File</label>
                              </div>
                                <figure class="pos-relative col-md-4" v-if="showImage" >
                                   <p>{{form.placeholder}}</p>            
                                </figure>

                     <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"  @click="saveUnderwriterUpload()">
                            <i data-feather="file" class="wd-10 mg-r-5"></i> Save Upload
                     </button>
                         

            </template>
        </ModalForm>
        
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Insurance List</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Pending Underwritters</h4>
            </div>
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5" @click="addUnderwritter()">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Add Underwritter
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"  @click="uploadUnderwritters()">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Upload Underwritters
                </button>
            </div>
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
                <CustomTable 
                    :icon="Insurance.table.icon"
                    :title="Insurance.table.title"
                    :subtitle="Insurance.table.subtitle"
                    :tableFields="Insurance.table.table_fields" 
                    :tableData="underwritters" 
                    :searchFields="Insurance.table.searchFields"
                    :rowData="Insurance.table.rowData">
                </CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	created() {
        this.Insurance = Models.Insurance
            
        Event.$on('modal:close',async (payload) => {
            if(payload.action === 'insurance'){this.showAddModal = false}
            if(payload.action === 'upload_underwritters'){this.showUploadUnderwritters = false}
            if(payload.action === 'underwritter_group'){this.showAddGroup = false}
            if(payload.action === 'underwritter_super_user'){this.showAddSuperUser = false}
        })

        Event.$on('form:submit',async (payload) => {
            if(payload.action === this.Insurance.setup.action){
                await this.saveUnderwritter(payload.formData)
            }else if(payload.action === this.Insurance.wizard.upload_underwritters.action){
                await this.saveUploadedUnderwritter(payload.formData)
            }else if(payload.action === this.Insurance.wizard.group.action){
                await this.addUnderwritterToGroup(payload.formData)
            }else if(payload.action === this.Insurance.wizard.super_user.action){
                await this.addUnderwritterSuperUser(payload.formData)
            }
        })

        Event.$on('underwritter:add_group',(payload) => {
            this.selectedUnderwritter = payload.underwritter
            this.showAddGroup = true
        })
        
        Event.$on('underwritter:add_super_user',(payload) => {
            this.selectedUnderwritter = payload.underwritter
            console.log(" Showing adding super user")
            this.showAddSuperUser = true
        })

        Event.$on('underwritter:view',(payload) => {
           
            this.$router.push({name: 'Entities-Underwritters-ViewUnderwritter',params: {id: payload.code.underwitter_id}})
        })
	},
	data() {
		return {
            selectedUnderwritter: {},
            created: false,
			Insurance: {},
            underwritters: [],
            group_options: [],
			showAddSuperUser: false,
			showAddGroup: false,
			showAddModal: false,
			showUploadUnderwritters: false,
                  file:'',
          showImage:false,
           form: {
                placeholder: '',
                model: '',
                url:''
            },
		};
	},
	methods: {
       async saveUnderwriterUpload () {
           try {
            	Event.$emit("loader:show", true);
                await this.uploadDoc();
                await this.getUnderwritters()
                this.showUploadUnderwritters = false
                Event.$emit("loader:show", false);
                notification.notify('success', 'Underwritters Uploaded', `Underwritters have been successfully uploaded`)
           }
           catch (err) {
                 notification.notify('error', 'Underwritter Upload Error ', `The upload was unsuccessfully Kindly retry later `)
	             Event.$emit("loader:show", false);
           }

        },
        uploadDoc () {
               return new Promise(async (resolve,reject) => {
                  try {
                        var formDataToUpload = new FormData();
                        formDataToUpload.append("file", this.file);   
                        formDataToUpload.append("file_type", "underwriter_upload");                                    
                        let respData=await req.callServerMultipart('/api/upload/v1/admin/underwriter',formDataToUpload);
                        return resolve(respData)
                        }
                        catch (err) {
                            return reject(err)
                        }
            })
      },
    onFileSelected(event){
              Array.from(event.target.files).forEach(file => {
                this.file=file
                 this.form.url=URL.createObjectURL(file);
                  this.form.placeholder = file.name
                  this.showImage=true;  
              });
      },
        addUnderwritterToGroup(formData){
            return new Promise(async (resolve,reject) => {
                try {
                    if(formData.group_code === ''){
                        formData.group_code = undefined
                    }

                    let res = await req.callServerSecure({
                        resource: "underwritter",
                        action: "configure",
                        data: {
                            group_code: formData.group_code,
                            underwritter_code: this.selectedUnderwritter.code,
                            remarks: formData.remarks
                        },
                        meta_data: {},
                    });

                    notification.notify('success', 'Underwritter Configured', `${this.selectedUnderwritter.name} has been successfully configured`)
                    await this.getUnderwritters()
                    Event.$emit('form:action-complete',{action: this.Insurance.wizard.group.action, clear: true})
                    this.showAddGroup = false
                    resolve(true)
                }catch (err) {
                     Event.$emit('form:action-error',{action: this.Insurance.wizard.group.action})
                    console.log(err)
                    reject(err)
                }
            });
        },
        addUnderwritterSuperUser(formData){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "underwritter_employee",
                        action: "add",
                        data: {
                            underwritter_code: this.selectedUnderwritter.code,
                            email: formData.email,
                            employee_ref: formData.employee_ref,
                            names: formData.first_name+' '+formData.last_name,
                        },
                        meta_data: {},
                    });

                    notification.notify('success', 'Super User Created', `${formData.email} has been successfully added`)
                    await this.getUnderwritters()
                    Event.$emit('form:action-complete',{action: this.Insurance.wizard.super_user.action, clear: true})
                    this.showAddSuperUser = false
                    resolve(true)
                }catch (err) {
                     Event.$emit('form:action-error',{action: this.Insurance.wizard.super_user.action})
                    console.log(err)
                    reject(err)
                }
            });
        },
        fetchGroups(){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "underwritter_group",
                        action: "list",
                        data: {},
                        meta_data: {},
                    });

                    let pages = this.Insurance.wizard.group.pages
                    let groups = res.data.result.underwritter_groups
                    this.group_options = []
                    for (let i = 0; i < groups.length; i++) {
                        const group = groups[i];

                        pages[0].forms[0].options.push({val: group.group_code, label: group.names})
                    }

                    this.Insurance.wizard.group.pages = pages
                    resolve(true)
                }catch (err) {
                    console.log(err)
                    reject(err)
                }
            });
        },
        getUnderwritters(){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "underwritter",
                        action: "list",
                        data: {},
                        meta_data: {},
                    });

                    this.underwritters = res.data.result.underwritters
                    resolve(true)
                }catch (err) {
                    console.log(err)
                    reject(err)
                }
            });
        },
        saveUploadedUnderwritter(formData){
            return new Promise(async (resolve,reject) => {
                try {
                  
                    let res = await req.callServerMultipart('upload/v1/admin/underwriter',formData.selected_file);

                    console.log(formData)
                    notification.notify('success', 'Underwritters Uploaded', `Underwritters have been successfully uploaded`)
                    await this.getUnderwritters()
                    Event.$emit('form:action-complete',{action: this.Insurance.wizard.upload_underwritters.action, clear: true})
                    this.showUploadUnderwritters = false
                }catch (err) {
                    Event.$emit('form:action-error',{action: this.Insurance.wizard.upload_underwritters.action})
                    reject(err)
                }
            });
        },
		addUnderwritter () {
			this.showAddModal = true
        },
		uploadUnderwritters () {
			this.showUploadUnderwritters = true
        },
        saveUnderwritter(formData){
            return new Promise(async (resolve,reject) => {
                try {
                    let subclasses = this.getSubclasses(formData)
                    let underwritter = await req.callServerSecure({
                        resource: "underwritter",
                        action: "add",
                        data: {
                            code: formData.insurer_code,
                            ira_code: formData.ira_code,
                            name: formData.insurer_name,
                            classes: subclasses
                        },
                        meta_data: {},
                    });

                    let res = await req.callServerSecure({
                        resource: "underwritter_employee",
                        action: "add",
                        data: {
                            underwritter_code: formData.insurer_code,
                            email: formData.email,
                            employee_ref: formData.employee_ref,
                            names: formData.first_name+' '+formData.last_name,
                        },
                        meta_data: {},
                    });

                    notification.notify('success', 'Underwritter Created', `${formData.insurer_name} has been successfully saved`)
                    await this.getUnderwritters()
                    Event.$emit('form:action-complete',{action: this.Insurance.setup.action, clear: true})
                    this.showAddModal = false
                }catch (err) {
                    Event.$emit('form:action-error',{action: this.Insurance.setup.action})
                    reject(err)
                }
            });
        },
        getSubclasses(data){
            let sub_classes = []
            Object.keys(data).forEach(function(key) {
                if(data[key] === true){
                    sub_classes.push(key)
                }
            })

            return sub_classes
        },
        fetchIsuranceClasses(){
            return new Promise(async (resolve,reject) => {
                try {
                    let res = await req.callServerSecure({
                        resource: "insurance_class",
                        action: "details",
                        data: {},
                        meta_data: {},
                    });
                    
                    let classes = res.data.result.class_sub_classes
                    let pages = this.Insurance.setup.pages
                    for (let i = 0; i < classes.length; i++) {
                        const _class = classes[i];
                        pages[0].forms.push({
                            type: 'label', class: 'col-12', label: _class.name, model: 'label'
                        })

                        for (let j = 0; j < _class.sub_classes.length; j++) {
                            const sub_class = _class.sub_classes[j];

                            pages[0].forms.push({
                                type: 'check', class: 'col-12 col-md-6 col-lg-4', model: sub_class.code,
                                label: sub_class.name
                            })
                        }
                    }

                    this.Insurance.setup.pages = pages
                    resolve(true)
                } catch (err) {
                    reject(err)
                }
            });
        }
	},
	async mounted() {
        await this.fetchGroups()

      /*  if(this.Insurance.setup.pages[0].forms.length <= 3){
            await this.fetchIsuranceClasses()
        }
        */
        await this.getUnderwritters()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.account-types row row-xs {
}
</style>