<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Intermediries</li>
                    </ol>
                </nav>
         
            </div>
       
        </div>


           <CustomTable :tableFields="IntermediaryField.table.table_fields"
				:icon="IntermediaryField.table.icon"
				:title="IntermediaryField.table.title"
				:subtitle="IntermediaryField.table.subtitle"
				:tableData="intermediaries" 
				:searchFields="IntermediaryField.table.searchFields"
				:rowData="IntermediaryField.table.rowData">
			</CustomTable>
        
 
    </div>
</template>

<script>
export default {
	created(){
          this.IntermediaryField = Models.intermediary
		Event.$on('view_intermediary:view',async (payload) => {
			console.log('Payload: ',payload)
			this.$router.push({name: 'ViewIntermediary', params: {id: payload.intermediary.intermediary_id}})
		})
	},
	data() {
			return {
			   intermediaries:[]
            }
	},
	methods: {
		Fetchintermediaries() {
		   return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "intermediary",
						action: "list",
						data: {},
						meta_data: {}
					});
               this.intermediaries = res.data.result.intermediaries
					resolve(true);
				} catch (err) {
                    alert(err)
					reject(err);
				}
			});
		}
	},
	async mounted(){
		await this.Fetchintermediaries()
		Event.$emit('loader:show', false)
	}
};
</script>

<style lang="less">
.user-list {
}
</style>