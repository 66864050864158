module.exports = {
  name: "Resource",
  table: {
    title: "Resource",
    subtitle: "Resource List",
    table_fields: [
      { name: "Code", align: "r" },
      { name: "Name", align: "c" },
      { name: "Description", align: "r" },
      { name: "Available Permissions", align: "c" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["name", "code", "description", "status"],
    rowData: [
      { type: "text", field: "resource_code", class: "small center" },
      { type: "text", field: "name", class: "small center" },
      { type: "text", field: "description", class: "small center" },
      { type: "text", field: "available_permissions", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "resource:view",
            icon: "ico-right",
            params: [{ key: "resource", field: "*" }],
          },
        ],
      },
    ],
  },
};
