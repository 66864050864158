<template>
  <div class="add-cover-area">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Cover Area</div>
            <p class="tx-13 tx-color-03 mg-b-0">Add Cover Area</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="CoverArea.wizard.pages"
          :action="CoverArea.wizard.action"
          :buttonText="CoverArea.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.CoverArea = Models.CoverArea;

    Event.$on('form:submit', async (payload) => {
      console.log(payload.formData);
      this.addCoverArea(payload.formData);
    });
  },
  data() {
    return {
      CoverArea: {},
    };
  },
  methods: {
    addCoverArea(data) {
      const lat = data.coordinates.lat;
      const lng = data.coordinates.lng;
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'cover_area',
            action: 'create',
            data: {
              area_type: data.area_type,
              cover_area_name: data.cover_area_name,
              cover_area_description: data.cover_area_description,
              cover_radius: data.cover_radius,
              coordinates: [lat, lng],
            },
          });

          this.$router.push({ name: 'ListCoverAreas' });
          notification.notify(
            'success',
            'Cover Area Added',
            `Cover Area ${data.cover_area_name} successfully added`
          );
          Event.$emit('form:action-complete', {
            action: this.CoverArea.wizard.action,
            clear: true,
          });
        } catch (err) {
          console.log(err);
          notification.notify('error', 'Cover Area Add Error', err);
        }
      });
    },
  },
  async mounted() {
    Event.$emit('loader:show', false);
  },
};
</script>

<style></style>
