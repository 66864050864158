module.exports = {
  name: "Security Context",
  table: {
    title: "Security Context",
    subtitle: "Security Context List",
    table_fields: [
      { name: "Code", align: "r" },
      { name: "Name", align: "c" },
      { name: "Description", align: "r" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["name", "security_context_code", "description"],
    rowData: [
      { type: "text", field: "security_context_code", class: "small center" },
      { type: "text", field: "name", class: "small center" },
      { type: "text", field: "description", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "securityContext:view",
            icon: "ico-right",
            params: [{ key: "SecurityContext", field: "*" }],
          },
        ],
      },
    ],
  },
};
