export default class FileStore {

   constructor() {
      window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
   }

   openFileSystem(executeFunc) {
      window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024 /*5MB*/, executeFunc, this.errorHandler);
   }

   openPersistentFileSystem(executeFunc) {
      let _this = this

      window.webkitStorageInfo.requestQuota(PERSISTENT, 5 * 1024 * 1024 /*5MB*/, function (grantedBytes) {
         window.requestFileSystem(PERSISTENT, grantedBytes, executeFunc, _this.errorHandler);
      }, function (e) {
         console.log('Error', e);
      });
   }

   readFile(name) {
      let _this = this
      return new Promise((resolve, reject) => {
         _this.openFileSystem((fs) => {
            fs.root.getFile(name, {}, function (fileEntry) {

               // Get a File object representing the file,
               // then use FileReader to read its contents.
               fileEntry.file(function (file) {
                  var reader = new FileReader();

                  reader.onloadend = function (e) {
                     resolve(this.result)
                  };

                  reader.onerror = function (e) {
                     reject(e)
                  };

                  reader.readAsText(file);
               }, (e) => {
                  _this.errorHandler(e)
                  reject(e)
               }
               );

            }, (e) => {
               _this.errorHandler(e)
               reject(e)
            }
            );
         })
      });
   }

   appendDataToFile(name, data) {
      let _this = this
      return new Promise((resolve, reject) => {
         _this.openFileSystem((fs) => {
            fs.root.getFile(name, { create: false }, function (fileEntry) {

               // Create a FileWriter object for our FileEntry (name).
               fileEntry.createWriter(function (fileWriter) {
                  fileWriter.onwriteend = function (e) {
                     resolve(e)
                  };

                  fileWriter.onerror = function (e) {
                     _this.errorHandler(e)
                     reject(e)
                  };

                  fileWriter.seek(fileWriter.length); // Start write position at EOF.

                  // Create a new Blob and write it to name.
                  var blob = new Blob([data], { type: 'text/plain' });
                  fileWriter.write(blob);
               }, (e) => {
                  _this.errorHandler(e)
                  reject(e)
               }
               );

            }, (e) => {
               _this.errorHandler(e)
               reject(e)
            }
            );
         })
      });
   }

   writeFile(name, data) {
      let _this = this
      return new Promise((resolve, reject) => {
         _this.openFileSystem((fs) => {
            fs.root.getFile(name, { create: true }, function (fileEntry) {

               // Create a FileWriter object for our FileEntry (name).
               fileEntry.createWriter(function (fileWriter) {

                  fileWriter.onwriteend = function (e) {
                     resolve(e)
                  };

                  fileWriter.onerror = function (e) {
                     _this.errorHandler(e)
                     reject(e)
                  };

                  // Create a new Blob and write it to name
                  var blob = new Blob([data], { type: 'text/plain' });

                  fileWriter.write(blob);

               }, (e) => {
                  _this.errorHandler(e)
                  reject(e)
               });

            }, (e) => {
               _this.errorHandler(e)
               reject(e)
            });
         })
      });
   }

   removeFile(name) {
      let _this = this
      return new Promise((resolve, reject) => {
         _this.openFileSystem((fs) => {
            fs.root.getFile(name, { create: false }, function (fileEntry) {

               fileEntry.remove(function () {
                  resolve(true)
               }, (e) => {
                  _this.errorHandler(e)
                  reject(e)
               });

            }, (e) => {
               _this.errorHandler(e)
               reject(e)
            });
         })
      })
   }

   createDir(rootDirEntry, folders) {
      let _this = this

      // Throw out './' or '/' and move on to prevent something like '/foo/.//bar'.
      if (folders[0] == '.' || folders[0] == '') {
         folders = folders.slice(1);
      }
      rootDirEntry.getDirectory(folders[0], { create: true }, function (dirEntry) {
         // Recursively add the new subfolder (if we still have another to create).
         if (folders.length) {
            _this.createDir(dirEntry, folders.slice(1));
         }
      }, _this.errorHandler);
   };

   createDirectory(path) {
      let _this = this
      return new Promise((resolve, reject) => {
         _this.openFileSystem((fs) => {
            _this.createDir(fs.root, path.split('/'))
            resolve(true)
         })
      })
   }

   errorHandler(e) {
      console.error('Error: ' + e);

      return e.msg
   }
}