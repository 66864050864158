module.exports = {
    name: "Role",
    table: {
        title: "Role",
        subtitle: "Roles List",
        table_fields: [
            { name: "Code", align: "r" },
            { name: "Name", align: "c" },
            { name: "Description", align: "r" },
            { name: "Actions", align: "c" },
        ],
        searchFields: ["name", "role_code", "description", "status"],
        rowData: [
            { type: "text", field: "role_code", class: "small center" },
            { type: "text", field: "name", class: "small center" },
            { type: "text", field: "description", class: "small center" },
            {
                type: "action",
                field: "name",
                class: "center",
                icon: "",
                actions: [
                    // {type: 'title', title: 'Actions'},
                    {
                        type: "menu",
                        title: "View",
                        event: "Role:view",
                        icon: "ico-right",
                        params: [{ key: "Role", field: "*" }],
                    },
                ],
            },
        ],
    },
    wizard: {
        actionBtn: "Save",
        action: "add_Role",
        pages: [{
            title: "Role",
            subtitle: "Add new Role",
            forms: [{
                    type: "text",
                    form_type: "text",
                    label: "Code",
                    model: "code",
                    validation: "required|min:2|max:50",
                    class: "col-12 col-md-12",
                },
                {
                    type: "text",
                    form_type: "text",
                    label: "Name",
                    model: "name",
                    validation: "required|min:2|max:50",
                    class: "col-12 col-md-12",
                },
                {
                    type: "textarea",
                    form_type: "textarea",
                    label: "Description",
                    model: "description",
                    validation: "required|min:20|max:500",
                    class: "col-12 col-md-12",
                },
            ],
        }, ],
    },
};