<template lang="html">
   <div class="charges">
      <!-- <ModalForm :showModal="showModal" :action="Charge.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Charge.wizard.pages" 
               :action="Charge.wizard.action" 
               :buttonText="Charge.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm> -->

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createCharge()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Charge
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Charge.table.table_fields"
         :icon="Charge.table.icon"
         :title="Charge.table.title"
         :subtitle="Charge.table.subtitle"
         :tableData="charges" 
         :searchFields="Charge.table.searchFields"
         :rowData="Charge.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Charge = Models.Charge;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Charge.wizard.action) {
        await this.saveGroup(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Charge: {},
      charges: [],
      showModal: false,
    };
  },
  methods: {
    fetchCharges() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_charges",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.charges = res.data.result.charges;
          console.log(this.charges);
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchData() {
      return new Promise(async (resolve, reject) => {
        try {
          let pages = this.Charge.wizard.pages;

          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[4].options = utils.appendOptions(
            res.data.result.accounts,
            "account_number",
            "account_name"
          );

          this.Charge.wizard.pages = pages;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createCharge() {
      this.$router.push({
        name: "Configs-Transactions-AddCharge",
        params: {},
      });
      // this.showModal = true
    },
    saveGroup(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_charges",
            action: "add",
            data: {
              charge_code: data.code,
              charge_name: data.name,
              description: data.description,
              account_number: data.account,
              type: data.type,
              value: data.val,
              slab: [],
            },
            meta_data: {},
          });

          await this.fetchCharges();
          notification.notify(
            "success",
            "Charge Added",
            `The charge ${data.name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.Charge.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.Charge.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.fetchData();
    await this.fetchCharges();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">

</style>