<template lang="html">
   <div class="topups">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createTopup()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Topup
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Topup.table.table_fields"
         :icon="Topup.table.icon"
         :title="Topup.table.title"
         :subtitle="Topup.table.subtitle"
         :tableData="topups" 
         :searchFields="Topup.table.searchFields"
         :rowData="Topup.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Topup = Models.Topup;

    Event.$on("topup:view", async (payload) => {
      console.log(payload.account.topup_config_id);
      this.$router.push({
        name: "Configs-Transactions-ViewTopup",
        params: { id: payload.account.topup_config_id },
      });
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Topup: {},
      topups: [],
      showModal: false,
    };
  },
  methods: {
    fetchData() {
      return new Promise(async (resolve, reject) => {
        try {
          let pages = this.Topup.wizard.pages;
          // let res = await req.callServerSecure({
          //   resource: "system_account",
          //   action: "list",
          //   data: {},
          //   meta_data: {},
          // });
          // pages[0].forms[1].options = utils.appendOptions(
          //   res.data.result.accounts,
          //   "account_number",
          //   "account_name"
          // );
          res = await req.callServerSecure({
            resource: "transaction_channel",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[2].options = utils.appendOptions(
            res.data.result.transaction_channels,
            "channel_code",
            "channel_name"
          );
          let res = await req.callServerSecure({
            resource: "transaction_limit",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[3].options = utils.appendOptions(
            res.data.result.transaction_limits,
            "code",
            "name"
          );
          res = await req.callServerSecure({
            resource: "transaction_charges",
            action: "list",
            data: {},
            meta_data: {},
          });
          pages[0].forms[5].options = utils.appendOptions(
            res.data.result.charges,
            "charge_code",
            "charge_name",
            "charge_name"
          );

          this.Topup.wizard.pages = pages;
          resolve(true);
        } catch (err) {
          console.log(err);
        }
      });
    },
    fetchTopups() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "topup_config",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.topups = res.data.result.topup_configs;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createTopup() {
      this.$router.push({
        name: "Configs-Transactions-AddTopup",
        params: {},
      });
      // this.showModal = true;
    },
  },
  async mounted() {
    await this.fetchData();
    await this.fetchTopups();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.topups {
}
</style>