<template>
  <div class="map-location">
    <div
      class="modal fade"
      id="viewMapLocation"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewMapLocation"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content redial-border-light">
          <div class="modal-header redial-border-light">
            <div class="row-contents">
              <div class="ci ci-info">
                <i class="fab fa-slack icon"></i>
              </div>
              <div class="text" id="formModalLabel">
                <div class="fx-h5">Map Location</div>
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row justify-content-md-center">
              <div class="col-12">
                <div id="map" style="width: 100%; height: 380px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    Event.$on('map:showLocation', (payload) => {
      this.latitude = payload.location.coordinates[0];
      this.longitude = payload.location.coordinates[1];
      this.initialize();
      $('#viewMapLocation').modal('show');
    });
  },
  data() {
    return {
      latitude: '',
      longitude: '',
      geocoder: {},
      map: null,
      address: '',
      selectedLocation: { lat: -1.326368, lng: 36.848483 },
      foundLocation: {},
      predictions: [],
      infowindow: new google.maps.InfoWindow(),
      form: '',
      isAddressSelected: false,
      styles: {},
    };
  },

  methods: {
    initialize() {
      this.styles = utils.getMapStyles();
      this.geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(
        this.selectedLocation.lat,
        this.selectedLocation.lng
      );
      var mapOptions = {
        zoom: 10,
        controlSize: 26,
        center: { lat: this.latitude, lng: this.longitude },
        styles: this.styles,
      };

      this.map = new google.maps.Map(
        document.getElementById('map'),
        mapOptions
      );

      var marker = new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: this.map,
      });
    },
  },

  mounted() {},
};
</script>

<style lang="less">
#viewMapLocation {
  z-index: 1080;
}

.map-location {
  .txt {
    font-weight: 600;
    text-align: center;
    margin: 20% 0 0 0;
    font-size: 15px;
  }
  .selector {
    margin-bottom: 20px;
  }
}
</style>
