<template>
  <div class="contact-content">
    <div class="contact-content-header">
      <nav class="nav nav-line">
        <a href="#details" class="nav-link active" data-toggle="tab"
          >Global Configuration</a
        >
        <a href="#accountConfig" class="nav-link" data-toggle="tab"
          >Account Template Configuration</a
        >
        <a href="#feeConfig" class="nav-link" data-toggle="tab"
          >Fee Configuration</a
        >
      </nav>
      <a href="" id="contactOptions" class="text-secondary mg-l-auto d-xl-none"
        ><i data-feather="more-horizontal"></i
      ></a>
    </div>
    <!-- contact-content-header -->

    <div class="contact-content-body bg-gray-100">
      <div class="tab-content">
        <div id="details" class="tab-pane show active pd-20 pd-xl-25">
          <div class="row row-sm">
            <div class="col-6 col-sm-5">
              <h6 class="mg-t-10 mg-b-20">Config</h6>
              <div class="row row-sm">
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Status</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ ModuleConfig.config_status }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Category</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">Configuration</p>
                </div>
                <div class="col-12 mg-t-20 mg-sm-t-30">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Message</label
                  >
                  <p class="tx-13 mg-b-0">
                    {{ ModuleConfig.config_status_message }}
                  </p>
                </div>
              </div>
            </div>

            <div class="divider-text divider-vertical"></div>

            <div class="col-6 col-sm-5">
              <h6 class="mg-t-10 mg-b-20">Running</h6>

              <div class="row row-sm">
                <div class="col-6 col-sm-6">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Status</label
                  >
                  <p class="tx-danger tx-rubik mg-b-0">
                    {{ ModuleConfig.running_status }}
                    <span class="tx-primary ml-4"
                      ><u><a href="#">view exceptions</a></u></span
                    >
                  </p>
                </div>

                <div class="col-12 mg-t-20 mg-sm-t-30">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Message</label
                  >
                  <p class="tx-13 mg-b-0">
                    {{ ModuleConfig.running_status_message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
          <h6 class="mg-t-40 mg-b-40">General Configuration</h6>

          <div class="row row-sm">
            <div class="col-6 col-sm-5">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="min">Min Tenure (Months of Finance)</label>
                    <input
                      type="text"
                      id="min"
                      class="form-control"
                      placeholder="Min Tenure"
                      v-model="Currentgeneralconfig.minimum_tenor"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="max">Max Tenure(Months of Finance)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="max"
                      placeholder="Max Tenure"
                      v-model="Currentgeneralconfig.maximum_tenor"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="release">DSLRA Value(% Value)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="release"
                      placeholder=""
                      v-model="Currentgeneralconfig.dslra_value"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="release">Number of Days to Release</label>
                    <input
                      type="text"
                      class="form-control"
                      id="release"
                      placeholder=""
                      v-model="Currentgeneralconfig.number_of_days_to_release"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <button class="btn btn-primary" @click="saveGeneralConfig()">
                    Save Generals Configuration
                    <div class="lds-ring" v-show="SavingGeneralConfig">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </form>
            </div>

            <div class="divider-text divider-vertical"></div>

            <div class="col-6 col-sm-5">
              <h6 class="mg-t-10 mg-b-40">Current Config</h6>
              <div class="row row-sm">
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Min Tenure</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.minimum_tenor }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Max Tenure</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.maximum_tenor }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >Number of Days to Release</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.number_of_days_to_release }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="
                      tx-10
                      tx-medium
                      tx-spacing-1
                      tx-color-03
                      tx-uppercase
                      tx-sans
                      mg-b-10
                    "
                    >DSLRA Value</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.dslra_value }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- tab-pane -->
        <div id="accountConfig" class="tab-pane pd-20 pd-xl-25">
          <form>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Select Currency</label>

                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.currency"
                >
                  <option
                    v-for="curr in currencies"
                    v-bind:value="curr.currency_iso_code"
                  >
                    {{ curr.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Deposit Account Opening Template</label>
                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.deposit_act_opn_template"
                >
                  <option
                    v-for="actt in accTemplates"
                    v-bind:value="actt.template_code"
                  >
                    {{ actt.template_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Trust Account Opening Template </label>
                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.trust_act_opn_template"
                >
                  <option
                    v-for="actt in accTemplates"
                    v-bind:value="actt.template_code"
                  >
                    {{ actt.template_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Operating Account Opening Template</label>
                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.operating_act_opn_template"
                >
                  <option
                    v-for="actt in accTemplates"
                    v-bind:value="actt.template_code"
                  >
                    {{ actt.template_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">System DSLRA Account</label>
                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.system_dslra_account"
                >
                  <option
                    v-for="acttt in Sysaccounts"
                    v-bind:value="acttt.account_number"
                  >
                    {{ acttt.account_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Bank DSLRA Account Opening Template</label>
                <select
                  class="custom-select"
                  v-model="Currentacttempconfig.lender_dslra_account_template"
                >
                  <option v-for="actt in accTemplates">
                    {{ actt.template_name }}
                  </option>
                </select>
              </div>
              <div class="form-row">
                <button class="btn btn-primary" @click="saveTemplateConfig()">
                  Save Template Configuration
                  <div class="lds-ring" v-show="SavingTemplateConfig">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </div>
            </div>
          </form>
          <h6 class="mg-t-40 mg-b-20">Current Configurations</h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Currency</th>
                <th scope="col">Deposit Account</th>
                <th scope="col">Trust Account</th>
                <th scope="col">Operating Account</th>
                <th scope="col">System DSLRA Act</th>
                <th scope="col">Bank DSLRA Tmpl</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, name, index) in ActTemplateConfig">
                <td>{{ name }}</td>
                <td>{{ value.deposit_act_opn_template }}</td>
                <td>{{ value.trust_act_opn_template }}</td>
                <td>{{ value.operating_act_opn_template }}</td>
                <td>{{ value.system_dslra_account }}</td>
                <td>{{ value.lender_dslra_account_template }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- tab-pane -->

        <div id="feeConfig" class="tab-pane pd-20 pd-xl-25">
          <form>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="min">Select Currency</label>
                <select
                  class="custom-select"
                  v-model="Currentfeeconfig.currency"
                >
                  <option
                    v-for="(cur, index) in currencies"
                    v-bind:value="cur.currency_iso_code"
                    :key="index"
                  >
                    {{ cur.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Client Loan Application Fee</label>
                <select
                  class="custom-select"
                  v-model="Currentfeeconfig.client_loan_application_fee"
                >
                  <option v-for="(ff, index) in fees" v-bind:value="ff.system_fee_ref" :key="index">
                    {{ ff.fee_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">Lender Disbursment Fee</label>
                <select
                  class="custom-select"
                  v-model="Currentfeeconfig.lender_disbursment_fee"
                >
                  <option v-for="(ff, index) in fees" v-bind:value="ff.system_fee_ref" :key="index">
                    {{ ff.fee_name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="max">School Release Fee</label>
                <select
                  class="custom-select"
                  v-model="Currentfeeconfig.school_release_fee"
                >
                  <option v-for="(ff, index) in fees" v-bind:value="ff.system_fee_ref" :key="index">
                    {{ ff.fee_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary" @click="saveFeeConfig()">
                Save Fee Configuration
                <div class="lds-ring" v-show="SavingFeeConfig">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </form>
          <h6 class="mg-t-40 mg-b-20">Current Fee Configuration</h6>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Currency</th>
                <th scope="col">Client Loan Aplication Fee</th>
                <th scope="col">Lender Disbursment Fee</th>
                <th scope="col">School Release Fee</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, name, index) in FeeConfig" :key="index">
                <td>{{ name }}</td>
                <td>{{ value.client_loan_application_fee }}</td>
                <td>{{ value.lender_disbursment_fee }}</td>
                <td>{{ value.school_release_fee }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- tab-pane -->
      </div>
      <!-- tab-content -->
    </div>
    <!-- contact-content-body -->
  </div>
  <!-- contact-content -->
</template>

<script>
export default {
  async created() {
    let _this = this;
  },
  data() {
    return {
      Currentacttempconfig: {},
      Currentfeeconfig: {},
      Currentgeneralconfig: {},
      fees: [],
      SavingGeneralConfig: false,
      SavingTemplateConfig: false,
      SavingFeeConfig: false,
      accTemplates: [],
      currencies: [],
      Sysaccounts: [],
      ModuleConfig: {},
      GeneralConfig: {},
      FeeConfig: {},
      SchoolModuleConfig: {},
      ActTemplateConfig: {},
    };
  },
  methods: {
    async saveGeneralConfig() {
      try {
        this.SavingGeneralConfig = true;
        let data = {};
        data.general = {};
        data.general = this.Currentgeneralconfig;
        await this.saveConfig(data);
        this.SavingGeneralConfig = true;
      } catch (err) {
        this.SavingGeneralConfig = true;
      }
    },
    async saveTemplateConfig() {
      try {
        this.SavingTemplateConfig = true;
        let data = {};
        data.account_opening_template = {};
        data.account_opening_template[this.Currentacttempconfig.currency] =
          this.Currentacttempconfig;
        //  alert(JSON.stringify(data))
        await this.saveConfig(data);
        this.SavingTemplateConfig = true;
      } catch (err) {
        alert(err);
        this.SavingTemplateConfig = true;
      }
    },
    async saveFeeConfig() {
      try {
        this.SavingFeeConfig = true;
        let data = {};
        data.fees = {};
        data.fees[this.Currentfeeconfig.currency] = this.Currentfeeconfig;
        await this.saveConfig(data);
        this.SavingFeeConfig = true;
      } catch (err) {
        this.SavingFeeConfig = true;
      }
    },

    saveConfig(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "school",
            action: "save_school_config",
            data: data,
            meta_data: {},
          });
          await this.getSchoolConfig();
          return resolve();
        } catch (err) {
          return reject(err);
         }
      });
    },
    fetchSytemFees() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fees",
            action: "list_fees",
            data: {},
            meta_data: {},
          });

          this.fees = res.data.result.entity_fees;
          // console.log(  this.fees )
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchSystemAccounts() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Sysaccounts = res.data.result.accounts;
          console.log(this.Sysaccounts);
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchCurrencies() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "currency",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.currencies = res.data.result.currencies;
          // console.log(" currencies " , this.currencies)
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchAccountTemplate() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_account_template",
            action: "list",
            data: {
              // entity_type: "UNDERWRITTER",
            },
            meta_data: {},
          });
          this.accTemplates = res.data.result.templates;
          //  console.log("this.accTemplates", this.accTemplates);
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    getSchoolConfig() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "school",
            action: "get_school_config",
            data: {},
            meta_data: {},
          });

          let config = res.data.result || {};
          console.log("School Config Data .. ", config);
          if (config.account_opening_template) {
            this.ActTemplateConfig = config.account_opening_template;
          }
          if (config.fees) {
            this.FeeConfig = config.fees;
          }
          if (config.general) {
            this.GeneralConfig = config.general;
          }
          if (config.module) {
            this.ModuleConfig = config.module;
          }

          resolve(true);
        } catch (err) {
          console.log(" == Err ===", err);
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.getSchoolConfig();
    await this.fetchSytemFees();
    await this.fetchCurrencies();
    await this.fetchAccountTemplate();
    await this.fetchSystemAccounts();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>
