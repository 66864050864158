<template lang="html">
  <div class="editClass">
    <VerifyModal :showVerifyModal="showVerifyModal" />
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Class
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="form-row">
          <div class="form-group col-md-5">
            <nav class="nav nav-icon-only">
              <div class="dropdown">
                <button
                  class="btn btn-xs btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Select Icon
                </button>

                <div
                  class="dropdown-menu dropdown-menu-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <img
                    :src="getImg(icon.file_path)"
                    class="img wd-60 ht-60 rounded-circle img-thumbnail"
                    :alt="icon.originalname"
                    v-for="(icon, index) in icons"
                    @click="iconSelected(icon.file_path)"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div class="form-group col-md-5">
            <img
              v-bind:src="Class.icon"
              class="img wd-60 ht-60 rounded-circle"
              alt=""
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Insurance Category"
              name="clientTypeCode"
              id="clientTypeCode1"
              v-model="Class.class_code"
              disabled
            />
          </div>
          <div class="form-group col-md-4">
            <label>Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Insurance Class code"
              name="clientTypeCode"
              id="clientTypeCode"
              v-model="Class.code"
              disabled
            />
          </div>

          <div class="form-group col-md-4">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Insurance Class name"
              name="clientTypename"
              id="clientTypename"
              v-model="Class.name"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <label>Description</label>
            <textarea
              class="form-control"
              rows="2"
              placeholder="Insurance Class Description "
              name="clientTypedesc"
              id="clientTypedesc"
              v-model="Class.description"
            ></textarea>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <button
              type="button"
              class="btn btn-primary"
              @click="updateClass()"
              :disabled="audit_id !== null"
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-secondary mg-l-10"
              @click="openVerifyModal()"
              :disabled="audit_id === null"
            >
              Verify
            </button>
          </div>
          <div class="form-group col-md-6">
            <button type="button" class="btn btn-warning" @click="clearClass()">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    Event.$on('verifyModal:submit', async (payload) => {
      this.verify(payload);
    });

    Event.$on('verifyModal:close', async (payload) => {
      this.showVerifyModal = false;
    });
  },
  data() {
    return {
      icons: [],
      imageSelected: false,
      Class: {},
      audit_id: null,
      showVerifyModal: false,
    };
  },
  methods: {
    openVerifyModal() {
      this.showVerifyModal = true;
    },
    getImg(path) {
      return req.getParsedUrl(path);
    },
    iconSelected(icon) {
      this.Class.icon = icon;
    },
    clearClass() {
      this.Class = {};
    },
    selectIcon() {},
    getIcons() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'icons',
            action: 'list',
            data: {},
            meta_data: {},
          });
          this.icons = res.data.result;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    async updateClass() {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'edit',
          data: {
            sub_class_id: this.Class.sub_class_id,
            update: {
              name: this.Class.name,
              description: this.Class.description,
              icon: this.Class.icon,
            },
          },
        });

        this.audit_id = res.data.result.audit_id;
      } catch (err) {
        console.log(err);
        notification.notify('error', 'Insurance Class Error ', err);
      }
    },
    async verify(data) {
      try {
        let res = await req.callServerSecure({
          resource: 'insurance_sub_class',
          action: 'verify_subclass',
          data: {
            remarks: data.remarks,
            audit_id: this.audit_id,
          },
          meta_data: {},
        });

        notification.notify(
          'success',
          'Insurance Class Updated',
          `Insurance Class ${this.Class.name} successfully updated`
        );
        this.$router.push({
          name: 'Configs-Insurance-InsuranceSubClass',
          params: {},
        });
      } catch (err) {
        notification.notify('error', 'Insurance Class Error ', err);
      }
    },
    async fetchSubclass() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'insurance_sub_class',
            action: 'summary',
            data: {
              code: this.$route.params.id,
            },
          });

          console.log(
            ' Subclass details ...',
            res.data.result.subclass_summary
          );
          this.Class = res.data.result.subclass_summary;

          resolve();
        } catch (err) {
          notification.notify(
            'error',
            'Error Fetching class Details  ',
            `Kindly Retry Later`
          );
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.getIcons();
    await this.fetchSubclass();
    Event.$emit('loader:show', false);
  },
  beforeDestroy() {
    Event.$off('verifyModal:submit');
  },
};
</script>
<style lang="less">
.editClass {
}
</style>
