module.exports = {
  name: "holidays",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "holiday",
    pages: [
      {
        title: "Holiday",
        subtitle: "Configure new holiday",
        forms: [
          // {
          //   type: "text",
          //   form_type: "text",
          //   label: "Code",
          //   model: "code",
          //   validation: "required|min:2|max:50",
          //   class: "col-12 col-md-6",
          // },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
         /* {
            type: "radio",
            form_type: "radio",
            label: "Type",
            model: "type",
            options: [
              {
                id: "bank_holiday",
                val: "bank",
                label: "Bank Holiday",
              },
              {
                id: "normal_holiday",
                val: "normal",
                label: "Normal Holiday",
              },
            ],
            validation: "required",
            class: "col-12",
          },
          */
          {
            type: "select",
            class: "col-12",
            label: "Day of Month",
            model: "day",
            options: [],
            validation: "required",
          },
          {
            type: "select",
            class: "col-12",
            label: "Month",
            model: "month",
            options: [],
            validation: "required",
          },
          {
            type: "radio",
            form_type: "radio",
            label: "Event Occurence",
            model: "event_occurence",
            options: [
              {
                id: "repeated",
                val: "Repeated",
                label: "Repeated",
              },
              {
                id: "once",
                val: "Once",
                label: "Once",
              },
            ],
            validation: "required",
            class: "col-12",
          },
          {
            type: "select",
            class: "col-12",
            label: "Year",
            model: "year",
            options: [],
            validation: "required",
          },
        ],
      },
    ],
  },
  table: {
    title: "Holidays",
    subtitle: "A list of holidays",
    table_fields: [
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "Date", align: "l" },
      { name: "Description", align: "l" },
      { name: "Type", align: "l" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["code", "name", "description", "type"],
    rowData: [
      { type: "text", field: "holiday_system_ref", class: "left bolder small" },
      { type: "text", field: "holiday_name", class: "left bolder" },
      { type: "text", field: "created_date", class: "left bolder small" },
      { type: "text", field: "holiday_description", class: "left bolder small" },
      { type: "text", field: "holiday_type", class: "left bolder small" },
      { type: "text", field: "holiday_status", class: "left bolder small" },
      {
        type: "action",
        field: "account_number",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Edit",
            event: "holiday:edit",
            icon: "ico-right",
            params: [{ key: "holiday", field: "*" }],
          },
          {
            type: "menu",
            title: "View",
            event: "holiday:view",
            icon: "ico-reply",
            params: [{ key: "holiday", field: "*" }],
          },
          // {type: 'divider'},
          {
            type: "menu",
            title: "Delete",
            event: "holiday:delete",
            icon: "ico-mail-a",
            params: [{ key: "holiday", field: "*" }],
          },
        ],
      },
    ],
  },
};
