<template>
  <div class="view-cover-area">
    <MapShowLocation />
    <div class="more-details">
      <div class="card">
        <div class="card-body">
          <div class="title">Cover Area Details</div>
          <div class="row">
            <div class="col-12 col-lg-4 col-md-6">
              <label>Type</label>
              <div class="data-field">
                {{ cover_area.area_type }}
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>Name</label>
              <div class="data-field">
                {{ cover_area.cover_area_name }}
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>Description</label>
              <div class="data-field">
                {{ cover_area.cover_area_description }}
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>Cover Radius</label>
              <div class="data-field">{{ cover_area.cover_radius }} M</div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>Status</label>
              <div class="data-field">
                {{ cover_area.status }}
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>Created Date</label>
              <div class="data-field">
                {{ cover_area.created_date }}
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <label>View on Map</label>
              <div class="data-field">
                <a href="" @click.prevent="openMap()">Map</a>
              </div>
            </div>
          </div>
          <div class="button-area mg-t-40">
            <button class="btn btn-light wd-200 mg-r-20">
              <i class="fas fa-check-circle mg-r-2"></i>Verify
            </button>
            <button class="btn btn-light wd-200 mg-r-20">
              <i class="fas fa-check-circle mg-r-2"></i>Update
            </button>
            <button class="btn btn-light wd-200">
              <i class="fas fa-check-circle mg-r-2"></i>Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.loading = true;
  },
  data() {
    return {
      cover_area: {},
      loading: false,
    };
  },
  methods: {
    openMap() {
      Event.$emit('map:showLocation', {
        location: this.cover_area.cover_area_location,
      });
    },
    getCoverArea() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'cover_area',
            action: 'get',
            data: {
              cover_area_system_ref: this.$route.params.id,
            },
            meta_data: {},
          });

          this.cover_area = res.data.result.cover_area;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.getCoverArea();
    this.loading = false;
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less" scoped>
.view-cover-area {
  .title {
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0 20px 0;
    color: #7d89a1;
  }
  .more-details {
    margin-top: 50px;

    .data-field {
      margin-bottom: 20px;
    }
  }
  .tab-navigator .navigator .btn-nav {
    padding: 11px 0;
    text-align: center;
    border-left: solid 1px #efefef;
    width: 20%;
    float: left;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
