module.exports = {
  name: "tran_config_purchase",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "add_tran_purchase",
    pages: [
      {
        title: "Purchase Policy Config",
        subtitle: "Add new purchase policy config",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:3|max:50",
            class: "col-12 col-md-12",
          },
          {
            type: "select",
            class: "col-12 col-md-12",
            label: "Transaction Limit",
            model: "limit",
            options: [
              { val: "MOBILE_MONEY_LIMIT", label: "Mobile Money Limit" },
              { val: "CARD_LIMIT", label: "Card Limit" },
              { val: "PAYPAL_LIMIT", label: "Paypal Limit" },
              { val: "BANK_LIMIT", label: "Bank Limit" },
            ],
            validation: "required",
          },
          {
            type: "check",
            class: "col-12 col-md-6",
            label: "Is Charged",
            model: "is_charged",
            options: [
              { id: "yes", label: "Yes", val: "Yes" },
              { id: "no", label: "No", val: "No" },
            ],
          },
          {
            type: "check",
            class: "col-12 col-md-6",
            label: "Charges",
            model: "charges",
            options: [
              {
                id: "nexus charge",
                label: "Nexus Charge",
                val: "Nexus Charge",
              },
              {
                id: "other charge",
                label: "Other Charge",
                val: "Other Charge",
              },
            ],
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Config Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
};
