module.exports = {
   name: 'gl',
   wizard: {},
   table: {
      title: 'Transactions',
      subtitle: 'A list of transactions',
      table_fields: [
         { name: 'Tran Ref', align: 'l' },
         { name: 'Currency', align: 'l' },
         { name: 'Amount', align: 'l' },
         { name: 'Tran Date', align: 'l' },
         { name: 'Remarks', align: 'c' },
         { name: 'Tran Type', align: 'l' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["account_number", "account_name", "available_balance", "currency_code"],
      rowData: [
         { type: 'text', field: 'tran_ref', class: 'left bolder small' },
         { type: 'text', field: 'currency_code', class: 'left bolder small' },
         { type: 'text', field: 'tran_amount', class: 'left bolder' },
         { type: 'text', field: 'tran_date', class: 'left bolder small' },
         { type: 'text', field: 'remarks', class: 'center bolder small' },
         { type: 'number', field: 'tran_type', class: 'center bolder small' },
         // { type: 'text', field: 'account_opening_date', class: 'left small' },
         {
            type: 'action', field: 'account_number', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'transaction:view', icon: 'ico-reply', params: [
                     { key: 'tran', field: '*' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'account', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
}