
<template lang="html">
   <div class="listreleaseQueues">

        

         <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
       
      </div>

       
       <div class="contents">
         <CustomTable :tableFields="School.releaseQueueTable.table_fields" 
            :tableData="releaseQueues" 
            :title="School.releaseQueueTable.title" 
            :subtitle="School.releaseQueueTable.subtitle" 
            :searchFields="School.releaseQueueTable.searchFields"
            :rowData="School.releaseQueueTable.rowData">
         </CustomTable>
      </div>
              
   </div>
</template>
<script>
export default {
  data() {
    return {
      School: {},
      releaseQueues: [],
      showModal: false,
      icons: [],
    };
  },
  async created() {
    this.School = Models.School;
    Event.$on("releaseQueues:view", (payload) => {
      this.$router.push({
        name: "View-ReleaseQueue",
        params: { id: payload.releaseQueue.fee_release_system_ref },
      });
    });

    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.School.wizard.action) {
        await this.savereleaseQueues(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });
  },

  async mounted() {
    await this.fetchreleaseQueues();
    Event.$emit("loader:show", false);
  },

  methods: {
    getImg(path) {
      return req.getParsedUrl(path);
    },
    addreleaseQueues() {
      this.showModal = true;
    },

    savereleaseQueues(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "services",
            action: "create",
            data: {
              service_code: data.service_code,
              service_name: data.service_name,
              service_type: data.service_type,
              service_category: data.service_category,
              service_description: data.service_description,
              service_image_path: data.service_image_path,
            },
            meta_data: {},
          });

          await this.fetchreleaseQueues();
          notification.notify(
            "success",
            "fee release Added",
            `The service ${data.service_name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.School.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.School.wizard.action,
          });
        }
      });
    },
    fetchreleaseQueues() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fund_release",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.releaseQueues = res.data.result.fee_releases;
          resolve(true);
        } catch (err) {
          notification.notify(
            "error",
            "Error while fetching fee release",
            `Error while fetching fee release`
          );
          reject(err);
        }
      });
    },
  },
};
</script>
<style lang="less">
.listreleaseQueues {
}
</style>