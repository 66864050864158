<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="ClientSubtype.wizard.add_subtype.action">
            <template slot="form">
               <CustomForm 
                  :pages="ClientSubtype.wizard.add_subtype.pages" 
                  :action="ClientSubtype.wizard.add_subtype.action" 
                  :buttonText="ClientSubtype.wizard.add_subtype.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addClientSubType()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Client Subtype
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="ClientSubtype.table.table_fields" 
            :tableData="subtypes" 
            :title="ClientSubtype.table.title" 
            :subtitle="ClientSubtype.table.subtitle" 
            :searchFields="ClientSubtype.table.searchFields"
            :rowData="ClientSubtype.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.ClientSubtype = Models.ClientSubtype;
		let _this = this;

		this.subtypes = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.ClientSubtype.wizard.add_subtype.action) {
				await this.addClientSubtype(payload.formData);
				this.showModal = false;
			}
		});
		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("client_subtype:view", payload => {
			this.$router.push({
				name: "Configs-Insurance-ViewClientSubType",
				params: { id: payload.subtype.subtype_code }
			});
        });
        
        	Event.$on("client_subtype:edit", payload => {
                console.log(" editing subtype code ",payload)
			this.$router.push({
				name: "Configs-Insurance-EditClientSubType",
				params: { id: payload.client_subtype.subtype_code }
			});
		});
	},
	data() {
		return {
			ClientSubtype: {},
			subtypes: [],
			formData: {},
			showModal: false
		};
	},
	methods: {
      fetchClientTypes() {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "client_type",
						action: "list",
						data: {},
						meta_data: {}
					});

               let pages = this.ClientSubtype.wizard.add_subtype.pages,options = [];
               await res.data.result.client_types.forEach(element => {
						options.push({ val: element.code, label: element.name });
               });
               
               pages[0].forms[1].options = options;
					this.ClientSubtype.wizard.add_subtype.pages = pages;
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
      fetchClientSubtypes () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.subtypes = res.data.result.client_subtypes
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },

        addClientSubType () {
          		this.$router.push({
				name: "Configs-add-client-subtype",
				params: {}
			});
        },
		openAddClientSubtypeModal() {
			this.showModal = true;
		},
		async uploadicon (icon,code) {
			try {
             var block = icon.split(";");
			// Get the content type of the image
			var contentType = block[0].split(":")[1];// In this case "image/gif"
			// get the real base64 content of the file
			var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

			// Convert it to a blob to upload
			var blob = this.b64toBlob(realData, contentType);

			// Create a FormData and append the file with "image" as parameter name
			var formDataToUpload = new FormData();
			formDataToUpload.append("image", blob);
			await req.callServerMultipart('upload/v1/admin/icon/client_sub_type'+code,formDataToUpload);
			}
			catch (err) {
				alert(err)
			}
            
		},
		b64toBlob(b64Data, contentType, sliceSize) {
					contentType = contentType || '';
					sliceSize = sliceSize || 512;

					var byteCharacters = atob(b64Data);
					var byteArrays = [];

					for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
						var slice = byteCharacters.slice(offset, offset + sliceSize);

						var byteNumbers = new Array(slice.length);
						for (var i = 0; i < slice.length; i++) {
							byteNumbers[i] = slice.charCodeAt(i);
						}

						var byteArray = new Uint8Array(byteNumbers);

						byteArrays.push(byteArray);
					}

				var blob = new Blob(byteArrays, {type: contentType});
				return blob;
			},
		addClientSubtype(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "add",
						data: {
							subtype_code: data.subtype_code,
							subtype_name: data.subtype_name,
							subtype_description: data.subtype_description,
						}
					});

					this.uploadicon(data.icon,data.subtype_code);

               await this.fetchClientSubtypes()
					notification.notify("success","Client Subtype Added",`Client Subtype ${data.name} successfully added`);
					Event.$emit("form:action-complete", {action: this.ClientSubtype.wizard.add_subtype.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.ClientSubtype.wizard.add_subtype.action});
				}
			});
		}
	},
	async mounted() {
    //  await this.fetchClientTypes()
      await this.fetchClientSubtypes()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>