<template lang="html">
   <div class="view-bank view">
      <div class="row row-xs">
         <div class="col-lg-12 col-xl-12">
            <div class="tab-navigator">
               <div class="navigator clearfix">
                  <div class="btn-nav" :class="{'active' : tab === 1}" @click="showTab(1)"> <i class="fa fa-coffee"></i> Details</div>
                  <div class="btn-nav" :class="{'active' : tab === 2}" @click="showTab(2)"> <i class="fa fa-coffee"></i> Directors</div>
                  <div class="btn-nav" :class="{'active' : tab === 3}" @click="showTab(3)"> <i class="fa fa-coffee"></i> Super Users</div>
               </div>

               <div class="pages">
                  <div class="page" v-show="tab === 1">
                     <div class="top-content clearfix">
                        <div class="float-l left-content">
                           <div class="profile">
                              <img v-show="bank.logo" :src="`http://nexus.ke/${bank.logo}`" alt="" class="profile-img">
                              <img v-show="!bank.logo" src="/img/placeholder.jpg" alt="" class="profile-img">
                              <!-- <div class="status"><i class="fa fa-check"></i></div> -->
                           </div>
                        </div>
                        <div class="float-l right-content">
                           <div class="main-details">
                              <div class="name">{{bank.name}}</div>
                              <div class="number">{{bank.code}}</div>
                              <div class="text">{{bank.description}}</div>
                           </div>
                           <div class="stats clearfix">
                              <div class="float-l stat">
                                 <div class="big float-l">Ksh 15,637,399</div>
                                 <div class="small float-l">Total Income</div>
                              </div>
                              <div class="float-l stat">
                                    <div class="big float-l">20</div>
                                    <div class="small float-l">Total Subscribers</div>
                              </div>
                              <div class="float-l stat">
                                    <div class="big float-l">10</div>
                                    <div class="small float-l">Products</div>
                              </div>
                           </div>

                           <div class="actions">
                              <div class="float-l btn-action pink" title="Edit"><i class="icon icon-briefcase"></i></div>
                              <div class="float-l btn-action orange" title="Verify User"><i class="icon icon-calendar"></i></div>
                              <div class="float-l btn-action green"><i class="icon icon-email"></i></div>
                              <div class="float-l btn-action blue"><i class="icon icon-exchange"></i></div>
                           </div>
                        </div>
                     </div>
                     <div class="more-details">
                        <div class="title">More Details</div>
                        <div class="row">
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Email</label>
                              <div class="data-field">{{bank.emails}}</div>
                           </div>
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Address</label>
                              <div class="data-field">{{bank.address || '-'}}</div>
                           </div>
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Reg Status</label>
                              <div class="data-field">{{bank.registration_status}}</div>
                           </div>
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Profile Status</label>
                              <div class="data-field">{{bank.profile_status}}</div>
                           </div>
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Reg Date</label>
                              <div class="data-field">{{bank.created_date}}</div>
                           </div>
                           <div class="col-12 col-lg-3 col-md-6">
                              <label>Reg By</label>
                              <div class="data-field">
                                 <a href="javascript:void()">Superadmin</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="page" v-show="tab === 2">
                     <div class="title">Products</div>
                     <BankDirectors :bank="bank"/>
                  </div>
                  <div class="page" v-show="tab === 3">
                     <div class="title">Super Users</div>
                     <BankSuperUsers :bank="bank"/>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
	data() {
		return {
			showModal: false,
         tab: 1,
         bank: {}
		};
	},
	methods: {
		showTab(tab) {
			this.tab = tab;
      },
      fetchBankDetails(){
         return new Promise(async (resolve,reject) => {
            try {
               let res = await req.callServerSecure({
                  resource: "bank",
                  action: "get",
                  data: {
                     code: this.$route.params.id
                  },
                  meta_data: {},
               });

               this.bank = res.data.result.bank_details
               resolve(true)
            }catch (err) {
               console.log(err)
               reject(err)
            }
         });
      },
	},
	async mounted() {
      await this.fetchBankDetails()
      Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.view-bank{
   .btn-nav{
      width: 33.3% !important;
   }
   .title{
      font-weight: 600;
      font-size: 20px;
      margin: 10px 0 20px 0;
      color: #7d89a1;
   }
   .more-details{
      margin-top: 50px;

      .data-field{
         margin-bottom: 20px;
      }
   }
}
</style>