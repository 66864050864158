<template>
	<div class="alert alert-dismissible notify" :class="{
		'alert-danger' : type === 'error',
		'alert-info' : type === 'info',
		'alert-success' : type === 'success',
		'alert-warning' : type === 'warning'}">

		<button type="button" class="close" @click="close()">
			<span aria-hidden="true">&times;</span>
		</button>
		<strong class="title">{{title}}</strong>
		<br />
		<span v-html="message"></span>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			default: "success"
		},
		title: {
			default: "Store Created"
		},
		message: {
			default:
				"The store Orange Shop has been successfully added to the database"
		},
		id: {
			default: ''
		}
	},
	methods: {
		close() {
			Event.$emit('notification:close',{id: this.id})
		}
	}
};
</script>

<style lang="less">
.notify.alert-warning{
	.close{
		opacity: 1;
		border: 12px -4px 0 0;
	}
}
</style>