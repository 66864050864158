<template lang="html">
   <div class="clienttype">
 <div class="row">
      <div class="col-sm">
       <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Client Type Details for   {{clientType.type_name}} </h6>
                <nav class="nav nav-icon-only">
                  <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="media align-items-center mg-b-20">
                  <div class="avatar avatar-online"><img v-bind:src="clientType.type_icon" class="rounded-circle" alt=""></div>
                  <div class="media-body pd-l-15">
                    <h6 class="mg-b-3">{{clientType.type_code}}</h6>
                    <span class="d-block tx-13 tx-color-03">{{clientType.type_description}}</span>
                    <span class="d-block tx-13 tx-color-03">{{clientType.status}}</span>
                  </div>
                  <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">{{clientType.created_date}}</span>
                </div><!-- media -->
    
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                  <a href="" class="nav-link"><i data-feather="edit"></i> Edit</a>
                 <!-- <a href="" class="nav-link"><i data-feather="trash-2"></i> Delete</a> -->
                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->
      </div>
    </div>

    
    <div class="row">
       <div class="col-sm">
                   <button type="button" class="btn btn-outline-dark pull-right" @click="addsubtype()">Add Client Subtype</button>
                    <h4 id="section1" class="mg-b-10">Subtypes</h4>
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Id</th>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in clientSubtypes">
                                <th scope="row">{{ index }}</th>
                                <td>{{item.subtype_code}}</td>
                                <td>{{item.subtype_name}}</td>
                                <td>{{item.subtype_description}}</td>
                                <td><button type="button" class="btn btn-outline-danger" @click="removeSubtype(item)">Remove</button></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
         
    </div>
      <ModalForm :showModal="showModal" >
            <template slot="form" >
            <ul class="list-group">
                    <li  v-for="subtype in subtypes" class="list-group-item">
                            <div class="custom-control custom-checkbox ">
                             <input type="checkbox" class="custom-control-input col-sm-4" :id="subtype.subtype_code" :value="subtype.subtype_code"  v-model="selectedSubType">
                            <label class="custom-control-label label-checkbox" :for="subtype.subtype_code">{{subtype.subtype_name}}</label>
                                  <span class="d-block tx-11 text-muted">{{subtype.subtype_description}}</span>
                           </div>
		           		    </li>
                    </ul>
              <div class="d-none d-md-block">
							<button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="SaveClientType()">
							<i data-feather="mail" class="wd-10 mg-r-5"></i> Save Client subtype
							</button>
				   	</div>	
             </template>
         </ModalForm>
    </div>

        
  
  
</template>
<script>
export default {
    async created() {
            Event.$on("modal:close", async payload => {
                this.showModal = false;
                });
    },
    data() {
		return {
			showModal: false,
            clientType:{},
            clientSubtypes:[],
            subtypes:[],
            selectedSubType:[]
		};
	},
    methods: {
        addsubtype () {
             this.showModal = true;
        },
        removeSubtype () {

        },
      async  SaveClientType () {
                  try {
                    	Event.$emit("loader:show", true);
                    let res = await req.callServerSecure({
                      resource: "client_type",
                      action: "add_subtype",
                      data: {
                          type_code:  this.$route.params.id,
                          subtype_codes : this.selectedSubType
                      },
                    });
                    
                    this.showModal = false;
                    	Event.$emit("loader:show", false);
                      await this.fetchClientType()
                      		notification.notify("success","Client Subtype Added",`Client  Type successfully added`);
                
                  } catch (err) {
                    	Event.$emit("loader:show", false);
                   	notification.notify("error","Client Subtype Error ",err);
                  }

        },

        async removeSubtype (subtype) {
            Event.$emit("loader:show", true);
            try {
                    let res = await req.callServerSecure({
                      resource: "client_type",
                      action: "remove_subtype",
                      data: {
                          type_code:  this.$route.params.id,
                          subtype_code : subtype.subtype_code
                      },
                    });
                
                    
                    this.showModal = false;
                    	Event.$emit("loader:show", false);
                      await this.fetchClientType()
                      notification.notify("success","Client Subtype Removed",`Client  Type successfully removed`);
                
                  } catch (err) {
                    	Event.$emit("loader:show", false);
                   	notification.notify("error","Client Subtype Error ",err);
                  }

        },
        fetchClientSubtypes () {
             return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.subtypes = res.data.result.client_subtypes
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
      fetchClientType() {
		    return new Promise(async (resolve,reject) => {
                try {
                let res = await req.callServerSecure({
						resource: "client_type",
						action: "summary",
						data: {
                            type_code:  this.$route.params.id,
                        }
               });
                // alert(res)
          
              this.clientType=res.data.result.client_type_summary;
              this.clientSubtypes=res.data.result.client_type_summary.subtypes
                    console.log(this.clientSubtypes)
                     resolve()
                }
                catch (err) {
              console.log(err)
                     reject(err)
                }
            })
         }
        }
    ,
	async mounted() {
      await this.fetchClientType()
      await this.fetchClientSubtypes()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.clienttype {
}
</style>