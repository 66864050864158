<template lang="html">
   <div class="taxcategories">
      <ModalForm :showModal="showModal" :action="TaxCategory.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="TaxCategory.wizard.pages" 
               :action="TaxCategory.wizard.action" 
               :buttonText="TaxCategory.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createTaxCategory()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Tax Category
               </button>
         </div>
      </div>

      <CustomTable :tableFields="TaxCategory.table.table_fields"
         :icon="TaxCategory.table.icon"
         :title="TaxCategory.table.title"
         :subtitle="TaxCategory.table.subtitle"
         :tableData="taxcategories" 
         :searchFields="TaxCategory.table.searchFields"
         :rowData="TaxCategory.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.TaxCategory = Models.TaxCategory

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.TaxCategory.wizard.action){
               await this.saveTaxCategory(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            TaxCategory: {},
            taxcategories: [],
            showModal: false
         }
      },
      methods: {
         fetchtaxcategories(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "tax_category",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                  this.taxcategories = res.data.result.tax_categories
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createTaxCategory() {
            this.showModal = true
         },
         saveTaxCategory(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "tax_category",
                     action: "create",
                     data: {
                        tax_category_name: data.tax_category_name,
                        tax_category_code: data.tax_category_code,
                        tax_category_description: data.tax_category_description,
                     },
                     meta_data: {},
                  });

                  await this.fetchtaxcategories()
                  notification.notify('success', 'TaxCategory Added', `The TaxCategory ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.TaxCategory.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.TaxCategory.wizard.action})
               }
            })
         }
      },
      async mounted(){
         await this.fetchtaxcategories()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .taxcategories{
      
   }
</style>