<template>
  <div
    class="modal fade"
    id="tnc"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel d-block">
            {{ title }}
          </h5>
          <button
            @click="close()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submit()">
          <div class="modal-body">
            <div class="container-fluid">
              <h5 class="card-title">
                {{ subTitle }}
              </h5>
              <div class="row">
                <div class="form-group col-md-6 ">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    v-model="form.name"
                    class="form-control"
                    name="name"
                    id="name"
                    v-validate="'required'"
                  />
                  <div class="text-danger" v-if="errors.has('name')">
                    {{ errors.first("name") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="ref">Internal Ref</label>
                  <input
                    type="text"
                    v-model="form.ref"
                    class="form-control"
                    name="ref"
                    id="ref"
                    v-validate="'required'"
                  />
                  <div class="text-danger" v-if="errors.has('ref')">
                    {{ errors.first("ref") }}
                  </div>
                </div>
                <div class="form-group col-md-6 ">
                  <label for="version">Version</label>
                  <select
                    name="version"
                    id="version"
                    v-model="form.version"
                    class="custom-select"
                  >
                    <option
                      v-for="option in versions"
                      :value="option.val"
                      :key="option.val"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="errors.has('version')">{{
                    errors.first("version")
                  }}</small>
                </div>
                <div class="col-md-6">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="mg-r-20">Type:</label>
                      <div class="d-flex">
                        <!-- <div class="form-check mg-r-20">
                          <input
                            type="radio"
                            value="text"
                            name="type"
                            id="text"
                            class="form-check-input"
                            v-model="form.type"
                            v-validate="'required'"
                          />
                          <label class="form-check-label" for="text"
                            >Text</label
                          >
                        </div> -->

                        <div class="form-check">
                          <input
                            type="radio"
                            value="upload"
                            name="type"
                            id="upload"
                            class="form-check-input"
                            v-model="form.type"
                            v-validate="'required'"
                          />
                          <label class="form-check-label" for="upload"
                            >Upload</label
                          >
                        </div>
                      </div>
                      <small class="text-danger" v-if="errors.has('type')">{{
                        errors.first("type")
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.type === 'upload'">
                  <div class="form-group custom-file">
                    <label
                      for="document_name"
                      class="custom-file-label wd-500"
                      @click="onPickDocument"
                      ><span v-if="form.document_name">{{
                        form.document_name
                      }}</span>
                      <span v-else>Select File</span></label
                    >
                    <input
                      name="document"
                      type="file"
                      class="form-control custom-file-input"
                      ref="documentInput"
                      @change="onDocumentPicked"
                      id="document_name"
                      v-validate="'required'"
                    />
                  </div>
                  <div class="text-danger" v-if="errors.has('document')">
                    {{ errors.first("document") }}
                  </div>
                </div>
                <div class="col-md-12" v-if="form.type === 'text'">
                  <quill-editor
                    class="editor"
                    ref="myTextEditor"
                    v-model="form.content"
                    :config="editorOption"
                    name="editor"
                    v-validate="'required'"
                  >
                  </quill-editor>
                  <div class="text-danger" v-if="errors.has('editor')">
                    {{ errors.first("editor") }}
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <label for="description">Description</label>
                  <textarea
                    type="text"
                    v-model="form.description"
                    class="form-control"
                    id="description"
                    rows="4"
                    name="description"
                    v-validate="'required'"
                  ></textarea>
                  <div class="text-danger" v-if="errors.has('description')">
                    {{ errors.first("description") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
              Save
            </button>
            <button
              @click="close()"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {},
  props: {
    showTnCModal: {
      default: false,
    },
    title: {
      default: "",
    },
    subTitle: {
      default: "",
    },
  },
  watch: {
    showTnCModal: function(to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $("#tnc").modal(action);
    },
  },
  data() {
    return {
      form: {
        name: "",
        type: "",
        ref: "",
        version: "",
        description: "",
        content: "",
        document_name: "",
        document: null,
        path: "",
      },

      editorOption: {
        modules: {
          toolbar: "#toolbar",
          syntax: true,
          theme: "snow",
        },
      },
      versions: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
      ],
    };
  },
  methods: {
    onPickDocument() {
      this.$refs.documentInput.click();
    },
    onDocumentPicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();

      fileReader.readAsDataURL(files[0]);
      this.form.document = files[0];
      this.form.document_name = this.form.document.name;
    },
    uploadDoc() {
      return new Promise(async (resolve, reject) => {
        try {
          let formDataToUpload = new FormData();
          formDataToUpload.append("file", this.form.document);
          let res = await req.callServerMultipart(
            "/api/upload/v1/client",
            formDataToUpload
          );
          this.form.path = res.data.result.file_path;
          console.log("DOC UPLOAD", res);
          notification.notify(
            "success",
            "Doc Upload Complete",
            `TnC doc uploaded successully`
          );
          return resolve(true);
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      });
    },
    async submit() {
      try {
        let valid = await this.$validator.validate();
        if (!valid) {
          return " Validation Error ";
        } else {
          await this.uploadDoc();
        }
        Event.$emit("tncModal:submit", {
          tnc: this.form,
        });
      } catch (err) {
        console.log("error", err);
      }
    },

    close() {
      (this.form.name = ""),
        (this.form.type = ""),
        (this.form.ref = ""),
        (this.form.version = ""),
        (this.form.content = ""),
        (this.form.document_name = ""),
        (this.form.document = null),
        (this.form.description = "");
      Event.$emit("tncModal:close", {});
    },
  },
};
</script>

<style>
.option-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 350px;
  height: 100px;
  position: relative;
}
.option {
  flex-grow: 1;
  display: block;
  padding: 0.5em;
  background: #f5f6fa;
  border-radius: 0.25em;
  font-size: 3rem;
  position: relative;
  margin: 0.1em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.8s, 0.6s;
  transition-property: flex-grow, opacity;
  transition-timing-function: cubic-bezier(0.98, 0, 0.22, 0.98), ease-in-out;
}
.option:before,
.option:after {
  content: "";
  display: block;
  border: solid 2px #6f85ff;
  visibility: visible;
}
.option:before {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border-radius: 50%;
  width: 0.25em;
  height: 0.25em;
}
.option:after {
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0.2);
  transition: inherit;
  transition-property: transform, opacity;
}
.option-input {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
}
.option__label {
  display: inline-block;
  font-size: 0.4em;
  font-weight: bold;
  transform-origin: left bottom;
  transform: scale(0.7);
  transition: inherit;
  transition-property: transform, opacity;
}

.option-input:checked + .option {
  flex-grow: 4;
  opacity: 1;
}
.option-input:checked + .option::after {
  transform: scaleX(1);
}
.option-input:checked + .option::before {
  background: #6f85ff;
}
.option-input:checked + .option .option__label {
  transform: scale(1);
}
</style>
