 
<template lang="html">
   <div class="editClientType">
              
           <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Client Type</li>
                    </ol>
                </nav>    
            </div>  
        </div>


             <div class="row">
               <div class="col-sm-8 offset-sm-2">

                  <div class="form-row">
                      <div class="form-group col-md-5">
                    <nav class="nav nav-icon-only">
                     <div class="dropdown">
                       <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Icon</button>
                
                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                            <img :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail"  :alt="icon.originalname" v-for="(icon, index) in icons" @click="iconSelected(icon.file_path)">
                         </div>
                            </div>  
                       </nav> 
                     </div>
                        <div class="form-group col-md-5">
                         <img  v-bind:src="clientType.type_icon" class="img wd-60 ht-60 rounded-circle" alt="">
                          </div>
                   </div>


                     <div class="form-row">
                      <div class="form-group col-md-6">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Client type code" name="clientTypeCode" id="clientTypeCode" v-model="clientType.type_code"   >
                     </div>
                      <div class="form-group col-md-6">
                       <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Client type name" name="clientTypename" id="clientTypename" v-model="clientType.type_name"  >
                     </div>
                   </div>



                    <div class="form-row">
                      <div class="form-group col-md-12">
                       <label>Description</label> 
                      <textarea class="form-control" rows="2" placeholder="Client Type Description " name="clientTypedesc" id="clientTypedesc" v-model="clientType.type_description"   ></textarea>
                     </div>
                   </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <button type="button" class="btn btn-primary" @click="updateClientType()">Update</button>
                     </div>
                      <div class="form-group col-md-6">
                          <button type="button" class="btn btn-warning" @click="clearClientType()">Clear</button>
                     </div>
                   </div>


                   
               </div>
            </div>  
    
 
 </div>
</template>

<script>
export default {
	async created() {
    },
    data() {
		return {
            icons:[],
            imageSelected: false,
            clientType:{}
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        iconSelected (icon) {
        this.clientType.type_icon=req.getParsedUrl(icon)
        },
        selectIcon () {
        },
        async updateClientType() {
             try {

                    let res = await req.callServerSecure({
						resource: "client_type",
						action: "edit",
						data: {
                            client_type_id:  this.clientType.client_type_id,
                            update :{
                                    type_name:this.clientType.type_name,
                                    type_description:this.clientType.type_description,
                                    type_icon:this.clientType.type_icon
                                }
                        }
               });
		       	notification.notify("success","Client Type Updated",`Client Type ${this.clientType.type_name} successfully added`);
                    this.$router.push({
                        name: "Configs-Insurance-ClientType",
                        params: {}
                    });
             }
             catch (err) {
                	console.log(err);
					Event.$emit("form:action-error", 'An Error Has Occured');
             }
           },
       fetchClientType() {
		    return new Promise(async (resolve,reject) => {
                try {
                let res = await req.callServerSecure({
						resource: "client_type",
						action: "summary",
						data: {
                            type_code:  this.$route.params.id,
                        }
               });
                // alert(res)
          
              this.clientType=res.data.result.client_type_summary;
              console.log(" client type is .." ,this.clientType)
                     resolve()
                }
                catch (err) {
              console.log(err)
                     reject(err)
                }
            })
         }
        ,
        clearClientType () {
          this.clientType={}
        },
         getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  this.icons = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
    }
    ,
    async mounted() {
        await this.getIcons()
        await this.fetchClientType()
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.editClientType {
}
</style>