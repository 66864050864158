module.exports = {
    name: "Countries",
    table: {
        title: "Countries",
        subtitle: "Country List",
        table_fields: [
            { name: "Country Code", align: "r" },
            { name: "Name", align: "c" },
            { name: "iso code", align: "r" },
            { name: "Timezone", align: "c" },
            { name: "Actions", align: "c" },
        ],
        searchFields: ["names", "name", "username", "role_code"],
        rowData: [
            { type: "text", field: "country_code", class: "small center" },
            { type: "text", field: "country_name", class: "small center" },
            { type: "text", field: "country_iso_code", class: "small center" },
            { type: "text", field: "status", class: "small center" },
            {
                type: "action",
                field: "name",
                class: "center",
                icon: "",
                actions: [
                    // {type: 'title', title: 'Actions'},
                    {
                        type: "menu",
                        title: "View",
                        event: "country:view",
                        icon: "ico-right",
                        params: [{ key: "code", field: "*" }],
                    },
                ],
            },
        ],
    }
};