<template>
  <div class="add-Channel">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Transaction Channel</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new transaction channel</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->

        <template slot="icon">
            <div class="form-row mg-b-20">
              <div class="form-group col-md-5">
                <nav class="nav nav-icon-only">
                  <div class="dropdown dropright">
                    <button
                      class="btn btn-xs btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >Select Icon</button>
                    <div
                      class="dropdown-menu dropdown-menu-left"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <img
                        :src="getImg(icon.file_path)"
                        class="img wd-60 ht-60 rounded-circle img-thumbnail"
                        :alt="icon.originalname"
                        v-for="(icon, index) in icons"
                        :key="index"
                        @click="iconSelected(icon.file_path)"
                      />
                    </div>
                  </div>
                </nav>
              </div>
              <div class="form-group col-md-5">
                <img v-bind:src="icon" class="img wd-60 ht-60 rounded-circle" alt />
              </div>
            </div>
          </template>   

        <CustomForm
          :pages="Channel.wizard.pages"
          :action="Channel.wizard.action"
          :buttonText="Channel.wizard.actionBtn"
          :showTitle="false"
        >
      
        </CustomForm>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.Channel = Models.Channel;
     console.log('model', Models.Channel)
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Channel.wizard.action) {
        console.log(payload);
        await this.saveChannel(payload.formData);
      }
    });
  },
  data() {
    return {
      Channel: {},
      icons: [],
      icon: "",
    };
  },
  methods: {
    getImg(path) {
      return req.getParsedUrl(path);
    },
    iconSelected(icon) {
      this.icon = req.getParsedUrl(icon);
    },
    getIcons() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "icons",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.icons = res.data.result;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    saveChannel(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_channel",
            action: "add",
            data: {
              icon: this.icon,
              channel_name: data.name,
              channel_code: data.code,
              description: data.description,
              category: data.category,
              account_number: data.account,
              channel_transaction_limit: data.per_transaction_limit,
              details: [data.other_details],
              supported_currency: data.currency,
              thirdparty_provider: data.third_party,
              support_refund: data.refund_allowed,
            },
            meta_data: {},
          });
          console.log("saving...channel")

//           await this.fetchChannels();

          this.$router.push({
              name: "Configs-Transactions-Channels",
              params: {}
          }); 
          
          notification.notify(
            "success",
            "Channel Added",
            `The channel ${data.name} was successfully added`
          );
          
    

          Event.$emit("form:action-complete", {
            action: this.Channel.wizard.action,
            clear: true,
          });
          this.showModal = false;


        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.Channel.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.getIcons();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>