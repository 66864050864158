<template lang="html">
    <div class="roles">
      <div>
        <div class="contents">   
           <div>Something went wrong. Please try again!</div>           
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="$router.go(-1)">
                    <i data-feather="mail" class="wd-10 mg-r-5"></i> Retry
                </button>
            </div>       
        </div>
      </div>      
    </div>
</template>

<script>
export default {

  data() {
    return {
      Role: {},
      roles: [],
    };
  },
  methods: {
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}
</style>
