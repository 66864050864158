<template>
  <div class="view-user">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Chat User</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-4">
            <img
              src="/img/placeholder.jpg"
              class="rounded-circle mx-auto d-block mg-t-10"
              style="width: 80px;"
            />
          </div>
          <div class="col-md-8">
            <div class="media mg-b-20">
              <div class>Chat User Desc :</div>
              <div class="media-body pd-l-15">
                <h6 class>Best in Motor</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Staff Ref :</div>
              <div class="media-body pd-l-15">
                <h6 class>RK003</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>Robert Kamau</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Online Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>ONLINE</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-10">
            <div class="mdia mg-b-20">
              <div class>Description :</div>
              <div class="mdia-body d-block pd-t-10">
                <h6
                  class
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod, at qui, reiciendis repellat doloremque minus explicabo quo saepe totam cumque illo neque nihil aliquam eveniet labore iste alias itaque tempore dolore modi amet deleniti. Non ratione eius, laboriosam laborum distinctio laudantium aut? In, debitis.</h6>
              </div>
            </div>
            <!-- media -->
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <button class="btn btn-sm">
            <i data-feather="file"></i> Remove
          </button>
          <button class="btn btn-sm mg-l-20">
            <i data-feather="file"></i> Chat History
          </button>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
  </div>
</template>

<script>
export default {
  methods: {
    approveUser() {
      console.log("approveUser");
    },
    editUser() {
      console.log("editUser");
    },
    activateUser() {
      console.log("activateUser");
    },
    deactivateUser() {
      console.log("deactivateUser");
    },
  },
  async mounted() {
    //await this.myServiceProviders();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>