<template lang="html">
   <div class="queues">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div class="query">
            <select class="custom-select" 
               v-model="filter"
               v-validate="'required'" 
               name="account query entity">
                  <option value="MPESA">M-Pesa</option>
            </select>
            <button class="btn btn-primary"
               @click="queryPaymentQueues()"
               :disabled="loading">
                  Query <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
            </button>
         </div>
      </div>

      <CustomTable :tableFields="PaymentQueues.table.table_fields"
         :icon="PaymentQueues.table.icon"
         :title="PaymentQueues.table.title"
         :subtitle="PaymentQueues.table.subtitle"
         :tableData="queues" 
         :searchFields="PaymentQueues.table.searchFields"
         :rowData="PaymentQueues.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
	async created() {
		this.PaymentQueues = Models.PaymentQueues;

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.PaymentQueues.wizard.action) {
				await this.saveAccount(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			this.showModal = false;
		});

		Event.$on('account:view',(payload) => {
			this.$router.push({name: 'Configs-Accounting-ViewAccount',params: {id: payload.account.account_number}})
		})
	},
	data() {
		return {
			PaymentQueues: {},
			queues: [],
			showModal: false,
			filter: "MPESA",
			loading: false
		};
	},
	methods: {
		addAccount() {
			this.showModal = true;
		},
		queryPaymentQueues() {
			return new Promise(async (resolve, reject) => {
				try {
					this.loading = true;

					let res = await req.callServerSecure({
						resource: "queue",
						action: "payments",
						data: {
							filter:{
                        channel: this.filter
                     },
                     limit: 100,
                     sort: {created_date: 1}
						},
						meta_data: {}
					});

               this.queues = res.data.result;
					this.loading = false;
				} catch (err) {
					this.loading = false;
				}
			});
		}
	},
	async mounted() {
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.queues {
	.query {
		display: flex;
		justify-content: left;

		.btn {
			margin: 0 0 0 20px;
			text-align: center;
			display: flex;
			justify-content: center;
		}
		.custom-select {
			float: left;
			min-width: 200px;
		}
	}
}
</style>