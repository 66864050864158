<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Sub General Ledger </li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">List Of Sub General Ledger</h4>
            </div>
            <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="add()"
                >Add</button>
        </div>
                <div class="table-responsive">
                     <table class="table ml-table ml-responsive-md">
                        <thead  class="thead-primary">
                                <tr>
                             <th scope="col">No.</th>
                            <th scope="col">Code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in subgls">
                                <th scope="row">{{ index +1 }}</th>
                                <td>{{ item.sub_gl_header_code }}</td>
                                <td>{{ item.sub_gl_header_name }}</td>
                                 <td>{{ item.sub_gl_header_description }}</td>
                 
                            </tr>
       
                        </tbody>
                        </table>
         </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             subgls:[]
         }

      },
    methods: {
     add() {
        this.$router.push({ name: 'accounting.subgl.addSubGl' })
     },
    async fetchGl () {
         try {
         let resp = await req.callServerSecure({
            resource: "sub_gl",
            action: "list",
            data: {},
            meta_data: {},
          });

                  this.subgls=resp.data.result.sub_gl_headers
         }
         catch (err) {
         console.log(err)
         }
     }
    }, 
    async mounted(){
         await this.fetchGl()
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>