<template lang="html">
  <div class="login">
    <div class="content content-fixed content-auth">
      <div class="container">
        <div
          class="media align-items-stretch justify-content-center ht-100p pos-relative"
        >
          <div class="media-body align-items-center">
            <div class="mx-wd-600">
              <img src="img/login.svg" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div class="wd-100p">
              <h3 class="tx-color-01 mg-b-5">Sign In</h3>
              <p class="tx-color-03 tx-16 mg-b-40">
                Welcome to Nexus! Sign in to continue.
              </p>

              <div class="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  class="form-control"
                  name="login email"
                  placeholder="yourname@yourmail.com"
                  v-model="login_email"
                
                />
          
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mg-b-5">
                  <label class="mg-b-0-f">Password</label>
                </div>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter your password"
                  name="login password"
                  v-model="login_password"
               
                  @keyup.enter="logIn()"
                />
             
              </div>
              <div class="divider-text">
                <a href="/forgotpassword" class="tx-13">Forgot password?</a>
              </div>
              <button class="btn btn-brand-02 btn-block" @click="logIn()">
                Sign In
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CustomFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      login_password: '',
      login_email: '',
    };
  },
  methods: {
    forgotPassword() {},

    async logIn() {
      let _this = this;
      this.loading = true;

      // check why validator is not validating
     // this.$validator.validate('login email', this.login_email);
    //  this.$validator.validate('login password', this.login_password);

      await this.$nextTick();
    
        try {
          let res = await req.callServer({
            resource: 'login',
            action: 'login',
            data: {
              username: _this.login_email,
              password: _this.login_password,
              device_details: {
                device_type: 'Chrome',
                device_category: 'WEB',
                device_version: 'V1',
                device_name: 'CHROME',
                device_uuid: '1223455',
              },
            },
          });

          _this.login_email = '';
          _this.login_password = '';

          // set the token
          let results = res.data.result;
          await $session.set('token', results.token);
          await $session.set('user', results.user_details);
          this.loading = false;

          // navigate to dashboard
          this.$router.push({ name: 'Dashboard' });
        } catch (err) {
          console.log(err);
          this.loading = false;
          if (err.data && err.data.err_code === 'USER_STATUS') {
            notification.notify(
              'error',
              'User Status Error',
              `User is ${err.data.err_msg}`
            );
          }
        }
     
    },
  },
  mounted() {
    $session.clear();
  },
};
</script>

<style lang="less">
@media (min-width: 992px) {
  .login {
    .content-fixed {
      margin-top: 0 !important;
    }
    .app {
      position: fixed;
      bottom: 0px;
      left: 0;
      right: 0;
    }
  }
}
</style>
