<template lang="html">

    <div class="reccomendation">
	    <ModalForm :showModal="showModal" :action="recomendation.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="recomendation.wizard.pages" 
               :action="recomendation.wizard.action" 
               :buttonText="recomendation.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Form Fields</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Form Fields</h4>
            </div>
            <div class="d-none d-md-block">
                
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" @click="createReccomendation()">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Add Recommendation
                </button>
            </div>
        </div>

           <CustomTable :tableFields="recomendation.table.table_fields"
				:icon="recomendation.table.icon"
				:title="recomendation.table.title"
				:subtitle="recomendation.table.subtitle"
				:tableData="recomendations" 
				:searchFields="recomendation.table.searchFields"
				:rowData="recomendation.table.rowData">
			</CustomTable>
    </div>
</template>

<script>
export default {
    	async created(){

	     this.recomendation = Models.reccomendation,
         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

        Event.$on('view_reccomendation:view',async (payload) => {
			console.log('Payload: ',payload)
			this.$router.push({name: 'ViewReccomendation', params: {id: payload.reccomendation.reccomendation_code}})
		})


         

             Event.$on('form:submit', async (payload) => {
                    if(payload.action === this.recomendation.wizard.action){
                        try {
                            let forms= await this.saveReccomendation(payload.formData) ;
                             this.showModal = false;              
                        }
                        catch (err) {
                            Event.$emit('form:action-error',{action: this.recomendation.wizard.action})
                             this.showModal = false;  
                        }
                                
                    }
                })
        },
      data() {
		 return {
	      recomendations:[],
		  showModal: false
		 }
	},
    methods: {

        createReccomendation () {
              this.showModal = true
        },
        fetchReccomendations() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "reccomendation",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.recomendations = res.data.result.recomendations;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		},
       async saveReccomendation (rec_data) {
          return new Promise(async (resolve,reject) => {
            try {
	            let res = await req.callServerSecure({
						resource: "reccomendation",
						action: "add",
						data: {
                                reccomendation_code:rec_data.reccomendation_code,
                                reccomendation_name:rec_data.reccomendation_name,
                                reccomendation_description:rec_data.reccomendation_description,
                                reccomendation_label:rec_data.reccomendation_label
                      },
						meta_data: {}
               });
               
			    this.fetchReccomendations()
			   	Event.$emit("loader:show", false);
				   resolve(true)
            }
            catch (err) {
                    notification.notify('error', 'Error Saving Reccomendation', `Kindly Retry Later`);
                    resolve(true)
            }
         })
      }
    }
    ,
	async mounted(){
        this.fetchReccomendations()
		Event.$emit('loader:show', false)
	}

    };
</script>

<style lang="less">
.reccomendation {
}
</style>