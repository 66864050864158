<template lang="html">
   <div class="forms">
      <!-- Will add page Navigation -->
      <div class="page-navigator"></div>

      <ModalForm :showModal="showModal" :action="Select.action">
         <template slot="form">
            <SelectForm 
               :pages="Select.pages" 
               :action="Select.action" 
               :name="Select.name" 
               :buttonText="Select.actionBtn" 
               :showTitle="false">
            </SelectForm>
         </template>
      </ModalForm>

      <slot name="icon"></slot>
      <div class="pages">
         <div class="page" 
            v-if="pages && pages.length > 0"  
            v-for="i in pages.length">
            <span v-show="false">{{i -= 1}}</span>
            
            <!-- Page Content -->
            <div class="page-content" v-show="currentPage === i">
               <!-- Page title -->
               <div class="page-title" v-show="showTitle">
                  <div class="title" v-if="pages[i] && pages[i].title">{{pages[i].title}}</div>
                  <div class="subtitle" v-if="pages[i] && pages[i].subtitle">{{pages[i].subtitle}}</div>
               </div>

               <div class="row">
                  <div class="padded-form" v-if="pages[i] && pages[i].forms" v-for="form in pages[i].forms" :class="form.class || 'col-12'">
                     
                     <!-- Label -->
                     <div class="form-group" v-if="form.type === 'label'">
                        <label class="redial-font-weight-800 redial-dark">{{form.label}}</label>
                     </div>

                     <!-- Select Input form -->
                     <div class="form-group" v-if="form.type === 'select'">
                        <label :for="action+form.model">{{form.label}}</label>
                        <select class="custom-select" 
                           v-model="resource.type_info[form.model]"
                           :id="action+form.model"
                           v-validate="form.validation" 
                           :name="action+' '+form.model"
                           @change="handleSelectChange(form)"                                                      
                           :disabled="(formData.is_child_of===false && form.model==='parent') ? true : false">
                              <option disabled value="">Select {{form.label}}</option>
                              <option v-for="option in form.options" :value="option.val">{{option.label}}</option>
                        </select>
                     </div>
                     
                     <!-- Radio Buttons -->
                     <div v-if="form.type === 'radio'">
                        <label class="d-block">{{form.label}}</label>
                        <div class="custom-control custom-radio custom-control-inline" v-for="radio in form.options">
                           <input 
                              type="radio" 
                              class="custom-control-input"
                              :name="action+' '+form.model" 
                              :id="action+' '+form.model+radio.id" 
                              v-model="resource.validation[form.model]" 
                              :value="radio.val"
                              v-validate="form.validation"                              
                            >
                           <label class="custom-control-label" :for="action+' '+form.model+radio.id">{{radio.label}} </label>
                        </div>
                     </div>

                     <!-- Checkbox input form -->
                     <div v-if="form.type === 'check'">
                        <label class="d-block">{{form.label}}</label>
                        <div class="custom-control custom-checkbox custom-control-inline" v-for="option in form.options">
                           <input 
                              class="custom-control-input"
                              type="checkbox" 
                              :id="option.id"
                              v-model="resource[form.model]" 
                              :value="option.val">                              
                           <label :for="option.id" class="custom-control-label">{{option.label}}</label>
                        </div>
                     </div>

                     <!-- KeyValue input form -->
                     <div v-if="form.type === 'keyValue'">
                        <label :for="action+form.model">{{form.label}}</label>
                        <div class="form-inline">
                          <input 
                            :type="form.form_type" 
                            class="form-control col-md-5 mg-r-10"
                            :id="action+form.model"
                            :placeholder="'Key'"
                            v-model="formData[form.model]['name']"
                            :name="action+' '+form.model">
                          <input 
                            :type="form.form_type" 
                            class="form-control col-md-5 mg-l-10"                            
                            :placeholder="'Value'"
                            v-model="formData[form.model]['value']"
                            :name="action+' '+form.model">
                        </div>
                     </div>

                     <!-- Multiple fields array component -->
                     <div class="form-group" v-if="form.type === 'array' && form.pages && form.table">
                        <input 
                           type="hidden"
                           v-model="resource[form.model]"
                           :name="action+' '+form.model"
                           v-validate="form.validation">
                        <TableForm 
                           :pages="form.pages" 
                           :action="form.action" 
                           :buttonText="form.actionBtn" 
                           :showTitle="false"
                           type="table"
                           :model="form.model">
                        </TableForm>
                        <CustomTable :tableFields="form.table.table_fields" 
                           :tableData="formData[form.model]" 
                           :searchFields="form.table.searchFields"
                           :rowData="form.table.rowData">
                        </CustomTable>
                     </div>


                     <!-- Cleave Component -->
                     <div class="form-group" v-if="form.type === 'cleave' && form.options">
                        <label :for="action+form.model">{{form.label}}</label>
                        <cleave 
                           :type="form.form_type"
                           v-model="resource[form.model]" 
                           :options="form.options" 
                           :id="action+form.model"
                           class="form-control" 
                           :name="action+' '+form.model"
                           v-validate="form.validation"></cleave>
                     </div>
                     
                     <!-- Date Picker -->
                     <div class="form-group" v-if="form.type === 'date'">
                        <div class="row">
                           <div :class="{'col-12': !form.hasTime,'col-6': form.hasTime}">
                              <label :for="action+form.model+'_date'">{{form.label ||'Date'}}</label>
                              <cleave 
                                 type="text"
                                 v-model="formData[form.model]['date']" 
                                 :options="date_options" 
                                 :id="form.model+'_date'"
                                 placeholder="dd-mm-yyyy"
                                 class="form-control" 
                                 :name="action+' '+form.model+'_date'"
                                 v-validate="'required|length:8'">
                              </cleave>
                              <small class="text-danger" 
                                 v-show="errors.has(action+' '+form.model+'_date')">
                                    {{errors.first(action+' '+form.model+'_date')}}
                              </small>
                           </div>
                           <div v-if="form.hasTime" :class="{'col-12': !form.hasTime,'col-6': form.hasTime}">
                              <label :for="action+form.model+'_time'">Time</label>
                              <cleave 
                                 type="text"
                                 v-model="formData[form.model]['time']" 
                                 :options="time_options" 
                                 :id="form.model+'_time'"
                                 class="form-control"
                                 placeholder="hh:mm"
                                 :name="action+' '+form.model+'_time'"
                                 v-validate="'required|length:4'">
                              </cleave>
                              <small class="text-danger" 
                              v-show="errors.has(action+' '+form.model+'_time')">
                                 {{errors.first(action+' '+form.model+'_time')}}
                              </small>
                           </div>
                        </div>
                     </div>

                     <!-- File Picker -->
                     <div class="form-group" v-if="form.type === 'file'">
                        <label>{{form.label}}</label>
                        <div class="custom-file" @click="openFileUploader(form)">
                           <input type="hidden" 
                              :v-model="resource[form.model]" 
                              :name="action+' '+form.model" 
                              v-validate="form.validation">
                           <input type="text" 
                              class="custom-file-input" 
                              :id="action+form.model"
                              disabled>
                           <label class="custom-file-label" :for="action+form.model">{{form.placeholder || 'Select File'}}</label>
                        </div>
                     </div>

                     <!-- Location Picker -->
                     <div class="form-group" v-if="form.type === 'location'">
                        <label>{{form.label}}</label>
                        <div class="custom-file" @click="openMap(form)">
                           <input type="hidden" 
                              :v-model="resource[form.model]" 
                              :name="action+' '+form.model" 
                              v-validate="form.validation">
                           <input type="text" 
                              class="custom-file-input" 
                              :id="action+form.model"
                              disabled>
                           <label class="custom-file-label" :for="action+form.model">{{form.placeholder || 'Select Location'}}</label>
                        </div>
                     </div>

                     <!--  Color Picker -->
                     <div class="form-group" v-if="form.type === 'color'">
                        <label>{{form.label}}</label>
                        <div class="custom-file" @click="openColorPicker(form)">
                           <input type="hidden" 
                              :v-model="resource[form.model]" 
                              :name="action+' '+form.model" 
                              v-validate="form.validation">
                           <label class="custom-file-label"  
                              :style="{background: formData[form.model]}" 
                              :for="action+form.model">
                                 {{form.placeholder || 'Pick a color'}}
                           </label>
                        </div>
                     </div>

                     <!-- Text input form -->
                     <div class="form-group" v-if="form.type === 'textarea'">
                        <label :for="action+form.model">{{form.label}}</label>
                        <textarea 
                           class="form-control"
                           :id="action+form.model"
                           :rows="form.rows || 3"
                           :placeholder="form.placeholder || form.label || 'Placeholder'"
                           v-model="resource[form.model]"
                           :name="action+' '+form.model"
                           v-validate="form.validation">
                        </textarea>
                     </div>

                     <!-- Text input form -->
                     <div class="form-group" v-if="form.type === 'text'">
                        <label :for="action+form.model">{{form.label}}</label>
                        <input 
                           :type="form.form_type" 
                           class="form-control"
                           :id="action+form.model"
                           :placeholder="form.placeholder || form.label || 'Placeholder'"
                           v-model="resource[form.model] "
                           :name="action+' '+form.model"
                           v-validate="form.validation">
                     </div>

                     <div class="form-group" v-if="form.type === 'country'">
                        <label class="redial-font-weight-800 redial-dark">{{form.label}}</label>
                        <v-select label="name" 
                           :options="countries" 
                           v-model="resource[form.model]" 
                           :name="action+' '+form.model" 
                           v-validate="form.validation">
                              <template slot="option" slot-scope="option">
  <img class="flag-image" :src="option.flag" />
  {{ option.name }}
</template>
                        </v-select>
                     </div>

                     <div class="form-group" v-if="form.type === 'mobile'">
                        <label class="redial-font-weight-800 redial-dark">{{form.label}}</label>
                        <div class="row">
                           <div class="col-sm-5">
                              <v-select label="name"
                                 :options="countries" 
                                 v-model="formData[form.model]['country_code']" 
                                 :name="'country_code_'+action+' '+form.model" 
                                 placeholder="Country Code"
                                 v-validate="'required'">
                                    <template slot="option" slot-scope="option">
  <img class="flag-image" :src="option.flag" />
  {{ option.callingCodes[0] }}
</template>
                                    <template slot="selected-option" slot-scope="option">
  <div class="selected d-center">{{ option.callingCodes[0] }}</div>
</template>
                              </v-select>
                              <small class="text-danger" 
                                 v-show="errors.has('country_code_'+action+' '+form.model)">
                                    {{errors.first('country_code_'+action+' '+form.model)}}
                              </small>
                           </div>
                           <div class="col-sm-7">
                              <input 
                                 type="number" 
                                 class="form-control"
                                 :placeholder="form.placeholder || form.label || 'Placeholder'"
                                 v-model="formData[form.model]['number']"
                                 :name="action+' '+form.model"
                                 v-validate="form.validation">
                              <small class="text-danger" v-show="errors.has(action+' '+form.model)">{{errors.first(action+' '+form.model)}}</small>
                           </div>
                        </div>
                        
                     </div>

                     

                     <div class="form-group" v-if="form.type === 'image'">
                        <label :for="action+form.model">{{form.label}}</label>
                        <div class="img-uploader clearfix">
                           <div class="left">
                              <img v-show="!formData[form.model]" :src="'/img/placeholder.jpg'" class="profile-img">
                              <img v-show="formData[form.model]" :src="formData[form.model]" class="profile-img">
                           </div>
                           <div class="right upload-btn" @click="openImgModal">Upload</div>
                           <UploadImage field="img"
                              @crop:success="cropSuccess"
                              :model="form.model"
                              v-model="showImgModal"
                              :name="action+' '+form.model"
                              v-validate="form.validation"
                              :img-format="form.imageFormat || 'png'">
                           </UploadImage>
                        </div>
                     </div>

                     <!-- validation errors -->
                     <small v-if="form.type !== 'mobile' && form.type !== 'date'" class="text-danger" v-show="errors.has(action+' '+form.model)">{{errors.first(action+' '+form.model)}}</small>
                  </div>

                  <div class="buttons-area">
                     <!-- Previous button -->
                     <button v-if="pages.length > 1" 
                        v-show="showStepBtns && i > 0" 
                        class="btn btn-secondary"
                        @click="previousPage()"
                        :disabled="loading">
                           Previous <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>

                     <!-- Next button -->
                     <button v-if="pages.length > 1" 
                        v-show="showStepBtns && i < (pages.length - 1)" 
                        class="btn btn-primary f-r"
                        @click="nextPage()"
                        :disabled="loading">
                           Next <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>

                     <!-- Submit button -->
                     <button class="btn btn-primary f-r" 
                        @click="submit()" 
                        v-show="i === (pages.length - 1)"
                        :disabled="loading">
                           {{buttonText}} <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-md-6">
      </div>
   </div>
</template>

<script>
export default {
  async created() {    
    let _this = this;
    this.countries = await $session.get("countries");

    // if specified action is complete clear form data and stop loader
    Event.$on(
      "form:action-complete",
      (payload) => {
        console.log("Complete", payload);
        if (payload.action === _this.action) {
          if (payload.clear) {
            _this.clearData();
          }
          _this.currentPage = 0;
          _this.loading = false;
          _this.showStepBtns = true;
        }
      },
      _this.action
    );

    Event.$on(
      "form:action-error",
      (payload) => {
        if (payload.action === _this.action) {
          _this.currentPage = 0;
          _this.loading = false;
          _this.showStepBtns = true;
        }
      },
      _this.action
    );

    Event.$on(
      "file:uploaded",
      (payload) => {
        if (payload.model) {
          _this.formData[payload.model] = payload.file;
          Event.$emit("web:upload", payload.file);
        }
      },
      _this.action
    );

    Event.$on(
      "data:select",
      async (payload) => {
        if (!payload.validation) {
          this.formData[this.currentSelectForm.model] = "";
        }
        _this.formData = Object.assign({}, _this.formData, payload.formData);
        this.showModal = false;
      },
      _this.action
    );

    Event.$on(
      "arr_table:delete",
      (payload) => {
        if (payload.item.model) {
          let res = this.deleteFromArray(
            _this.formData[payload.item.model],
            payload.item.id
          );
          _this.formData[payload.item.model] = res;
        }
      },
      _this.action
    );

    Event.$on(
      "add:array",
      async (payload) => {
        console.log(payload.model && _this.formData[payload.model]);
        if (payload.model && _this.formData[payload.model]) {
          _this.formData[payload.model].push(payload.formData);
        }
      },
      _this.action
    );

    Event.$on(
      "map:location",
      (payload) => {
        if (payload.model) {
          _this.formData[payload.model] = payload.location;
        }
      },
      _this.action
    );

    Event.$on(
      "color-picker:color",
      (payload) => {
        if (payload.model) {
          _this.formData[payload.model] = payload.color;
        }
      },
      _this.action
    );
  },
  props: {
    action: {
      default: "form-action",
    },
    buttonText: {
      default: "save",
    },
    showTitle: {
      default: true,
    },
    resource: {      
      default: function () {
        return {};
      },      
    },
    pages: {
      default: function () {
        return [];
      },
    },
    forms: {
      default: function () {
        return [];
      },
    },
    data: {
      default: function () {
        return undefined;
      },
    },
  },
  data() {
    return {
      Select: {
        pages: [
          {
            title: "",
            subtitle: "",
            name: "",
            forms: [],
          },
        ],
        action: "select",
        actionBtn: "Update",
      },
      resourceUpdate: this.resource,
      currentSelectForm: {},
      showModal: false,
      currentPage: 0,
      loading: false,
      showStepBtns: true,
      country: null,
      showImgModal: false,
      countries: [],
      date_options: {
        date: true,
        datePattern: ["d", "m", "Y"],
        delimiter: "-",
      },
      time_options: {
        time: true,
        timePattern: ["h", "m"],
      },
      formData: {},
    };
  },
  watch: {
    pages: function (to, from) {
      this.setupFormData();

      let page = this.pages[0];
      if (page) {
        Event.$emit("page-changed:" + this.action, {
          page: 0,
          title: page.title,
          subtitle: page.subtitle,
          icon: page.icon,
        });
      }
    },
  },
  methods: {
    deleteFromArray(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        const el = arr[i];

        if (el.id === id) {
          arr.splice(i, 1);
        }
      }

      return arr;
    },
    handleSelectChange(form) {
      this.currentSelectForm = form;
      let selectForms = this.formData[form.model].forms;

      if (selectForms) {
        this.Select.pages = selectForms.pages;
        this.Select.name = selectForms.name;
        this.showModal = true;
        console.log(selectForms, this.Select, this.showModal);
      }
    },
    openImgModal() {
      this.showImgModal = true;
    },
    cropSuccess(payload) {
      if (payload.createImgUrl) {
        this.formData[payload.model] = payload.createImgUrl;
        console.log(payload.model);
        Event.$emit("web:upload", payload.file);
      }
    },
    setupFormData() {      
      if (!this.data) {
        let formData = {};

        if (this.pages) {
          for (let i = 0; i < this.pages.length; i++) {
            const page = this.pages[i];

            if (page && page.forms) {
              for (let j = 0; j < page.forms.length; j++) {
                const form = page.forms[j];
                console.log(form);

                if (form.type === "mobile") {
                  formData[form.model] = {
                    country_code: "",
                    number: "",
                  };
                } else if (form.type === "date") {
                  formData[form.model] = {
                    date: "",
                    time: "",
                  };
                } else if (form.type === "keyValue") {
                  formData[form.model] = {
                    name: "",
                    value: "",
                  };
                } else if (form.type === "check") {
                  formData[form.model] = [];
                } else if (form.type === "label") {
                  // do nothing
                } else if (form.type === "color") {
                  formData[form.model] = "#de0b31";
                } else if (form.type === "array") {
                  formData[form.model] = [];
                } else {
                  formData[form.model] = "";
                }
              }
            }
          }
        }

        this.formData = formData;
        console.log(this.formData);
      } else {
        this.formData = this.data;
      }
    },
    openFileUploader(form) {
      Event.$emit("file:upload", { form });
    },
    openMap(form) {
      Event.$emit("map:show", { form });
    },
    openColorPicker(form) {
      console.log("Showing color picker");
      Event.$emit("color-picker:show", { form });
    },
    emitPageChange() {
      let page = this.pages[this.currentPage];
      Event.$emit("page-changed:" + this.action, {
        page: this.currentPage,
        title: page.title,
        subtitle: page.subtitle,
      });
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.emitPageChange();
      }
    },
    clearData() {
      let formData = {};

      for (let i = 0; i < this.pages.length; i++) {
        const page = this.pages[i];

        for (let j = 0; j < page.forms.length; j++) {
          const form = page.forms[j];

          formData[form.model] = "";

          if (form.type === "file") {
            form.placeholder === "Select File";
          }
        }
      }

      this.formData = formData;
    },
    validateForm(form) {
      if (form.type === "mobile") {
        this.$validator.validate(
          this.action + " " + form.model,
          this.formData[form.model]["number"]
        );
        this.$validator.validate(
          "country_code_" + this.action + " " + form.model,
          this.formData[form.model]["country_code"]
        );
      } else if (form.type === "date") {
        this.$validator.validate(
          this.action + " " + form.model + "_date",
          this.formData[form.model]["date"]
        );
        if (form.hasTime) {
          this.$validator.validate(
            this.action + " " + form.model + "_time",
            this.formData[form.model]["time"]
          );
        }
      } else {
        this.$validator.validate(
          this.action + " " + form.model,
          this.formData[form.model]
        );
      }
    },
    async nextPage() {
      if (this.currentPage < this.pages.length - 1) {
        this.loading = true;

        for (let i = 0; i < this.pages[this.currentPage].forms.length; i++) {
          const form = this.pages[this.currentPage].forms[i];

          if (form.validation && form.validation.length > 0) {
            this.validateForm(form);
          }
        }

        await this.$nextTick();
        if (utils.isValid(this.errors.items, this.action)) {
          this.currentPage++;
          this.emitPageChange();
        }

        this.loading = false;
      }
    },
    async submit() {
      this.showStepBtns = false;
      this.loading = true;
      let forms = [];

      for (let i = 0; i < this.pages.length; i++) {
        const page = this.pages[i];

        if (page.forms) {
          forms = forms.concat(page.forms);
        }
      }

      for (let i = 0; i < forms.length; i++) {
        const form = forms[i];

        if (form.validation && form.validation.length > 0) {
          this.validateForm(form);
        }
      }

      await this.$nextTick();
      if (utils.isValid(this.errors.items, this.action)) {
        Event.$emit("form:submit", {
          action: this.action,
          formData: this.formData,
        });
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {      
    this.setupFormData();
    Event.$emit("page-changed:" + this.action, {
      page: this.currentPage,
      title: this.pages[0].title,
      subtitle: this.pages[0].subtitle,
    });
  },
};
</script>

<style lang="less">
.forms {
  .flag-image {
    width: 24px;
    margin-right: 4px;
  }
  .buttons-area {
    padding: 20px 30px;
    width: 100%;

    .f-r {
      float: right !important;
    }
  }
  .img-uploader {
    margin: 5px 0;
    height: 100%;

    .left {
      float: left;
    }
    .right {
      float: left;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 22px 0 0 10px;
    }
    .right:hover {
      color: var(--primary);
    }
    .profile-img {
      max-width: 85px;
      max-height: 85px;
      width: auto;
      height: auto;
    }
  }
}
</style>