<template>
  <div class="view-topup">
    <div
      class="
        d-sm-flex
        align-items-center
        justify-content-between
        mg-b-20 mg-lg-b-25 mg-xl-b-30
      "
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>

            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Entities-SchoolJobList' }">
                School Jobs List
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              View School Job
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div>
      <div class="media">
        <img
          class="mr-3"
          :src="getImg(schooljob.school_image)"
          :alt="schooljob.school_name"
        />
        <div class="media-body">
          <h5 class="text-capitalize">Job Name: {{ schooljob.username || "Awesome Job" }}</h5>
            <p class="text-muted">
                
            {{ schooljob.school_description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, ab. Voluptatibus, voluptas?" }}
          </p>
          <div class="row">
            <div class="col-12 py-2">
              ID: <strong>{{ schooljob.entity || "124325" }}</strong>
            </div>
            <div class="col-12 py-2">
              JOB STATUS:
              <span class="badge badge-success mb-2">{{
                schooljob.status || "Currentyly Runnung"
              }}</span>
            </div>
            <div class="col-12 py-2">
              Last Run Date:
              <span>{{ schooljob.created_date || "2021/09/21 09:30:12" }}</span>
            </div>
          
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs">
              <div class="col-lg-12 col-xl-12 mg-t-10">
                <CustomTable
                  :tableFields="table_fields"
                  :tableData="students"
                  :title="`School Jobs Logs`"
                  :subtitle="`School Jobs Logs`"
                  :searchFields="searchFields"
                  :rowData="rowData"
                >
                </CustomTable>
              </div>
            </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schooljob: {},
      students: [],
    searchFields: ["status", "run_by"],
      table_fields: [
        // { name: 'Image', align: 'l' },
        { name: "Status", align: "c" },
        { name: "Start Date", align: "c" },
        { name: "End Date", align: "c" },
        { name: "Run By", align: "c" },
        { name: "Details", align: "c" },
      ],
      rowData: [
        { type: "status", field: "student_grade", class: "small center" },
        { type: "status", field: "student_grade", class: "small center" },
        { type: "text", field: "student_id", class: "small center" },
        { type: "text", field: "student_name", class: "small center" },
        { type: "text", field: "studentage", class: "small center" },
        
      ],
    };
  },
  methods: {
    fetchSchool() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "school",
            action: "get_school_details",
            data: {
              school_system_ref: this.$route.params.id,
            },
          });
          this.school = res.data.result.school_details;
          this.accounts = res.data.result.school_accounts;
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },

    getImg(path) {
      return `${domain}/${path}`;
    },
  },
  async mounted() {
    // await this.fetchSchool();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>