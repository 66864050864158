

<template>
  <div class="contact-content">
    <div class="contact-content-header">
      <nav class="nav nav-line">
        <a href="#details" class="nav-link active" data-toggle="tab"
          > Service Provider Configuration Summary</a
        >
        <a href="#accountConfig" class="nav-link" data-toggle="tab"
          >Account Template Configuration</a
        >
        <a href="#feeConfig" class="nav-link" data-toggle="tab"
          >Fee Configuration</a
        >
        <a href="#securityConfig" class="nav-link" data-toggle="tab"
          >Security Configuration</a
        >
      </nav>
      <a href="" id="contactOptions" class="text-secondary mg-l-auto d-xl-none"
        ><i data-feather="more-horizontal"></i
      ></a>
            <div class="contact-content-body bg-gray-100">
                <div class="tab-content">
                 
                        <div id="details" class="tab-pane show active pd-20 pd-xl-25">
                            <h6 class="mg-t-10 mg-b-20">Details</h6>
                            
                            
                                <ul class="list-group">
                                <li class="list-group-item"> Account Opening Setup  <span class="badge badge-warning"> Pending</span></li>
                                <li class="list-group-item">Fees  Setup <span class="badge badge-warning"> Pending</span></li>
                                <li class="list-group-item">Security  Setup <span class="badge badge-warning"> Pending</span></li>
                                </ul>
                                <div class="form-row">
                                  <div class="form-group col-md-4">
                                   <label>Default Account Opening Currency </label>
                                      <select
                                          class="custom-select"
                                          v-model="entityDefault.currency"
                                        >
                                          <option
                                            v-for="curr in currencies"
                                            v-bind:value="curr.currency_iso_code"
                                          >
                                            {{ curr.name }}
                                          </option>
                                        </select>
                                  </div>
         
                                 </div>
                              <button class="btn btn-primary" @click="saveDefaultEntityConfig()"> Save Default </button>
                        </div>
                        
                   <div id="accountConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Account Templates</h6>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <div class="form-row">
                                       <label>Select Currency</label>
                                        <select
                                            class="custom-select"
                                            v-model="accountTemplate.currency"
                                          >
                                            <option
                                              v-for="curr in currencies"
                                              v-bind:value="curr.currency_iso_code"
                                            >
                                              {{ curr.name }}
                                            </option>
                                          </select>
                                      </div>   

                                      <div class="form-row">
                                       <label>Select Service</label>
                                        <select
                                            class="custom-select"
                                            v-model="accountTemplate.entity_type"
                                          >
                                            <option
                                              v-for="service in InsuranceService"
                                              v-bind:value="service.service_code"
                                            >
                                              {{ service.service_name }}
                                            </option>
                                          </select>
                                      </div>
                                 </div>
                                  <div class="form-group col-md-8">
                                     <div class="form-row">
                                       <div class="form-group col-md-4"> 
                                          <label>Operating Wallet</label>
                                          <select
                                              class="custom-select"
                                              v-model="accountTemplate.template.OPERATING"
                                            >
                                              <option
                                                v-for="accountTemplate in accountTemplates"
                                                v-bind:value="accountTemplate.template_code"
                                              >
                                                {{ accountTemplate.template_name }}
                                              </option>
                                            </select>
                                        </div>
                                          <div class="form-group col-md-4"> 
                                          <label>Service Wallet</label>
                                          <select
                                              class="custom-select"
                                              v-model="accountTemplate.template.SERVICEPROVIDER_SERVICE"
                                            >
                                              <option
                                                v-for="accountTemplate in accountTemplates"
                                                v-bind:value="accountTemplate.template_code"
                                              >
                                                {{ accountTemplate.template_name }}
                                              </option>
                                            </select>
                                        </div>
                                          <div class="form-group col-md-4"> 
                                          <label>Withholding Tax Wallet</label>
                                          <select
                                              class="custom-select"
                                              v-model="accountTemplate.template.WITHOLDING_TAX"
                                            >
                                              <option
                                                v-for="accountTemplate in accountTemplates"
                                                v-bind:value="accountTemplate.template_code"
                                              >
                                                {{ accountTemplate.template_name }}
                                              </option>
                                            </select>
                                        </div>



                                            <div class="form-group col-md-4"> 
                                          <label>VAT Wallet</label>
                                          <select
                                              class="custom-select"
                                              v-model="accountTemplate.template.VAT"
                                            >
                                              <option
                                                v-for="accountTemplate in accountTemplates"
                                                v-bind:value="accountTemplate.template_code"
                                              >
                                                {{ accountTemplate.template_name }}
                                              </option>
                                            </select>
                                        </div>

                        


                           

                                          <div class="form-group col-md-4"> 
                                          <label>Stamp Duty Wallet</label>
                                          <select
                                              class="custom-select"
                                              v-model="accountTemplate.template.STAMP_DUTY"
                                            >
                                              <option
                                                v-for="accountTemplate in accountTemplates"
                                                v-bind:value="accountTemplate.template_code"
                                              >
                                                {{ accountTemplate.template_name }}
                                              </option>
                                            </select>
                                        </div>
                                        

                                     </div>
                                 </div>
                            </div>
                            <div class="form-row">
                              <button class="btn btn-primary" @click="saveAccountTemplates()"> Save Template </button>
                            </div>

                            <div class="row" v-for="(value, index) in configuredAccountTemplates" >
                                        <div class="col-sm card">
                                          <div class="card-header"> {{index + 1 }} . <b>Currency Iso Code :</b> {{value.currency}}  <b>Service Type :</b> {{value.entity_type}}  </div>

                                            <div class="card-body">
                                                <div class="row">
                                                   <div class="col-sm-2" v-for="(act, key, indx) in value.template" ><b>{{key}} :</b> {{act}}</div>
                                                  </div>   
                                              </div>
                                         </div>
                            </div> 
                  </div>

                   <div id="feeConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Fees</h6>
                                <div class="form-row">
                                  <div class="form-group col-md-4">  
                                     <div class="form-row">                               
                                       <label>Select Currency</label>
                                        <select
                                            class="custom-select"
                                            v-model="entityfee.currency"
                                            @change="selectFeeEvent"
                                          >
                                            <option
                                              v-for="curr in currencies"
                                              v-bind:value="curr.currency_iso_code"
                                            >
                                              {{ curr.name }} 
                                            </option>
                                        </select>
                                      </div> 

                                       <div class="form-row">                               
                                       <label>Select Service</label>
                                        <select
                                            class="custom-select"
                                            v-model="entityfee.entity_type"
                                          >
                                            <option
                                              v-for="service in InsuranceService"
                                              v-bind:value="service.service_code"
                                            >
                                              {{ service.service_name }}
                                            </option>
                                        </select>
                                      </div> 
 
                                  </div>
                                  <div class="form-group col-md-8">  
                                    <div class="form-row"> 
                                          <div class="form-group col-md-4">                                 
                                            <label>Service Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.fee.SERVICE_FEE"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                    <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                   
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>



                               


                                          <div class="form-group col-md-4">                                 
                                            <label>Entity Advertisement Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.fee.ENTITY_ADVERTISEMENT_FEE"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>

                                          <div class="form-group col-md-4">                                 
                                            <label>Service Advertisement Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.fee.ENTITY_PRODUCT_ADVERTISEMENT_FEE"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>
                                         
                                          <div class="form-group col-md-4">                                 
                                            <label>Entity Topup Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.fee.ENTITY_TOPUP_FEE"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>

                                            <div class="form-group col-md-4">                                 
                                            <label>Entity Withdrawal Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.fee.ENTITY_WITHDRAWAL_FEE"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>


                                    </div>
                       
                                  </div>
                              </div> 
                                  <div class="form-row">
                                        <div class="form-group col-md-4"> 
                                          <button class="btn btn-primary" @click="saveentityfees()"> Save Fees </button>
                                        </div> 
                                  </div> 

                  

                                    <div class="row" v-for="(value, index) in configuredFees" >
                                        <div class="col-sm card">
                                          <div class="card-header"> {{index + 1 }} . <b>Currency Iso Code :</b> {{value.currency}}   <b>Service type :</b> {{value.entity_type}}  </div>

                                            <div class="card-body">
                                                <div class="row">
                                                 <div class="col-sm-2" v-for="(fee, key, indx) in value.fee" ><b>{{key}} :</b> {{fee}}</div>
                                                  </div>   
                                              </div>
                                         </div>
                                     </div>       

                    </div>   

         <div id="securityConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Security</h6>
                              <div class="form-row">
                                  <div class="form-group col-md-4"> 
                                            <label>Default Role </label>
                                                <select
                                                    class="custom-select"
                                                    v-model="security.default_role"
                                                  >
                                                  <option
                                                        v-for="role in roles"
                                                        v-bind:value="role.role_code"
                                                    >
                                                      {{ role.name }}
                                                    </option>
                                                </select>  
                                  </div> 

                                  <div class="form-group col-md-4">  
                                            <label>Default Security Context</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="security.default_security_context"
                                                  >
                                                  <option
                                                        v-for="securityContext in securityContexts"
                                                      v-bind:value="securityContext.security_context_code"
                                                    >
                                                      {{ securityContext.name }}
                                                    </option>
                                                </select>

                                  </div> 

                                    <div class="form-group col-md-4"> 
                                        <label>Enable Global 2 Factor Login</label> 
                                      <div class="custom-control custom-radio">
                                        <input type="radio" id="secYes" v-model="security.global_twofactor_enabled" value="YES" class="custom-control-input" checked>
                                        <label class="custom-control-label" for="secYes">YES</label>
                                      </div>

                                      <div class="custom-control custom-radio">
                                        <input type="radio" id="secNO" v-model="security.global_twofactor_enabled" value="NO" class="custom-control-input">
                                        <label class="custom-control-label" for="secNO">NO</label>
                                      </div>
 
                                  </div> 
                          
                                     
                                        

                              </div>   
                                    <div class="row">
                                      <button class="btn btn-primary" @click="saveSecurity()"> Save Security Config </button>
                                    </div>
                                   <hr>
                                   <div class="row">
                                      <div class="col-sm"><p>Default Role :  {{security.default_role}}</p></div>
                                      <div class="col-sm"><p>Default Security Context : {{security.default_security_context}}</p></div>
                                      <div class="col-sm"><p>Two Factor Enabled  : {{security.global_twofactor_enabled}}</p></div>
                                    </div>
                                    
                   </div>

               </div>   
            </div> 
    </div>  
  </div>     
</template>

<script>
export default {

  data() {
    return {
            InsuranceService:[],
            fees:[],
            entityDefault:{
              currency:""
            },
            feeInputsDisabled:true,
            feeFilteredCurrency:[],
            entityFees:[],
            entityfee:{
              currency:"",
              entity_type:"",
              fee:{}
            },
            securityContexts:[],
            accountTemplates:[],
            accountTemplate:{
              currency:"",
              entity_type:"",
              template:{}
            },
            currencies:[],
            roles:[],
            configuredAccountTemplates:[],
            configuredFees:[],
            security:{
              default_role:"",
              default_security_context:"",
              global_twofactor_enabled:""
            },
    };
  },
  methods: {
        async saveSecurity () {
              let updateData={}
              updateData.security=this.security;
            await this.updateEntityConfig(updateData);

         },
          async saveentityfees() {

                if(this.entityfee.entity_type ==='') {
                  notification.notify('error',"Entity Configuration", "Select Service Type");
                  return ;
                }
               if(this.configuredFees.some(fconf => fconf.currency === this.entityfee.currency && fconf.entity_type === this.entityfee.entity_type)){
                  notification.notify('error',"Entity Configuration", "Entity fee already configured");
                  return ;
               }else{
                this.configuredFees.push(this.entityfee)
                          let updateData={}
                              updateData.fees=this.configuredFees;
                  await this.updateEntityConfig(updateData);
               }
          
        },
         async selectFeeEvent() {
              this.feeInputsDisabled=false;
             // console.log(" ==== this.fees.filter(fee => fee.entity_fee_currency === this.entityfee.currency);",this.fees.filter(fee => fee.entity_fee_currency === this.entityfee.currency))
              this.feeFilteredCurrency=this.fees.filter(fee => fee.entity_fee_currency === this.entityfee.currency );
          },
          async saveDefaultEntityConfig () {
                  let updateData={}
                      updateData.default=this.entityDefault;
                  await this.updateEntityConfig(updateData);
          },
          async  saveAccountTemplates() {

            // act opening templates should not be empty
              if(this.accountTemplate.entity_type === '') {
                notification.notify('error',"Account Template Configuration", "Select the Service Type");
                  return
              }
               if(this.configuredAccountTemplates.some(tconf => tconf.currency === this.accountTemplate.currency && tconf.entity_type === this.accountTemplate.entity_type) ){
                  notification.notify('error',"Account Template Configuration", "The currency already exists");
                  return
               }
                else{

                   this.configuredAccountTemplates.push(this.accountTemplate);
                  let updateData={}
                      updateData.account_opening_template=this.configuredAccountTemplates;
                  await this.updateEntityConfig(updateData);
                }
                

                

            },
          async listroles() {
            return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                    resource: "role",
                    action: "list",
                    data: {},
                    meta_data: {},
                    });

                    this.roles = res.data.result.roles;
                    resolve(true);
                  } catch (err) {
                       reject(err);
                  }
              });  
           },
          fetchSytemFees() {
            return new Promise(async (resolve, reject) => {
              try {
                let res = await req.callServerSecure({
                  resource: "fees",
                  action: "list_fees",
                  data: {},
                  meta_data: {},
                });
                this.fees = res.data.result.entity_fees;
                resolve(true);
              } catch (err) {
                reject(err);
              }
            });
          },
          fetchCurrencies() {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                      resource: "currency",
                      action: "list",
                      data: {},
                      meta_data: {},
                    });

                    this.currencies = res.data.result.currencies;
                    resolve(true);
                  } catch (err) {
                    reject(err);
                  }
                });
              },
        fetchAccountTemplate() {
            return new Promise(async (resolve, reject) => {
              try {
                let res = await req.callServerSecure({
                  resource: "entity_account_template",
                  action: "list",
                  data: {
                    entity:"UNDERWRITER"
                  },
                  meta_data: {},
                });
                this.accountTemplates = res.data.result.templates;
                resolve(true);
              } catch (err) {
                reject(err);
              }
            });
        },
            getEntityConfig() {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                      resource: "sp_config",
                      action: "get",
                      data: {},
                      meta_data: {},
                    });

                   if(res.data.result.account_opening_template) {
                     this.configuredAccountTemplates=res.data.result.account_opening_template
                   }
                   if(res.data.result.fees) {
                      this.configuredFees=res.data.result.fees
                   }
                   if(res.data.result.security) {
                      this.security=res.data.result.security
                   }

                   if(res.data.result.default) {
                      this.entityDefault=res.data.result.default
                   }

                    resolve(true);
                  } catch (err) {
                    reject(err);
                  }
                });
              },
        updateEntityConfig(data) {
          return new Promise(async (resolve, reject) => {
             Event.$emit('loader:show', true)
                  try {
                    await req.callServerSecure({
                      resource: "sp_config",
                      action: "create",
                      data: data,
                      meta_data: {},
                    });  
                      Event.$emit('loader:show', false)     
                    resolve(true);
                  } catch (err) {
                      Event.$emit('loader:show', false)  
                    reject(err);
                  }
             });
         },

          fetchServiceProviderServices() {
          return new Promise(async (resolve, reject) => {
                  try {
                       let res = await req.callServerSecure({
                            resource: "services",
                            action: "list",
                            data: {},
                            meta_data: {},
                          });  
                      this.InsuranceService = res.data.result.insurance_services;
                      console.log(" ===  this.InsuranceService  == ", this.InsuranceService )
                    resolve(true);
                  } catch (err) { 
                    reject(err);
                  }
             });
         },            
        
        async listSecurityContexts() {
                      return new Promise(async (resolve, reject) => {
                        try {
                        let res = await req.callServerSecure({
                          resource: "security_context",
                          action: "list",
                          data: {},
                          meta_data: {},
                        });
                        this.securityContexts = res.data.result.security_contexts;
                          resolve();
                        }
                        catch (err) {
                              reject(err);
                        }
            
                      });
    },
  },
  async mounted() {
        await this.fetchSytemFees();
        await this.fetchCurrencies();
        await this.fetchAccountTemplate();
        await this.fetchServiceProviderServices();
        await this.listroles();
        await this.listSecurityContexts();
        await this.getEntityConfig()
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>
