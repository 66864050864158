<template lang="html">
   <div class="fees">
      <ModalForm :showModal="showModal" :action="Fees.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Fees.wizard.pages" 
               :action="Fees.wizard.action" 
               :buttonText="Fees.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createFees()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Fee
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Fees.table.table_fields"
         :icon="Fees.table.icon"
         :title="Fees.table.title"
         :subtitle="Fees.table.subtitle"
         :tableData="fees" 
         :searchFields="Fees.table.searchFields"
         :rowData="Fees.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
  async created() {
    this.Fees = Models.Fees;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Fees.wizard.action) {
        await this.saveFees(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("fee:view", (payload) => {
      console.log(payload);
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });

    Event.$on("select-field-changed", async (payload) => {
      console.log("form", payload);
      if (payload.model === "tax_type") {
       // console.log(payload.model);
      }
    });

    // Event.$on('client:view',(payload) => {})
  },

  async mounted() {
    await this.fetchCurrencies();
    await this.fetchAccounts();
    await this.fetchtaxes();
    await this.fetchfees();
    Event.$emit("loader:show", false);
  },
  data() {
    return {
      Fees: {},
      fees: [],
      showModal: false,
    };
  },
  methods: {
    fetchfees() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fees",
            action: "list_fees",
            data: {},
            meta_data: {},
          });
          //console.log("fees", res);
          this.fees = res.data.result.entity_fees;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createFees() {
      this.showModal = true;
    },
    saveFees(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fees",
            action: "add_system_fee",
            data: {
              entity_fee_ref: data.entity_fee_ref,
              fee_name: data.fee_name,
              fee_description: data.fee_description,
              entity_fee_currency: data.entity_fee_currency,
              entity_fee_account: data.entity_fee_account,
              tax_details: {
                is_taxed: data.is_taxed,
               /* taxes: [
                  {
                    system_tax_ref: data.system_tax_ref,
                    is_inclusive: true,
                  },
                ],
                */
              },
              fee_type: data.fee_type,
              fee_value: data.fee_value,
              remarks: data.remarks,
              /*fee_slab: [
                {
                  min: data.min,
                  max: data.max,
                  value: data.value,
                  value_type: data.value_type,
                },
              ],
              */
            },
            meta_data: {},
          });

          await this.fetchfees();
          notification.notify(
            "success",
            "Fees Added",
            `The Fees ${data.fee_name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.Fees.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", { action: this.Fees.wizard.action });
        }
      });
    },
    fetchCurrencies() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "currency",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Fees.wizard.pages[0].forms[3].options = utils.appendOptions(
            res.data.result.currencies,
            "currency_iso_code",
            "name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchAccounts() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Fees.wizard.pages[0].forms[2].options = utils.appendOptions(
            res.data.result.accounts,
            "account_number",
            "account_name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    fetchtaxes() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "taxes",
            action: "list",
            data: {},
            meta_data: {},
          });
         // console.log("taxes", res.data.result.entity_taxes);

          this.Fees.wizard.pages[0].forms[5].options = utils.appendOptions(
            res.data.result.entity_taxes,
            "system_tax_ref",
            "tax_name"
          );
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },

  beforeDestroy() {
    Event.$off("form:submit");
  },
};
</script>

<style lang="less">
</style>