module.exports = {
    name: 'Insurance Claim Types',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
       actionBtn: 'Save',
       action: 'add_insurace_claim_type',
       pages: [
          {
             title: 'Insurance Claim Type',
             subtitle: 'Configure new insurance class',
             forms: [
                {
                   type: 'text', form_type: 'text', label: 'Name', model: 'claim_type_name',
                   validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                },
 
                {
                   type: 'text', form_type: 'text', label: 'Code', model: 'claim_type_code',
                   validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                },
                {
                   type: 'textarea', form_type: 'text', label: 'Description', model: 'claim_type_description',
                   validation: 'required|min:10|max:500', class: 'col-12'
                },
             ],
          }
       ]
    },
    table: {
       title: 'Insurance Claim Type',
       subtitle: 'A list of configured insurance claim_types',
       table_fields: [
          { name: 'Code', align: 'l' },
          { name: 'Name', align: 'l' },
          { name: 'Description', align: 'l' },
          { name: 'actions', align: 'c' }
       ],
       searchFields: ["code", "name", "min_account_bal", "max_account_bal"],
       rowData: [
          { type: 'text', field: 'claim_type_code', class: 'left bolder small' },
          { type: 'text', field: 'claim_type_name', class: 'left bolder' },
          { type: 'text', field: 'claim_type_description', class: 'left bolder' },
          {
             type: 'action', field: 'name', class: 'center', icon: '', actions: [
                // {type: 'title', title: 'Actions'},
              /*  {
                   type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                      { key: 'client', field: '*' }
                   ]
                },
                */
                {
                   type: 'menu', title: 'View', event: 'claimType:view', icon: 'ico-reply', params: [
                      { key: 'claimtype', field: '*' },
                   ]
                },
             
             ]
          },
       ]
    },
 }