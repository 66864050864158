module.exports = {
   name: 'wallet',
   gen_template: `
      {
         "name": "{{finance.currencyName}}",
         "currency": "{{finance.currencyCode}}", 
         "balance": "{{finance.account}}.00"
      }
   `,
   table: {
      icon: 'icon-suit',
      title: 'Wallets',
      subtitle: 'Registered Wallets',
      table_fields: [
         { name: 'Currency', align: 'c' },
         { name: 'Name', align: 'l' },
         { name: 'Balance', align: 'l' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["name", "code", "ira_code", "status"],
      rowData: [
         { type: 'text', field: 'currency', class: 'center' },
         { type: 'text', field: 'name', class: 'left' },
         { type: 'status', field: 'status', class: 'center pt-3' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'edit-3', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'eye', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'delete', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
               { type: 'divider' },
               {
                  type: 'menu', title: 'View Transactions', event: 'view_transactions:view', icon: 'eye', params: [
                     { key: 'client', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
   wizard: {
      actionBtn: 'Save',
      action: 'wallet',
      pages: [
         {
            title: 'wallet Details',
            subtitle: 'Confgure wallet Details',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'Name', model: 'name',
                  validation: 'required|min:2|max:50', class: 'col-12'
               },
               {
                  type: 'text', form_type: 'text', label: 'Currency', model: 'currency',
                  validation: 'required|min:1|max:3', class: 'col-12 col-md-6'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'is_default',
                  options: [
                     { val: 'Yes', label: 'Yes' },
                     { val: 'No', label: 'No' },
                  ],
                  validation: 'required', label: 'Is Default'
               },
            ],
         }
      ]
   },
}