<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Users</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Users List</h4>
            </div>
            <!-- <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase">
                    <i data-feather="mail" class="wd-10 mg-r-5"></i> Email
                </button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Add Client
                </button>
            </div> -->
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
					<CustomTable :tableFields="table_fields" 
						:tableData="users" 
						:title="`Entity Users`"
						:subtitle="`Entity Users List`" 
						:searchFields="searchFields"
						:rowData="rowData">
					</CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  created() {
    Event.$on("user:view", async (payload) => {
      console.log("Payload: ", payload);
      this.$router.push({
        name: "Entities-UsersView",
        params: { id: payload.user.user_id },
      });
    });
  },
  data() {
    return {
      users: [],
      searchFields: ["name", "phone_number", "email", "subtype"],
      table_fields: [
        { name: 'Image', align: 'l' },
        { name: "ID", align: "c" },
        { name: "Username", align: "c" },
        { name: "Status", align: "c" },
        { name: "Entity", align: "c" },
        { name: "Email", align: "c" },
        { name: "Created Date", align: "c" },
        { name: "actions", align: "c" },
      ],
      rowData: [
        { type: 'image', field: 'image', class: 'left' },
        { type: "text", field: "user_id", class: "small center" },
        { type: "text", field: "username", class: "small center" },
        { type: "status", field: "registration_status", class: "small center" },
        {
          type: "status",
          field: "entity_id",
          class: "small center",
        },
        { type: "text", field: "email", class: "small center" },
        { type: "text", field: "created_date", class: "small center" },
        {
          type: "action",
          field: "name",
          class: "center",
          icon: "",
          actions: [
            // {type: 'title', title: 'Actions'},
            // {
            // 	type: 'menu', title: 'Edit', event: 'user:edit', icon: 'edit-3', params: [
            // 		{ key: 'client', field: '*' }
            // 	]
            // },
            {
              type: "menu",
              title: "View",
              event: "user:view",
              icon: "eye",
              params: [{ key: "user", field: "*" }],
            },
            // {type: 'divider'},
            // {
            // 	type: 'menu', title: 'Delete', event: 'user:delete', icon: 'delete', params: [
            // 		{ key: 'email', field: 'email' }
            // 	]
            // },
          ],
        },
      ],
    };
  },
  methods: {
    fetchUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "user",
            action: "all_users",
            data: {},
            meta_data: {},
          });
          console.log(res);
          this.users = res.data.result.users.map((item) => {
            item.image = domain + item.profile_photo_path;
            return item;
          });
          resolve(true);
        } catch (err) {
          notification.notify(
            "error",
            "Fetching Insurance Service",
            `Fetching Insurance Service`
          );
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchUsers();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.user-list {
}
</style>