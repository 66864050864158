<template lang="html">
     <div class="modal fade classic-modal" 
      id="colorPicker" 
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="colorPicker" 
      aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <div class="row-contents">
                           <!-- <div class="ci ci-info">
                              <i class="fa fa-group-tie"></i>
                           </div>
                           <div class="text" id="formModalLabel">
                              <div class="title">Color Picker</div>
                              <div class="small">Select a color to use.</div>
                           </div> -->
                           <button type="button" class="close" @click="close()">
                              <span aria-hidden="true">&times;</span>
                           </button>
                     </div>
                  </div>
                  <div class="modal-body">
                     <color-picker ref="color_picker"></color-picker>
                  </div>

                  <div class="modal-footer">
                     <button type="button" class="btn btn-primary" :disabled="loading" @click="selectColor()">
                        Select <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>
                  </div>
               </div>
         </div>
    </div>
</template>

<script>
export default {
	async created() {
		var _this = this;

		Event.$on("color-picker:show", function(payload) {
         if(payload.form){
            _this.form = payload.form
            $("#colorPicker").modal("show")
         }
      });
	},
	data() {
		return {
         color: '#fff',
         form: {},
         loading: false
		};
	},
	methods: {
      close(){
         $("#colorPicker").modal("hide")
      },
      selectColor(){
         Event.$emit('color-picker:color',{
            model: this.form.model,
            color: this.color
         })

         $("#colorPicker").modal("hide")
      }
   },
   mounted(){
      let _this = this

      this.$refs.color_picker.$on('change', function(payload){
         _this.color = payload.hex
      })
   }
};
</script>

<style lang="less">
#colorPicker {
	.modal-body{
      padding: 0 !important;
      display: flex;
      justify-content: center;
   }
}
</style>