<template lang="html">
   <div class="entities">
      <ModalForm :showModal="showModal" :action="AccountEntity.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="AccountEntity.wizard.pages" 
               :action="AccountEntity.wizard.action" 
               :buttonText="AccountEntity.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createAccountEntity()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Account Entity
               </button>
         </div>
      </div>

      <CustomTable :tableFields="AccountEntity.table.table_fields"
         :icon="AccountEntity.table.icon"
         :title="AccountEntity.table.title"
         :subtitle="AccountEntity.table.subtitle"
         :tableData="entities" 
         :searchFields="AccountEntity.table.searchFields"
         :rowData="AccountEntity.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.AccountEntity = Models.AccountEntity

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.AccountEntity.wizard.action){
               await this.saveGroup(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            AccountEntity: {},
            entities: [],
            showModal: false
         }
      },
      methods: {
         fetchAccountEntities(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "entity",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.entities = res.data.result.entities
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         fetchSubTypes(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "client",
                     action: "client_sub_types",
                     data: {},
                     meta_data: {}
                  });

                  let pages = this.AccountEntity.wizard.pages,options = [];
                  await res.data.result.client_subtypes.forEach(element => {
                     options.push({ val: element.subtype_code, label: element.subtype_name});
                  });
                  pages[0].forms[3].options = options;
                  this.AccountEntity.wizard.pages = pages

                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createAccountEntity() {
            this.showModal = true
         },
         saveGroup(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "entity",
                     action: "add",
                     data: {
                        entity_code: data.code,
                        entity_name: data.name,
                        entity_description: data.description,
                        entity_type: data.type,
                        entity_sub_type: data.sub_type,
                     },
                     meta_data: {},
                  });

                  await this.fetchAccountEntities()
                  notification.notify('success', 'Account Entity Added', `The entity ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.AccountEntity.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.AccountEntity.wizard.action})
               }
            })
         }
      },
      async mounted(){
         await this.fetchSubTypes()
         await this.fetchAccountEntities()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .entities{
      
   }
</style>