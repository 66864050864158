module.exports = {
  name: "Department",
  table: {
    title: "Department",
    subtitle: "Department List",
    table_fields: [
      { name: "Code", align: "r" },
      { name: "Name", align: "c" },
      { name: "Description", align: "r" },
      { name: "Entity", align: "c" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["department_name", "department_code", "department_description", "entity_type"],
    rowData: [
      { type: "text", field: "department_code", class: "small center" },
      { type: "text", field: "department_name", class: "small center" },
      { type: "text", field: "department_description", class: "small center" },
      { type: "text", field: "entity_type", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "department:view",
            icon: "ico-right",
            params: [{ key: "department", field: "*" }],
          },
        ],
      },
    ],
  },
  wizard: {
    actionBtn: "Save",
    action: "add_department",
    pages: [
      {
        title: "Department",
        subtitle: "Add new Department",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-12",
          },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-12",
          },
          {
            type: "textarea",
            form_type: "textarea",
            label: "Description",
            model: "description",
            validation: "required|min:20|max:500",
            class: "col-12 col-md-12",
          },
        ],
      },
    ],
  },
};
