<template lang="html">
   <div class="clientsubtype">

    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">View Client Sub Type</li>
                    </ol>
                </nav>    
            </div>  
        </div>

        <ModalForm :showModal="showModal">
            <template slot="form" v-if="showkycModal">
      
                      <div class="custom-control custom-checkbox form-group">
					  <li  v-for="field in formFields">
                        <input
						   type="checkbox"
                           class="custom-control-input"
                           :id="field.field_code" 
						   :value="field.field_code"
						    v-model="selectedKyc"
                          >    
                        <label  :for="field.field_code" class="custom-control-label label-checkbox" >{{field.field_name}}</label>
						</li>
                     </div>
					   <div class="d-none d-md-block">
							<button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="SaveKyc()">
							<i data-feather="mail" class="wd-10 mg-r-5"></i> Save Kyc
							</button>
				   	</div>		
            </template>

               <template slot="form" v-if="showassesmentModal">
      
                      <div class="custom-control custom-checkbox form-group">
					  <li  v-for="assesment in assesments">
                        <input
						   type="checkbox"
                           class="custom-control-input"
                           :id="assesment.code" 
						   :value="assesment.code"
						    v-model="selectedAssesments"
                          >    
                        <label  :for="assesment.code" class="custom-control-label label-checkbox" >{{assesment.question}}</label>
						</li>
                     </div>
					   <div class="d-none d-md-block">
							<button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="SaveAssesment()">
							<i data-feather="mail" class="wd-10 mg-r-5"></i> Save Assesment
							</button>
				   	</div>		
            </template>
      </ModalForm>


 <div class="row">
      <div class="col-sm">
       <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Client Sub Type Details for   {{clientSubtype.subtype_name}} </h6>
                <nav class="nav nav-icon-only">
                  <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="media align-items-center mg-b-20">
                  <div class="avatar avatar-online"><img v-bind:src="clientSubtype.subtype_icon" class="rounded-circle" alt=""></div>
                  <div class="media-body pd-l-15">
                    <h6 class="mg-b-3">{{clientSubtype.subtype_code}}</h6>
                    <span class="d-block tx-13 tx-color-03">{{clientSubtype.subtype_description}}</span>
                    <span class="d-block tx-13 tx-color-03">{{clientSubtype.status}}</span>
                  </div>
                  <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">{{clientSubtype.created_date}}</span>
                </div><!-- media -->
    
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                         <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="editClientSubType()"><i data-feather="edit"></i> Edit  </button>

                              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="editClientSubType()">
                           <i data-feather="edit"> Approve</i>   </button>


                                 <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="editClientSubType()">
                           <i data-feather="edit"> Delete</i>   </button>
                            

         
         
              
                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->
      </div>
    </div>
 
    
        <div class="row">
       <div class="col-sm">
                   <button type="button" class="btn btn-outline-dark pull-right" @click="addKyc()">Attach Kyc Requirement</button>
                    <h4 id="section1" class="mg-b-10">Kyc Requirements</h4>
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                 <th scope="col">Description</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in clientsubtypeKycs">
                                <th scope="row">{{ index }}</th>
                                <td>{{item.field_code}}</td>
                                <td>{{item.field_name}}</td>
                                <td>{{item.field_description}}</td>
                                <td><button type="button" class="btn btn-outline-danger" @click="removeKyc(item)">Remove</button></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
         
    </div>

        <div class="row">          
       <div class="col-sm">
                   <button type="button" class="btn btn-outline-dark pull-right" @click="addAssesment()">Attach Assesment</button>
                    <h4 id="section1" class="mg-b-10">Assesments</h4>
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                                 <th scope="col">Question</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in clientsubtypeAssesments">
                                <th scope="row">{{ index }}</th>
                                <td>{{item.code}}</td>
                                <td>{{item.description}}</td>
                                <td>{{item.question}}</td>
                                <td><button type="button" class="btn btn-outline-danger" @click="removeAssesment(item)">Remove</button></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
         
    </div>


      
  
   </div>   
</template>
<script>
export default {
    async created() {

    },
    data() {
		return {
            clientSubtype:{},
            ClientSubtype:{},
            	formFields:[],
               	assesments:[],
             KycField: {},
             showModal: false,
             selectedKyc:[],
             clientsubtypeKycs:[],
             clientsubtypeAssesments:[],
             showkycModal:false,
             showassesmentModal:false,
             selectedAssesments:[]


		};
	},
    methods: {
      async removeKyc (item) {
              try {
                    let res = await req.callServerSecure({
                        resource: "client_sub_type",
                        action: "remove_kyc",
                        data: {
                          subtype_code:  this.$route.params.id,
                          field_code : item.field_code
                        },
                        meta_data: {}
                      });

                 
                      notification.notify("success","Kyc Removed Successfully ",`Kyc Removed Successfully `);
                       await this.fetchClientSubTypeSummary();
                  }
                  catch (err) {
                        notification.notify('error', 'Error Removing a Kyc Type    ', err)
                  }

      }
      ,
      async removeAssesment (item) {
   try {
                    let res = await req.callServerSecure({
                        resource: "client_sub_type",
                        action: "remove_assesment",
                        data: {
                          subtype_code:  this.$route.params.id,
                          assesment_code : item.code
                        },
                        meta_data: {}
                      });

                      notification.notify("success","Assesment Removed Successfully ",`Assesment Removed Successfully `);
                       await this.fetchClientSubTypeSummary();
                      
                  }
                  catch (err) {
                        notification.notify('error', 'Error Removing Assesment   ', err)
                  }
      },
      addKyc() {
            this.showModal = true;
            this.showkycModal=true;
            this.showassesmentModal=false;
      },
      hidemodal () {
            this.showModal = false;
            this.showassesmentModal=false;
            this.showkycModal=false;
      },
      async addAssesment () {
            this.showModal = true;
            this.showkycModal=false;
            this.showassesmentModal=true;
      },
      async SaveAssesment () {
          try{
               let res = await req.callServerSecure({
                  resource: "client_sub_type",
                  action: "add_assesment",
                  data: {
                                  subtype_code:  this.$route.params.id,
                                  assesment_codes:this.selectedAssesments
                            },
                  meta_data: {}
                    });

                   await this.fetchClientSubTypeSummary();
                   this.hidemodal();

          }
            catch (err) {
   notification.notify('error', 'Error Attaching Kyc to Client Subtype', `Kindly Retry Later`)
            } 

      },
     async SaveKyc () {
          try{
               let res = await req.callServerSecure({
                  resource: "client_sub_type",
                  action: "add_kyc",
                  data: {
                                  subtype_code:  this.$route.params.id,
                                  field_codes:this.selectedKyc
                            },
                  meta_data: {}
                    });

                   await this.fetchClientSubTypeSummary();
                   this.hidemodal();

          }
            catch (err) {
   notification.notify('error', 'Error Attaching Kyc to Client Subtype', `Kindly Retry Later`)
            } 

      },
       fetchClientSubTypeSummary () {
         return new Promise(async (resolve, reject) => {
		   		try {
                let res = await req.callServerSecure({
                  resource: "client_sub_type",
                  action: "summary",
                  data: {
                    subtype_code:  this.$route.params.id
                         },
                  meta_data: {}
                    });

          console.log("summary results", res)
            
			   this.ClientSubtype =res.data.result.client_subtype_details
         this.clientSubtype=res.data.result.client_subtype_summary;
        //  this.clientsubtypeKycs=res.data.result.client_subtype_summary.kyc_fields;
         this.clientsubtypeKycs = res.data.result.client_subtype_summary.kyc_fields
          console.log("cl subtypes", res.data.result.client_subtype_summary.kyc_fields)
        //  this.clientsubtypeAssesments=res.data.result.client_subtype_summary.subtype_assesments;
         this.clientsubtypeAssesments = res.data.result.client_subtype_summary.subtype_assesments;

         console.log("cl asss", res.data.result.client_subtype_summary.subtype_assesments)

         console.log('assessments CSUBTYPES', res.data.result.client_subtype_summary)
            
					resolve(true);
				} catch (err) {
                      notification.notify('error', 'Error Fetching Client Subtype Details', `Kindly Retry Later`)
					     reject(err)
				}
            
			});
      },
      fetchFormFields() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "form_field",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.formFields = res.data.result.form_fields;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		    },

  fetchAssesments() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "assesment",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                    console.log( " assesmsnets " ,res.data.result)
                  this.assesments = res.data.result.assesments;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		    },

      fetchClientSubType() {
		    return new Promise(async (resolve,reject) => {
                try {
                let res = await req.callServerSecure({
						resource: "client_sub_type",
						action: "get",
						data: {
                            subtype_code:  this.$route.params.id,
                        }
               });
              this.clientSubtype=res.data.result.client_subtype_details
                     resolve()
                }
                catch (err) {
              console.log(err)
                     reject(err)
                }
            })
         },
          editClientSubType() {
                this.$router.push({
                        name: "Configs-Insurance-EditClientSubType",
                        params: { id: this.clientSubtype.subtype_code }
                    });
     }
        },

        
	async mounted() {
        // await this.fetchClientSubType();
        await this.fetchClientSubTypeSummary();
         await this.fetchFormFields();
         await this.fetchAssesments();
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.clientsubtype {
}
</style>