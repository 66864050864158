<template lang="html">
    <div class="user-list">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Users</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Users</h4>
            </div>
            <div class="d-none d-md-block">
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase">
                    <i data-feather="mail" class="wd-10 mg-r-5"></i> Email
                </button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
                </button>
            </div>
        </div>

        <div class="row row-xs">
            <div class="col-lg-12 col-xl-12 mg-t-10">
					<CustomTable 
						:icon="table.icon"
						:title="table.title"
						:subtitle="table.subtitle"
						:tableFields="table.table_fields" 
						:tableData="clients" 
						:searchFields="table.searchFields"
						:rowData="table.rowData">
					</CustomTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	created(){
		Event.$on('user:view',async (payload) => {
			this.$router.push({name: 'Configs-Users-ViewClient', params: {id: payload.client.entity_module_id}})
		})
	},
	data() {
		return {
			clients: [],
			table: {
				icon: 'icon-user',
				title: 'Users',
				subtitle: 'Users List',
				table_fields: [
					// { name: 'Image', align: 'l' },
					{ name: 'Username', align: 'c' },
					{ name: 'Email', align: 'r' },
					{ name: 'Status', align: 'c' },
					{ name: 'actions', align: 'c' }
				],
				searchFields: ["name", "phone_number", "email", "subtype"],
				rowData: [
					// { type: 'image', field: 'profile_photo_path', class: 'left' },
					{ type: 'text', field: 'username', class: 'center' },
					{ type: 'text', field: 'email', class: 'center' },
					{ type: 'status', field: 'kyc_status', class: 'center' },
					{
						type: 'action', field: 'name', class: 'center', icon: '', actions: [
							// {type: 'title', title: 'Actions'},
							{
								type: 'menu', title: 'Edit', event: 'user:edit', icon: 'edit-3', params: [
									{ key: 'client', field: '*' }
								]
							},
							{
								type: 'menu', title: 'View', event: 'user:view', icon: 'eye', params: [
									{ key: 'client', field: '*' }
								]
							},
							// {type: 'divider'},
							{
								type: 'menu', title: 'Delete', event: 'user:delete', icon: 'delete', params: [
									{ key: 'email', field: 'email' }
								]
							},
						]
					},
				]
			}
		};
	},
	methods: {
		fetchUsers() {
			return new Promise(async (resolve,reject) => {
				let res = await get_req.fetchUsers()
				this.clients = res.users

				resolve(true)
			})
		}
	},
	async mounted(){
		await this.fetchUsers()
		Event.$emit('loader:show', false)
	}
};
</script>

<style lang="less">
.user-list {
}
</style>