module.exports = {
   name: 'gl',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'account_config',
      pages: [
         {
            title: 'Account Template Config',
            subtitle: 'Configure a account template',
            forms: [
               {
                  type: 'text', form_type: 'text', class: 'col-6', model: 'code',
                  validation: 'required|min:3|max:50', label: 'Code',
               },
               {
                type: 'text', form_type: 'text', class: 'col-6', model: 'name',
                validation: 'required', label: 'Name',
               },
               {
                type: 'textarea', form_type: 'textarea', class: 'col-6', model: 'description',
                validation: 'required', label: 'Description',
               },
               {
                type: 'text', form_type: 'text', class: 'col-6', model: 'prefix',
                validation: 'required', label: 'Account Generation Prefix',
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'sub_gl_code',
                  options: [],
                  validation: 'required', label: 'General Ledger'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'currency',
                  options: [],
                  validation: 'required', label: 'Default Currency'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'entity',
                  options: [
                     { val: 'UNDERWRITTER', label: 'Underwritter' },
                     { val: 'CLIENT', label: 'Client' },
                     { val: 'BANK', label: 'Bank' },
                     { val: 'SERVICEPROVIDER', label: 'Service Provider' },
                     { val: 'PRODUCT', label: 'Products' },
                     { val: 'INTERMEDIARY', label: 'Intermediaries' },
                     { val: 'SCHOOL', label: 'School' },
                     { val: 'SYSTEM', label: 'System' }
                  ],
                  validation: 'required', label: 'Entity Type'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'limit',
                  options: [],
                  validation: 'required', label: 'Account Limit'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'status',
                  options: [],
                  validation: 'required', label: 'Account Status'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'statement',
                  options: [],
                  validation: 'required', label: 'Account Statement'
               }
            ],
         }
      ]
   },
   table: {
      title: 'Accounts',
      subtitle: 'A list of accounts',
      table_fields: [
         { name: 'Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'Entity Type', align: 'l' },
         { name: 'Currency', align: 'c' },
         { name: 'GL', align: 'l' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["account_number", "account_name", "available_balance", "currency_code"],
      rowData: [
         { type: 'text', field: 'template_code', class: 'left bolder small' },
         { type: 'text', field: 'template_name', class: 'left bolder small' },
         { type: 'text', field: 'entity_type', class: 'left bolder small' },
         { type: 'text', field: 'currency_code', class: 'center bolder small' },
         { type: 'text', field: 'sub_gl_code', class: 'left small' },
         {
            type: 'action', field: 'account_number', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'account', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
}