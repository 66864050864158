module.exports = {
    name: "Uploaded Car Model",
    table: {
        title: "Uploaded Car Model",
        subtitle: "Car Model List",
        table_fields: [
            { name: "Id", align: "r" },
            { name: "Name", align: "c" },
        ],
        searchFields: ["name"],
        rowData: [
            { type: "text", field: "'id_car_model'", class: "small center" },
            { type: "text", field: "'name'", class: "small center" },

        ],
    }
};