<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account System Account</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add System Acccount</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="iscocode">Account Name</label>
      <input type="text" class="form-control" id="name" v-model="account_name" placeholder="Account Name">
    </div>
    <div class="form-group col-md-4">
          <label for="description">Select Account Opening Template</label>
            <select class="custom-select" v-model="template_code">
            <option v-for="acttempl in actOpnTmpl" v-bind:value="acttempl.template_code">
                {{ acttempl.template_name }}
            </option>
            </select>
    </div>

            <div class="form-group col-md-4">
            <label for="description">Select Account Category</label>
            <select class="custom-select" v-model="account_category">
            <option v-for="actcat in account_categories" v-bind:value="actcat.code">
                {{ actcat.name }}
            </option>
            </select>
       </div>

  </div>
  
  


        <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveSystemAccount()" class="btn btn-primary">Add System Account</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             account_name:"",
             template_code:"",
             account_category:"",
             actOpnTmpl:[] ,
             account_categories:[
                 {
                     "name":"Transaction",
                     "code":"TRANSACTION"
                 },
                 {
                     "name":"Suspence",
                     "code":"SUSPENCE"
                 },
                {
                     "name":"Trust",
                     "code":"TRUST"
                 },
                {
                     "name":"Escrow",
                     "code":"ESCROW"
                 },
             ]
         }

      },
    methods: {
       async saveSystemAccount () {
          try {
                  let res = await req.callServerSecure({
                     resource: "system_account",
                     action: "add",
                     data: {
                         account_name:this.account_name,
                         template_code:this.template_code
                     },
                     meta_data: {},
                  });
                  
                notification.notify('success', 'System Account Added', `The ${this.account_name} was successfully added`)
                this.$router.push({ name: 'accounting.system.listSystemAccounts' })
  
          }
          catch (err) {
              console.log(err)
                  notification.notify('error', err)
          }
        
    }, 
  
    fetchTemplates() {
         return new Promise(async (resolve, reject) => {
            try {
               let res = await req.callServerSecure({
                  resource: "entity_account_template",
                  action: "list",
                  data: {},
                  meta_data: {}
               });

               this.actOpnTmpl = res.data.result.templates;
               resolve()
            } catch (err) {
                console.log(err)
               reject(err) 
            }
         });
      },
    },
    async mounted(){
        await this.fetchTemplates();
        Event.$emit('loader:show', false)
     }  
    };
</script>

<style lang="less">
</style>