export default class PageStore {
  constructor(data) {
    this.pages = null;
    this.data = data;
    this.searchData = data;
    this.current = 1;
  }

  fetchKeyData(key, record) {
    let key_data = null;

    if (key.includes(".")) {
      let key_props = key.split(".");

      for (let i = 0; i < key_props.length; i++) {
        const key_prop = key_props[i];

        if (i == 0) {
          if (record.hasOwnProperty(key_prop)) {
            key_data = record[key_prop];
          }
        } else {
          if (key_data !== null && key_data.hasOwnProperty(key_prop)) {
            key_data = key_data[key_prop];
          }
        }
      }
    } else {
      if (record.hasOwnProperty(key)) {
        key_data = record[key];
      }
    }

    key_data = key_data + "";
    if (key_data === null) {
      key_data = "";
    }
    return key_data.toLowerCase();
  }

  searchRecords(keys, query) {
    if (query !== "") {
      let _this = this;
      this.data = this.searchData;

      this.data = this.data.filter(function (record) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];

          if (_this.fetchKeyData(key, record).startsWith(query.toLowerCase())) {
            return true;
          }
        }

        return false;
      });
    } else {
      this.data = this.searchData;
    }

    this.current = 1;
    return this.paginateRecords(10);
  }

  paginateRecords(noOfPages) {
    let splitData = [],
      count = 0,
      _data = [];
    if (noOfPages === undefined) {
      noOfPages = 10;
    }

    for (let i = 0; i < this.data.length; i++) {
      count++;
      _data.push(this.data[i]);
      if (count === noOfPages) {
        splitData.push(_data);
        _data = [];
        count = 0;
      }

      if (i == this.data.length - 1) {
        splitData.push(_data);
      }
    }

    this.pages = splitData;
    return splitData[0];
  }

  getAllPages() {
    return this.pages;
  }

  getNextPage(currentPage) {
    if (currentPage <= this.pages.length - 1) {
      this.current += 1;
      return this.pages[currentPage];
    } else {
      this.current = this.pages.length;
      return this.pages[this.pages.length - 1];
    }
  }

  getPreviousPage(currentPage) {
    if (currentPage >= 2) {
      this.current -= 1;
      return this.pages[currentPage - 2];
    } else {
      this.current = 1;
      return this.pages[0];
    }
  }

  getPage(page) {
    if (page >= 1 && page <= this.pages.length) {
      return this.pages[page - 1];
    } else {
      return this.pages[0];
    }
  }

  getTotalPages() {
    return this.pages.length;
  }

  getTotalRecords() {
    return this.data.length;
  }

  getCurrent() {
    return this.current;
  }
}
