<template lang="html">
	<div class="login">
		<div class="content content-fixed content-auth">
			<div class="container">
				<div class="media align-items-stretch justify-content-center ht-100p pos-relative">
				
			<div class="media-body align-items-center">
						<div class="mx-wd-600">
							<img src="img/login.svg" class="img-fluid" alt="">
						</div>
					</div>
					<div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
						
						<h3 class="tx-color-01 mg-b-5">Forgot Password</h3>
						<p class="tx-color-03 tx-16 mg-b-40">Enter your username to continue.</p>

						<div class="form-group">
							<label>Admin Username</label>
							<input 
								type="text" 
								class="form-control" 
								name="username" 
								placeholder="your username"
								v-model="username"
								v-validate="'required|min:3|max:150'">
							<small class="text-danger" v-show="errors.has('login email')">{{errors.first('login email')}}</small>
						</div>
						<div class="form-group">
                  <button class="btn btn-brand-02 btn-block" @click="passwordReset()">
                     Password Reset
				      	<div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
						</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<CustomFooter />
	</div>
</template>

<script>
export default {
	data () {
		return {
			loading: false,
			login_password: '',
			username: ''
		}
	},
	methods: {

      async passwordReset () {
         try {
               let _this = this
               this.loading = true
               this.$validator.validate('login email', this.username)
               let res = await req.callServer({
						resource: "forgot_password",
						action: "request",
						data :{
							username: this.username
						}
					})
                this.loading = false
                  notification.notify('success', 'Password Reset', `Password Change request success ,an email has been sent `);
                  this.$router.push({name: 'LogIn'})
         }
         catch (err) {
            	this.loading = false
					if(err.data && err.data.err_code === 2){
						this.errors.add({
							field: 'login Email',
							msg: "Invalid Credentials"
						})
         }
       }
      },

	},
	mounted(){
		$session.clear()
	}
};
</script>

<style lang="less">
@media (min-width: 992px){
	.login{
		.content-fixed {
			margin-top: 0 !important;
		}
		.app{
			position: fixed;
			bottom: 0px;
			left: 0;
			right: 0;
		}
	}
}
</style>