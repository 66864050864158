module.exports = {
    name: 'gl',
    gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
    wizard: {
        actionBtn: 'Save',
        action: 'account_config',
        pages: [{
            title: 'System Accounts',
            subtitle: 'Configure a new system account',
            forms: [{
                    type: 'text',
                    form_type: 'text',
                    class: 'col-12 col-md-6',
                    model: 'name',
                    validation: 'required|min:3|max:100',
                    label: 'Name',
                },
                {
                    type: 'select',
                    class: 'col-12 col-md-6',
                    model: 'gl',
                    options: [],
                    validation: 'required',
                    label: 'General Ledger'
                },
                {
                    type: 'select',
                    class: 'col-12 col-md-6',
                    model: 'country',
                    options: [
                        { val: 'KEN', label: 'Kenya' },
                        // { val: 'UGD', label: 'Uganda' },
                        // { val: 'TNZ', label: 'Tanzania' },
                    ],
                    validation: 'required',
                    label: 'Country'
                },
                {
                    type: 'select',
                    class: 'col-12 col-md-6',
                    model: 'currency',
                    options: [],
                    validation: 'required',
                    label: 'Currency'
                },
            ],
        }]
    },
    table: {
        title: 'System Accounts',
        subtitle: 'A list of system accounts',
        table_fields: [
            { name: 'Acc No.', align: 'l' },
            { name: 'Acc Name', align: 'l' },
            { name: 'Currency Code', align: 'c' },
            { name: 'Balance', align: 'l' },
            // { name: 'Opening Date', align: 'c' },
            { name: 'Actions', align: 'c' }
        ],
        searchFields: ["account_number", "account_name", "available_balance", "currency_code"],
        rowData: [
            { type: 'text', field: 'account_number', class: 'left bolder small' },
            { type: 'text', field: 'account_name', class: 'left bolder small' },
            { type: 'text', field: 'currency_code', class: 'center bolder small' },
            { type: 'number', field: 'available_balance', class: 'left bolder' },
            // { type: 'text', field: 'account_opening_date', class: 'left small' },
            {
                type: 'action',
                field: 'account_number',
                class: 'center',
                icon: '',
                actions: [
                    // {type: 'title', title: 'Actions'},
                    {
                        type: 'menu',
                        title: 'Edit',
                        event: 'client:edit',
                        icon: 'ico-right',
                        params: [
                            { key: 'account', field: '*' }
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'View',
                        event: 'client:view',
                        icon: 'ico-reply',
                        params: [
                            { key: 'account', field: '*' }
                        ]
                    },
                    // {type: 'divider'},
                    {
                        type: 'menu',
                        title: 'Delete',
                        event: 'client:delete',
                        icon: 'ico-mail-a',
                        params: [
                            { key: 'account', field: '*' }
                        ]
                    },
                ]
            },
        ]
    },
}