module.exports = {
  name: "tran_topup",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "add_tran_topup",
    pages: [
      {
        title: "Topup Config",
        subtitle: "Configure new topup method",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12 col-md-12",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Category",
            model: "category",
            options: [
              { val: "MOBILE_MONEY", label: "Mobile Money" },
              { val: "CARD", label: "Card" },
              { val: "PAYPAL", label: "Paypal" },
              { val: "BANK", label: "Bank" },
            ],
            validation: "required",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Channel",
            model: "channel",
            options: [],
            validation: "required",
          },          
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Transaction Limit",
            model: "limit",
            options: [
              // { val: "MOBILE_MONEY_LIMIT", label: "Mobile Money Limit" },
              // { val: "CARD_LIMIT", label: "Card Limit" },
              // { val: "PAYPAL_LIMIT", label: "Paypal Limit" },
              // { val: "BANK_LIMIT", label: "Bank Limit" },
            ],
            validation: "required",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Currency",
            model: "currency",
            options: [
              { label: "KES", val: "KES" },
              { label: "USD", val: "USD" },
            ],
            validation: "required",
          },
          {
            type: "check",
            class: "col-12 col-md-12",
            label: "Charges",
            model: "charges",
            options: [
              // { id: "charge 1", label: "charge 1", val: "charge 1" },
              // { id: "charge 2", label: "charge 2", val: "charge 2" },
              // { id: "charge 3", label: "charge 3", val: "charge 3" },
              // { id: "charge 4", label: "charge 4", val: "charge 4" },
              // { id: "charge 5", label: "charge 5", val: "charge 5" },
              // { id: "charge 6", label: "charge 6", val: "charge 6" },
            ],
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "Topup Configs",
    subtitle: "Configured Top-Ups",
    table_fields: [
      { name: "Category", align: "l" },
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "Description", align: "l" },
      { name: "Status", align: "l" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["category", "channel_code", "name", "description", "status"],
    rowData: [
      { type: "text", field: "category", class: "left bolder small" },
      { type: "text", field: "channel_code", class: "left bolder small" },
      { type: "text", field: "name", class: "left bolder small" },
      { type: "text", field: "description", class: "left bolder small" },
      { type: "text", field: "status", class: "left bolder small" },
      {
        type: "action",
        field: "account_number",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "topup:view",
            icon: "ico-reply",
            params: [{ key: "account", field: "*" }],
          },
        ],
      },
    ],
  },
};
