<template lang="html">
   <div class="view-form">
      <div class="pages">
         <div class="page" 
            v-if="pages && pages.length > 0"  
            v-for="i in pages.length">
            <span v-show="false">{{i -= 1}}</span>
            
            <!-- Page Content -->
            <div class="page-content" v-show="currentPage === i">
               <!-- Page title -->
               <div class="page-title" v-show="showTitle">
                  <div class="title" v-if="pages[i] && pages[i].title">{{pages[i].title}}</div>
                  <div class="subtitle" v-if="pages[i] && pages[i].subtitle">{{pages[i].subtitle}}</div>
               </div>

               <div class="row">
                  <div class="padded-form" v-if="pages[i] && pages[i].forms" v-for="form in pages[i].forms" v-show="form.type !== 'file'" :class="form.class || 'col-12'">
                     <!-- Text input form -->
                     <div class="form-group" v-if="form.type !== 'radio'">
                        <label :for="'#'+form.model">{{form.label}}</label>
                        <div :id="form.model" class="data-field">{{data[form.model]}}</div>
                     </div>

                     <div class="form-group" v-if="form.type === 'radio'">
                        <label>{{form.label}} 
                           <span v-if="form.classes.answer_class" :class="form.classes.answer_class">{{data[form.model]}}</span>
                           <span v-else>{{data[form.model]}}</span>
                        </label>
                     </div>
                  </div>

                  <div class="buttons-area">
                     <!-- Previous button -->
                     <button v-if="pages.length > 1" 
                        v-show="showStepBtns && i > 0" 
                        class="btn btn-secondary"
                        @click="previousPage()"
                        :disabled="loading">
                           Previous <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>

                     <!-- Next button -->
                     <button v-if="pages.length > 1" 
                        v-show="showStepBtns && i < (pages.length - 1)" 
                        class="btn btn-primary f-r"
                        @click="nextPage()"
                        :disabled="loading">
                           Next <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      props: {
         action: {
            default: 'form-action'
         },
         showTitle: {
            default: true
         },
         pages: {
            default: function(){
               return []
            }
         },
         forms: {
            default: function(){
               return []
            }
         },
         data: {
            default: function(){
               return undefined
            }
         },
      },
      data () {
         return {
            currentPage: 0,
            loading: false,
            showStepBtns: true,
            date_options: {
               date: true,
               datePattern: ['d', 'm', 'Y'],
               delimiter: '-',
            },
            time_options: {
               time: true,
               timePattern: ['h', 'm']
            }
         }
      },
      methods: {
         async nextPage(){
            if(this.currentPage < this.pages.length -1){
               this.loading = true

               this.currentPage++
               this.emitPageChange()

               this.loading = false
            }
         },
         emitPageChange(){
            let page = this.pages[this.currentPage]
            Event.$emit('page-changed:'+this.action,{
               page: this.currentPage,
               title: page.title,
               subtitle: page.subtitle,
            });
         },
         previousPage(){
            if(this.currentPage > 0){
               this.currentPage--
               this.emitPageChange()
            }
         },
      },
   }
</script>

<style lang="less">
   .view-form{
      
   }
</style>