module.exports = {
    name: "Users",
    table: {
        title: "Users",
        subtitle: "Users List",
        table_fields: [
            { name: "Staff Ref", align: "r" },
            { name: "Name", align: "c" },
            { name: "Username", align: "r" },
            { name: "Role", align: "c" },
            { name: "Actions", align: "c" },
        ],
        searchFields: ["names", "employee_ref", "username", "role_code"],
        rowData: [
            { type: "text", field: "employee_ref", class: "small center" },
            { type: "text", field: "names", class: "small center" },
            { type: "text", field: "username", class: "small center" },
            { type: "text", field: "role_code", class: "small center" },
            {
                type: "action",
                field: "name",
                class: "center",
                icon: "",
                actions: [
                    // {type: 'title', title: 'Actions'},
                    {
                        type: "menu",
                        title: "View",
                        event: "user:view",
                        icon: "ico-right",
                        params: [{ key: "Users", field: "*" }],
                    },
                ],
            },
        ],
    },
    wizard: {
        actionBtn: "Save",
        action: "add_user",
        pages: [{
            title: "Users",
            subtitle: "Add new users",
            forms: [{
                    type: "text",
                    form_type: "text",
                    label: "Staff Ref",
                    model: "ref",
                    validation: "required|min:2|max:50",
                    class: "col-12 col-md-6",
                },
                {
                    type: "text",
                    form_type: "text",
                    label: "Name",
                    model: "name",
                    validation: "required|min:2|max:150",
                    class: "col-12 col-md-6",
                },
                {
                    type: "text",
                    form_type: "text",
                    label: "Username",
                    model: "username",
                    validation: "required|min:2|max:150",
                    class: "col-12 col-md-6",
                },
                {
                    type: "text",
                    form_type: "text",
                    label: "Phone Number",
                    model: "contact",
                    validation: "required|min:10|max:12",
                    class: "col-12 col-md-6",
                },
                {
                    type: "text",
                    form_type: "email",
                    label: "Email",
                    model: "email",
                    validation: "required|email|min:2|max:150",
                    class: "col-12 col-md-6",
                },
                {
                    type: "select",
                    class: "col-12 col-md-6",
                    model: "role",
                    options: [],
                    validation: "required",
                    label: "Role",
                },
                {
                    type: "select",
                    class: "col-12 col-md-6",
                    model: "department",
                    options: [],
                    validation: "required",
                    label: "Department",
                },
                {
                    type: "select",
                    class: "col-12 col-md-6",
                    model: "security_context",
                    options: [],
                    validation: "required",
                    label: "Security Context",
                },
                {
                    type: "text",
                    form_type: "password",
                    label: "Password",
                    model: "password",
                    validation: "required",
                    class: "col-12 col-md-6",
                },
                {
                    type: "text",
                    form_type: "password",
                    label: "Confirm Password",
                    model: "confirm_password",
                    validation: "required",
                    class: "col-12 col-md-6",
                },
            ],
        }, ],
    },
};