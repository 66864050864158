module.exports = {
  name: 'Form Fields',
  gen_template: `
     {
        "coa": "ASSET",
        "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
        "name": "{{lorem.word}}"
     }
  `,
  wizard: {
     actionBtn: 'Save',
     action: 'upload_file',
     pages: [
        {
           title: 'System Uploads',
           subtitle: 'Upload System files',
           forms: [
             
                {
                  type: 'text', form_type: 'text', label: 'Name', model: 'name',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                },
                 
                {
                  type: 'text', form_type: 'text', label: 'Code', model: 'code',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                },
                {
                  type: 'text', form_type: 'text', label: 'Sheet Name', model: 'sheet_name',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
               },
               
                {
                  type: 'select', class: 'col-12 col-md-6', model: 'type',
                  options: [
                    { val: 'crsp', label: 'CRSP' },
                    { val: 'industry', label: 'INDUSTRY' }
                        ], validation: 'required', label: 'Type'
                  },
                  {
                    type: 'select', class: 'col-12 col-md-6', model: 'subtype',
                    options: [
                      { val: 'sic', label: 'sic' },
                      { val: 'vehicles', label: 'Vehicles' },
                      { val: 'motorcycles', label: 'Motorcycles' },
                      { val: 'trailers', label: 'Trailers' },
                      { val: 'tractors', label: 'Tractors' },
                      { val: 'generators', label: 'Generators' },
                          ], validation: 'required', label: 'SubType'
                    },
                 
                   {
                    type: 'textarea', form_type: 'text', label: 'Description', model: 'description',
                    validation: 'required|min:10|max:500', class: 'col-12 col-md-12'
                  },
            
               
           ],
        }
     ]
  },
  table: {
    title: 'System Uploads',
    subtitle: 'Configure System Uploads',
    table_fields: [
       { name: 'Code', align: 'l' },
       { name: 'Name', align: 'l' },
       { name: 'Type', align: 'l' },
       { name: 'Description', align: 'l' },
       { name: 'Subtype', align: 'c' },
       { name: 'Originalname', align: 'c' },
       { name: 'actions', align: 'c' }
    ],
    searchFields: ["code", "name", "type", "name"],
    rowData: [
       { type: 'text', field: 'code', class: 'left bolder small' },
       { type: 'text', field: 'name', class: 'left bolder small' },
       { type: 'text', field: 'type', class: 'left bolder' },
       { type: 'text', field: 'description', class: 'left bolder' },
       { type: 'text', field: 'subtype', class: 'left bolder small' },
       { type: 'text', field: 'originalname', class: 'left bolder small' },
       
       {
          type: 'action', field: 'name', class: 'center', icon: '', actions: [
          
             {
                type: 'menu', title: 'View', event: 'upload:view', icon: 'ico-reply', params: [
                   { key: 'upload', field: '*' },

                ]
             },
            
          ]
       },
    ]
 },
}