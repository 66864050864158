<template lang="html">
  <div class="orange">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div></div>
      <div class="d-none d-md-block">
        <button
          class="btn btn-sm pd-x-15 btn-white btn-uppercase"
          @click="openAddCoverArea()"
        >
          <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Cover Area
        </button>
        <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
      </div>
    </div>

    <div class="contents">
      <CustomTable
        :tableFields="CoverArea.table.table_fields"
        :tableData="cover_areas"
        :title="CoverArea.table.title"
        :subtitle="CoverArea.table.subtitle"
        :searchFields="CoverArea.table.searchFields"
        :rowData="CoverArea.table.rowData"
      >
      </CustomTable>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.CoverArea = Models.CoverArea;

    Event.$on('cover-area:view', (payload) => {
      console.log('Payload', payload);
      this.$router.push({
        name: "ViewCoverArea",
        params: { id: payload.cover_area.cover_area_system_ref }
      });
    });
  },
  data() {
    return {
      CoverArea: {},
      cover_areas: [],
    };
  },
  methods: {
    fetchCoverAreas() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'cover_area',
            action: 'list',
            data: {},
            meta_data: {},
          });

          this.cover_areas = res.data.result.cover_areas;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    openAddCoverArea() {
      this.$router.push({ name: 'AddCoverArea' });
    },
  },
  async mounted() {
    await this.fetchCoverAreas();
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>
