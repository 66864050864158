module.exports = {
   setup: {
      actionBtn: 'Save',
      action: 'insurance',
      pages: [
         {
            title: 'Insurance Details',
            subtitle: 'Confgure Insurance Details',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'Name', model: 'insurer_name',
                  validation: 'required|min:2|max:50', class: 'col-12'
               },
               {
                  type: 'text', form_type: 'text', label: 'Insurer Code', model: 'insurer_code',
                  validation: 'required|min:2|max:18', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'IRA Code', model: 'ira_code',
                  validation: 'required|min:2|max:12', class: 'col-12 col-md-6'
               },
            ],
         },
         {
            title: 'Superadmin Details',
            subtitle: 'Confgure the insurance superadmin',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'First Name', model: 'first_name',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Last Name', model: 'last_name',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Employee Ref', model: 'employee_ref',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'email', label: 'Email', model: 'email',
                  validation: 'required|min:2|max:150|email', class: 'col-12 col-md-6'
               }
            ],
         },
      ]
   },
   wizard: {
      upload_underwritters: {
         actionBtn: 'Save',
         action: 'upload_underwritters',
         pages: [
            {
               title: 'Underwritters',
               subtitle: 'Upload Underwritters list',
               forms: [
                  {
                     type: 'file', class: 'col-12', model: 'selected_file',
                     validation: 'required', label: 'Select File', placeholder: 'Select csv file'
                  }
               ],
            },
         ]
      },
      group: {
         actionBtn: 'Save',
         action: 'underwritter_group',
         pages: [
            {
               title: 'Underwritters',
               subtitle: 'Add underwritter to group',
               forms: [
                  {
                     type: 'select', class: 'col-12', model: 'group_code',
                     options: [], label: 'Group', placeholder: 'If not in group leave empty'
                  },
                  {
                     type: 'text', form_type: 'text', class: 'col-12', model: 'remarks',
                     validation: 'required|min:3|max:350', label: 'Remarks', placeholder: 'Provide your remarks'
                  },
               ],
            },
         ]
      },
      super_user: {
         actionBtn: 'Save',
         action: 'underwritter_super_user',
         pages: [
            {
               title: 'Superadmin Details',
               subtitle: 'Confgure the insurance superadmin',
               forms: [
                  {
                     type: 'text', form_type: 'text', label: 'First Name', model: 'first_name',
                     validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
                  },
                  {
                     type: 'text', form_type: 'text', label: 'Last Name', model: 'last_name',
                     validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
                  },
                  {
                     type: 'text', form_type: 'text', label: 'Employee Ref', model: 'employee_ref',
                     validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
                  },
                  {
                     type: 'text', form_type: 'email', label: 'Email', model: 'email',
                     validation: 'required|min:2|max:150|email', class: 'col-12 col-md-6'
                  }
               ],
            },
         ]
      }
   },
   table: {
      icon: 'icon-suit',
      title: 'Pending Underwritters',
      subtitle: 'Pending Underwritters List',
      table_fields: [
         { name: 'Code', align: 'c' },
         { name: 'Name', align: 'c' },
         { name: 'Category', align: 'c' },
         { name: 'Profile Status', align: 'c' },
         { name: 'Registration Status', align: 'c' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["name","code","ira_code", "status"],
      rowData: [
         { type: 'text', field: 'code', class: 'center' },
         { type: 'text', field: 'name', class: 'center' },
         { type: 'text', field: 'category', class: 'small center' },
         { type: 'status', field: 'profile_status', class: 'small center pt-3' },
         { type: 'status', field: 'registration_status', class: 'small center pt-3' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Add Group', event: 'underwritter:add_group', icon: 'edit-3', params: [
                     { key: 'underwritter', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'Add Super User', event: 'underwritter:add_super_user', icon: 'edit-3', params: [
                     { key: 'underwritter', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'edit-3', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'underwritter:view', icon: 'eye', params: [
                     { key: 'code', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'delete', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
               {type: 'divider'},
               {
                  type: 'menu', title: 'View Directors', event: 'view_directors:view', icon: 'eye', params: [
                     { key: 'client', field: '*' }
                  ]
               },
            ]
         },
      ]
   }
}