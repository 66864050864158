
<template lang="html">
    <div class="addClass"> 
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Notifications-Config'}">
                                Notifications
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">View</li>
                    </ol>
                </nav>    
            </div>  
        </div>

        <div class="row text-capitalize">
            <div class="col-12 col-sm-6">
                <h3>Entity Notifications</h3>
               
            </div>
            <div class="col-12 col-sm-6">
                <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div></div>
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createEntityNotification" >
                      <i data-feather="mail" class="wd-10 mg-r-5"></i> Create Entity Notification
                    </button>
               </div>
               
            </div>

            <!-- modal -->
            <div class="modal fade classic-modal" 
            :id="`${this.action}Modal`" 
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1" 
            role="dialog" 
            aria-labelledby="exampleModalLabel" 
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row-contents">
                            <div class="ci ci-info">
                                <i class="icon ico" :class="icon"></i>
                            </div>
                            <div class="text" id="formModalLabel">
                                <div class="fx-h5">Create Entity Notification</div>
                                <div class="text-muted">Create Entity Notification Channel</div>
                            </div>
                            <button type="button" class="close" @click="closeModal()" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="form-group col-12">
                            <label for="module">Select Module</label>
                            <select class="custom-select" 
                                v-model="formData.event"
                                id="module"
                                @change="fetchModuleServices($event)"
                                name="module">
                                    <option v-for="(option, index) in notificationEvents" :key="index + 'mo'"  :value="option.system_notification_event_ref">{{option.event_name}}</option>
                            </select>
                            <div v-if="errors.has('module')" class="text-danger">
                                {{ errors.first('module') }}
                            </div>
                        </div>

                        <div class="form-group col-12 my-2">
                            <button type="button" class="btn btn-primary" @click="saveEntityNotification()">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /modal -->
        </div>


            <div class="col-12">
                 <CustomTable :tableFields="NotificationsConfig.table.entity_table_fields"
                    :icon="NotificationsConfig.table.icon"
                    :title="'Entity Notifications'"
                    :subtitle="'A list of entity notifications'"
                    :tableData="entityNotifications" 
                    :searchFields="NotificationsConfig.table.entitySearchFields"
                    :rowData="NotificationsConfig.table.entityRowData">
                </CustomTable>

            </div>
        </div>

        
    </div>
</template>


<script>
export default {
  name: "View",
  async created() {
    this.NotificationsConfig = Models.NotificationsConfig;

    Event.$on("entityNotificationChanel:create", async ({ entity }) => {
      this.showModal = true;
      this.formData.entity_notification_event_ref =
        entity.entity_notification_event_ref;
    });

    Event.$on("entityNotificationChanel:view", async ({ notification }) => {
        this.$router.push({
          name: "Notifications-Event-Config",
          params: { event_ref: notification.entity_notification_event_ref },
        });
    });
    
  },

  data() {
    return {
      action: "addChannel",
      notificationEvents: {},
      entityNotifications: [],
      formData: {
      },
      showModal: false,
   
    };
  },

  watch: {
    showModal: async function (to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $(`#${this.action}Modal`).modal(action);
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
    addToTemplate(template) {
      console.log("template", template);
      console.log("this.formData.template", this.formData.template);
      this.formData.template += template || "";
    },
   
    // fetchNotificationEvents() {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       let res = await req.callServerSecure({
    //         resource: "notification_event",
    //         action: "list",
    //         data: {},
    //         meta_data: {},
    //       });
    //       console.log("notification", res.data.result);
    //       this.notificationEvents = res.data.result;
    //       resolve(true);
    //     } catch (err) {
    //       reject(err);
    //     }
    //   });
    // },
    fetchEntityNotifications() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_notification",
            action: "list",
            data: {},
          });
          console.log("entity_notifications", res.data.result);
          this.entityNotifications = res.data.result;
        } catch (error) {
          reject(error);
        }
      });
    },
    createEntityNotification() {
        this.showModal = true
    },
    async saveEntityNotification() {
      try {
        let res = await req.callServerSecure({
          resource: "entity_notification",
          action: "create",
          data: {
            system_notification_event_ref: this.formData.event,
          },
        });
        notification.notify(
          "success",
          "Entity notification",
          `Entity notification for ${this.formData.event} successfully added`
        );
        this.fetchEntityNotifications()
        this.showModal = false
      } catch (err) {
        console.log(err);
        Event.$emit("form:action-error", "An Error Has Occured");
      }
    },
  },

  async mounted() {
    this.fetchEntityNotifications();
    // this.fetchNotificationEvents();
    Event.$emit("loader:show", false);
  },
};
</script>