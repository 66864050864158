<template>
  <div class="card mg-b-20 mg-lg-b-25 pd-30">
    <h4 class="text-center">Transaction Configuration</h4>
    <div class="card-deck text-center mg-t-35">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Purchase</h5>
          <p class="card-text mg-t-30">
            <span class="tx-bold mg-r-10">Policy</span>
            <button class="btn btn-secondary btn-sm" @click="configPurchasePolicy()">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Payment</h5>
          <p class="card-text mg-t-30">
            <span class="tx-bold mg-r-10">Services</span>
            <button class="btn btn-secondary btn-sm">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Charges</h5>
          <p class="card-text mg-t-30">
            <span class="tx-bold mg-r-10">System Charges</span>
            <button class="btn btn-secondary btn-sm">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
          <p class="card-text mg-t-30">
            <span class="tx-bold mg-r-10">Product Charges</span>
            <button class="btn btn-secondary btn-sm">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="card-deck text-center mg-t-35">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Claim</h5>
          <p class="card-text mg-t-30">
            <button class="btn btn-secondary">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Settlement</h5>
          <p class="card-text mg-t-30">
            <button class="btn btn-secondary">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Transfer</h5>
          <p class="card-text mg-t-30">
            <button class="btn btn-secondary">
              <i data-feather="settings"></i> Configure
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    configPurchasePolicy() {
      this.$router.push({
        name: "Configs-Transactions-PurchasePolicy",
        params: {},
      });
    },
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>