<template lang="html">
   <div class="modal fade classic-modal" 
      id="fileUploadModal" 
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="fileUploadModal" 
      aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <div class="row-contents">
                           <div class="ci ci-info">
                              <i class="fa fa-group-tie"></i>
                           </div>
                           <div class="text" id="formModalLabel">
                              <div class="title">File Upload</div>
                              <div class="small">Select a file to upload.</div>
                           </div>
                           <button type="button" class="close" @click="close()">
                              <span aria-hidden="true">&times;</span>
                           </button>
                     </div>
                  </div>
                  <div class="modal-body">
                     <div class="row">
                        <div class="col-12">	
                           <div class="custom-file">
                              <input 
                                 type="file" 
                                 class="custom-file-input" 
                                 id="fileSelector"
                                 @change="onFileSelected"
                                 name="files[]" 
                                 aria-describedby="inputGroupFileAddon01">
                              <label class="custom-file-label" for="fileSelector">{{form.placeholder}}</label>
                           </div>
                           <span class="text-danger small" v-show="msg">{{msg}}</span>
                        </div>
                     </div>
                  </div>

                  <div class="modal-footer">
                     <button type="button" class="btn btn-primary" :disabled="loading" @click="uploadFile()">
                        Select <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
                     </button>
                  </div>
               </div>
         </div>
   </div>
</template>

<script>
export default {
	async created() {
		var _this = this;

		Event.$on("file:upload", function(payload) {
         if(payload.form){
            _this.form = payload.form
            $("#fileUploadModal").modal("show")
         }
		});
	},
	data() {
		return {
         form: {
            placeholder: '',
            model: '',
         },
         msg: "",
			loading: false,
			country: null,
			countries: []
		};
	},
	methods: {
      close(){
         $("#fileUploadModal").modal("hide")
      },
      onFileSelected(event){
         Array.from(event.target.files).forEach(file => {
            this.form.placeholder = file.name
         });
      },
      async uploadFile(){
         this.loading = true
         let files = document.getElementById('fileSelector').files
         let bodyFormData = new FormData()

         Array.from(files).forEach(file => {
            bodyFormData.append('file',file);
         });

         try {
            // let res = await axios.post('http://localhost:3000/upload',bodyFormData,{
            //    headers: {
            //       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGI4NDZkOGFlMzc2ODZlMjBmOTk5NzQiLCJpYXQiOjE1NzI1Mjk2NjUsImV4cCI6MTU3MjUzNjg2NX0.dMGBx8VPXy2RNcUC4ttjp9gcx3Ysh4jXZZYWc88qnHU',
            //       'Content-Type': 'multipart/form-data',
            //    },
            //    onUploadProgress: function( progressEvent ) {
            //       console.log(progressEvent,progressEvent.loaded)
            //    }.bind(this)
            // })

            let file = res.data.result.file
            Event.$emit('file:uploaded',{
               file,
               model: this.form.model
            })

            $("#fileUploadModal").modal("hide")
         } catch (err) {
            let msg = err.response.data.error.msg

            if(err.response && err.response.data && msg){
               this.msg = msg
            }else{
               console.log(err)
            }
         }

         this.loading = false
      },
		clear() {
			this.country = {
				name: "",
				code: "",
				min_account_bal: "",
				max_account_bal: "",
				description: ""
			};
		}
	}
};
</script>

<style lang="less">
#fileUploadModal {
	.mobile-selector {
		.flag-image {
			width: 24px;
			margin-right: 4px;
		}
	}
}
</style>