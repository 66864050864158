var faker = require('faker');

export default class Generator {
   constructor() {
      // filestore.removeFile('data')
      filestore.createDirectory('data')
   }

   generateData(template, noOfRecords) {
      if (!noOfRecords) {
         noOfRecords = Math.random() * 500
      }

      let data = '['

      for (let i = 0; i < noOfRecords; i++) {
         let record = faker.fake(template)

         if (i < (noOfRecords - 1)) {
            data += record + ','
         } else {
            data += record + ']'
         }
      }

      return data
   }

   async fetchData(name, template, noOfRecords) {
      let _this = this
      return new Promise(async (resolve, reject) => {
         try {
            let results = await filestore.readFile(`data/${name}.json`).catch((e) => {
               return null
            })

            if (results === null) {
               let data = _this.generateData(template, noOfRecords)
               await filestore.writeFile(`data/${name}.json`, data)
               resolve(JSON.parse(data))
            } else {
               resolve(JSON.parse(results))
            }
         } catch (err) {
            reject(err)
         }
      })
   }
}