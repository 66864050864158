<template lang="html">
   <div class="directors">
      <CustomTable :tableFields="Bank.table.directors.table_fields"
         :icon="Bank.table.directors.icon"
         :title="Bank.table.directors.title"
         :subtitle="Bank.table.directors.subtitle"
         :tableData="bank.directors" 
         :searchFields="Bank.table.directors.searchFields"
         :rowData="Bank.table.directors.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      props:{
         bank: {
            default: function(){
               return {}
            }
         },
      },
      async created(){
         this.Bank = Models.Bank

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })
      },
      data () {
         return {
            Bank: {},
            showModal: false
         }
      },
      methods: {
         createDirector() {
            this.showModal = true
         }
      },
      mounted(){
         // Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .directors{
      
   }
</style>