
<template lang="html">
    <div class="uploadview">
        <ModalForm :showModal="showModal" :action="uploadField.wizard.action">
         <template slot="form">
         <div class="custom-file">
  <input type="file" class="custom-file-input" id="file" ref="file" @change="processFile()">
  <label class="custom-file-label" for="file">{{filelabel}}</label>
</div>
            <CustomForm 
               :pages="uploadField.wizard.pages" 
               :action="uploadField.wizard.action" 
               :buttonText="uploadField.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

 <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Dashboard
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">File Uploads</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">File Uploads</h4>
            </div>
            <div class="d-none d-md-block">
                
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" @click="fuploadFile()">
                    <i data-feather="file" class="wd-10 mg-r-5"></i> Upload File
                </button>
            </div>
    </div>


  <CustomTable :tableFields="uploadField.table.table_fields"
				:icon="uploadField.table.icon"
				:title="uploadField.table.title"
				:subtitle="uploadField.table.subtitle"
				:tableData="uploads" 
				:searchFields="uploadField.table.searchFields"
				:rowData="uploadField.table.rowData">
  </CustomTable>
 </div>
</template>
<script>
export default {
       async created(){
             this.uploadField = Models.uploadField;
             	 Event.$on('modal:close',async (payload) => {
                        this.showModal = false
                    })
              	Event.$on('upload:view',async (payload) => {
                        console.log('Payload: ',payload)
                        this.$router.push({name: 'Configs-Insurance-ViewUpload', params: {id: payload.upload.code}})
                    })

                Event.$on('form:submit', async (payload) => {
                 if(payload.action === this.uploadField.wizard.action){
                        try {
                            Event.$emit('loader:show', true);
                       
                             await this.saveUpload(payload.formData)
                             this.showModal = false;
                             notification.notify('success', 'Upload Success', `Success`);
                            await this.Fetchuploads()
                             Event.$emit('loader:show', false);
                        }
                        catch (err) {
                            alert(err)
                             Event.$emit('loader:show', false);
                        Event.$emit('form:action-error',{action: this.uploadField.wizard.action})
                        }
                                
                    }
                })
        },
        data() {
		  return {
		   showModal: false,
           uploads:[],
           filelabel:"Choose File",
           fileToUpload:{},
            file: ''
       
		 }
	},
    methods: {
  processFile(event) {
     this.filelabel=this.$refs.file.files[0].name;
     this.file = this.$refs.file.files[0];
  },
    fuploadFile () {
                  this.showModal = true;
     },
     saveUpload (payload) {
          return new Promise(async (resolve, reject) => {
           try {
	              var formDataToUpload = new FormData();
                     
		    	      formDataToUpload.append("file",    this.file);
                      formDataToUpload.append("type",  payload.type);
                      formDataToUpload.append("name",  payload.name);
                      formDataToUpload.append("code",  payload.code);
                      formDataToUpload.append("subtype",  payload.subtype);
                      formDataToUpload.append("sheet_name",  payload.sheet_name);   
                      formDataToUpload.append("description",  payload.description);   
                       
                        console.log(formDataToUpload)        
			await req.callServerMultipart('/api/upload/v1/admin/upload',formDataToUpload);
                    resolve()
			}
    
         catch (err) {
           reject(err)
         }
     })

     },
        Fetchuploads() {
                      return new Promise(async (resolve, reject) => {
                                try {
                                    let res = await req.callServerSecure({
                                        resource: "uploads",
                                        action: "list",
                                        data: {},
                                        meta_data: {}
                                    });
                                   
                            this.uploads = res.data.result;
                            console.log( this.uploads)
                                    resolve(true);
                                } catch (err) {
                                       notification.notify('error', 'Error Fetching Uploads', `Kindly Retry Later`);
                                    reject(err);
                                }
                            });
                }
    },
    async mounted(){
        await this.Fetchuploads()
		Event.$emit('loader:show', false)
	}
    };
</script>

<style lang="less">
.uploadview {
}
</style>