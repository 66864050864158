



<template lang="html">

    <div class="upload">
         <CustomTable :tableFields="viewupload.table.table_fields"
				:icon="viewupload.table.icon"
				:title="viewupload.table.title"
				:subtitle="viewupload.table.subtitle"
				:tableData="uploads" 
				:searchFields="viewupload.table.searchFields"
				:rowData="viewupload.table.rowData">
			</CustomTable>

    </div>
</template>

<script>
export default {
    async created(){
          this.viewupload = Models.viewupload;
     },
      data() {
		 return {
		  showModal: false,
          uploads:[]
		 }
	},
    methods: {
         getUpload () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "uploads",
                     action: "search_type",
                     data: {
                         type:  this.$route.params.id
                     
                     },
                     meta_data: {},
                  });

console.log(res.data.result)
                  this.uploads = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
    },
	async mounted(){
         await this.getUpload()
		Event.$emit('loader:show', false)
	}
  
    };
</script>

<style lang="less">
.reccomendation {
}
</style>  