<template lang="html">
  <div class="directors">
    <ModalForm
      :showModal="showModal"
      :action="UnderwritterSuperUsers.wizard.action"
    >
      <template slot="form">
        <CustomForm
          :pages="UnderwritterSuperUsers.wizard.pages"
          :action="UnderwritterSuperUsers.wizard.action"
          :buttonText="UnderwritterSuperUsers.wizard.actionBtn"
          :showTitle="false"
        >
        </CustomForm>
      </template>
    </ModalForm>

    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div></div>
      <div class="d-none d-md-block">
        <button
          class="btn btn-sm pd-x-15 btn-white btn-uppercase"
          @click="createSuperUser()"
        >
          <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Super User
        </button>
      </div>
    </div>

    <CustomTable
      :tableFields="UnderwritterSuperUsers.table.table_fields"
      :icon="UnderwritterSuperUsers.table.icon"
      :title="UnderwritterSuperUsers.table.title"
      :subtitle="UnderwritterSuperUsers.table.subtitle"
      :tableData="directors"
      :searchFields="UnderwritterSuperUsers.table.searchFields"
      :rowData="UnderwritterSuperUsers.table.rowData"
    >
    </CustomTable>
  </div>
</template>

<script>
export default {
  async created() {
    this.UnderwritterSuperUsers = Models.UnderwritterSuperUsers;
    // this.directors = await utils.getDummyData(Models.UnderwritterSuperUsers,3)

    // if specified action is complete clear form data and stop loader
    Event.$on('form:submit', async (payload) => {
      if (payload.action === this.UnderwritterSuperUsers.wizard.action) {
        await this.saveDirector(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on('modal:close', async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      UnderwritterSuperUsers: {},
      directors: [],
      showModal: false,
    };
  },
  methods: {
    createSuperUser() {
      this.showModal = true;
    },
    saveDirector(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'profile',
            action: 'director',
            data: {
              names: data.first_name + ' ' + data.last_name,
              contact: `${data.contact.country_code.callingCodes[0]}:${data.contact.number}`,
              email: data.email,
            },
            meta_data: {},
          });

          notification.notify(
            'success',
            'Director Added',
            `Company director successfully added`
          );
          Event.$emit('form:action-complete', {
            action: this.UnderwritterSuperUsers.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          console.log(err);
          Event.$emit('form:action-error', {
            action: this.UnderwritterSuperUsers.wizard.action,
          });
        }
      });
    },
  },
  mounted() {
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.directors {
}
</style>
