<template lang="html">
   <div class="notifications">

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <router-link class="btn btn-sm pd-x-15 btn-white btn-uppercase" :to="{ name: 'Notifications-Configuration' }">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Create Notification Event
               </router-link>
         </div>
      </div>

      <CustomTable :tableFields="NotificationsConfig.table.table_fields"
         :icon="NotificationsConfig.table.icon"
         :title="NotificationsConfig.table.title"
         :subtitle="NotificationsConfig.table.subtitle"
         :tableData="notifications" 
         :searchFields="NotificationsConfig.table.searchFields"
         :rowData="NotificationsConfig.table.rowData">
      </CustomTable>
   </div>
</template>

<script> 
export default {
  async created() {
    this.NotificationsConfig = Models.NotificationsConfig;

    

    Event.$on("notification:configure", async ({notification}) => {
      // this.$router.push({
      //     name: "Notifications-Event-Config",
      //     params: { event_name: notification.event_name },
      //   });
    });

  },
  data() {
    return {
      NotificationsConfig: {},
      notifications: [],
    };
  },
  methods: {
    fetchNotifications() {
      return new Promise(async (resolve, reject) => {
        try { 
          let res = await req.callServerSecure({
            resource: "notification_event",
            action: "list",
            data: {},
            meta_data: {},
          });
          console.log("notification", res.data.result);
          this.notifications = res.data.result;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createNotificationsConfig() {
      this.showModal = true;
    },
    
  },
  async mounted() {
    await this.fetchNotifications();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
</style>