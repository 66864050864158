<template>
  <div class="contact-content">
    <div class="contact-content-body bg-gray-100">
      <div id="details" class="pd-20 pd-xl-25">
        <form @submit.prevent="saveConfig()">
          <div class="row row-sm">
            <div class="col-12 col-sm-6">
              <h6 class="mg-b-40">Account Template Configuration</h6>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Select Currency</label>

                  <select
                    class="custom-select"
                    v-model="form.currency"
                    name="currency"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(curr, i) in currencies"
                      v-bind:value="curr.currency_iso_code"
                      :key="i"
                    >
                      {{ curr.name }}
                    </option>
                  </select>
                  <div class="text-danger" v-if="errors.has('currency')">
                    {{ errors.first("currency") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">Deposit Account Opening Template</label>
                  <select
                    class="custom-select"
                    v-model="form.deposit_act_opn_template"
                    name="deposit account"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(actt, i) in accTemplates"
                      v-bind:value="actt.template_code"
                      :key="i"
                    >
                      {{ actt.template_name }}
                    </option>
                  </select>
                  <div class="text-danger" v-if="errors.has('deposit account')">
                    {{ errors.first("deposit account") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">Trust Account Opening Template </label>
                  <select
                    class="custom-select"
                    v-model="form.trust_act_opn_template"
                    name="trust account"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(actt, i) in accTemplates"
                      v-bind:value="actt.template_code"
                      :key="i"
                    >
                      {{ actt.template_name }}
                    </option>
                  </select>
                  <div class="text-danger" v-if="errors.has('trust account')">
                    {{ errors.first("trust account") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">Operating Account Opening Template</label>
                  <select
                    class="custom-select"
                    v-model="form.operating_act_opn_template"
                    name="operating account"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(actt, i) in accTemplates"
                      v-bind:value="actt.template_code"
                      :key="i"
                    >
                      {{ actt.template_name }}
                    </option>
                  </select>
                  <div
                    class="text-danger"
                    v-if="errors.has('operating account')"
                  >
                    {{ errors.first("operating account") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">System DSLRA Account</label>
                  <select
                    class="custom-select"
                    v-model="form.system_dslra_account"
                    name="system dslra"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(acttt, i) in Sysaccounts"
                      v-bind:value="acttt.account_number"
                      :key="i"
                    >
                      {{ acttt.account_name }}
                    </option>
                  </select>
                  <div class="text-danger" v-if="errors.has('system dslra')">
                    {{ errors.first("system dslra") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">Bank DSLRA Account Opening Template</label>
                  <select
                    class="custom-select"
                    v-model="form.lender_dslra_account_template"
                    name="lender dslra"
                    v-validate="'required'"
                  >
                    <option
                      v-for="(actt, i) in accTemplates"
                      :key="i"
                      v-bind:value="actt.template_code"
                    >
                      {{ actt.template_name }}
                    </option>
                  </select>
                  <div class="text-danger" v-if="errors.has('lender dslra')">
                    {{ errors.first("lender dslra") }}
                  </div>
                </div>
                <div class="form-row">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    :disabled="loading"
                  >
                    Save Configuration
                    <div class="lds-ring" v-show="loading">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="divider-text divider-vertical"></div>
            <div class="col-12 col-sm-5">
              <h6 class="mg-b-40">Fee Template Config</h6>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="max">Client Loan Application Fee</label>
                  <select
                    class="custom-select"
                    v-model="form.client_loan_application_fee"
                  >
                    <option
                      v-for="(ff, index) in fees"
                      :value="ff.system_fee_ref"
                      :key="index"
                    >
                      {{ ff.fee_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">Lender Disbursment Fee</label>
                  <select
                    class="custom-select"
                    v-model="form.lender_disbursment_fee"
                  >
                    <option
                      v-for="(ff, index) in fees"
                      v-bind:value="ff.system_fee_ref"
                      :key="index"
                    >
                      {{ ff.fee_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="max">School Release Fee</label>
                  <select
                    class="custom-select"
                    v-model="form.school_release_fee"
                  >
                    <option
                      v-for="(ff, index) in fees"
                      v-bind:value="ff.system_fee_ref"
                      :key="index"
                    >
                      {{ ff.fee_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- <nav class="nav nav-line">
          <a href="#gen-configs" class="nav-link active" data-toggle="tab"
            >General Configuration</a
          >
          <a href="#account-configs" class="nav-link" data-toggle="tab"
            >Account Template Configuration</a
          >
        </nav> -->
        <!-- <div class="tab-content"> -->
        <!-- <div id="gen-configs" class="tab-pane show active pd-20 pd-xl-25">
            
              <div class="row row-sm">
                <div class="col-6 col-sm-4">
                  <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10"
                    >Min Tenure</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.minimum_tenor }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10"
                    >Max Tenure</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.maximum_tenor }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10"
                    >Number of Days to Release</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.number_of_days_to_release }}
                  </p>
                </div>
                <div class="col-6 col-sm-4">
                  <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10"
                    >DSLRA Value</label
                  >
                  <p class="tx-primary tx-rubik mg-b-0">
                    {{ GeneralConfig.dslra_value }}
                  </p>
                </div>
              </div>
        
          </div> -->
        <!-- <div id="account-configs" class="tab-pane show pd-20 pd-xl-25"> -->
        <h6 class="mg-t-40 pb-3 border-bottom">Current Configurations</h6>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="border-0">Currency</th>
              <th scope="col" class="border-0">Deposit Account</th>
              <th scope="col" class="border-0">Trust Account</th>
              <th scope="col" class="border-0">Operating Account</th>
              <th scope="col" class="border-0">System DSLRA Act</th>
              <th scope="col" class="border-0">Bank DSLRA Tmpl</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, name, index) in UnderwritterConfig" :key="index">
              <td>{{ name }}</td>
              <td>{{ value.deposit_act_opn_template }}</td>
              <td>{{ value.trust_act_opn_template }}</td>
              <td>{{ value.operating_act_opn_template }}</td>
              <td>{{ value.system_dslra_account }}</td>
              <td>{{ value.lender_dslra_account_template }}</td>
            </tr>
          </tbody>
        </table>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- contact-content-body -->
  </div>
  <!-- contact-content -->
</template>

<script>
export default {
  async created() {},
  data() {
    return {
      loading: false,
      accTemplates: [],
      currencies: [],
      Sysaccounts: [],
      fees:[],
      ModuleConfig: {},
      GeneralConfig: {},
      UnderwritterConfig: {},
      SchoolModuleConfig: {},
      ActTemplateConfig: {},

      form: {
        minimum_tenure: "",
        maximum_tenure: "",
        dslra_value: "",
        number_of_days_to_release: "",
        currency: "",
        deposit_act_opn_template: "",
        trust_act_opn_template: "",
        operating_act_opn_template: "",
        system_dslra_account: "",
        lender_dslra_account_template: "",
      },
    };
  },
  methods: {
    async fetchUnderwritterConfigs() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "underwriter_config",
            action: "get",
            data: {},
          });

          this.UnderwritterConfig = res.data.result.account_opening_template;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
      fetchSytemFees() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "fees",
            action: "list_fees",
            data: {},
            meta_data: {},
          });

          this.fees = res.data.result.entity_fees;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    async saveConfig() {
      try {
        let valid = await this.$validator.validate();
        if (!valid) {
          return " Validation Error ";
        } else {
          this.loading = true;
          let res = await req.callServerSecure({
            resource: "underwriter_config",
            action: "create",
            data: {
              account_opening_template: {
                [this.form.currency]: {
                  deposit_act_opn_template: this.form.deposit_act_opn_template,
                  trust_act_opn_template: this.form.trust_act_opn_template,
                  operating_act_opn_template: this.form
                    .operating_act_opn_template,
                  system_dslra_account: this.form.system_dslra_account,
                  lender_dslra_account_template: this.form
                    .lender_dslra_account_template,
                },
              },
              general: {
                minimum_tenor: this.form.minimum_tenure,
                maximum_tenor: this.form.maximum_tenure,
                dslra_value: this.form.dslra_value,
                number_of_days_to_release: this.form.number_of_days_to_release,
              },
            },
          });
          notification.notify(
            "success",
            "Success",
            `Config added successfully`
          );

          await fetchUnderwritterConfigs();
          this.loading = false;
        }
        (this.form.minimum_tenure = ""),
          (this.form.maximum_tenure = ""),
          (this.form.dslra_value = ""),
          (this.form.number_of_days_to_release = ""),
          (this.form.currency = ""),
          (this.form.deposit_act_opn_template = ""),
          (this.form.trust_act_opn_template = ""),
          (this.form.operating_act_opn_template = ""),
          (this.form.system_dslra_account = ""),
          (this.form.lender_dslra_account_template = "");
      } catch (err) {
        this.loading = false;
      }
    },

    async fetchSystemAccounts() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "system_account",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.Sysaccounts = res.data.result.accounts;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    async fetchCurrencies() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "currency",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.currencies = res.data.result.currencies;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    async fetchAccountTemplate() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_account_template",
            action: "list",
            data: {
              // entity_type: "UNDERWRITTER",
            },
            meta_data: {},
          });
          this.accTemplates = res.data.result.templates;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
        await this.fetchSytemFees();
    await this.fetchCurrencies();
    await this.fetchAccountTemplate();
    await this.fetchSystemAccounts();
    await this.fetchUnderwritterConfigs();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.orange {
}
</style>
