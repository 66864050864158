<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Currency </li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">List Of Account Currency</h4>
            </div>
            <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="addCurrency()"
                >Add</button>
        </div>
                <div class="table-responsive">
                     <table class="table ml-table ml-responsive-md">
                        <thead  class="thead-primary">
                                <tr>
                             <th scope="col">No.</th>
                            <th scope="col">Code</th>
                            <th scope="col">Iso Code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in currencies">
                                <th scope="row">{{ index +1 }}</th>
                                <td>{{ item.currency_code }}</td>
                                <td>{{ item.currency_iso_code }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.description }}</td>
                            </tr>
                            
                     
                        </tbody>
                        </table>
         </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             currencies:[]
         }

      },
    methods: {
     addCurrency() {
        this.$router.push({ name: 'accounting.currency.AddCurrency' })
     },
    fetchCurrencies(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "currency",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.currencies = res.data.result.currencies
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
    }, 
    async mounted(){
         await this.fetchCurrencies()
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>