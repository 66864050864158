module.exports = {
   name: 'gl',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'account_config',
      pages: [
         {
            title: 'Account Template Config',
            subtitle: 'Configure a account template',
            forms: [
               {
                  type: 'text', form_type: 'text', class: 'col-12', model: 'code',
                  validation: 'required|min:3|max:50', label: 'Code',
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'gl',
                  options: [],
                  validation: 'required', label: 'General Ledger'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'currency',
                  options: [],
                  validation: 'required', label: 'Currency'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'entity',
                  options: [
                     { val: 'UNDERWRITTER', label: 'Underwritter' },
                     { val: 'CLIENT', label: 'Client' }
                  ],
                  validation: 'required', label: 'Entity Type'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'entity_subtype',
                  options: [],
                  validation: 'required', label: 'Entity Subtype'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'limit',
                  options: [],
                  validation: 'required', label: 'Account Limit'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'status',
                  options: [],
                  validation: 'required', label: 'Account Status'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'statement',
                  options: [],
                  validation: 'required', label: 'Account Statement'
               },
               {
                  type: 'select', class: 'col-12 col-md-6', model: 'type',
                  options: [
                     { val: 'TRANSACTION', label: 'Transaction' },
                     { val: 'LOAN', label: 'Loan' }
                  ],
                  validation: 'required', label: 'Account Type'
               },
            ],
         }
      ]
   },
   table: {
      title: 'Accounts',
      subtitle: 'A list of accounts',
      table_fields: [
         { name: 'Acc No.', align: 'l' },
         { name: 'Acc Name', align: 'l' },
         { name: 'Entity Type', align: 'l' },
         { name: 'Currency Code', align: 'c' },
         { name: 'Balance', align: 'l' },
         // { name: 'Opening Date', align: 'c' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["account_number", "account_name", "available_balance","currency_code"],
      rowData: [
         { type: 'text', field: 'account_number', class: 'left bolder small' },
         { type: 'text', field: 'account_name', class: 'left bolder small' },
         { type: 'text', field: 'entity_type', class: 'left bolder small' },
         { type: 'text', field: 'currency_code', class: 'center bolder small' },
         { type: 'number', field: 'available_balance', class: 'left bolder' },
         // { type: 'text', field: 'account_opening_date', class: 'left small' },
         {
            type: 'action', field: 'account_number', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'account:view', icon: 'ico-reply', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'account', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
}