<template>
  <div
    class="modal fade"
    id="userModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-pledby="staticBackdropp"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" type="button" class="close" data-dismiss="modal" aria-p="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-header">
              <h4 class="text-center">Select Users</h4>
            </div>
            <div class="card-body">
              <div class="form-check" v-for="(user, index) in users" :key="index">
                <input class="form-check-input" type="checkbox" :value="usernames" :id="{user}" />
                <label class="form-check-label" :for="{user}">{{user}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="add()" type="button" class="btn btn-secondary">Add</button>
          <button
            @click="close()"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getUsers();
  },
  data() {
    return {
      users: [],
      usernames: [],
    };
  },
  props: {
    showUserModal: {
      default: false,
    },
  },
  watch: {
    showUserModal: function (to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $("#userModal").modal(action);
    },
  },
  methods: {
    async getUsers() {
      this.users = ["Robert Kamau", "Jane Doe", "Tom Edison", "Allison Burke"];
      // return new Promise(async (resolve, reject) => {
      //   try {
      //     let res = await req.callServerSecure({
      //       resource: "users",
      //       action: "list",
      //       data: {},
      //       meta_data: {},
      //     });

      //     this.users = res.data.result.users;
      //     resolve(true);
      //   } catch (err) {
      //     if (err.status === 500) {
      //       notification.notify(
      //         "error",
      //         "Server unreachable",
      //         "Try again later"
      //       );
      //     }
      //     reject(err);
      //   }
      // });
    },
    close() {
      Event.$emit("userModal:close", {});
    },
  },
};
</script>

<style>
</style>