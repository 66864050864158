<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account Status</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add Account Status</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="code">Code</label>
      <input type="text" class="form-control" id="code" placeholder="Code" v-model="act_status.account_status_code">
    </div>
    <div class="form-group col-md-3">
      <label for="iscocode">Name</label>
      <input type="text" class="form-control" id="name" v-model="act_status.account_status_name" placeholder="Name">
    </div>
    <div class="form-group col-md-6">
      <label for="name">Description</label>
      <input type="text" class="form-control" id="description" v-model="act_status.account_status_description" placeholder="Description">
    </div>
  </div>
   <div class="form-row">
    <div class="form-group col-md-6">
     <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="allowdr" true-value="true" false-value="false" v-model="act_status.allow_dr">
      <label class="custom-control-label" for="allowdr">Allow Debit</label>
    </div> 
     </div>
    <div class="form-group col-md-6">
     <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="allowcr"  true-value="true" false-value="false" v-model="act_status.allow_cr">
      <label class="custom-control-label" for="allowcr">Allow Credit</label>
    </div> 
    </div>

   </div>  
  


        <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveStatus()" class="btn btn-primary">Add Account Status</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             act_status:{
                account_status_code:"" ,
                account_status_name:"" ,
                account_status_description:"", 
                allow_dr:true, 
                allow_cr:true, 
             }
                

         }

      },
    methods: {
       async saveStatus () {
          try {
                let res = await req.callServerSecure({
                     resource: "account_status",
                     action: "add",
                     data: this.act_status,
                     meta_data: {},
                  });

              this.$router.push({ name: 'accounting.accountstatus.listStatus' })
  
          }
          catch (err) {
              console.log(err)
     
          }
        }
    }, 
    async mounted(){
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>