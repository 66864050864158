<template>
  <div class="add-formField">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">System Form Fields</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new Form Field field</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="formField.wizard.pages"
          :action="formField.wizard.action"
          :buttonText="formField.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.formField = Models.formField;

    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.formField.wizard.action) {
        try {
          let forms = await this.validateFormFields(payload);
          Event.$emit("loader:show", true);
          this.saveFormField(forms);
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.formField.wizard.action,
          });
        }
      }
    });
  },
  data() {
    return {
      formField: {},
      formFields: [],
    };
  },
  methods: {
    fetchFormFields() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "form_field",
            action: "list",
            data: {},
            meta_data: {},
          });
          let pages = this.formField.wizard.pages;
          pages[0].forms[23].options = utils.appendOptions(
            res.data.result.form_fields,
            "field_code",
            "field_name"
          );

          this.formField.wizard.pages = pages;

          this.formFields = res.data.result.form_fields;

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchFormFields();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>