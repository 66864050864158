<template lang="html">
   <div class="withdrawals">
      <ModalForm :showModal="showModal" :action="Withdrawal.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Withdrawal.wizard.pages" 
               :action="Withdrawal.wizard.action" 
               :buttonText="Withdrawal.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <ModalView :showModal="showView">
         <template slot="view" class="more-details">
            
            <div class="row" v-if="withdraw">
                  <div class="col-12"><div class="title">Details</div></div>
                  <div class="col-12 col-lg-3 col-md-6 mb-3"  v-for="(index) in Object.entries(withdraw)" :key="index + 'withd'">
                        <label v-if="index !== 'created_by' && index !== 'version' && index !== 'Version'" class="text-capitalize">{{ index[0].replace('_', ' ').replace('_', ' ') || "" }}</label>
                        <div v-if="index !== 'created_by' && index !== 'version' && index !== 'Version'" class="data-field">
                           {{ index[1] || "" }}
                        </div>                      
                  </div>
            </div>
         </template>
      </ModalView>


      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createWithdraw()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Withdrawal
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Withdrawal.table.table_fields"
         :icon="Withdrawal.table.icon"
         :title="Withdrawal.table.title"
         :subtitle="Withdrawal.table.subtitle"
         :tableData="withdrawals" 
         :searchFields="Withdrawal.table.searchFields"
         :rowData="Withdrawal.table.rowData">
      </CustomTable>


   </div>
</template>

<script>
   export default {
       data () {
         return {
            Withdraw: {},
            withdrawals: [],
            showModal: false,
            showView: false,
            withdraw: {}
         }
      },
      async created(){

         this.Withdrawal = Models.Withdrawal
         await this.fetchChannels()
         await this.fetchCurrencies()
         await this.$nextTick()

         var pages = this.Withdrawal.wizard.pages;
         Event.$on('withdrawal:view', async (payload) => { 
            this.withdraw = payload.withdrawal // set active withdrawal
            this.showView = true
            console.log("payload", this.withdraw);
            Event.$emit("page-changed", {
               // page: 0,
               title: "Withdraw",
               subtitle: "View",
               icon: pages.icon,
               data: payload
            });
          })
         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.Withdrawal.wizard.action){
               await this.saveWithdraw(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
            this.showView = false
         })

         // Event.$on('client:view',(payload) => {})
      },
     

      async mounted(){
         await this.fetchwithdrawals()
         await this.fetchChannels()
         await this.fetchCurrencies()
         Event.$emit('loader:show', false)
      },
      methods: {
         async fetchwithdrawals(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "withdrawal",
                     action: "list_config",
                     data: {},
                     meta_data: {},
                  });
                  this.withdrawals = res.data.result.withdrawal_configs

                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createWithdraw() {
            this.showModal = true
         },
         saveWithdraw(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "withdrawal",
                     action: "create_config",
                     data: {
                        channel_code: data.channel_code,
                        currency_code: data.currency_code,
                        withdrawal_limit: data.withdrawal_limit,
                        withdrawal_fee: data.withdrawal_fee,
                        withdraw_code: data.withdrawal_code,
                        withdraw_name: data.withdrawal_name,
                        withdraw_description: data.withdraw_description,
                     },
                     meta_data: {},
                  });

                  await this.fetchwithdrawals()
                  notification.notify('success', 'Withdraw Added', `The Withdraw ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.Withdrawal.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.Withdrawal.wizard.action})
               }
            })
         },

         fetchCurrencies(){
            return new Promise(async (resolve,reject) => {
               try {
                  var pages = this.Withdrawal.wizard.pages;
                  let res = await req.callServerSecure({
                     resource: "currency",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                  // set channel select variables
                  pages[0].forms[1].options = utils.appendOptions(
                     res.data.result.currencies,
                     "currency_code",
                     "name",
                  ),
                  // this.currencies = res.data.result.currencies
                  resolve(true)
               } catch (err) {
                  console.log("fetchCurrencies err", err);
                  reject(err)
               }
            })
         },
         fetchChannels() {
            return new Promise(async (resolve, reject) => {
            try {
               var pages = this.Withdrawal.wizard.pages;
               let res = await req.callServerSecure({
                  resource: "transaction_channel",
                  action: "list",
                  data: {},
                  meta_data: {},
               });
               // set channel select variables
               pages[0].forms[0].options = utils.appendOptions(
                  res.data.result.transaction_channels,
                  "channel_code",
                  "channel_name",
               ),

               resolve(true);
            } catch (err) {
               console.log("fetchChannels err", err);
               reject(err);
            }
            });
         },
      },
      
   }
</script>

<style lang="less">
.title {
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0 20px 0;
    color: #7d89a1;
  }
  .more-details {
    margin-top: 50px;

    .data-field {
      margin-bottom: 20px;
    }
  }
</style>