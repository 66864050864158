module.exports = {
   name: 'account_status',
   gen_template: `
      {
         "coa": "ASSET",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'account_status_config',
      pages: [
         {
            title: 'Account Status',
            subtitle: 'Configure new account status',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'Code', model: 'code',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Name', model: 'name',
                  validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
               },
               { type: 'label', class: 'col-12', label: 'Account Actions' },
               {
                  type: 'check', class: 'col-12 col-md-6', model: 'allow_cr',
                  label: 'Allow Credit'
               },
               {
                  type: 'check', class: 'col-12 col-md-6', model: 'allow_dr',
                  label: 'Allow Debit'
               },
               {
                  type: 'textarea', form_type: 'text', label: 'Description', model: 'description',
                  validation: 'required|min:10|max:500', class: 'col-12'
               },
            ],
         }
      ]
   },
   table: {
      title: 'Account Status Configs',
      subtitle: 'A list of configured account status',
      table_fields: [
         { name: 'Code', align: 'l' },
         { name: 'Name', align: 'l' },
         { name: 'Debit Allowed', align: 'c' },
         { name: 'Credit Allowed', align: 'c' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["names", "contact", "email"],
      rowData: [
         { type: 'text', field: 'account_status_code', class: 'left bolder small' },
         { type: 'text', field: 'account_status_name', class: 'left bolder' },
         { type: 'text', field: 'allow_dr', class: 'left bolder small' },
         { type: 'text', field: 'allow_cr', class: 'left bolder small' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'email', field: 'email' },
                     { key: 'name', field: 'name' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}