<template>
  <div class="view-holiday">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Holiday</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Code :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_system_ref}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_name}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Description :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_description}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Type :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_type}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Day of Month :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_date.date}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Month :</div>
              <div class="media-body pd-l-15">
                <h6>{{ this.holiday.holiday_date.month}}</h6>
              </div>
            </div>
            <!-- media -->

            <div class="media mg-b-20">
              <div class>Event Occurence :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_type}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ this.holiday.holiday_status}}</h6>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <button class="btn btn-sm">
            <i data-feather="edit"></i> Edit
          </button>
          <button class="btn btn-sm mg-l-20">
            <i data-feather="thumbs-up"></i> Approve
          </button>
          <button class="btn btn-sm mg-l-20">
            <i data-feather="file"></i> Audit
          </button>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
  </div>
</template>

<script>
export default {
  created() {
    // console.log(this.$route.params.id);
  },
  data() {
    return {
      holiday: {},
    };
  },
  methods: {
    approveHoliday() {
      console.log("approveUser");
    },
    editHoliday() {
      console.log("editUser");
    },
    auditHoliday() {
      console.log("activateUser");
    },

    fetchHoliday() {
       console.log("holiday dets",this.$route.params.id)
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            	"resource":"holiday",
	"action":"get",
    "data" : {
        "holiday_system_ref": this.$route.params.id
    }
           
          });
          // alert(res)
          console.log("holiday dets", res,this.$route.params.id)

          this.holiday = res.data.result.holiday;

          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
  },
  async mounted() {
     await this.fetchHoliday();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>