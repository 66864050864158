<template>
  <div class="add-provider">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Department</div>
            <p class="tx-13 tx-color-03 mg-b-0">Add Department</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="Department.wizard.pages"
          :action="Department.wizard.action"
          :buttonText="Department.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  created() {
    this.Department = Models.Department;

    Event.$on("form:submit", async (payload) => {
      this.addDepartment(payload.formData)
    });
  },
  data() {
    return {
      Department: {},
      departments: [],
    };
  },
  methods: {
       addDepartment:  debounce(async function(data) {
        console.log('dataa department add..', data)
        try {
          const res =   await req.callServerSecure({
            resource: "department",
            action: "create",
            data: {
                department_code: data.code,
                department_name: data.name,
                department_description: data.description
            },
            meta_data: {},
            });

            console.log('res add department', res)
            console.log("departsments", this.departments)

            this.$router.push({name: 'list_departments'})
            notification.notify('success', 'Success', 'Department added successfully!')
        } catch (err) {
          notification.notify('warning', 'Error', 'Error creating department!')
          console.log("err", err);
        }

      })
  },
  async mounted() {
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>