<template lang="html">
    <div class="reviews">
        <div class="row">
           <div class="col-12 mt-3" v-for="i in 10">
                <div class="fx-card fx-card-social">
                    <div class="fx-card-top clearfix">
                        <img class="fx-profile-image" src="/img/avatars/3.png" alt="">
                        <div class="details">
                            <div class="fx-h5">Jane Doe <span class="fx-lighter text-muted fx-small-text right">4 Hours Ago</span></div>
                            <div class="fx-text">ipsum dolor sit amet consectetur adipisicing</div>
                        </div>
                    </div>

                    <div class="fx-card-content">
                        <div class="fx-text">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas cum ut repellat ipsa blanditiis 
                            ipsum eaque repellendus maiores tempora similique optio facilis nobis aliquid quos,
                            vero dolor corporis deserunt distinctio! Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                            Fugit officia harum porro sint ullam doloribus expedita repellendus unde ea impedit, quam at reprehenderit 
                            recusandae corporis sunt ipsa tempore, dolor vel!
                        </div>
                    </div>
                    
                    <div class="fx-card-bottom clearfix">
                        <div class="text float-l">Rating: </div>
                        <div class="ratings float-l">
                           <i class="icon icon-star"></i>
                           <i class="icon icon-star"></i>
                           <i class="icon icon-star"></i>
                           <i class="icon icon-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less">
   .reviews{
        .ratings{
            margin: 5px 0 0 0;
            color: var(--primary);
        }
        .text{
            font-weight: 600;
            margin: 2px 10px 0 0;
        }
        .float-l{
            float: left;
        }
   }
</style>