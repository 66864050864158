// Global Imports
import Vue from "vue";
import Vuex from 'vuex';
import IdleVue from "idle-vue";
import App from "./App.vue";
import router from "./router/routes";
import Vuelidate from 'vuelidate'

import VeeValidate from 'vee-validate';
import VueCompositionAPI from '@vue/composition-api';


Vue.use(VeeValidate);

// Third Party Imports
// import VeeValidate from "vee-validate";

Vue.use(Vuelidate)
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        count: 0
    },
    mutations: {
        increment(state) {
            state.count++
        }
    }
})

// Vue.use(VeeValidate);
import { quillEditor } from "vue-quill-editor";
Vue.component("quillEditor", quillEditor);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import Cleave from "vue-cleave-component";
Vue.component("cleave", Cleave);
import ColorPicker from "v-color";
Vue.component("ColorPicker", ColorPicker);
var moment = require("moment");
global.moment = moment;

// Local Imports
global.notification = require("./assets/js/notifications");
import PageStore from "./assets/js/page-store";
import Filestore from "./assets/js/filestore";
import SessionStorage from "./assets/js/session";
global.PageStore = PageStore;
import ApiRequests from "./assets/js/api-requests";
import Utils from "./assets/js/utilities";
import Gen from "./assets/js/generator";
// import Events from './assets/js/events'

global.utils = new Utils();
global.filestore = new Filestore();
global.$session = new SessionStorage();
global.get_req = new ApiRequests(Event);
global.req = get_req.getReq();
global.gen = new Gen();
global.Models = require("./assets/js/models");

// Import the custom theme from my less files
import "./assets/less/index.less";
import "vue-select/dist/vue-select.css";

// quill editor css
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//Vue Settings
Vue.config.productionTip = false;
window.Event = new Vue();
const eventsHub = window.Event


Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 240000,
    startAtIdle: false
});

new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});

// Scroll to the top and initialize feather icons
router.beforeEach(async(to, from, next) => {
    Event.$emit("loader:show", true);
    $("html,body").animate({ scrollTop: 0 });
    next();
});

setInterval(() => {
    feather.replace();
}, 5000);