<template>
  <div class="add-Topup">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">Topup Config</div>
            <p class="tx-13 tx-color-03 mg-b-0">Configure new topup method</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="Topup.wizard.pages"
          :action="Topup.wizard.action"
          :buttonText="Topup.wizard.actionBtn"
          :showTitle="false"
        >
          <template slot="icon">
            <div class="form-row mg-b-20">
              <div class="form-group col-md-5">
                <nav class="nav nav-icon-only">
                  <div class="dropdown dropright">
                    <button
                      class="btn btn-xs btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >Select Icon</button>
                    <div
                      class="dropdown-menu dropdown-menu-left"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <img
                        :src="getImg(icon.file_path)"
                        class="img wd-60 ht-60 rounded-circle img-thumbnail"
                        :alt="icon.originalname"
                        v-for="(icon, index) in icons"
                        :key="index"
                        @click="iconSelected(icon.file_path)"
                      />
                    </div>
                  </div>
                </nav>
              </div>
              <div class="form-group col-md-5">
                <img v-bind:src="icon" class="img wd-60 ht-60 rounded-circle" alt />
              </div>
            </div>
          </template>
        </CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.Topup = Models.Topup;

    // if specified action is complete clear form data and stop loader
    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.Topup.wizard.action) {
        await this.saveTopup(payload.formData);
      }
    });
  },
  data() {
    return {
      Topup: {},
      topups: [],
      icons: [],
      icon: "",
    };
  },
  methods: {
    getImg(path) {
      return req.getParsedUrl(path);
    },
    iconSelected(icon) {
      this.icon = req.getParsedUrl(icon);
    },
    getIcons() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "icons",
            action: "list",
            data: {},
            meta_data: {},
          });
          this.icons = res.data.result;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    saveTopup(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "topup_config",
            action: "add",
            data: {
              icon: this.icon,
              name: data.name,
              description: data.description,
              category: data.category,
              limit_code: data.limit,
              country_code: "KEN",
              channel_code: data.channel,
              charge: data.charges,
              currency_code: data.currency,
            },
            meta_data: {},
          });

          await this.fetchTopups();
          notification.notify(
            "success",
            "Topup Config Added",
            `The topup ${data.name} was successfully added`
          );

          this.$router.push({
            name: "Configs-Transactions-Topups",
            params: {},
          });
          Event.$emit("form:action-complete", {
            action: this.Topup.wizard.action,
            clear: true,
          });
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.Topup.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.getIcons();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>