
<template lang="html">
   <div class="addAssesment">

						<div class="form-row">
                            <div class="form-group col-md-6">
                            <label for="code">Code</label>
                            <input type="text" class="form-control" id="code" name="code" placeholder="Code" v-model="code"   >
                               <!-- <span class="invalid-feedback">{{ errors.first('code') }}</span> -->
                            </div>
                            <div class="form-group col-md-6">
                            <label for="Question">Question</label>
                            <input type="text" class="form-control" id="Question" name="Question" placeholder="Question" v-model="question"   >
                            </div>
                        </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                                     <label>Description</label>
								   <textarea class="form-control" rows="4" name="description" placeholder="Description" v-model="description"></textarea>
							</div>
                         	</div>
                                <div class="form-row">
                                   
                                    <div class="form-group col-md-6">
                                              <label>YES Answer Description</label>
                                        <select class="custom-select"    v-model="yes_ans_reccomendation" name="yes_ans_reccomendation">
                                            <option v-for="recco in recomendations" :value=recco.reccomendation_id >{{recco.reccomendation_name}}</option>
                                            </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                          <label>NO Answer Description</label>
                                        <select class="custom-select"   v-model="no_ans_reccomendation" name="no_ans_reccomendation">
                                      <option v-for="recco in recomendations" :value=recco.reccomendation_id >{{recco.reccomendation_name}}</option>
                                        </select>
                                    </div>
                                	</div>
                             <button class="btn btn-primary"  @click="addAssessment()">Save</button>
		
 </div>
</template>

<script>
export default {
	async created() {
    },
    data() {
		return {
			recomendations: {},
            assessmentQuestions: [],
            code:'',
            question:'',
            description:'',
            yes_ans_reccomendation:'',
            no_ans_reccomendation:'',
			showModal: false
		};
    },
    methods: {
        	async addAssessment() {
				try {
                // let result = await this.$validator.validateAll()
                //     if(result) {
                   
                //  }

                await req.callServerSecure({
                            resource: "assesment",
                            action: "add",
                            data: {
                                code: this.code,
                                question: this.question,
                                description: this.description,
                                yes_ans_reccomendation:this.yes_ans_reccomendation,
                                no_ans_reccomendation:this.no_ans_reccomendation,
                                created_by:"SYSTEM" 
                            }
                        });
                        
                        
                		notification.notify("success","Assesment Added",`Claim Type ${this.code} successfully added`);
  
                        this.$router.push({
                            name: "Configs-Insurance-Assessment",
                            params: {}
                        });
				
                    // navigate to assesments
				} catch (err) {
                    alert(err)
					console.log(err);
			
				}
        },
        	fetchReccomendations() {
		    return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "reccomendation",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });
                  this.recomendations = res.data.result.recomendations;
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
		},
        },
	async mounted() {
		await this.fetchReccomendations()
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.addAssesment {
}
</style>