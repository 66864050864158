<template lang="html">
    <div class="roles">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddRole()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Role
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="Role.table.table_fields" 
              :tableData="roles" 
              :title="Role.table.title" 
              :subtitle="Role.table.subtitle" 
              :searchFields="Role.table.searchFields"
              :rowData="Role.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.Role = Models.Role;

    Event.$on("Role:view", async (payload) => {
      this.$router.push({
        name: "view_role",
        params: {
            id: payload.Role.role_code
        },
      });
    });

    Event.$on("Role:approve", async (payload) => {
      console.log(payload);
    });

    Event.$on("Role:edit", async (payload) => {
      console.log(payload);
    });

    Event.$on("RoleUsers:view", async (payload) => {
      console.log("Payload: ", payload);
    });
  },
  data() {
    return {
      Role: {},
      roles: [],
    };
  },
  methods: {
    openAddRole() {
      this.$router.push({
        name: "add_role",
        params: {},
      });
    },
    async listroles() {
      try {
        let res = await req.callServerSecure({
        resource: "role",
        action: "list",
        data: {},
        meta_data: {},
        });
        // console.log("res roles", res)

        this.roles = res.data.result.roles;
      } catch (err) {
        console.log(err);
      }
    },
    async authenticate() {
      try {
        let res = await req.callServerSecure({
        resource: "role",
        action: "resource_permissions",
        data: {
          resource_code: "profile"
        },
        meta_data: {},
        });

        console.log("eee", res)
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    await this.listroles();
   // this.authenticate();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
