<template >
     <div class="modal fade classic-modal" 
      :id="`${this.action}Modal`" 
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1" 
      role="dialog" 
      aria-labelledby="exampleModalLabel" 
      aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <div class="row-contents">
                     <div class="ci ci-info">
                           <i class="icon ico" :class="icon"></i>
                     </div>
                     <div class="text" id="formModalLabel">
                        <div class="fx-h5">{{title}}</div>
                        <div class="text-muted">{{subtitle}}</div>
                     </div>
                     <button type="button" class="close" @click="closeModal()" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                     </button>

                     
                  </div>
               </div>
               <div class="modal-body">
                  <slot ></slot>
               
               </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	async created() {
      console.log(" === Showing modal Created ===",this.action)
		Event.$on('page-changed:'+this.action,(payload) => {
         this.title = payload.title
         this.subtitle = payload.subtitle
         this.icon = payload.icon
      })
   },
   props: {
		showModal: {
			default: false
      },
      action: {
         default: 'form-action'
      }
   },
   data () {
      return {
         title: '',
         subtitle: '',
         icon: 'ico-mail-a'
      }
   },
   watch: {
      showModal: async function(to,from){
        console.log(" Watch show modal === to === ",to)
        console.log(" Watch this.action === === ",this.action)
         let action = 'hide'

         if(to){
            action = 'show'
         }
         $(`#${this.action}Modal`).modal(action);
      }
   },
   methods: {
      closeModal() {
         Event.$emit('modal:close',{action: this.action})
      },
   },
};
</script>

<style lang="less">
#modalForm {
	// .mobile-selector {
	// 	.flag-image {
	// 		width: 24px;
	// 		margin-right: 4px;
	// 	}
	// }
}
</style>