<template lang="html">
   <div class="intermediary">
     <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Intermediary View </li>
                    </ol>
                </nav>
         
            </div>
       
        </div>
   
   <div class="row">
      <div class="col-sm">
       <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Names  :  {{intermediary.intermediary_details.intermediary_names}} </h6>
                <nav class="nav nav-icon-only">
                  <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="media align-items-center mg-b-20">
                  <div class="avatar avatar-online"><img src="https://via.placeholder.com/500" class="rounded-circle" alt=""></div>
                  <div class="media-body pd-l-15">
                    <h6 class="mg-b-3">Ira Code : {{intermediary.intermediary_details.ira_code}}</h6>
                    <span class="d-block tx-13 tx-color-03">Category : {{intermediary.intermediary_details.category}}</span>
                    <span class="d-block tx-13 tx-color-03">Status : {{intermediary.intermediary_details.status}}</span>
                    <span class="d-block tx-13 tx-color-03">KYC Status : {{intermediary.intermediary_details.kyc_status}}</span>
                    <span class="d-block tx-13 tx-color-03">Description : {{intermediary.intermediary_details.description}}</span>
                      <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">Profile Status :{{intermediary.intermediary_details.profile_status}}</span>
                    
                  </div>
                  <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">{{intermediary.intermediary_details.created_date}}</span>
                </div><!-- media -->
                  <div class="media align-items-center mg-b-20">
                     <div class="media-body pd-l-15">
                      <span class="d-block tx-13 tx-color-03">address : {{intermediary.intermediary_details.address}}</span>
                      <span class="d-block tx-13 tx-color-03">contacts : {{intermediary.intermediary_details.contacts}}</span>
                      <span class="d-block tx-13 tx-color-03">Emails : {{intermediary.intermediary_details.emails}}</span>
                      <span class="d-block tx-13 tx-color-03">Id Number : {{intermediary.intermediary_details.identification_number}}</span>
                       <span class="d-block tx-13 tx-color-03">Kra Pin : {{intermediary.intermediary_details.kra_pin}}</span>
                           <span class="d-block tx-13 tx-color-03">Proffession : {{intermediary.intermediary_details.profession}}</span>
                       
                     </div>
                  </div>
    
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                  <a href="" class="nav-link"><i data-feather="edit"></i> Edit</a>
               
                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->
      </div>
    </div>

    <template v-if="hasdirectors">
      <h1>Directors</h1>
      <p>Director 1</p>
      <p>Director 2</p>
    </template>

   </div>
</template>

<script>
export default {
	async created() {   

    },
    	data() {
			return {
			   intermediary:{},
         hasdirectors:false
            }
	},
    methods: {
           fetchIntermediary () {
                return new Promise(async (resolve, reject) => {
                    try {
                     let res = await req.callServerSecure({
                            resource: "intermediary",
                            action: "get",
                            data: {
                                intermediary_id: this.$route.params.id
                            },
                            meta_data: {}
                        });
                       this.intermediary = res.data.result.intermediary_details;
                       if( this.intermediary.length > 0) {
                         this.hasdirectors=true
                       }
                       console.log(this.intermediary.intermediary_details)

                       resolve()
                    }
                    catch(err) {
                        reject(err)
                    }
                })
           }  
     },
    async mounted() {
        await this.fetchIntermediary()
		Event.$emit("loader:show", false);
	}

     };
</script>

<style lang="less">
.intermediary {
}
</style>