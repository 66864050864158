<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account General Ledger</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Add General Ledger</h4>
            </div>
        </div>
    
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="code">Code</label>
      <input type="text" class="form-control" id="code" placeholder="Code" v-model="gl.gl_header_code">
    </div>
    <div class="form-group col-md-6">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" v-model="gl.gl_header_name" placeholder="Name">
    </div>
  </div>
    <div class="form-group">
       <label for="description">Description</label>
       <input type="text" class="form-control" id="description" v-model="gl.gl_header_description" placeholder="Description">
     </div>
    <div class="form-group row">
       <label class="col-form-label col-sm-2 pt-0">Chart of Account</label>
       <div class="col-sm-10">
            <div class="custom-control custom-radio">
                <input type="radio" name="asset" id="asset" value="ASSET" v-model="gl.coa_code" class="custom-control-input" >
                <label for="asset" class="custom-control-label">Asset</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" name="liability"  id="liability" value="LIABILITY" v-model="gl.coa_code" class="custom-control-input">
                <label for="liability" class="custom-control-label">Liability</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" name="income" id="income" value="INCOME" v-model="gl.coa_code" class="custom-control-input" >
                <label for="income" class="custom-control-label">Income</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" name="expense" id="expense" value="EXPENSE" v-model="gl.coa_code" class="custom-control-input" >
                <label for="expense" class="custom-control-label">Expense</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" name="equity" id="equity" value="EQUITY" v-model="gl.coa_code" class="custom-control-input" >
                <label for="equity" class="custom-control-label">Equity</label>
            </div>
    </div>
  </div>

        <div class="form-group row mg-b-0">
            <div class="col-sm-10">
            <button  @click="saveGl()" class="btn btn-primary">Add General Ledger</button>
            </div>
        </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             gl:{
                gl_header_code: "",
                coa_code:"",
                gl_header_name:"",
                gl_header_description:""
             }

         }

      },
    methods: {
       async saveGl () {
          try {
                 Event.$emit('loader:show', true)
                await req.callServerSecure({
                     resource: "gl",
                     action: "add",
                     data: this.gl,
                     meta_data: {},
                  });
               // notification.notify('success', 'GL Added', `The gl ${data.name} was successfully added`)
                this.$router.push({ name: 'accounting.gl.listGl' })
  
          }
          catch (err) {
                Event.$emit('loader:show', false)
                  notification.notify('error', err)
          }
        }
    }, 
    async mounted(){
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>