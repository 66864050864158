module.exports = {
    name: "Uploaded Car Makes",
    table: {
        title: "Uploaded Car Makes",
        subtitle: "Car Make List",
        table_fields: [
            { name: "Id", align: "r" },
            { name: "Name", align: "c" },
        ],
        searchFields: ["code", "name"],
        rowData: [
            { type: "text", field: "'id_car_make'", class: "small center" },
            { type: "text", field: "'name'", class: "small center" },

        ],
    }
};