<template lang="html">
  <div class="directors">
    <CustomTable
      :tableFields="UnderwritterDirectors.table.table_fields"
      :icon="UnderwritterDirectors.table.icon"
      :title="UnderwritterDirectors.table.title"
      :subtitle="UnderwritterDirectors.table.subtitle"
      :tableData="directors"
      :searchFields="UnderwritterDirectors.table.searchFields"
      :rowData="UnderwritterDirectors.table.rowData"
    >
    </CustomTable>
  </div>
</template>

<script>
export default {
  async created() {
    this.UnderwritterDirectors = Models.UnderwritterDirectors;
    // this.directors = await utils.getDummyData(Models.UnderwritterDirectors,3)

    // if specified action is complete clear form data and stop loader
    Event.$on('form:submit', async (payload) => {
      if (payload.action === this.UnderwritterDirectors.wizard.action) {
        notification.notify(
          'success',
          'Director Added',
          `Company director successfully added`
        );
        Event.$emit('form:action-complete', {
          action: this.UnderwritterDirectors.wizard.action,
          clear: true,
        });
        this.showModal = false;
      }
    });

    Event.$on('modal:close', async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      UnderwritterDirectors: {},
      directors: [],
      showModal: false,
    };
  },
  methods: {
    createDirector() {
      this.showModal = true;
    },
  },
  mounted() {
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.directors {
}
</style>
