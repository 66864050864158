<template lang="html">
   <div class="statements">
      <ModalForm :showModal="showModal" :action="Statement.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="Statement.wizard.pages" 
               :action="Statement.wizard.action" 
               :buttonText="Statement.wizard.actionBtn" 
               :showTitle="false">
            </CustomForm>
         </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="createStatement()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Statement
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Statement.table.table_fields"
         :icon="Statement.table.icon"
         :title="Statement.table.title"
         :subtitle="Statement.table.subtitle"
         :tableData="statements" 
         :searchFields="Statement.table.searchFields"
         :rowData="Statement.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
   export default {
      async created(){
         this.Statement = Models.Statement

         // if specified action is complete clear form data and stop loader
         Event.$on('form:submit', async (payload) => {
            if(payload.action === this.Statement.wizard.action){
               await this.saveStatement(payload.formData)
               this.showModal = false
            }
         })

         Event.$on('modal:close',async (payload) => {
            this.showModal = false
         })

         // Event.$on('client:view',(payload) => {})
      },
      data () {
         return {
            Statement: {},
            statements: [],
            showModal: false
         }
      },
      methods: {
         fetchStatements(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_statement",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.statements = res.data.result.account_statements
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
         createStatement() {
            this.showModal = true
         },
         saveStatement(data){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "account_statement",
                     action: "add",
                     data: {
                        code: data.code,
                        name: data.name,
                        description: data.description, 
                        frequency: data.frequency, 
                        delivery_channel: data.delivery_channels, 
                        charges_applicable: true, 
                        charge_amount: data.charges, 
                        statement_type: data.type,
                        num_of_transaction: data.no_of_transactions, 
                        day_of_week: data.day_of_week, 
                        day_of_month: data.day_of_month, 
                     },
                     meta_data: {},
                  });
                  
                  await this.fetchStatements()
                  notification.notify('success', 'Statement Added', `The statement ${data.name} was successfully added`)
                  Event.$emit('form:action-complete',{action: this.Statement.wizard.action, clear: true})
                  this.showModal = false
               } catch (err) {
                  Event.$emit('form:action-error',{action: this.Statement.wizard.action})
               }
            })
         }
      },
      async mounted(){
         await this.fetchStatements()
         Event.$emit('loader:show', false)
      }
   }
</script>

<style lang="less">
   .statements{
      
   }
</style>