let session = window.sessionStorage

export default class SessionStorage {
   constructor() {
      filestore.createDirectory('states')
   }

   notifyTokenChanged(key, data) {


      return true;
   }

   set(key, data) {
      session.setItem(key, JSON.stringify(data))

      // Notify session changed
     // Event.$emit('session:set', { key })
      return true;
   }

   get(key) {
     // Event.$emit('session:get', { key })
      return JSON.parse(session.getItem(key))
   }

   clear() {
      session.clear()
    //  Event.$emit('session:cleared')
      return true;
   }

   remove(key) {
      session.removeItem(key)
     // Event.$emit('session:removed', { key })
      return true;
   }

   fileSet(key, data) {
      return new Promise(async (resolve, reject) => {
         try {
            let results = await filestore.readFile(`states/sessions.json`).catch((e) => {
               return null
            })

            let obj = {}
            obj[key] = data
            if (results === null) {
               let f = JSON.stringify(obj)
               await filestore.writeFile(`states/sessions.json`, f)
            } else {
               results = JSON.parse(results)
               results[key] = data
               let f = JSON.stringify(results)
               await filestore.writeFile(`states/sessions.json`, f)
            }

           // Event.$emit('session:store', obj)
            resolve(true)
         } catch (err) {
            reject(err)
         }
      })
   }

   fileGet(key) {
      return new Promise(async (resolve, reject) => {
         try {
            let results = await filestore.readFile(`states/sessions.json`).catch((e) => {
               return null
            })

            if (results === null) {
               resolve(null)
            } else {
               results = JSON.parse(results)
               resolve(results[key])
            }
         } catch (err) {
            reject(err)
         }
      })
   }

   fileClear() {
      return new Promise(async (resolve, reject) => {
         try {
            await filestore.removeFile(`states/sessions.json`)
          //  Event.$emit('session:cleared')
            resolve(true)
         } catch (err) { }
      })
   }

   fileRemove(key) {
      return new Promise(async (resolve, reject) => {
         try {
            let results = await filestore.readFile(`states/sessions.json`).catch((e) => {
               return null
            })

            if (results !== null) {
               results = JSON.parse(results)
               delete results[key]
               let f = JSON.stringify(results)
               await filestore.removeFile(`states/sessions.json`)
               await filestore.writeFile(`states/sessions.json`, f)
            }

          //  Event.$emit('session:remove', key)
            resolve(true)
         } catch (err) {
            reject(err)
         }
      })
   }
}