module.exports = {
   name: 'Notification Queue',
   wizard: {},
   table: {
      title: 'Notification Queue',
      subtitle: 'Notification queues in the system',
      table_fields: [
         { name: 'Channel', align: 'l' },
         { name: 'Type', align: 'l' },
         { name: 'To', align: 'l' },
         { name: 'Template Name', align: 'l' },
         { name: 'HTML Template', align: 'c' },
         { name: 'Created Date', align: 'c' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["account_number", "account_name", "available_balance", "currency_code"],
      rowData: [
         { type: 'text', field: 'channel', class: 'left bolder small' },
         { type: 'text', field: 'type', class: 'left bolder small' },
         { type: 'text', field: 'to', class: 'left bolder' },
         { type: 'text', field: 'template_name', class: 'left bolder small' },
         { type: 'number', field: 'html_template', class: 'center bolder small' },
         { type: 'number', field: 'created_date', class: 'center bolder small' },
         // { type: 'text', field: 'account_opening_date', class: 'left small' },
         {
            type: 'action', field: 'account_number', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'account', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'transaction:view', icon: 'ico-reply', params: [
                     { key: 'tran', field: '*' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'account', field: '*' }
                  ]
               },
            ]
         },
      ]
   },
}