<template lang="html">
    <div class="addGl">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Account System Accounts </li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">List Of System Accounts</h4>
            </div>
            <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="addSystemAccount()"
                >Add</button>
        </div>
                <div class="table-responsive">
                     <table class="table ml-table ml-responsive-md">
                        <thead  class="thead-primary">
                                <tr>
                             <th scope="col">No.</th>
                            <th scope="col">Account Name</th>
                            <th scope="col">Account Currency</th>
                            <th scope="col">Account Balance</th>
                            <th scope="col">Account Number</th>
                             <th scope="col">Sub gl Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in accounts">
                                <th scope="row">{{ index +1 }}</th>
                                <td>{{ item.account_name }}</td>
                                <td>{{ item.currency_code }}</td>
                                <td>{{ item.available_balance }}</td>
                                 <td>{{ item.account_number }}</td>
                                <td>{{ item.sub_gl_code }}</td>
                            </tr>
                            
                     
                        </tbody>
                        </table>
         </div>
    </div> 
  
    
</template>

<script>
export default {
    data () {
         return {
             accounts:[]
         }

      },
    methods: {
     addSystemAccount() {
        this.$router.push({ name: 'accounting.system.addSystemAccounts' })
     },
        fetchSystemAccounts(){
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "system_account",
                     action: "list",
                     data: {},
                     meta_data: {},
                  });

                  this.accounts = res.data.result.accounts
                  console.log(" ==System Accounts  == ", this.accounts)
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         },
    }, 
    async mounted(){
         await this.fetchSystemAccounts()
         Event.$emit('loader:show', false)
      }
    };
</script>

<style lang="less">
</style>