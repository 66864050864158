module.exports = {
    name: 'Withdrawal',
    gen_template: `
       {
          "coa": "ASSET",
          "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
          "name": "{{lorem.word}}"
       }
    `,
    wizard: {
        actionBtn: 'Save',
        action: 'add_widthdrawal',
        pages: [
            {
                title: 'Withdrawal',
                subtitle: 'New withdrawal',
                forms: [
                    {
                        type: 'select', label: 'Channel Code', model: 'channel_code',
                        validation: 'required', class: 'col-12 col-md-6', options: []
                    },

                    {
                        type: 'select', label: 'Currency Code', model: 'currency_code',
                        validation: 'required', class: 'col-12 col-md-6', options: []
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Withdrawal Limit', model: 'withdrawal_limit',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'number', label: 'Withdrawal Fee', model: 'withdrawal_fee',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Withdrawal Code', model: 'withdrawal_code',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },
                    {
                        type: 'text', form_type: 'text', label: 'Withdrawal Name', model: 'withdrawal_name',
                        validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                    },

                    {
                        type: 'textarea', form_type: 'text', label: 'Description', model: 'withdraw_description',
                        validation: 'required|min:10|max:1000', class: 'col-12'
                    },
                ],
            }
        ]
    },
    table: {
        title: 'Withdrawal',
        subtitle: 'A list of withdrawals',
        table_fields: [
            { name: 'Code', align: 'l' },
            { name: 'Name', align: 'l' },
            { name: 'Description', align: 'l' },
            { name: 'actions', align: 'c' }
        ],
        searchFields: ["withdraw_description", "withdraw_name", "withdraw_code"],
        rowData: [
            { type: 'text', field: 'withdraw_code', class: 'left bolder small' },
            { type: 'text', field: 'withdraw_name', class: 'left bolder' },
            { type: 'text', field: 'withdraw_description', class: 'left bolder' },
            {
                type: 'action', field: 'name', class: 'center', icon: '', actions: [
                    // {type: 'title', title: 'Actions'},
                    /*  {
                         type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                            { key: 'client', field: '*' }
                         ]
                      },
                      */
                    {
                        type: 'menu', title: 'View', event: 'withdrawal:view', icon: 'ico-reply', params: [
                            { key: 'withdrawal', field: '*' },
                        ]
                    },

                ]
            },
        ]
    },
}