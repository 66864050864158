<template lang="html">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
                <router-link :to="{name: 'Dashboard'}">
                    Admin
                </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
    </nav>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less">
    .app{
        
    }
</style>