<template>
	<div id="app">
	    <ModalIdle v-if="isIdle" />
		<FileUploader />
		<!-- <MapLocation /> -->
		<NotificationAlert />
		
		<transition>
			<router-view />
		</transition>
	</div>
</template>

<script>
import ModalIdle from "@/components/ModalIdle";
export default {
	components: {
		ModalIdle
	},
	name: "app",
	computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
	
    },
  },
};
</script>

<style lang="less">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;

	.app-loader{
		position: fixed;
		background: rgba(255, 255, 255,.98);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1100;

		.center-text{
			padding-top: calc(50vh - 50px);
			text-align: center;

			.df-logo{
				display: flex;
				justify-content: center;
			}
		}
	}
}
</style>
