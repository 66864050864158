<template lang="html">
    <div class="app">
        <footer class="footer">
            <div>
                <span>&copy; 2019 Insurance Admin v2.0.5. </span>
                <span>Powered by <a href="https://nexus.ke/aboutus">Nexus</a></span>
            </div>
            <div>
                <nav class="nav">
                <a href="https://nexus.ke/license" class="nav-link">Licenses</a>
                <a href="../../change-log.html" class="nav-link">Change Log</a>
                <a href="https://nexus.ke/help" class="nav-link">Get Help</a>
                </nav>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less">
    .app{
        
    }
</style>