<template lang="html">
   <div class="orange">


   <div class="row">
      <div class="col-sm">
       <div class="card mg-b-20 mg-lg-b-25">
              <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 class="tx-uppercase tx-semibold mg-b-0">Reccomendation Details for   {{recomendation.reccomendation_code}} </h6>
                <nav class="nav nav-icon-only">
                  <!--<a href="" class="nav-link"><i data-feather="more-horizontal"></i></a>-->
                </nav>
              </div><!-- card-header -->
              <div class="card-body pd-20 pd-lg-25">
                <div class="media align-items-center mg-b-20">
                  <div class="media-body pd-l-15">
                    <h6 class="mg-b-3">{{recomendation.reccomendation_name}}</h6>
                    <span class="d-block tx-13 tx-color-03">{{recomendation.reccomendation_description}}</span>
                    <span class="d-block tx-13 tx-color-03">{{recomendation.status}}</span>
                  </div>
                  <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">{{recomendation.created_date}}</span>
                </div><!-- media -->
    
              </div>
              <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
                <nav class="nav nav-with-icon tx-13">
                  <a href="" class="nav-link"><i data-feather="edit"></i> Edit</a>
                  <a href="" class="nav-link"><i data-feather="trash-2"></i> Delete</a>
                </nav>
              </div><!-- card-footer -->
            </div><!-- card -->
      </div>
    </div>


    <div class="row">
       <div class="col-sm">
                   <button type="button" class="btn btn-outline-dark pull-right" @click="addSubclass()">Attach Subclass</button>
                    <h4 id="section1" class="mg-b-10">Sub classes</h4>
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Id</th>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in subclasses">
                                <th scope="row">{{ index }}</th>
                                <td>{{item.sub_class_id}}</td>
                                <td>{{item.code}}</td>
                                <td>{{item.name}}</td>
                                <td><button type="button" class="btn btn-outline-danger" @click="removesubclass(item)">Remove</button></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
         
    </div>

   


 
      <ModalForm :showModal="showModal" >
             <template slot="form" >
                      <div class="custom-control custom-checkbox form-group">
					                 <li  v-for="sub_class in insurance_sub_classes">
                             <div class="custom-control custom-checkbox">
                             <input type="checkbox" class="custom-control-input" :id="sub_class.code" :value="sub_class.code"  v-model="selectedSubclass">
                            <label class="custom-control-label label-checkbox" :for="sub_class.code">{{sub_class.name}}</label>
                             </div>
				          	    	</li>
                     </div>
					
					   <div class="d-none d-md-block">
							<button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="SaveSubclass()">
							<i data-feather="mail" class="wd-10 mg-r-5"></i> Save Subclass
							</button>
				   	</div>		
            </template>
      </ModalForm>


     
   </div>




   

   
</template>

<script>
export default {
	async created() {
        Event.$on("modal:close", async payload => {
          this.showModal = false;
        });
	},
	data() {
		return {
            recomendation:{},
		      	subclasses:[],
            showModal:false,
            selectedSubclass:[],
            insurance_sub_classes:[]
		};
	},
	methods: {


    async fetchReccomendationsummary () {
      	    return new Promise(async (resolve,reject) => {
             try {
	           let res = await req.callServerSecure({
						resource: "reccomendation",
						action: "summary",
						data: {
                           reccomendation_code:  this.$route.params.id,

                      }
               });
                     //console.log(res.data.result.recomendation_summary)
             this.recomendation = res.data.result.recomendation_summary;
             this.subclasses =this.recomendation.subclasses
          
               resolve()
        }
        catch(err) {
           // console.log(err)
               notification.notify('error', 'Error Fetching Reccomendation Details  ', `Kindly Retry Later`)
               reject(err)
            } 
        })   
    },

    addSubclass () {
         this.showModal=true;
    },

    async removesubclass (idd) {
       		let res = await req.callServerSecure({
						resource: "reccomendation",
						action: "remove_subclass",
						data: {
                      reccomendation_code:  this.$route.params.id,
                      sub_class_code:idd.code
                      }
            });

              await this.fetchReccomendationsummary();
           
       
    },
	async	SaveSubclass() {
      try {

					let res = await req.callServerSecure({
						resource: "reccomendation",
						action: "add_subclass",
						data: {
                            reccomendation_code:  this.$route.params.id,
                            sub_class:this.selectedSubclass
                      }
               });

                await this.fetchReccomendationsummary();
         		  	this.showKycModal = false;
           }
             
      catch (err) {
   
           	Event.$emit("loader:show", false);
       			this.showKycModal = false;
            notification.notify('error', 'Error Attaching Subclass to Reccomendation ', `Kindly Retry Later`)
      }  


		},
    	

      fetchSubClasses () {
        
         return new Promise(async (resolve, reject) => {
				try {
                Event.$emit("loader:show", true);
                    let res = await req.callServerSecure({
                    resource: "insurance_sub_class",
                    action: "list",
                    data: {
                                
                              },
                    meta_data: {}
                      });
              
            this.insurance_sub_classes =res.data.result.insurance_sub_classes
			   	Event.$emit("loader:show", false);
				   resolve(true)
				}catch (err) {
                      notification.notify('error', 'Error Fetching Subclasses', `Kindly Retry Later`)
					     reject(err)
				}
            
			});
      }

	},
	async mounted() {
       await this.fetchReccomendationsummary()
       await this.fetchSubClasses()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>