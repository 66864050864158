<template>
  <div class="view-topup">
    <div
      class="
        d-sm-flex
        align-items-center
        justify-content-between
        mg-b-20 mg-lg-b-25 mg-xl-b-30
      "
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>

            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Entities-UsersList' }">
                Users List
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              View User
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div>
      <div class="media">
        <img
          class="mr-3"
          :src="getImg(user.profile_photo_path)"
          :alt="user.school_name"
        />
        <div class="media-body">
          <h5 class="text-capitalize">{{ user.username || "" }} - {{ user.user_type || "" }}</h5>

          <div class="row">
            <div class="col-12 py-2">
              Entity: <span>{{ user.registration_id || "" }}</span>
            </div>
            <div class="col-12 py-2">
              Registration Status:
              <span class="badge badge-success mb-2">{{
                user.registration_status || ""
              }}</span>
            </div>

            
            <div class="col-12 py-2">
              Create Date:
              <span>{{ user.created_date || "2021/09/21 09:30:12" }}</span>
            </div>
            <div class="col-12 py-2">
              Password Status:
              <span>{{ user.password_status || "locked" }}</span>
            </div>
            <div class="col-12 py-2">
              Name: <span>{{ user.names || "" }}</span>
            </div>
            <div class="col-12 py-2">
              Email: <span>{{ user.email || "" }}</span>
            </div>
            
            <div class="col-12 py-2">
              Current Login Device: <span>{{ user.current_login_device || "" }}</span>
            </div>
            <div class="col-12 py-2">
              Contact: <span>{{ user.created_date || "+23423545545" }}</span>
            </div>
            <div class="col-12 py-2">
              Login Status:
              <span class="badge badge-success mb-2">{{
                user.login_status || ""
              }}</span>
            </div>
            <div class="col-12 py-2">
              <button class="mr-2 btn btn-primary">Reset Attempts</button>
              <button class="mr-2 btn btn-primary">Enable</button>
              <button class="mr-2 btn btn-danger">Disable</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  methods: {
    fetchUser() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "user",
            action: "get",
            data: {
              user_id: this.$route.params.id,
            },
          });
          console.log("user", res);
          this.user = res.data.result.user_details;
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },

    getImg(path) {
      return `${domain}/${path}`;
    },
  },
  async mounted() {
    await this.fetchUser();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>