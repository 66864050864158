module.exports = {
   name: 'Sample',
   gen_template: `
      {
         "image": "{{image.avatar}}",
         "name": "{{lorem.word}}",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "status": "active"
      }
   `,
   table: {
      title: 'Products',
      subtitle: 'Product List',
      table_fields: [
         // { name: 'image', align: 'l' },
         { name: 'Name', align: 'c' },
         { name: 'Code', align: 'r' },
         { name: 'Class', align: 'r' },
         { name: 'Status', align: 'c' },
         { name: 'Actions', align: 'c' }
      ],
      searchFields: ["name", "code", "status"],
      rowData: [
         // { type: 'image', field: 'image', class: 'left' },
         { type: 'text', field: 'product_name', class: 'center' },
         { type: 'text', field: 'product_code', class: 'small center' },
         { type: 'text', field: 'class_code', class: 'small center' },
         { type: 'status', field: 'status', class: 'center' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'product:view', icon: 'ico-reply', params: [
                     { key: 'id', field: 'product_id' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}