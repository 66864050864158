module.exports = {
   name: 'super_users',
   gen_template: `
      {
         "names": "{{name.firstName}} {{name.lastName}}",
         "email": "{{internet.email}}", 
         "contact": "{{phone.phoneNumber}}",
         "image": "{{image.avatar}}"
      }
   `,
   wizard: {
      actionBtn: 'Save',
      action: 'insurance',
      pages: [
         {
            title: 'Super Users',
            subtitle: 'Add a super user',
            forms: [
               {
                  type: 'text', form_type: 'text', label: 'First Name', model: 'first_name',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Last Name', model: 'last_name',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'text', label: 'Employee Ref', model: 'employee_ref',
                  validation: 'required|min:2|max:50', class: 'col-12 col-md-6'
               },
               {
                  type: 'text', form_type: 'email', label: 'Email', model: 'email',
                  validation: 'required|min:2|max:150|email', class: 'col-12 col-md-6'
               }
            ],
         }
      ]
   },
   table: {
      title: 'Superusers',
      subtitle: 'A list of configured super users',
      table_fields: [
         { name: 'Profile', align: 'l' },
         { name: 'Names', align: 'l' },
         { name: 'Email', align: 'l' },
         { name: 'Contact', align: 'l' },
         { name: 'actions', align: 'c' }
      ],
      searchFields: ["names", "contact", "email"],
      rowData: [
         { type: 'image', field: 'image', class: 'left' },
         { type: 'text', field: 'names', class: 'left' },
         { type: 'text', field: 'email', class: 'left' },
         { type: 'text', field: 'contact', class: 'left' },
         {
            type: 'action', field: 'name', class: 'center', icon: '', actions: [
               // {type: 'title', title: 'Actions'},
               {
                  type: 'menu', title: 'Edit', event: 'client:edit', icon: 'ico-right', params: [
                     { key: 'client', field: '*' }
                  ]
               },
               {
                  type: 'menu', title: 'View', event: 'client:view', icon: 'ico-reply', params: [
                     { key: 'email', field: 'email' },
                     { key: 'name', field: 'name' }
                  ]
               },
               // {type: 'divider'},
               {
                  type: 'menu', title: 'Delete', event: 'client:delete', icon: 'ico-mail-a', params: [
                     { key: 'email', field: 'email' }
                  ]
               },
            ]
         },
      ]
   },
}