<template>
  <div class="map-location">
    <div
      class="modal fade"
      id="getMapLocation"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      role="dialog"
      aria-labelledby="getMapLocation"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content redial-border-light">
          <div class="modal-header redial-border-light">
            <div class="row-contents">
              <div class="ci ci-info">
                <i class="fab fa-slack icon"></i>
              </div>
              <div class="text" id="formModalLabel">
                <div class="fx-h5">Map Location</div>
                <div class="text-muted">Select location</div>
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row justify-content-md-center">
              <div class="col-md-9" style="padding: 0 20px">
                <v-select
                  class="selector"
                  label="description"
                  :options="predictions"
                  :filterable="false"
                  v-model="address"
                  @search="getSuggestions"
                  @change="findLocation"
                  placeholder="Enter Location to search"
                >
                </v-select>
              </div>
              <div class="col-12">
                <div
                  id="location-found"
                  style="width: 100%; height: 380px"
                  v-show="isAddressSelected"
                ></div>
                <div
                  style="width: 100%; height: 380px"
                  v-show="!isAddressSelected"
                >
                  <div class="txt">
                    Search and Select an address to continue
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer redial-border-light">
            <button class="btn btn-primary" @click="returnAddress()">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    let _this = this;

    Event.$on('map:show', (payload) => {
      _this.form = payload.form;
      $('#getMapLocation').modal('show');
    });
  },
  data() {
    return {
      locations: [],
      geocoder: {},
      map: null,
      address: '',
      selectedLocation: { lat: -1.326368, lng: 36.848483 },
      foundLocation: {},
      predictions: [],
      infowindow: new google.maps.InfoWindow(),
      form: '',
      isAddressSelected: false,
      styles: {},
    };
  },
  watch: {
    address(newVal, oldVal) {
      if (newVal !== null) {
        this.isAddressSelected = true;
        this.findLocation(newVal.description);
      } else {
        this.isAddressSelected = false;
      }
    },
  },
  methods: {
    initialize() {
      this.styles = utils.getMapStyles();
      this.geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(-1.326368, 36.848483);
      var mapOptions = {
        zoom: 9,
        controlSize: 26,
        center: this.selectedLocation,
        styles: this.styles,
      };
      this.map = new google.maps.Map(
        document.getElementById('location-found'),
        mapOptions
      );
    },
    findLocation(address) {
      var _this = this;

      this.map = new google.maps.Map(
        document.getElementById('location-found'),
        {
          zoom: 9,
          center: this.selectedLocation,
          styles: this.styles,
        }
      );

      _this.geocoder.geocode({ address: address }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            _this.map.setZoom(11);
            var marker = new google.maps.Marker({
              draggable: true,
              position: results[0].geometry.location,
              map: _this.map,
            });

            var circle = new google.maps.Circle({
              map: this.map,
              center: marker.position,
              radius: 7000,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            });
            _this.selectedLocation = results[0].geometry.location;

            _this.foundLocation = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            // circle.bindTo('center', marker, 'position');

            google.maps.event.addListener(marker, 'dragend', function (event) {
              _this.selectedLocation = {
                lat: this.getPosition().lat(),
                lng: this.getPosition().lng(),
              };
              _this.infowindow.setContent("Drag to the School's Location");
            });
            _this.map.setCenter(results[0].geometry.location);
            _this.infowindow.setContent(results[0].formatted_address);
            _this.infowindow.open(_this.map, marker);
          } else {
            notification.notify(
              'warning',
              'Results Not Found',
              `The results for ${address} could not be found`
            );
          }
        } else {
          notification.notify(
            'error',
            'Geo-Codder Fail',
            `An Error occurred with the geo-codder, try again later.`
          );
        }
      });
    },
    getSuggestions(search, loading) {
      var current = this;
      loading(true);

      var displaySuggestions = function (predictions, status) {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          alert(status);
          return;
        }

        current.predictions = predictions;
        loading(false);
      };

      var service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({ input: search }, displaySuggestions);
    },
    returnAddress() {
      this.form.placeholder = this.address.description;

      Event.$emit('map:location', {
        model: this.form.model,
        location: this.foundLocation,
        address: this.address,
      });

      $('#getMapLocation').modal('hide');
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less">
#getMapLocation {
  z-index: 1060;
}

.map-location {
  .txt {
    font-weight: 600;
    text-align: center;
    margin: 20% 0 0 0;
    font-size: 15px;
  }
  .selector {
    margin-bottom: 20px;
  }
}
</style>
