
<template lang="html">
   <div class="listSchools">

        

         <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
               <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addSchools">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Configure School
               </button>      -->
         </div>
      </div>

        <ModalForm :showModal="showModal" :action="School.wizard.action">
         <template slot="form">
            <CustomForm 
               :pages="School.wizard.pages" 
               :action="School.wizard.action" 
               :buttonText="School.wizard.actionBtn"  
               :showTitle="false">
            </CustomForm>
         </template>
        </ModalForm>
        

       <div class="contents">
         <CustomTable :tableFields="School.table.table_fields" 
            :tableData="Schools" 
            :title="School.table.title" 
            :subtitle="School.table.subtitle" 
            :searchFields="School.table.searchFields"
            :rowData="School.table.rowData">
         </CustomTable>
      </div>
              
   </div>
</template>
<script>
export default {
  data() {
    return {
      School: {},
      Schools: [],
      showModal: false,
      icons: [],
    };
  },
  async created() {
    this.School = Models.School;
    Event.$on("Schools:view", (payload) => {
      this.$router.push({
        name: "View-School",
        params: { id: payload.school.school_system_ref },
      });
    });

    Event.$on("form:submit", async (payload) => {
      if (payload.action === this.School.wizard.action) {
        await this.saveSchools(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on("modal:close", async (payload) => {
      this.showModal = false;
    });
  },

  async mounted() {
    await this.fetchSchools();
    await this.getIcons();
    Event.$emit("loader:show", false);
  },

  methods: {
    getImg(path) {
      return req.getParsedUrl(path);
    },
    addSchools() {
      this.showModal = true;
    },

    saveSchools(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "services",
            action: "create",
            data: {
              service_code: data.service_code,
              service_name: data.service_name,
              service_type: data.service_type,
              service_category: data.service_category,
              service_description: data.service_description,
              service_image_path: data.service_image_path,
            },
            meta_data: {},
          });

          await this.fetchSchools();
          notification.notify(
            "success",
            "Insurance Service Added",
            `The service ${data.service_name} was successfully added`
          );
          Event.$emit("form:action-complete", {
            action: this.School.wizard.action,
            clear: true,
          });
          this.showModal = false;
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.School.wizard.action,
          });
        }
      });
    },
    fetchSchools() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "school",
            action: "list_schools",
            data: {},
            meta_data: {},
          });
          console.log(res);
          this.Schools = res.data.result.schools.map((item) => {
            item.school_image = domain + item.school_image;
            return item;
          });
          resolve(true);
        } catch (err) {
          notification.notify(
            "error",
            "Fetching Insurance Service",
            `Fetching Insurance Service`
          );
          reject(err);
        }
      });
    },
    getIcons() {
      return new Promise(async (resolve, reject) => {
        try {
          let pages = this.School.wizard.pages;
          let res = await req.callServerSecure({
            resource: "icons",
            action: "list",
            data: {},
            meta_data: {},
          });
          (pages[0].forms[4].options = utils.appendOptions(
            res.data.result,
            "file_path",
            "originalname"
          )),
            resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};
</script>
