<template>
  <div class="add-user">
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">User</div>
            <p class="tx-13 tx-color-03 mg-b-0">Add User</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <slot name="form"></slot> -->
        <CustomForm
          :pages="User.wizard.pages"
          :action="User.wizard.action"
          :buttonText="User.wizard.actionBtn"
          :showTitle="false"
        ></CustomForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.User = Models.User;

    // Event.$on("form:submit", async (payload) => {
    //   this.createUser(payload.formData)
    // });
  },
  data() {
    return {
      User: {},
      users: [],
    };
  },
  methods: {
    async listroles() {
      try {
        let res = await req.callServerSecure({
        resource: "role",
        action: "list",
        data: {},
        meta_data: {},
        });

        let pages = this.User.wizard.pages,options = [];
        await res.data.result.roles.forEach(element => {
            options.push({ val: element.role_code, label: element.name });
        });
            
        pages[0].forms[5].options = options; 
        this.User.wizard.pages = pages;
      } catch (err) {
        console.log(err);
      }
    },
    async listDepartments() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "department",
            action: "list",
            data: {},
            meta_data: {},
          });

          let pages = this.User.wizard.pages,options = [];
          await res.data.result.departments.forEach(element => {
              options.push({ val: element.department_code, label: element.department_name });
          });
              
          pages[0].forms[6].options = options; 
          this.User.wizard.pages = pages;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    async listSecurityContexts() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "security_context",
            action: "list",
            data: {},
            meta_data: {},
          });

          let pages = this.User.wizard.pages,options = [];
          await res.data.result.security_contexts.forEach(element => {
              options.push({ val: element.security_context_code, label: element.name });
          });
              
          pages[0].forms[7].options = options; 
          this.User.wizard.pages = pages;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    async createUser(data) {
      try {
        const res = await req.callServerSecure({
        resource: "user",
        action: "create",
        data: {
            employee_ref: data.ref,
            names: data.name,            
            department: data.department,
            role_code: data.role,
            contact: data.contact,
            email: data.email,
            username : data.username,
            security_context_code: data.security_context,
            password: data.password,
            confirm_password: data.confirm_password
        },
        meta_data: {},
        });
       // console.log("res", res)

        this.$router.push({name: 'list_users'})
        notification.notify('success', 'Success', 'User added successfully!')
      } catch (err) {
        notification.notify('warning', 'Error', 'Error creating user!')
        console.log("err", err);
      }

    }
  },
  async mounted() {
    // await this.listroles()
    // await this.listDepartments()
    // await this.listSecurityContexts()
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>