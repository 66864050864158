<template>
  <div class="view-topup">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Dashboard' }"> Admin </router-link>
            </li>

            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Entities-SchoolsList' }">
                Schools List
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              View School
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="media">
      <img
        class="mr-3"
        :src="getImg(school.school_image)"
        :alt="school.school_name"
      />
      <div class="media-body">
        <h5 class="text-capitalize">{{ school.school_name || '' }}</h5>
        <p class="text-muted">
          {{ school.school_description || '' }}
        </p>
        <div class="row">
          <div class="col-12 col-sm-3">
            REF:
            <span style="font-size: 1.3rem">{{
              school.school_external_ref || ''
            }}</span>
          </div>
          <div class="col-12 col-sm-3">
            STATUS:
            <span class="badge badge-success mb-2">{{
              school.school_status || ''
            }}</span>
          </div>
        
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table ml-table ml-responsive-md">
        <thead>
          <tr>
            <th scope="col">Account Name</th>
            <th scope="col">Account No</th>
            <th scope="col">Account Type</th>
            <th scope="col">Available Balance</th>
            <!-- <th scope="col" class="text-center">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(account, index) in accounts.entity_accounts" :key="index">
            <td>{{ account.account_name || '' }}</td>
            <td>{{ account.account_number || '' }}</td>
            <td>{{ account.account_type || '' }}</td>
            <td>
              {{ account.currency_code || '' }}
              {{ account.available_balance || 0 }}
            </td>
             <td class="text-center">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="viewAccount(account.account_number)"
                  >
                    View Account Transactions
                  </button>
                </div>
              </td> 
          </tr>
        </tbody>
      </table>
    </div>

    <h5 class="mg-t-10">Release Info</h5>
    <div class="table-responsive">
      <table class="table ml-table ml-responsive-md">
        <thead>
          <tr>
            <th scope="col">Total Release Amount</th>
            <th scope="col">Expected Release Date</th>
            <th scope="col">Expected Release Amount</th>
            <th scope="col">Release Status</th>
            <th scope="col">Action</th>
            <!-- <th scope="col" class="text-center">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(release, index) in data" :key="index">
            <td>
              {{ release.currency || '' }} {{ release.total_fee_amount || '' }}
            </td>
            <td>{{ release.release_details.expected_release_date || '' }}</td>
            <td>
              {{ release.currency || '' }}
              {{ release.release_details.expected_release_amount || '' }}
            </td>
            <td v-if="release.release_status">
              {{ release.release_status.status || '' }}
            </td>
            <td v-else>n/a</td>

            <td>
              <button v-on:click="viewRelease(release.fee_release_system_ref)">
                View Release
              </button>
            </td>

            <!-- <td class="text-center">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="viewCharge(account.charge_amount)"
                  >
                    View
                  </button>
                </div>
              </td> -->
          </tr>
        </tbody>
        <div class="paginator">
          <Pagination
            :total="total"
            :current="current"
            @on-change="changePage"
            show-total
          />
        </div>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      school: {},
      accounts: [],
      schoolReleases: [],
      total: 0,
      current: 1,
      data: [],
    };
  },
  methods: {
    changePage(p) {
      if (this.data.length > 0) {
        this.data = this.store.getPage(p);
      }
    },
    fetchSchool() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'school',
            action: 'get_school_details',
            data: {
              school_system_ref: this.$route.params.id,
            },
          });
          this.school = res.data.result.school_details;
          this.accounts = res.data.result.school_accounts;
          await this.fetchSchoolReleases();
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    fetchSchoolReleases() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'fund_release',
            action: 'school_release',
            data: {
              school_system_ref: this.$route.params.id,
            },
          });

          this.schoolReleases = res.data.result.releases;
          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },

    viewAccount (acctNum) {
         this.$router.push({
           name: 'Configs-Accounting-ViewAccount',
           params: {id: acctNum}
           
           })
    },

    viewRelease(fee_release_system_ref) {
      //  alert(" release ref === "+ fee_release_system_ref)
      this.$router.push({
        name: 'View-ReleaseQueue',
        params: { id: fee_release_system_ref },
      });
    },

    getImg(path) {
      return `${domain}/${path}`;
    },
  },
  async mounted() {
    await this.fetchSchool();
    this.data = this.schoolReleases;

    if (this.data.length > 0) {
      this.store = new PageStore(this.data);
      this.data = this.store.paginateRecords(10);
      this.total = this.store.getTotalRecords();
    }
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.paginator {
  margin-top: 20px;
}
</style>
