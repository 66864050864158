module.exports = {
  name: "User Group",
  table: {
    title: "User Group",
    subtitle: "User Group List",
    table_fields: [
      { name: "Code", align: "r" },
      { name: "Name", align: "c" },
      { name: "Description", align: "r" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["name", "code", "description"],
    rowData: [
      { type: "text", field: "group_code", class: "small center" },
      { type: "text", field: "group_name", class: "small center" },
      { type: "text", field: "description", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            event: "user-group:view",
            icon: "ico-right",
            params: [{ key: "user-group", field: "*" }],
          },
        ],
      },
    ],
  },
  wizard: {
    actionBtn: "Save",
    action: "add_User-Group",
    pages: [
      {
        title: "User Group",
        subtitle: "Add new user group",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-12",
          },
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:2|max:150",
            class: "col-12 col-md-12",
          },
          {
            type: "textarea",
            form_type: "textarea",
            label: "Description",
            model: "description",
            validation: "required|min:20|max:500",
            class: "col-12 col-md-12",
          },
        ],
      },
    ],
  },
};
