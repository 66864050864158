<template lang="html">
   <div class="orange">
      <ModalForm :showModal="showModal" :action="ProductRequirement.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="ProductRequirement.wizard.pages" 
                  :action="ProductRequirement.wizard.action" 
                  :buttonText="ProductRequirement.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddProductRequirementModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Requirement
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="ProductRequirement.table.table_fields" 
            :tableData="requirements" 
            :title="ProductRequirement.table.title" 
            :subtitle="ProductRequirement.table.subtitle" 
            :searchFields="ProductRequirement.table.searchFields"
            :rowData="ProductRequirement.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.ProductRequirement = Models.ProductRequirement;
		let _this = this;

		this.requirements = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.ProductRequirement.wizard.action) {
				await this.addProductRequirement(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("product:view", payload => {
			console.log("Payload", payload);
			this.$router.push({
				name: "Configs-ProductRequirements-ViewProductRequirement",
				params: { id: payload.code }
			});
		});
	},
	data() {
		return {
			ProductRequirement: {},
			requirements: [],
			formData: {},
			showModal: false
		};
	},
	methods: {
      fetchSubClasses() {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "insurance_sub_class",
						action: "list",
						data: {},
						meta_data: {}
					});

               let pages = this.ProductRequirement.wizard.pages,options = [];
               await res.data.result.insurance_sub_classes.forEach(element => {
						options.push({ val: element.code, label: element.name });
               });
               
               pages[0].forms[3].options = options;
					this.ProductRequirement.wizard.pages = pages;
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
      fetchProductRequirements () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "product_requirement",
						action: "list",
						data: {},
						meta_data: {}
					});

               this.requirements = res.data.result.product_requirements
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
		openAddProductRequirementModal() {
			this.showModal = true;
		},
		addProductRequirement(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "product_requirement",
						action: "add",
						data: {
							name: data.name,
							code: data.code,
							sub_class_code: data.sub_class,
							type: data.type,
							label: data.label,
							description: data.description,
						}
					});

               await this.fetchProductRequirements()
					notification.notify("success","Product Requirement Added",`Product Requirement ${data.name} successfully added`);
					Event.$emit("form:action-complete", {action: this.ProductRequirement.wizard.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.ProductRequirement.wizard.action});
				}
			});
		}
	},
	async mounted() {
      await this.fetchSubClasses()
      await this.fetchProductRequirements()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.orange {
}
</style>