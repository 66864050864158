<template lang="html">
  <div class="orange">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <TnCModal
          title="Add Terms and Conditions"
          :showTnCModal="showTnCModal"
        />
      </div>
      <div class="d-none d-md-block">
        <button
          class="btn btn-sm pd-x-15 btn-white btn-uppercase"
          @click="showTnCModal = true"
        >
          Create TnC
        </button>
        <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
								<i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
							</button> -->
      </div>
    </div>

    <div class="card mg-b-10">
      <div class="contents">
        <CustomTable
          :tableFields="TnC.table.table_fields"
          :tableData="tnc"
          :title="TnC.table.title"
          :subtitle="TnC.table.subtitle"
          :searchFields="TnC.table.searchFields"
          :rowData="TnC.table.rowData"
        >
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.TnC = Models.TnC;
    let _this = this;

    Event.$on("tnc:view", (payload) => {
      this.$router.push({
        name: "ViewTnC",
        params: { id: payload.code.system_tnc_ref },
      });
    });
    Event.$on("tncModal:close", async (payload) => {
      console.log("Closing modal");
      this.showTnCModal = false;
    });

    Event.$on("tncModal:submit", async (payload) => {
      console.log("THE PAYLOAD", payload);
      await this.createTnC(payload.tnc);
      this.showTnCModal = false;
    });
  },
  data() {
    return {
      TnC: {},
      showTnCModal: false,
      tnc: [],
    };
  },
  methods: {
    fetchTnC() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_Tnc",
            action: "list",
            data: {},
          });
          console.log("THE TNC", res);
          this.tnc = res.data.result.Tnc;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    createTnC(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "entity_Tnc",
            action: "create",
            data: {
              Tnc_type: payload.type,
            //   Tnc_text: payload.content,
              Tnc_name: payload.name,
              Tnc_upload_path: payload.path,
              Tnc_description: payload.description,
              Tnc_entity_internal_ref: payload.ref,
            },
          });
          console.log("THE TNC created", res);
          this.fetchTnC();
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },

    addTnC() {
      this.showTnCModal = true;
    },
  },
  async mounted() {
    await this.fetchTnC();

    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.editor {
  line-height: normal !important;
  position: relative;
  height: 80vh;
  padding-bottom: 20%; /* of parent width */
}
</style>
