<template>
  <div class="view-user">
    <div class="card mg-b-20 mg-lg-b-25">
      <div
        class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center"
      >
        <h5 class="tx-uppercase tx-bold mg-b-0">View User</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-2">
            <img
              src="/img/placeholder.jpg"
              class="rounded-circle mx-auto d-block mg-t-10"
              style="width: 80px"
            />
          </div>
          <div class="col-md-5">
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.names }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>User Type :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.user_type }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Username :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.username }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.status_code }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Login Attempts :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.login_attempts }}</h6>
              </div>
            </div>

            <div class="media mg-b-20">
              <div class>Login Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.login_status }}</h6>
              </div>
            </div>

            <div class="media mg-b-20">
              <div class>Created By :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.created_by }}</h6>
              </div>
            </div>

            <!-- media -->
          </div>
          <div class="col-md-5">
            <div class="media mg-b-20">
              <div class>Department :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.department_code }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Role :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.role_code }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Email :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.email }}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Contact :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.contact }}</h6>
              </div>
            </div>
            <div class="media mg-b-20">
              <div class>Registration Channel :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.registration_channel }}</h6>
              </div>
            </div>
            <div class="media mg-b-20">
              <div class>Terms and Condition :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.app_tnc }}</h6>
              </div>
            </div>

            <div class="media mg-b-20">
              <div class>Time Zone:</div>
              <div class="media-body pd-l-15">
                <h6 class>{{ user_details.timezone }}</h6>
              </div>
            </div>

            <!-- media -->
          </div>
        </div>
      </div>
      <div
        class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20"
      >
        <nav class="nav nav-with-icon tx-13">
          <button @click="approveUser()" class="btn btn-sm">
            <i data-feather="thumbs-up"></i> Approve
          </button>
          <div
            class="btn-group mg-r-30 mg-l-30"
            role="group"
            aria-label="Basic example"
          >
            <button class="btn btn-sm btn-info" @click="activateUser()">
              <i data-feather="file"></i> Activate
            </button>
            <button @click="deactivateUser()" class="btn btn-sm btn-danger">
              <i data-feather="file"></i> Deactivate
            </button>
          </div>
          <div
            class="btn-group mg-r-30 mg-l-30"
            role="group"
            aria-label="Basic example"
          >
            <button class="btn btn-sm"><i data-feather="edit"></i> Edit</button>
            <button class="btn btn-sm">
              <i data-feather="activity"></i> Reset Password
            </button>
            <button class="btn btn-sm">
              <i data-feather="file"></i> Audit Trail
            </button>
            <button class="btn btn-sm">
              <i data-feather="file"></i> Login History
            </button>
          </div>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_details: {},
    };
  },
  methods: {
    approveUser() {
      console.log('approveUser');
    },
    editUser() {
      console.log('editUser');
    },
    async activateUser() {
      try {
        Event.$emit('loader:show', true);
        let res = await req.callServerSecure({
          resource: 'user',
          action: 'activate',
          data: {
            user_id: this.$route.params.id,
          },
        });

        await this.getUserDetails();
        Event.$emit('loader:show', false);
        notification.notify(
          'success',
          'User succeffully Activated',
          `The user ${this.user_details.email} was successfully Activated`
        );
      } catch (err) {
        notification.notify('Error', 'Error Activating user', err.message);
      }
    },
    async deactivateUser() {
      try {
        Event.$emit('loader:show', true);
        let res = await req.callServerSecure({
          resource: 'user',
          action: 'de-activate',
          data: {
            user_id: this.$route.params.id,
          },
        });

        await this.getUserDetails();
        Event.$emit('loader:show', false);
        notification.notify(
          'success',
          'User succeffully Deactivated',
          `The user ${this.user_details.email} was successfully Deactivated`
        );
      } catch (err) {
        notification.notify('Error', 'Error Deactivating user', err.message);
      }
    },
    getUserDetails() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'user',
            action: 'get',
            data: {
              user_id: this.$route.params.id,
            },
          });
          // alert(res
          console.log(' ==user details === ', res.data.result);
          this.user_details = res.data.result.user_details;

          return resolve();
        } catch (err) {
          return reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.getUserDetails();
    Event.$emit('loader:show', false);
  },
};
</script>

<style></style>
