<template>
  <div class="view-channel">
    <div class="card mg-b-20 mg-lg-b-25">
      <div class="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-center">
        <h5 class="tx-uppercase tx-bold mg-b-0">View Channel</h5>
      </div>
      <!-- card-header -->
      <div class="card-body pd-20 pd-lg-25">
        <div class="row">
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Name :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.channel_name}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Description :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.description}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Thirdparty Provider :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.thirdparty_provider}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Refund Allowed :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.support_refund}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Supported Currency :</div>
              <div class="media-body pd-l-15">
                <h6 v-for="(currency,index) in channel.supported_currency" :key="index">{{currency}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Code :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.channel_code}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Category :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.category}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Account :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.account_number}}</h6>
              </div>
            </div>
            <!-- media -->
            <div class="media mg-b-20">
              <div class>Transaction Limit :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.channel_transaction_limit}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-12">
            <div class="mdia mg-b-20">
              <div class>Other Details :</div>
              <div class="mdia-body d-block pd-t-10">
                <h6
                  v-for="(detail,index) in channel.details"
                  :key="index"
                >{{detail.name}}: {{detail.value}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Created Date :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.created_date}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
          <div class="col-md-6">
            <div class="media mg-b-20">
              <div class>Status :</div>
              <div class="media-body pd-l-15">
                <h6 class>{{channel.status}}</h6>
              </div>
            </div>
            <!-- media -->
          </div>
        </div>
      </div>
      <div class="card-footer bg-transparent pd-y-10 pd-sm-y-15 pd-x-10 pd-sm-x-20">
        <nav class="nav nav-with-icon tx-13">
          <button class="btn btn-sm">
            <i data-feather="edit"></i> Edit
          </button>
          <button class="btn btn-sm mg-l-20">
            <i data-feather="file"></i> Deactivate
          </button>
        </nav>
      </div>
      <!-- card-footer -->
    </div>
    <!-- card -->
  </div>
</template>

<script>
export default {
  created() {
    // console.log(this.$route.params.id);
  },
  data() {
    return {
      channel: {},
    };
  },
  methods: {
    approveUser() {
      console.log("approveUser");
    },
    editUser() {
      console.log("editUser");
    },
    activateUser() {
      console.log("activateUser");
    },
    deactivateUser() {
      console.log("deactivateUser");
    },
    fetchChannel() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "transaction_channel",
            action: "get",
            data: {
              channel_code: this.$route.params.id,
            },
          });
          // alert(res)

          this.channel = res.data.result.transaction_channel_details;

          resolve();
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchChannel();
    Event.$emit("loader:show", false);
  },
};
</script>

<style>
</style>