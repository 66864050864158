<template>
  <div
    class="modal fade"
    id="tncDelete"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel d-block">
            {{ title }}
          </h5>

          <button
            @click="close()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submit()">
          <div class="modal-body">
            <div class="container-fluid">
              <p>
                This action is irreversible.This will delete the
                <span class="text-primary">{{ this.name }}</span> permanently
              </p>
              <div class="form-group">
                <label for="name"
                  >Please type in the name of the terms and conditions to
                  proceed</label
                >

                <input
                  type="text"
                  v-model="confirmName"
                  class="form-control"
                  name="name"
                  id="name"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-secondary text-danger"
              :disabled="this.confirmName !== this.name"
            >
              Delete these terms and conditions
            </button>
            <button
              @click="close()"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {},
  props: {
    showTnCDeleteModal: {
      default: false,
    },
    title: {
      default: "",
    },
    subTitle: {
      default: "",
    },
    name: {
      default: "",
    },
  },
  watch: {
    showTnCDeleteModal: function(to, from) {
      let action = "hide";

      if (to) {
        action = "show";
      }
      $("#tncDelete").modal(action);
    },
  },
  data() {
    return {
      confirmName: "",
    };
  },
  methods: {
    async submit() {
      try {
        let valid = await this.$validator.validate();
        if (!valid) {
          return " Validation Error ";
        } else {
        }
        Event.$emit("tncDeleteModal:submit");
      } catch (err) {
        console.log("error", err);
      }
    },

    close() {
      (this.confirmName = ""), Event.$emit("tncDeleteModal:close", {});
    },
  },
};
</script>

<style>
.option-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 350px;
  height: 100px;
  position: relative;
}
.option {
  flex-grow: 1;
  display: block;
  padding: 0.5em;
  background: #f5f6fa;
  border-radius: 0.25em;
  font-size: 3rem;
  position: relative;
  margin: 0.1em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.8s, 0.6s;
  transition-property: flex-grow, opacity;
  transition-timing-function: cubic-bezier(0.98, 0, 0.22, 0.98), ease-in-out;
}
.option:before,
.option:after {
  content: "";
  display: block;
  border: solid 2px #6f85ff;
  visibility: visible;
}
.option:before {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border-radius: 50%;
  width: 0.25em;
  height: 0.25em;
}
.option:after {
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0.2);
  transition: inherit;
  transition-property: transform, opacity;
}
.option-input {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
}
.option__label {
  display: inline-block;
  font-size: 0.4em;
  font-weight: bold;
  transform-origin: left bottom;
  transform: scale(0.7);
  transition: inherit;
  transition-property: transform, opacity;
}

.option-input:checked + .option {
  flex-grow: 4;
  opacity: 1;
}
.option-input:checked + .option::after {
  transform: scaleX(1);
}
.option-input:checked + .option::before {
  background: #6f85ff;
}
.option-input:checked + .option .option__label {
  transform: scale(1);
}
</style>
