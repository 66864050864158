<template>
	<div class="alistUnderwriter">
	    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
	        <div>
	            <nav aria-label="breadcrumb">
	                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
	                    <li class="breadcrumb-item">
	                        <router-link :to="{name: 'Dashboard'}">
	                            Admin
	                        </router-link>
	                    </li>
	                    <li class="breadcrumb-item active" aria-current="page">Underwriter</li>
	                </ol>
	            </nav>
	            <h4 class="mg-b-0 tx-spacing--1">List Underwriter</h4>

				</div>
				<button
				          type="button"
				          class="btn btn-outline-primary"
				          @click="add()"
				        >Add</button>
	        </div>
			<div class="table-responsive">
			                 <table class="table ml-table ml-responsive-md">
			                    <thead  class="thead-primary">
			                            <tr>
			                         <th scope="col">No.</th>
			                        <th scope="col">License Number</th>
			                        <th scope="col">Name</th>
			                        <th scope="col">Category</th>
									 <th scope="col">Created Date</th>
									  <th scope="col">Profile Status</th>
			                        <th scope="col">Status</th>
									 <th scope="col">Action</th>
			                        </tr>
			                    </thead>
			                    <tbody>
			                        <tr v-for="(item, index) in underwriters">
			                            <th scope="row">{{ index +1 }}</th>
			                            <td>{{ item.license_number }}</td>
			                            <td>{{ item.name }}</td>
			                            <td>{{ item.category }}</td>
			                            <td>{{ item.status }}</td>
			                            
			                        </tr>
			                        
			                 
			                    </tbody>
			                    </table>
			     </div>
			</div> 
	  
	 
</template>

<script>
	export default {
		data () {
		     return {
				   underwriters:[]
				 }
		 },
		 methods: {
		  add() {
		    this.$router.push({ name: 'entity.underwriter.add' })
		 },
		 },
		 async mounted(){
		       Event.$emit('loader:show', false)
		 }
	}
</script>



<style>
</style>