module.exports = {
    name: "Uploaded Banks",
    table: {
        title: "Uploaded Banks",
        subtitle: "Uploaded Banks List",
        table_fields: [
            { name: "Bank Code", align: "r" },
            { name: "Bank Name", align: "c" },
            { name: "Bank Emails", align: "r" },
        ],
        searchFields: ["code", "name"],
        rowData: [
            { type: "text", field: "code", class: "small center" },
            { type: "text", field: "name", class: "small center" },
            { type: "text", field: "emails", class: "small center" },

        ],
    }
};