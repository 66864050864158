module.exports = {
  name: "tran_charges",
  gen_template: `
      {
         "channel": "Mobile Money",
         "code": "{{random.alphaNumeric}}{{random.number}}{{random.alphaNumeric}}{{random.number}}", 
         "name": "{{lorem.word}}"
      }
   `,
  wizard: {
    actionBtn: "Save",
    action: "add_tran_charges",
    pages: [
      {
        title: "Transaction Charges",
        subtitle: "Configure new transaction charges",
        forms: [
          {
            type: "text",
            form_type: "text",
            label: "Name",
            model: "name",
            validation: "required|min:3|max:150",
            class: "col-12",
          },
          {
            type: "text",
            form_type: "text",
            label: "Code",
            model: "code",
            validation: "required|min:2|max:50",
            class: "col-12 col-md-6",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Type",
            model: "type",
            options: [
              { val: "Fixed", label: "Fixed" },
              { val: "Percentage", label: "Percentage" },
              { val: "Slab", label: "Slab" },
            ],
            validation: "required",
          },
          {
            type: "text",
            form_type: "text",
            label: "Value",
            model: "val",
            validation: "required|min:1|max:8|numeric",
            class: "col-12 col-md-6",
          },
          {
            type: "select",
            class: "col-12 col-md-6",
            label: "Charge Account",
            model: "account",
            options: [],
            validation: "required",
          },
          {
            type: "textarea",
            form_type: "text",
            label: "Description",
            model: "description",
            validation: "required|min:10|max:500",
            class: "col-12",
          },
        ],
      },
    ],
  },
  table: {
    title: "Charges",
    subtitle: "A list of transction charges",
    table_fields: [
      { name: "Code", align: "l" },
      { name: "Name", align: "l" },
      { name: "Type", align: "l" },
      { name: "Created Date", align: "l" },
      { name: "Actions", align: "c" },
    ],
    searchFields: ["charge_code", "charge_name", "channel", "currency_code"],
    rowData: [
      { type: "text", field: "charge_code", class: "left bolder small" },
      { type: "text", field: "charge_name", class: "left bolder" },
      { type: "text", field: "type", class: "left bolder small" },
      { type: "text", field: "created_date", class: "left bolder small" },
      {
        type: "action",
        field: "account_number",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Edit",
            event: "client:edit",
            icon: "ico-right",
            params: [{ key: "account", field: "*" }],
          },
          {
            type: "menu",
            title: "View",
            event: "client:view",
            icon: "ico-reply",
            params: [{ key: "account", field: "*" }],
          },
          // {type: 'divider'},
          {
            type: "menu",
            title: "Delete",
            event: "client:delete",
            icon: "ico-mail-a",
            params: [{ key: "account", field: "*" }],
          },
        ],
      },
    ],
  },
};
