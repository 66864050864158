<template lang="html">
   <div class="tempaltes">
      <div class="modal fade classic-modal" 
         id="placeholderModal" 
         data-backdrop="static"
         data-keyboard="false"
         tabindex="-1"
         role="dialog"
         aria-labelledby="placeholderModal" 
         aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <div class="row-contents">
                              <div class="ci ci-info">
                                 <i class="fa fa-group-tie"></i>
                              </div>
                              <div class="text" id="formModalLabel">
                                 <div class="title">Placeholders</div>
                                 <div class="small">Placeholder descriptions.</div>
                              </div>
                              <button type="button" class="close" data-dismiss="modal">
                                 <span aria-hidden="true">&times;</span>
                              </button>
                        </div>
                     </div>
                     <div class="modal-body">
                        <div class="row">
                           <div class="col-12">
                              <div class="placeholder">{name}</div>
                              <div class="text">
                                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur similique dolorem dolores voluptatum recusandae provident quam voluptate nostrum, repudiandae saepe. Provident, corrupti odio veniam repellendus quae ullam cupiditate reiciendis quam.
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="placeholder">{product}</div>
                              <div class="text">
                                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur similique dolorem dolores voluptatum recusandae provident quam voluptate nostrum, repudiandae saepe. Provident, corrupti odio veniam repellendus quae ullam cupiditate reiciendis quam.
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="placeholder">{product.price}</div>
                              <div class="text">
                                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur similique dolorem dolores voluptatum recusandae provident quam voluptate nostrum, repudiandae saepe. Provident, corrupti odio veniam repellendus quae ullam cupiditate reiciendis quam.
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                           Close 
                        </button>
                     </div>
                  </div>
            </div>
      </div>
      <div class="clearfix spacing">
         <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5 right" @click="openPlaceHolderModal()">
            <i data-feather="printer" class="wd-10 mg-r-5"></i> Placeholders
         </button>
         <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5 right" @click="saveDocument()">
            <i data-feather="printer" class="wd-10 mg-r-5"></i> Save
         </button>
      </div>
      <textarea id="image-tools"></textarea>
   </div>
</template>

<script>
   export default {
      async mounted() {
         Event.$emit("loader:show", false);
         tinymce.init({
            selector: 'textarea#image-tools',
            height: 500,
            plugins: [
               "advlist autolink lists link image charmap print preview anchor",
               "searchreplace visualblocks code fullscreen",
               "insertdatetime media table paste imagetools wordcount"
            ],
            toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
            content_css: [
               '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
               '//www.tiny.cloud/css/codepen.min.css'
            ]
         });
      },
      methods: {
         openPlaceHolderModal () {
            $("#placeholderModal").modal("show")
         },

      },
   }
</script>

<style lang="less">
   .tempaltes{
      #image-tools{
         width: 100%;
      }
      .spacing{
         margin: 0 0 20px 0;
      }
      .right{
         float: right;
      }
      .placeholder{
         font-weight: 600;
         font-size: 15px;
         margin: 10px 0 5px 0;
      }
      .text{
         font-size: 13.5px;
      }
   }
</style>