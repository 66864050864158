<template lang="html">
  <div class="users">
    <ModalForm
      :showModal="showModal"
      :action="Bank.wizard.add_super_user.action"
    >
      <template slot="form">
        <CustomForm
          :pages="Bank.wizard.add_super_user.pages"
          :action="Bank.wizard.add_super_user.action"
          :buttonText="Bank.wizard.add_super_user.actionBtn"
          :showTitle="false"
        >
        </CustomForm>
      </template>
    </ModalForm>

    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div></div>
      <div class="d-none d-md-block">
        <button
          class="btn btn-sm pd-x-15 btn-white btn-uppercase"
          @click="createSuperUser()"
        >
          <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Super User
        </button>
      </div>
    </div>

    <CustomTable
      :tableFields="Bank.table.super_user.table_fields"
      :icon="Bank.table.super_user.icon"
      :title="Bank.table.super_user.title"
      :subtitle="Bank.table.super_user.subtitle"
      :tableData="users"
      :searchFields="Bank.table.super_user.searchFields"
      :rowData="Bank.table.super_user.rowData"
    >
    </CustomTable>
  </div>
</template>

<script>
export default {
  props: {
    bank: {
      default: function () {
        return {};
      },
    },
  },
  async created() {
    console.log(this.$route.params.id);
    this.Bank = Models.Bank;
    // this.users = await utils.getDummyData(Models.Bank,3)

    // if specified action is complete clear form data and stop loader
    Event.$on('form:submit', async (payload) => {
      if (payload.action === this.Bank.wizard.add_super_user.action) {
        await this.saveSuperUser(payload.formData);
        this.showModal = false;
      }
    });

    Event.$on('modal:close', async (payload) => {
      this.showModal = false;
    });

    // Event.$on('client:view',(payload) => {})
  },
  data() {
    return {
      Bank: {},
      users: [],
      showModal: false,
    };
  },
  methods: {
    createSuperUser() {
      this.showModal = true;
    },
    fetchSuperUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'bank_employee',
            action: 'list',
            data: {
              code: this.$route.params.id,
            },
            meta_data: {},
          });

          this.users = res.data.result.bank_employees;
          resolve(true);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    saveSuperUser(formData) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'bank_employee',
            action: 'add',
            data: {
              code: this.$route.params.id,
              email: formData.email,
              employee_ref: formData.employee_ref,
              contact: `${formData.mobile_number.country_code.callingCodes[0]}:${formData.mobile_number.number}`,
              department: 'IT',
              names: formData.first_name + ' ' + formData.last_name,
            },
            meta_data: {},
          });

          await this.fetchSuperUsers();
          notification.notify(
            'success',
            'Super User Created',
            `${formData.email} has been successfully added`
          );
          Event.$emit('form:action-complete', {
            action: this.Bank.wizard.add_super_user.action,
            clear: true,
          });
          this.showAddSuperUser = false;
          resolve(true);
        } catch (err) {
          Event.$emit('form:action-error', {
            action: this.Bank.wizard.add_super_user.action,
          });
          console.log(err);
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchSuperUsers();
  },
};
</script>

<style lang="less">
.users {
}
</style>
