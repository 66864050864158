module.exports = {
    name: 'Service Categories',

    wizard: {
       actionBtn: 'Save',
       action: 'add_client_type',
       pages: [
          {
             title: 'Service Categories',
             subtitle: 'Configure new Service Categories',
             forms: [
                {
                   type: 'image', class: 'col-12 col-md-12 ', model: 'icon',
                   imageFormat: 'png', validation: 'required', label: 'Icon'
                },
                {
                   type: 'text', form_type: 'text', label: 'Code', model: 'type_code',
                   validation: 'required|min:1|max:50', class: 'col-12 col-md-6'
                },
                {
                   type: 'text', form_type: 'text', label: 'Name', model: 'type_name',
                   validation: 'required|min:3|max:150', class: 'col-12 col-md-6'
                },
                {
                   type: 'textarea', form_type: 'text', label: 'Description', model: 'type_description',
                   validation: 'required|min:10|max:500', class: 'col-12'
                },
             ],
          }
       ]
    },
    table: {
       title: 'Service Categories',
       subtitle: 'A list of configured insurance service Categories',
       table_fields: [
   
          { name: 'Code', align: 'l' },
          { name: 'Name', align: 'l' },
          { name: 'Description', align: 'l' },  
          { name: 'actions', align: 'c' }
       ],
       searchFields: ["category_code", "category_name"],
       rowData: [
 
          { type: 'text', field: 'category_code', class: 'left bolder' },
          { type: 'text', field: 'category_name', class: 'left bolder small' },
          { type: 'text', field: 'category_description', class: 'left bolder' },
          {
             type: 'action', field: 'name', class: 'center', icon: '', actions: [
                // {type: 'title', title: 'Actions'},
             
                {
                   type: 'menu', title: 'View', event: 'servicecategory:view', icon: 'ico-reply', params: [
                      { key: 'service_category', field: '*' }
                   ]
                },
               
             ]
          },
       ]
    },
 }