module.exports = {
    name: "SIC Industry Upload",
    table: {
        title: "SIC Industry  Upload",
        subtitle: "SIC Industry  Upload List",
        table_fields: [
            { name: "Code", align: "r" },
            { name: "Name", align: "c" },
        ],
        searchFields: ["code", "name"],
        rowData: [
            { type: "text", field: "sic_code", class: "small center" },
            { type: "text", field: "sic_name", class: "small center" }

        ],
    }
};