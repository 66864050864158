module.exports = {
    name: "Terms and Conditions",
    table: {
        title: "Terms and Conditions",
        subtitle: "",
        table_fields: [
            { name: "Internal ref", align: "c" },
            { name: "Name", align: "c" },
            { name: "Type", align: "c" },
            { name: "Created Date", align: "c" },
            { name: "Status", align: "c" },

            { name: "Actions", align: "c" },
        ],
        searchFields: ["Tnc_entity_internal_ref", "Tnc_name", "Tnc_type", "created_date", "status"],
        rowData: [
            { type: "text", field: "Tnc_entity_internal_ref", class: "center" },
            { type: "text", field: "Tnc_name", class: " center" },
            { type: "text", field: "Tnc_type", class: " center" },
            { type: "date", field: "created_date", class: " " },
            { type: "status", field: "status", class: "" },
            {
                type: "action",
                field: "name",
                class: "center",
                icon: "",
                actions: [
                    {
                        type: "menu",
                        title: "View",
                        event: "tnc:view",
                        icon: "ico-reply",
                        params: [{ key: "code", field: "*" }],
                    },
                    // {type: 'divider'},
                ],
            },
        ],
    },
};
